import { Routes } from '@angular/router';

import { FormGuardGuard } from './services/guard.service';
import { FormGuardGuardProduts } from './services/guard-produts.service';
import { NotfoundComponent } from './www/notfound/notfound.component';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./www/www.component').then(m => m.routes),
    data: {
      title: 'Pillow pojišťovna.',
      description: 'Férové a srozumitelné pojištění, bez háčků a nesmyslných výluk. Zkuste pojištění vozidel s férovou cenou dle ujetých kilometrů.',
      titleEn: 'Pillow insurance.',
      descriptionEn: 'Fair and easy to understand, contains no catch 22 whatsoever. Try out our car insurance, you only pay for the kilometers you actually travel.'
    }
  },
  {
    path: 'login',
    loadComponent: () => import('./apps/login/login.component').then(m => m.LoginComponent),
    data: {
      title: 'Pillow pojišťovna.',
      description: 'Všechny informace o vašem pojištění na jednom místě. Na jedno kliknutí. Přehledně a srozumitelně.',
      titleEn: 'Pillow insurance company.',
      descriptionEn: 'All information about your insurance in one place. In one click. Clear and understandable.'
    }
  },
  {
    path: 'nahrat_kilometry',
    loadChildren: () => import('./apps/odometer/odometer.component').then(m => m.routes)
  },
  {
    path: 'email',
    redirectTo: '/kontakty#contactEmailId'
  },
  {
    path: 'verifyemail',
    loadComponent: () => import('./apps/extVerifyEmail/verifyEmail.component').then(m => m.VerifyEmailComponent)
  },
  {
    path: 'registration',
    loadChildren: () => import('./apps/registration/registration.component').then(m => m.routes)
  },
  {
    path: 'lost-login',
    loadChildren: () => import('./apps/lost-login/lost-login.component').then(m => m.routes)
  },
  {
    path: 'lost-password',
    loadChildren: () => import('./apps/lost-password/lost-password.component').then(m => m.routes)
  },
  {
    path: 'poprve',
    redirectTo: '/registration/step2?type=agent', pathMatch: 'full'
  },
  {
    path: 'vzniky',
    redirectTo: '/kalkulace', pathMatch: 'full'
  },
  {
    path: 'calculations',
    redirectTo: '/kalkulace', pathMatch: 'full'
  },
  {
    path: 'ap1',
    loadChildren: () => import('./apps/ap1/ap1.component').then(m => m.routes),
    canActivate: [FormGuardGuardProduts],
  },
  {
    path: 'rp1/:var',
    loadChildren: () => import('./apps/rp1/router-rp1.component').then(m => m.routes),
    canActivate: [FormGuardGuardProduts],
  },
  {
    path: 'pm1',
    loadComponent: () => import('./apps/pm1/main/main.component').then(m => m.Pm1MainComponent),
    canActivate: [FormGuardGuardProduts],
  },
  {
    path: 'workflow',
    loadChildren: () => import('./apps/workflow/workflow.component').then(m => m.routes),
    canActivate: [FormGuardGuard],
  },
  {
    path: 'portal-home',
    loadComponent: () => import('./apps/agent-home/agent-home.component').then((m) => m.AgentHomeComponent),
    canActivate: [FormGuardGuard],
  },
  {
    path: 'portal-calculation',
    loadComponent: () => import('./apps/agent-calculation/agent-calculation.component').then(m => m.AgentCalculationComponent),
    canActivate: [FormGuardGuard],
  },
  {
    path: 'portal-policies',
    loadChildren: () => import('./apps/agent-policies/agent-policies.component').then(m => m.routes),
    canActivate: [FormGuardGuard],
  },
  {
    path: 'portal-clients',
    loadChildren: () => import('./apps/agent-clients/router-agent-clients.component').then(m => m.routes),
    canActivate: [FormGuardGuard],
  },
  {
    path: 'portal-chat',
    loadChildren: () => import('./apps/agent-chat/router-agent-chat.component').then(m => m.routes),
    canActivate: [FormGuardGuard],
  },
  {
    path: 'user-info',
    loadChildren: () => import('./apps/user-info/user-info.component').then((m) => m.routes),
    canActivate: [FormGuardGuard],
  },
  {
    path: 'smlouvy',
    loadChildren: () => import('./apps/smlouvy/router-smlouvy.component').then((m) => m.routes),
    canActivate: [FormGuardGuard],
  },
  {
    path: 'konto',
    loadChildren: () => import('./apps/konto/router-konto.component').then((m) => m.routes),
    canActivate: [FormGuardGuard],
  },
  {
    path: 'portal-changes',
    loadComponent: () => import('./apps/agent-changes/agent-changes.component').then((m) => m.AgentChangesComponent),
    canActivate: [FormGuardGuard],
  },
  {
    path: 'approve',
    loadComponent: () => import('./modules/policy-cancel/policy-cancel-approve/policy-cancel-approve.component').then(
      (m) => m.PolicyCancelApproveComponent
    ),
    canActivate: [FormGuardGuard],
  },
  {
    path: 'policy-change',
    loadComponent: () => import('./modules/parent/parent.component').then((m) => m.ParentComponent),
    canActivate: [FormGuardGuard],
  },
  {
    path: 'pay',
    loadComponent: () => import('./apps/pay/pay.component').then((m) => m.PayComponent)
  },
  {
    path: 'ap2',
    loadComponent: () => import('./apps/ap2/main/main.component').then((m) => m.MainComponent),
    canActivate: [FormGuardGuardProduts]
  },
  {
    path: '**',
    component: NotfoundComponent
    // redirectTo: '/notfound', pathMatch: 'full'
  },
];
