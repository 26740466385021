export const links = {
    '/assets/docs/Prirucka_pojisteni_vozidel.pdf': 'https://dokumenty.pillow.cz/Pojisteni_vozidel/Pillow_Vozidla_Podminky_pojisteni.pdf',
    '/assets/docs/P%C5%99%C3%ADru%C4%8Dka%20poji%C5%A1t%C4%9Bn%C3%AD%20vozidel.pdf': 'https://dokumenty.pillow.cz/Pojisteni_vozidel/Pillow_Vozidla_Podminky_pojisteni.pdf',
    '/assets/docs/IPID_Auto.pdf': 'https://dokumenty.pillow.cz/Pojisteni_vozidel/Pillow_Vozidla_IPID.pdf',
    '/assets/docs/IPID%20Auto.pdf': 'https://dokumenty.pillow.cz/Pojisteni_vozidel/Pillow_Vozidla_IPID.pdf',
    '/assets/docs/Seznam-smluvnich-servisu.pdf': '`https://dokumenty.pillow.cz/Pojisteni_vozidel/Pillow_Vozidla_Seznam_smluvnich_servisu.pdf`',
    '/assets/docs/Seznam%20smluvn%C3%ADch%20servis%C5%AF.pdf': 'https://dokumenty.pillow.cz/Pojisteni_vozidel/Pillow_Vozidla_Seznam_smluvnich_servisu.pdf',
    '/assets/docs/Pillow_Nahradni_vozidlo.pdf': 'https://dokumenty.pillow.cz/Pojisteni_vozidel/Pillow_Vozidla_Nahradni_vozidlo.pdf',
    '/assets/docs/Zaznam_o_nehode.pdf': 'https://dokumenty.pillow.cz/Pojisteni_vozidel/Pillow_Vozidla_Zaznam_o_nehode.pdf',
    '/assets/docs/pm1/Pillow_PP_Majetek.pdf': 'https://dokumenty.pillow.cz/Pojisteni_majetku_a_odpovednosti/Pillow_Majetek_a_odpovednost_Podminky_pojisteni.pdf',
    '/assets/docs/pm1/Pillow_IPID_Majetek.pdf': 'https://dokumenty.pillow.cz/Pojisteni_majetku_a_odpovednosti/Pillow_Majetek_a_odpovednost_IPID.pdf',
    '/assets/docs/Valorizace_pojistnych_castek.pdf': 'https://dokumenty.pillow.cz/Pojisteni_majetku_a_odpovednosti/Pillow_Majetek_a_odpovednost_Valorizace_pojistnych_castek.pdf',
    '/assets/docs/Pillow_PP_UrazNemoc_dospely.pdf': 'https://dokumenty.pillow.cz/Pojisteni_urazu_a_nemoci/Pillow_Uraz_a_nemoc_Podminky_pojisteni_dospely.pdf',
    '/assets/docs/Pillow_PP_UrazNemoc_nezletily.pdf': 'https://dokumenty.pillow.cz/Pojisteni_urazu_a_nemoci/Pillow_Uraz_a_nemoc_Podminky_pojisteni_nezletily.pdf',
    '/assets/docs/Pillow_IPID_UrazNemoc.pdf': 'https://dokumenty.pillow.cz/Pojisteni_urazu_a_nemoci/Pillow_Uraz_a_nemoc_IPID.pdf',
    '/assets/docs/Pillow_Sporty_UrazNemoc.pdf': 'https://dokumenty.pillow.cz/Pojisteni_urazu_a_nemoci/Pillow_Uraz_a_nemoc_Tabulka_sporty.pdf',
    '/assets/docs/rp1/Pillow_Sporty_UrazNemoc.pdf': 'https://dokumenty.pillow.cz/Pojisteni_urazu_a_nemoci/Pillow_Uraz_a_nemoc_Tabulka_sporty.pdf',
    '/assets/docs/Pillow_OT_UrazNemoc.pdf': 'https://dokumenty.pillow.cz/Pojisteni_urazu_a_nemoci/Pillow_Uraz_a_nemoc_Ocenovaci_tabulky.pdf',
    '/assets/docs/Pillow_Prislib_UrazNemoc.pdf': 'https://dokumenty.pillow.cz/Pojisteni_urazu_a_nemoci/Pillow_Uraz_a_nemoc_Prislib_1.pdf',
    '/assets/docs/Ochrana_osobnich_udaju.pdf': 'https://dokumenty.pillow.cz/Soukromi/Pillow_Ochrana_osobnich_udaju.pdf',
    '/assets/docs/Ochrana_osobnich_udaju_partneri.pdf': 'https://dokumenty.pillow.cz/Soukromi/Pillow_Ochrana_osobnich_udaju_partneri.pdf',
    '/assets/docs/Ciselne_udaje_Q1_2020.xlsx': 'https://blog.mypillow.cz/wp-content/uploads/2021/11/Ciselne_udaje_Q1_2020.xlsx',
    '/assets/docs/Ciselne_udaje_Q1_2021.xlsx': 'https://blog.mypillow.cz/wp-content/uploads/2021/11/Ciselne_udaje_Q1_2021.xlsx',
    '/assets/docs/Ciselne_udaje_Q2_2020.xlsx': 'https://blog.mypillow.cz/wp-content/uploads/2021/11/Ciselne_udaje_Q2_2020.xlsx',
    '/assets/docs/Ciselne_udaje_Q2_2021.xlsx': 'https://blog.mypillow.cz/wp-content/uploads/2021/11/Ciselne_udaje_Q2_2021.xlsx',
    '/assets/docs/Ciselne_udaje_Q3_2019.xlsx': 'https://blog.mypillow.cz/wp-content/uploads/2021/11/Ciselne_udaje_Q3_2019.xlsx',
    '/assets/docs/Ciselne_udaje_Q3_2020.xlsx': 'https://blog.mypillow.cz/wp-content/uploads/2021/11/Ciselne_udaje_Q3_2020.xlsx',
    '/assets/docs/Ciselne_udaje_Q4_2019.xlsx': 'https://blog.mypillow.cz/wp-content/uploads/2021/11/Ciselne_udaje_Q4_2019.xlsx',
    '/assets/docs/Ciselne_udaje_Q4_2020.xlsx': 'https://blog.mypillow.cz/wp-content/uploads/2021/11/Ciselne_udaje_Q4_2020.xlsx',
    '/assets/docs/Ctvrtletni_zprava_1Q_2021.pdf': 'https://blog.mypillow.cz/wp-content/uploads/2021/11/Ctvrtletni_zprava_1Q_2021.pdf',
    '/assets/docs/Ctvrtletni_zprava_2Q_2021.pdf': 'https://blog.mypillow.cz/wp-content/uploads/2021/11/Ctvrtletni_zprava_2Q_2021.pdf',
    '/assets/docs/Ctvrtletni_zprava_3Q_2020.pdf': 'https://blog.mypillow.cz/wp-content/uploads/2021/11/Ctvrtletni_zprava_3Q_2020.pdf',
    '/assets/docs/Ctvrtletni_zprava_Q1_2020.pdf': 'https://blog.mypillow.cz/wp-content/uploads/2021/11/Ctvrtletni_zprava_Q1_2020.pdf',
    '/assets/docs/Ctvrtletni_zprava_Q2_2020.pdf': 'https://blog.mypillow.cz/wp-content/uploads/2021/11/Ctvrtletni_zprava_Q2_2020.pdf',
    '/assets/docs/Vyrocni_zprava_2015.pdf': 'https://blog.mypillow.cz/wp-content/uploads/2021/11/Vyrocni_zprava_2015.pdf',
    '/assets/docs/Výroční%20zpráva%202015.pdf': 'https://blog.mypillow.cz/wp-content/uploads/2021/11/Vyrocni_zprava_2015.pdf',
    '/assets/docs/Vyrocni_zprava_2016.pdf': 'https://blog.mypillow.cz/wp-content/uploads/2021/11/Vyrocni_zprava_2016.pdf',
    '/assets/docs/Výroční%20zpráva%202016.pdf': 'https://blog.mypillow.cz/wp-content/uploads/2021/11/Vyrocni_zprava_2016.pdf',
    '/assets/docs/Vyrocni_zprava_2017.pdf': 'https://blog.mypillow.cz/wp-content/uploads/2021/11/Vyrocni_zprava_2017.pdf',
    '/assets/docs/Výroční%20zpráva%202017.pdf': 'https://blog.mypillow.cz/wp-content/uploads/2021/11/Vyrocni_zprava_2017.pdf',
    '/assets/docs/Vyrocni_zprava_2018.pdf': 'https://blog.mypillow.cz/wp-content/uploads/2021/11/Vyrocni_zprava_2018.pdf',
    '/assets/docs/Výroční%20zpráva%202018.pdf': 'https://blog.mypillow.cz/wp-content/uploads/2021/11/Vyrocni_zprava_2018.pdf',
    '/assets/docs/Vyrocni_zprava_2019.pdf': 'https://blog.mypillow.cz/wp-content/uploads/2021/11/Vyrocni_zprava_2019.pdf',
    '/assets/docs/Vyrocni_zprava_2020.pdf': 'https://blog.mypillow.cz/wp-content/uploads/2021/11/Vyrocni_zprava_2020.pdf',
    '/assets/docs/V%C3%BDbor%20pro%20audit.pdf': 'https://blog.mypillow.cz/wp-content/uploads/2021/11/Vybor-pro-audit.pdf',
    '/assets/docs/Zprava_o_solventnosti_a_financni_situaci_2016.pdf': 'https://blog.mypillow.cz/wp-content/uploads/2021/11/Zprava_o_solventnosti_a_financni_situaci_2016.pdf',
    '/assets/docs/Zprava_o_solventnosti_a_financni_situaci_2017.pdf': 'https://blog.mypillow.cz/wp-content/uploads/2021/11/Zprava_o_solventnosti_a_financni_situaci_2017.pdf',
    '/assets/docs/Zpráva%20o%20solventnosti%20a%20finanční%20situaci%202017.pdf': 'https://blog.mypillow.cz/wp-content/uploads/2021/11/Zprava_o_solventnosti_a_financni_situaci_2017.pdf',
    '/assets/docs/Zprava_o_solventnosti_a_financni_situaci_2018.pdf': 'https://blog.mypillow.cz/wp-content/uploads/2021/11/Zprava_o_solventnosti_a_financni_situaci_2018.pdf',
    '/assets/docs/Zpráva%20o%20solventnosti%20a%20finanční%20situaci%202018.pdf': 'https://blog.mypillow.cz/wp-content/uploads/2021/11/Zprava_o_solventnosti_a_financni_situaci_2018.pdf',
    '/assets/docs/Zprava_o_solventnosti_a_financni_situaci_2019.pdf': 'https://blog.mypillow.cz/wp-content/uploads/2021/11/Zprava_o_solventnosti_a_financni_situaci_2019.pdf',
    '/assets/docs/Zpráva%20o%20solventnosti%20a%20finanční%20situaci%202019.pdf': 'https://blog.mypillow.cz/wp-content/uploads/2021/11/Zprava_o_solventnosti_a_financni_situaci_2019.pdf',
    '/assets/docs/Zprava_o_solventnosti_a_financni_situaci_2020.pdf': 'https://blog.mypillow.cz/wp-content/uploads/2021/11/Zprava_o_solventnosti_a_financni_situaci_2020.pdf',
    '/assets/docs/%C4%8Ctvrtletn%C3%AD%20zpr%C3%A1va%20Q1%202016.pdf': 'https://blog.mypillow.cz/wp-content/uploads/2021/11/Ctvrtletni-zprava-Q1-2016.pdf',
    '/assets/docs/%C4%8Ctvrtletn%C3%AD%20zpr%C3%A1va%20Q1%202017.pdf': 'https://blog.mypillow.cz/wp-content/uploads/2021/11/Ctvrtletni-zprava-Q1-2017.pdf',
    '/assets/docs/%C4%8Ctvrtletn%C3%AD%20zpr%C3%A1va%20Q1%202018.pdf': 'https://blog.mypillow.cz/wp-content/uploads/2021/11/Ctvrtletni-zprava-Q1-2018.pdf',
    '/assets/docs/%C4%8Ctvrtletn%C3%AD%20zpr%C3%A1va%20Q1%202019.pdf': 'https://blog.mypillow.cz/wp-content/uploads/2021/11/Ctvrtletni-zprava-Q1-2019.pdf',
    '/assets/docs/%C4%8Ctvrtletn%C3%AD%20zpr%C3%A1va%20Q2%202016.pdf': 'https://blog.mypillow.cz/wp-content/uploads/2021/11/Ctvrtletni-zprava-Q2-2016.pdf',
    '/assets/docs/%C4%8Ctvrtletn%C3%AD%20zpr%C3%A1va%20Q2%202017.pdf': 'https://blog.mypillow.cz/wp-content/uploads/2021/11/Ctvrtletni-zprava-Q2-2017.pdf',
    '/assets/docs/%C4%8Ctvrtletn%C3%AD%20zpr%C3%A1va%20Q2%202018.pdf': 'https://blog.mypillow.cz/wp-content/uploads/2021/11/Ctvrtletni-zprava-Q2-2018.pdf',
    '/assets/docs/%C4%8Ctvrtletn%C3%AD%20zpr%C3%A1va%20Q2%202019.pdf': 'https://blog.mypillow.cz/wp-content/uploads/2021/11/Ctvrtletni-zprava-Q2-2019.pdf',
    '/assets/docs/%C4%8Ctvrtletn%C3%AD%20zpr%C3%A1va%20Q3%202015.pdf': 'https://blog.mypillow.cz/wp-content/uploads/2021/11/Ctvrtletni-zprava-Q3-2015.pdf',
    '/assets/docs/%C4%8Ctvrtletn%C3%AD%20zpr%C3%A1va%20Q3%202016.pdf': 'https://blog.mypillow.cz/wp-content/uploads/2021/11/Ctvrtletni-zprava-Q3-2016.pdf',
    '/assets/docs/%C4%8Ctvrtletn%C3%AD%20zpr%C3%A1va%20Q3%202017.pdf': 'https://blog.mypillow.cz/wp-content/uploads/2021/11/Ctvrtletni-zprava-Q3-2017.pdf',
    '/assets/docs/%C4%8Ctvrtletn%C3%AD%20zpr%C3%A1va%20Q3%202018.pdf': 'https://blog.mypillow.cz/wp-content/uploads/2021/11/Ctvrtletni-zprava-Q3-2018.pdf',
    '/assets/docs/%C4%8Ctvrtletn%C3%AD%20zpr%C3%A1va%20Q3%202019.pdf': 'https://blog.mypillow.cz/wp-content/uploads/2021/11/Ctvrtletni-zprava-Q3-2019.pdf',
    '/assets/docs/%C4%8C%C3%ADseln%C3%A9%20%C3%BAdaje%20Q1%202017.xlsx': 'https://blog.mypillow.cz/wp-content/uploads/2021/11/Ciselne-udaje-Q1-2017.xlsx',
    '/assets/docs/%C4%8C%C3%ADseln%C3%A9%20%C3%BAdaje%20Q1%202018.xlsx': 'https://blog.mypillow.cz/wp-content/uploads/2021/11/Ciselne-udaje-Q1-2018.xlsx',
    '/assets/docs/%C4%8C%C3%ADseln%C3%A9%20%C3%BAdaje%20Q1%202019.xlsx': 'https://blog.mypillow.cz/wp-content/uploads/2021/11/Ciselne-udaje-Q1-2019.xlsx',
    '/assets/docs/%C4%8C%C3%ADseln%C3%A9%20%C3%BAdaje%20Q2%202017.xlsx': 'https://blog.mypillow.cz/wp-content/uploads/2021/11/Ciselne-udaje-Q2-2017.xlsx',
    '/assets/docs/%C4%8C%C3%ADseln%C3%A9%20%C3%BAdaje%20Q2%202018.xlsx': 'https://blog.mypillow.cz/wp-content/uploads/2021/11/Ciselne-udaje-Q2-2018.xlsx',
    '/assets/docs/%C4%8C%C3%ADseln%C3%A9%20%C3%BAdaje%20Q2%202019.xlsx': 'https://blog.mypillow.cz/wp-content/uploads/2021/11/Ciselne-udaje-Q2-2019.xlsx',
    '/assets/docs/%C4%8C%C3%ADseln%C3%A9%20%C3%BAdaje%20Q3%202016.xlsx': 'https://blog.mypillow.cz/wp-content/uploads/2021/11/Ciselne-udaje-Q3-2016.xlsx',
    '/assets/docs/%C4%8C%C3%ADseln%C3%A9%20%C3%BAdaje%20Q3%202017.xlsx': 'https://blog.mypillow.cz/wp-content/uploads/2021/11/Ciselne-udaje-Q3-2017.xlsx',
    '/assets/docs/%C4%8C%C3%ADseln%C3%A9%20%C3%BAdaje%20Q3%202018.xlsx': 'https://blog.mypillow.cz/wp-content/uploads/2021/11/Ciselne-udaje-Q3-2018.xlsx',
    '/assets/docs/%C4%8C%C3%ADseln%C3%A9%20%C3%BAdaje%20Q3%202019.xlsx': 'https://blog.mypillow.cz/wp-content/uploads/2021/11/Ciselne-udaje-Q3-2019.xlsx',
    '/assets/docs/%C4%8C%C3%ADseln%C3%A9%20%C3%BAdaje%20Q4%202016.xlsx': 'https://blog.mypillow.cz/wp-content/uploads/2021/11/Ciselne-udaje-Q4-2016.xlsx',
    '/assets/docs/%C4%8C%C3%ADseln%C3%A9%20%C3%BAdaje%20Q4%202017.xlsx': 'https://blog.mypillow.cz/wp-content/uploads/2021/11/Ciselne-udaje-Q4-2017.xlsx',
    '/assets/docs/%C4%8C%C3%ADseln%C3%A9%20%C3%BAdaje%20Q4%202018.xlsx': 'https://blog.mypillow.cz/wp-content/uploads/2021/11/Ciselne-udaje-Q4-2018.xlsx',
    '/assets/docs/Prirucka_pojisteni_vozidel_asistence.pdf': ' https://dokumenty.pillow.cz/Pojisteni_vozidel/Pillow_Vozidla_Asistence.pdf',
    '/assets/docs/pm1/Pillow_PM_asistence.pdf': 'https://dokumenty.pillow.cz/Pojisteni_majetku_a_odpovednosti/Pillow_Majetek_a_odpovednost_Asistence.pdf',
    '/assets/docs/pm1/Pillow_PM_odpovednost_pro_bezny_zivot.pdf': 'https://dokumenty.pillow.cz/Pojisteni_majetku_a_odpovednosti/Pillow_Majetek_a_odpovednost_Odpovednost_pro_bezny_zivot.pdf',
    '/assets/docs/pm1/Pillow_PM_odpovednost_k_nemovitosti.pdf': 'https://dokumenty.pillow.cz/Pojisteni_majetku_a_odpovednosti/Pillow_Majetek_a_odpovednost_Odpovednost_k_nemovitosti.pdf',
    '/assets/docs/logo.zip': 'https://dokumenty.pillow.cz/Press/Pillow_Logo.zip',
    '/assets/docs/Polštáře.zip': 'https://dokumenty.pillow.cz/Press/Pillow_Polstare.zip',
    '/assets/docs/Zaměstnanci.zip': 'https://dokumenty.pillow.cz/Press/Pillow_Zamestnanci.zip',
};
