import { TsXmlParAp1VariantOfSubjectResp } from 'src/app/tsXml/tsXmlParAp1VariantOfSubjectResp.model';
import { TsXmlParAp1VariantOfSubjectReq } from 'src/app/tsXml/tsXmlParAp1VariantOfSubjectReq.model';

export class Variant {
  index: number;

  complicity: string;
  gap: boolean;
  pr1: string;
  sk1: boolean;
  hp1: boolean;
  sz1: boolean;
  zv1: boolean;
  ov1: boolean;
  va1: boolean;
  pl1: boolean;
  pa1: boolean;
  as1: string;
  za1: string;
  ur1: string;

  selected: boolean;

  monthPremium: number;
  termPremium: number;
  kmPremium: number;

  variantOfSubject: TsXmlParAp1VariantOfSubjectResp[];

  constructor(index: number) {
    this.index = index;
  }

  toTsXmlParAp1VariantOfSubjectReq() {
    const req: TsXmlParAp1VariantOfSubjectReq[] = [];

    for (const resp of this.variantOfSubject) {
      const r = new TsXmlParAp1VariantOfSubjectReq();
      r.risk = resp.risk;
      r.variant = resp.variant;
      req.push(r);
    }

    return req;
  }
}
