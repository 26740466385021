import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogTitle, MatDialogClose } from '@angular/material/dialog';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-dialog-select',
    templateUrl: './dialog-select.component.html',
    styleUrls: ['./dialog-select.component.scss'],
    imports: [NgIf, MatDialogTitle, NgFor, MatDialogClose]
})
export class DialogSelectComponent {

  constructor(
    private dialogRef: MatDialogRef<DialogSelectComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      selects: string[],
      header?: string,
      message?: string,
    }
  ) { }

  _htmlFunCloseDialog() {
    this.dialogRef.close();
  }

}
