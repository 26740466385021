<div class="dialog-container">
    <button mat-button mat-dialog-close mat-dialog-close="true" class="close-bu">
        <span class="pilic icon-krizek"></span>
    </button>
    <mat-dialog-content class="center-text">
        <div class="flexed" [ngClass]="{ column: !!data.imgButtom }">
            <i *ngIf="data.icon" class="pilic pilic-94 {{ data.icon }}"></i>
            <img *ngIf="data.img" [src]="data.img" [ngClass]="{ hued: data.hued }" alt="tooltip image" />
            <div class="texts">
                <h3 *ngIf="data.headline">{{ data.headline }}</h3>
                @if (data.hybrid) {
                    @for (item of data.hybrid; track $index) {
                        @if (item.type === "string") {
                            @if (_htmlFunIsString(item.value)) {
                                <p>{{ item.value }}</p>
                            } @else {
                                <div
                                    class="u-button-shadowless"
                                    (click)="_htmlFunGo2Link($any(item.value).link)"
                                    tabindex="0"
                                    (keydown.enter)="_htmlFunGo2Link($any(item.value).link)">
                                    <span class="color-orange">{{ $any(item.value).text }}</span>
                                </div>
                            }
                        } @else {
                            <ul>
                                @for (fuck of item.value; track $index) {
                                    <li>
                                        <span>{{ fuck }}</span>
                                    </li>
                                }
                            </ul>
                        }
                    }
                } @else {
                    <p *ngIf="!_textIsArray && !lines?.length" [innerHtml]="data.text"></p>
                    <p *ngIf="_textIsArray">
                        <ng-container *ngFor="let txt of data.text">
                            <ng-container *ngIf="_htmlFunIsString(txt)">{{ txt }}</ng-container>
                            <div
                                *ngIf="!_htmlFunIsString(txt)"
                                class="u-button-shadowless"
                                (click)="_htmlFunGo2Link($any(txt).link)"
                                tabindex="0"
                                (keydown.enter)="_htmlFunGo2Link($any(txt).link)">
                                <span class="color-orange">{{ $any(txt).text }}</span>
                            </div>
                        </ng-container>
                    </p>
                    <ul>
                        <li *ngFor="let line of lines">
                            @if (_htmlFunIsString($any(line))) {
                                <span [innerHTML]="line"></span>
                            } @else {
                                @for (item of line; track $index) {
                                    @if (_htmlFunIsString($any(item))) {
                                        <span [innerHTML]="item"></span>
                                    } @else {
                                        <div
                                            class="u-button-shadowless"
                                            (click)="_htmlFunGo2Link($any(item).link)"
                                            tabindex="0"
                                            (keydown.enter)="_htmlFunGo2Link($any(item).link)">
                                            <span class="color-orange">{{ $any(item).text }}</span>
                                        </div>
                                    }
                                }
                            }
                        </li>
                    </ul>
                }
            </div>
            <img
                class="img2"
                [ngClass]="{ hued: data.hued }"
                *ngIf="data.imgButtom"
                [src]="data.imgButtom"
                alt="img button" />
        </div>
    </mat-dialog-content>
</div>
