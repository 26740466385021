import { Injectable } from '@angular/core';
import { TDate } from '../models/date.model';
import { TsXmlParParty } from '../tsXml/tsXmlParParty.model';

@Injectable({
  providedIn: 'root'
})
export class OtherService {

  /**
   * @param initialDate date to be moved
   * @param years number of years to move date
   * @param months number of months to move date
   * @param days number of days to move date
   * @returns moved TDate
   */
  public getDateChangedBy(initialDate: TDate | Date, years?: number, months?: number, days?: number): TDate {
    if (!initialDate) return;
    if (typeof initialDate === 'string') initialDate = new Date(initialDate);
    // eslint-disable-next-line prefer-const
    let [_month, _date, _year] = initialDate.toLocaleDateString('en-US').split('/');
    if (!years) years = 0;
    if (!months) months = 0;
    if (!days) days = 0;
    if (days === 0) {
      const lastDayOfMonth = new Date(+_year + years, +_month - 1 + months + 1, 0).getDate();
      if (+_date > lastDayOfMonth)
        _date = lastDayOfMonth as any;
    }

    return new TDate(new Date(+_year + years, +_month - 1 + months, +_date + days));
  }

  public getYears(birthDate: TDate, now: TDate): number {
    const _years = now.getFullYear() - birthDate.getFullYear();
    const _notFull = new Date(now.getFullYear(), birthDate.getMonth(), birthDate.getDate()) > now;
    return _notFull ? _years - 1 : _years;
  }

  public partiesAreSame(mineParty: TsXmlParParty, nextParty: TsXmlParParty): boolean {
    return (
      mineParty?.partyType === nextParty?.partyType &&
      mineParty?.personalIdentification === nextParty?.personalIdentification &&
      mineParty?.companyIdentification === nextParty?.companyIdentification &&
      new Date(mineParty?.birthdate).getTime() === new Date(nextParty?.birthdate).getTime() &&
      mineParty?.forename === nextParty?.forename &&
      mineParty?.surname === nextParty?.surname
    );
  }

}
