<!-- <span mat-button mat-dialog-close mat-dialog-close="false" class="icon-circle-delete pilic pilic-25 kill"></span> -->
<div class="icon-container">
  <i *ngIf="data.icon" class="pilic pilic-50 icon-{{data.icon}}"></i>
</div>
<h2 class="center-text" *ngIf="data.header" mat-dialog-title>{{ data.header }}</h2>
<mat-dialog-content class="center-text wrapped" *ngIf="data.message">
  <ng-container *ngFor="let it of messageAr">
    <ng-container *ngIf="it.type === 'text'">
      {{it.msg}}
    </ng-container>
    <ng-container *ngIf="it.type === 'phone'">
      <a [href]="it.msg | phone:'uri'">{{it.msg | phone }}</a>
    </ng-container>
  </ng-container>
</mat-dialog-content>
<mat-dialog-content *ngIf="data.list?.length">
  <div *ngFor="let obj of data.list">
    <h2 class="center-text mgt" mat-dialog-title>{{ obj.header }}</h2>
    <span class="wrapped">{{ obj.message }}</span>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="u-btn middle-button">
    <button mat-button mat-dialog-close tabindex="-1" mat-dialog-close="true">
      {{ data.button || 'OK' }}
    </button>
  </div>
</mat-dialog-actions>