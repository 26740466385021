<div class="gradient-top">
  <h2 i18n>Captcha</h2>
  <form (ngSubmit)="onNext(f)" #f="ngForm">
    <img id="captchaImg" name="captchaImg" src={{captchaImgUrl}} alt="Captcha" #captchaImg />
    <mat-form-field [ngClass]="{'form-group--error': !captchaCode.valid && captchaCode.touched && captchaCode.errors }"
      subscriptSizing="dynamic">
      <input matInput type="text" id="captchaCode" name="captchaCode" ngModel required
        #captchaCode="ngModel">
      <mat-error *ngIf="!captchaCode.valid && captchaCode.touched && captchaCode.errors">
        <ng-container *ngIf="captchaCode.errors.required" i18n>
          Text z obrázku je nutné přepsat.
        </ng-container>
        <ng-container *ngIf="captchaCode.errors.errorCode === msgCodes.msgCaptchaInvalidCode" i18n>
          Chyba při ověření captcha. Zkontrolujte kód a&nbsp;zkuste to znovu.
        </ng-container>
        <ng-container *ngIf="captchaCode.errors.errorCode === msgCodes.msgCaptchaCallError" i18n>
          Chyba při volání captcha!
        </ng-container>
      </mat-error>
    </mat-form-field>

    <div class="bb">
      <div class="u-btn u-bu-bigger">
        <button i18n (click)="onCancel()">Zrušit</button>
      </div>
      <div class="u-btn u-bu-bigger">
        <button i18n type="submit">Odeslat</button>
      </div>
    </div>
  </form>
</div>