import { TsXmlRestRuntimeMessage } from './tsXmlRestRuntimeMessage.model';

export class TsXmlRestError {
  callId: string;
  callIdType: string;
  restCallId: string;
  statusCode: number;
  statusName: string;
  runtimeMsgs: TsXmlRestRuntimeMessage[];
}
