import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { spzVal } from './vehicle.component';

@Injectable()
export class VehicleDataService {

  constructor(
    private router: Router,
  ) { }

  private _vehicleHtmlData: { name: string; title: string, type: string; extras?: any; }[] = [
    { name: 'spz', title: $localize`Vozidlo`, type: 'select' },
    { name: 'licensePlate', title: $localize`SPZ`, type: 'input' },
    { name: 'vehicleType', title: $localize`Typ vozidla`, type: 'select' },
    { name: 'brand', title: $localize`Značka`, type: 'select' },
    { name: 'model', title: $localize`Model`, type: 'select' },
    { name: 'modelYear', title: $localize`Modelový rok`, type: 'select' },
    { name: 'engine', title: $localize`Motor`, type: 'select' },
    { name: 'bodywork', title: $localize`Karoserie`, type: 'select' },
    { name: 'fuel', title: $localize`Palivo`, type: 'select' },
    { name: 'engineVolume', title: $localize`Objem`, type: 'number', extras: { unit: 'cm', unitSub: '3' } },
    { name: 'enginePower', title: $localize`Výkon`, type: 'number', extras: { unit: 'kW', unitSub: '' } },
    { name: 'numberOfSeats', title: $localize`Počet míst k sezení`, type: 'number', extras: { unit: '', unitSub: '' } },
    { name: 'firstRegistrationDate', title: $localize`Datum první registrace vozidla`, type: 'date' },
    { name: 'weight', title: $localize`Celková hmotnost`, type: 'number', extras: { unit: 'kg', unitSub: '' } },
    { name: 'vehicleName', title: $localize`Celý název vozidla`, type: 'input' },
    { name: 'productionYear', title: $localize`Rok výroby`, type: 'input' },
    { name: 'clientsVehiclePrice', title: $localize`Cena vozidla`, type: 'number', extras: { unit: 'Kč', unitSub: '' } },
    {
      name: 'withoutVAT', title: $localize`Režim DPH vozidla`, type: 'radio',
      extras: [{ title: $localize`Bez DPH`, value: true }, { title: $localize`S DPH`, value: false }]
    },
    {
      name: 'garaged', title: $localize`Parkuje v garáži`, type: 'radio',
      extras: [{ title: $localize`ano, parkuje`, value: true }, { title: $localize`ne, neparkuje`, value: false }]
    },
    { name: 'expectedKm', title: $localize`Roční nájezd`, type: 'select' },
    { name: 'vehicleUse', title: $localize`Užití vozidla`, type: 'select' },
  ];

  private _tooltips: {
    [key: string]: {
      headline: string;
      text: string;
    };
  } = {
      garaged: {
        headline: $localize`Garáž`,
        text: $localize`Garáží rozumíme zastřešenou stavbu nebo součást stavby svým účelem určenou nebo užívanou pro úschovu vozidel, která je ve Vašem vlastnictví nebo nájmu a ve které vozidlo pravidelně (ne však nutně vždy) parkuje přes noc. Za garáž považujeme také zastřešené parkovací místo, které je přístupné pouze omezenému okruhu osob.`
      },
      vehicleUse: {
        headline: $localize`Užití vozidla`,
        text: $localize`•\tBěžný provoz - běžný provoz vozidel fyzických i právnických osob, tj. činností, které nejsou dále uvedeny samostatně.\n•\tAutoškola - vozidla využívaná při výuce řízení motorových vozidel.\n•\tHistorické vozidlo, veterán - buď vozidlo na bílých značkách s veteránskou testací zapsanou v technickém průkazu nebo vozidlo na zelených značkách s průkazem historického vozidla.\n•\tPůjčovna, carsharing - vozidla autopůjčoven a vozidla zahrnutá v programech sdílených vozidel (carsharing).\n•\tTaxislužba, vč. alternativních -  vozidla užívaná k provozování taxislužby včetně alternativních služeb typu Uber, Liftago, Bolt atd.\n•\tPřeprava nebezpečných věcí -  vozidla používaná pro přepravu nebezpečného nákladu jako jsou např. výbušniny, hořlaviny, toxické látky, infekční látky atd.\n•\tRozvoz zboží konečnému spotřebiteli, kurýr -  vozidla kurýrních služeb a pro rozvoz zboží konečnému spotřebiteli.\n•\tPrávo přednostní jízdy - vozidla s právem přednosti jízdy, při plnění zvláštních úkolů používají sirénu či maják.`
      },
      withoutVAT: {
        headline: $localize`Režim DPH vozidla`,
        text: $localize`Pokud jste plátce DPH, vozidlo jste pořídil s odpočtem DPH, pak zvolte variantu Bez DPH.  V opačném případě jako neplátce DPH zvolte variantu S DPH.`
      },
      clientsVehiclePrice: {
        headline: $localize`Cena vozidla`,
        text: $localize`•\tV případě nového vozidla (stáří do 6 měsíců od první registrace) uveďte pořizovací cenu vozidla, tj. cenu, za kterou jste vozidlo pořídili jako zcela nové.\n•\tV případě staršího vozidla (stáří nad 6 měsíců od první registrace) uveďte obvyklou cenu, tj. cenu, za kterou je možné obdobné vozidlo prodat v běžném obchodním styku v České republice k datu počátku pojištění.`
      },
      expectedKm: {
        headline: $localize`Roční nájezd`,
        text: $localize`Odhadněte, kolik kilometrů vozidlo najede za jeden rok. Nebojte, pokud se netrefíte, nic se neděje. Po roce vše vyúčtujeme dle skutečně ujetých kilometrů.`,
      },
      enginePower: {
        headline: $localize`Výkon`,
        text: $localize`Hodnotu výkonu uveďte v kilowatech (1 kW = 1 000 W). Pokud vozidlo má nižší výkon než 1 kW (např. v manuálu ke koloběžce je uveden výkon 250 W), zadejte hodnotu 1. Na cenu to nemá žádný vliv`
      }
    };

  private _fcNames = [
    'noLicensePlate', 'notRegistered', 'licensePlate', 'vehicleType', 'brand', 'model', 'modelYear',
    'engine', 'bodywork', 'fuel', 'engineVolume', 'enginePower', 'numberOfSeats', 'weight',
    'firstRegistrationDate', 'vehicleName', 'productionYear', 'clientsVehiclePrice', 'withoutVAT',
    'garaged', 'expectedKm', 'vehicleUse', 'technicalCertificateNumber', 'vIN', 'noTechnicalCertificate'
  ];

  private _defaultData = {
    noLicensePlate: false,
    notRegistered: false,
    licensePlate: null,
    vehicleType: null,
    brand: null,
    model: null,
    modelYear: null,
    engine: null,
    bodywork: null,
    fuel: null,
    engineVolume: null,
    enginePower: null,
    numberOfSeats: null,
    weight: null,
    firstRegistrationDate: null,
    vehicleName: null,
    productionYear: null,
    clientsVehiclePrice: null,
    withoutVAT: false,
    garaged: false,
    expectedKm: null,
    vehicleUse: '10',
    vehiclePrice: null,
    technicalCertificateNumber: null,
    vIN: null,
    noTechnicalCertificate: null
  };

  private _savedData: {
    spz: 0 | 1 | 2 | 3;
    sameAsUser: boolean;
    vehicleValue: any;
    theseAreUnderMyProtection: string[];
  };

  public get vehicleHtmlData(): { name: string; title: string, type: string; extras?: any; }[] {
    return this._vehicleHtmlData;
  }

  public get tooltips(): {
    [key: string]: {
      headline: string;
      text: string;
    };
  } {
    return this._tooltips;
  }

  public get fcNames(): string[] {
    return this._fcNames;
  }

  public get defaultData() {
    return this._defaultData;
  }

  public get savedData(): {
    spz: 0 | 1 | 2 | 3;
    sameAsUser: boolean;
    vehicleValue: any;
    theseAreUnderMyProtection: string[];
  } {
    return this._savedData;
  }

  public set savedData(value: {
    spz: 0 | 1 | 2 | 3;
    sameAsUser: boolean;
    vehicleValue: any;
    theseAreUnderMyProtection: string[];
  }) {
    if (!value.spz && value.spz !== 0) {
      if (!value.vehicleValue.noLicensePlate && !value.vehicleValue.notRegistered) value.spz = spzVal.have;
      if (value.vehicleValue.noLicensePlate && value.vehicleValue.notRegistered) value.spz = spzVal.new;
      if (value.vehicleValue.noLicensePlate && !value.vehicleValue.notRegistered) value.spz = spzVal.import;
      if (!value.vehicleValue.noLicensePlate && value.vehicleValue.notRegistered) value.spz = spzVal.never;
    }
    // if (this.router.url.startsWith('/ap1/'))
      this._savedData = value;
  }

  public emptyMe() {
    this._savedData = null;
  }

}
