import { Component } from '@angular/core';

import { AppService } from 'src/app/services/app.service';


@Component({
    selector: 'app-loading-screen-default',
    templateUrl: './loading-screen-default.component.html',
    styleUrls: ['./loading-screen-default.component.scss'],
    standalone: true
})
export class LoadingScreenDefaultComponent {
  constructor(public appService: AppService) {
  }
}