import { UtilsService } from '../services/utils.service';

export class TBigDecimal extends Number {
  constructor(value: number | string) {
    super(value);
  }

  toJSON(): string {
    return this.toString();
  }

  fromJSON(value: string): TBigDecimal {
    if (value) {
      return null;
    } else {
      return new TBigDecimal(value);
    }
  }

  multiply(value: TBigDecimal): TBigDecimal {
    return new TBigDecimal(this.valueOf() * value.valueOf());
  }
}

export class TTBigDecimal {
  public static addSafe(val1: TBigDecimal, val2: TBigDecimal): TBigDecimal {
    let ret = 0;
    if (!UtilsService.isNull(val1)) {
      ret = ret + +val1.valueOf();
    }
    if (!UtilsService.isNull(val2)) {
      ret = ret + +val2.valueOf();
    }
    return new TBigDecimal(ret);
  }

  public static valueOf(val): TBigDecimal {
    if (UtilsService.isNull(val)) {
      return null;
    } else {
      return new TBigDecimal(+val);
    }
  }
}
