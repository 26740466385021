import { TDate } from "../models/date.model";

export class TsXmlCodelistColumn {
   /** Atribut ColumnCode. */
   columnCode: string;
   /** Atribut StringValue. */
   stringValue: string;
   /** Atribut BooleanValue. */
   booleanValue: boolean;
   /** Atribut IntValue. */
   intValue: number;
   /** Atribut BigDecimalValue. */
   bigDecimalValue: number;
   dateValue: TDate;
}
