import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';

import { findPhoneNumbersInText } from 'libphonenumber-js';
import { NgIf, NgFor } from '@angular/common';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { PhonePipe } from '../../../pipes/phone.pipe';


@Component({
    selector: 'app-warning-dialog',
    templateUrl: './warning-dialog.component.html',
    styleUrls: ['./warning-dialog.component.scss'],
    imports: [NgIf, MatDialogTitle, CdkScrollable, MatDialogContent, NgFor, MatDialogActions, MatDialogClose, PhonePipe]
})
export class WarningDialogComponent implements OnInit {
  protected messageAr: { msg: string, type: 'text' | 'phone' }[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      message?: string,
      header?: string,
      button?: string,
      icon?: string
      list?: { header: string, message: string }[],
    }

  ) {
  }
  ngOnInit(): void {
    if (!this.data.message) return;
    const ar = findPhoneNumbersInText(this.data.message);
    this.messageAr = [];
    if (!ar?.length) this.messageAr.push({ msg: this.data.message, type: 'text'});
    let index = 0;
    ar.forEach((it, i) => {
      this.messageAr.push(
        { msg: this.data.message.slice(index, it.startsAt), type: 'text' },
        { msg: this.data.message.slice(it.startsAt, it.endsAt), type: 'phone' }
      );
      index = it.endsAt;
      if (ar.length === i + 1) this.messageAr.push({ msg: this.data.message.slice(it.endsAt), type: 'text' });
    });
  }

}
