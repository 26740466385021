
import { InMemoryScrollingFeature, InMemoryScrollingOptions, provideRouter, withInMemoryScrolling } from '@angular/router';
import { ApplicationConfig, ErrorHandler, importProvidersFrom } from '@angular/core';
import { DatePipe, LocationStrategy, PathLocationStrategy } from '@angular/common';
// import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideHttpClient, withFetch } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { Platform } from '@angular/cdk/platform';

import { MatMenuModule } from '@angular/material/menu';
import { MatDialogModule } from '@angular/material/dialog';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, provideNativeDateAdapter } from '@angular/material/core';

import { AppDateAdapter } from './services/dateAdapter';

import { CookieService } from 'ngx-cookie-service';
import { AppService } from './services/app.service';
import { Ap1Service } from './apps/ap1/ap1.service';
import { OuhaService } from './services/ouha.service';
import { RestService } from './services/rest.service';
import { OtherService } from './services/other.service';
import { MyErrorHandler } from './services/error.service';
import { PolicyService2 } from './services/policy2.service';
import { CookiesService } from './www/cookies/cookies.service';
import { OdometerService } from './apps/odometer/odometer.service';
import { AuthenticationService } from './services/authentication.service';

import { routes } from './app.routes';
import { VehicleDataService } from './apps/ap1/step1/vehicle/vehicle-data.service';
import { OtherService as OtherService2 } from './apps/ap1/step1/other/other.service';
import { DemandsService } from './apps/ap1/step1/demands/demands.service';
import { LostPasswordService } from './apps/lost-password/lost-password.service';
import { RegistrationService } from './apps/registration/registration.service';

const scrollConfig: InMemoryScrollingOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
};

const inMemoryScrollingFeature: InMemoryScrollingFeature = withInMemoryScrolling(scrollConfig);

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(BrowserModule, MatDialogModule, MatMenuModule),
    provideNativeDateAdapter(),
    provideRouter(routes, inMemoryScrollingFeature),
    Ap1Service,
    AppDateAdapter,
    AppService,
    AuthenticationService,
    CookieService,
    CookiesService,
    DatePipe,
    DemandsService,
    LostPasswordService,
    MyErrorHandler,
    OdometerService,
    OtherService,
    OtherService2,
    OuhaService,
    PolicyService2,
    RegistrationService,
    RestService,
    VehicleDataService,
    { provide: DateAdapter, useClass: AppDateAdapter, deps: [MAT_DATE_LOCALE, MAT_DATE_FORMATS, Platform] },
    { provide: ErrorHandler, useClass: MyErrorHandler },
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    provideAnimations(),
    provideHttpClient(withFetch()),
  ]
};
