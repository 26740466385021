import { Injectable } from '@angular/core';

import { RestService } from 'src/app/services/rest.service';

import { TsXmlUid } from 'src/app/tsXml/tsXmlUid.model';
import { TsXmlParAp1Vehicle } from 'src/app/tsXml/tsXmlParAp1Vehicle.model';
// import { Ap1Service } from '../../ap1.service';

export type optionsNames = 'spz' | 'vehicleType' | 'brand' | 'model' | 'modelYear' | 'engine' | 'bodywork' | 'fuel' | 'expectedKm' | 'vehicleUse';

@Injectable({
  providedIn: 'root'
})
export class VehicleOptionsService {
  private cdsData: {
    5079: { [vehicleType: string]: string },
    5130: { [vehicleKind: string]: { vehicleType: string, weight: { since: number, till: number } }[] },
    5137: {
      model: string, modelTxt: string,
      brand: string, brandTxt: string,
      vehicleType: string,
    }[],
  } = {} as any;
  private searchValues: {
    brand: { vehicleType: string },
    bodywork: { brand: string, model: string, modelYear: string, engine: string },
    engine: { brand: string, model: string, modelYear: string },
    model: { brand: string },
    modelYear: { brand: string, model: string },
    expectedKm: { vehicleType: string, userPartyType: string }
  } = {} as any;

  constructor(
    private restServ: RestService,
    // private ap1Service: Ap1Service,
  ) { }

  private _options: {
    [key in optionsNames]: { text: string; value: any; }[];
  } = {} as any;
  public get options(): {
    [key in optionsNames]: { text: string; value: any; }[];
  } {
    return this._options;
  }

  public setOptionsFor(
    fcName: optionsNames,
    uid: TsXmlUid,
    searchValues?: {
      vehicleType?: string, brand?: string, model?: string, modelYear?: string,
      engine?: string, group?: string; userPartyType?: string
    }
  ): Promise<void> {
    if (searchValues) return this[`${fcName}OptionsSet`](searchValues as any, uid);
    else return this[`${fcName}OptionsSet`](null, null);
  }

  public prepareVehicleData(uid: TsXmlUid) {
    return Promise.all([
      this.spzOptionsSet(),
      this.vehicleTypeOptionsSet(uid),
      // this.brandOptionsSet(),
      this.setCd5137Data(uid),
      this.setVehicleTypes(uid),
      this.setVehicleGroups(uid),
      this.fuelOptionsSet(uid),
      this.vehicleUseOptionsSet(uid),
    ]);
  }

  private setCd5137Data(uid: TsXmlUid) {
    if (this.cdsData[5137]) return Promise.resolve();
    return this.restServ.parCodelistsEntriesGet(
      '5137',
      uid
    ).then(resp => {
      const options = resp.entry.map(ent => {
        return {
          model: ent.gclColumn.find(cl => cl.columnCode === 'MODEL175')?.stringValue,
          modelTxt: ent.gclColumn.find(cl => cl.columnCode === 'MODEL_POPIS')?.stringValue,
          brand: ent.gclColumn.find(cl => cl.columnCode === 'ZNACKA174')?.stringValue,
          brandTxt: ent.gclColumn.find(cl => cl.columnCode === 'ZNACKA_POPIS')?.stringValue,
          vehicleType: ent.gclColumn.find(cl => cl.columnCode === 'TYP')?.stringValue,
        };
      });
      this.cdsData[5137] = options;
    });
  }

  private setVehicleTypes(uid: TsXmlUid) {
    if (this.cdsData[5130]) return Promise.resolve();
    return this.restServ.parCodelistsEntriesGet('5130', uid).then(resp => {
      const options = resp.entry.map(ent => {
        return {
          vehicleKind: ent.gclColumn.find(clm => clm.columnCode === 'CKP_KOD').stringValue,
          weight: {
            since: ent.gclColumn.find(clm => clm.columnCode === 'HMOTNOST_OD').intValue,
            till: ent.gclColumn.find(clm => clm.columnCode === 'HMOTNOST_DO').intValue,
          },
          vehicleType: ent.gclColumn.find(clm => clm.columnCode === 'C_5078').stringValue
        };
      });
      const output = {};
      options.forEach(option => {
        if (option.vehicleKind) {
          if (output[option.vehicleKind]) {
            output[option.vehicleKind].push({
              weight: option.weight,
              vehicleType: option.vehicleType
            });
          } else {
            output[option.vehicleKind] = [{
              weight: option.weight,
              vehicleType: option.vehicleType
            }];
          }
        }
      });
      this.cdsData[5130] = output;
    });
  }

  private setVehicleGroups(uid: TsXmlUid) {
    if (this.cdsData[5079]) return Promise.resolve();
    return this.restServ.parCodelistsEntriesGet(
      '5079',
      uid
    ).then(resp => {
      const options = resp.entry.map(ent => {
        const vehicleType = ent.gclColumn.find(cl => cl.columnCode === 'TYP_VOZIDLA').stringValue;
        const group = ent.gclColumn.find(cl => cl.columnCode === 'SKUPINA_DRUHU').stringValue;

        return { vehicleType, group };
      }).filter(opt => opt.vehicleType && opt.group);
      const output = {};
      options.forEach(opt => {
        output[opt.vehicleType] = opt.group;
      });
      this.cdsData[5079] = output;
    });
  }

  private spzOptionsSet(): Promise<void> {
    if (this.options.spz) return Promise.resolve();
    this.options.spz = [
      { text: $localize`Má SPZ (ojeté nebo nové)`, value: 0 },
      { text: $localize`Nové, ještě bez SPZ`, value: 1 },
      { text: $localize`Ojeté, čeká na SPZ`, value: 2 },
      { text: $localize`Trvale bez SPZ`, value: 3 }
    ];
    return Promise.resolve();
  }

  private vehicleTypeOptionsSet(uid: TsXmlUid): Promise<void> {
    if (this.options.vehicleType) return Promise.resolve();
    return this.restServ.parCodelistsEntriesGet('5078', uid).then(resp => {
      const options = resp.entry.map(ent => {
        return {
          value: ent.id,
          text: ent.gclColumn.find(cl => cl.columnCode === 'POPIS_LOK')?.stringValue
        };
      });
      this.options.vehicleType = this._getFilteredOptions(options);
    });
  }

  private _getFilteredOptions(options: { value: any, text: string }[]): { value: any, text: string }[] {
    if (!options?.length) return [];
    options = options.filter(op => op?.value && op?.text);
    options.sort((a, b) => {
      return a.text.localeCompare(b.text);
    });
    return this._removeOptionsDuplicities(options);
  }

  private _removeOptionsDuplicities(options: { value: any, text: string }[]): { value: any, text: string }[] {
    const output = [];
    options.forEach(opt => {
      if (!output.some(otp => otp.value === opt.value && otp.text === opt.text)) {
        output.push(opt);
      }
    });
    return output;
  }

  private brandOptionsSet(searchValues: { vehicleType: string, }, uid: TsXmlUid): Promise<void> {
    if (JSON.stringify(this.searchValues.brand) === JSON.stringify(searchValues)) return Promise.resolve();
    return this.setCd5137Data(uid).then(() => {
      const options = this.cdsData[5137].filter(cd => cd.vehicleType === searchValues.vehicleType);
      const brandDupl = this._getFilteredOptions(options.map(opt => {
        return {
          value: opt.brand, text: opt.brandTxt
        };
      }));
      const brandUniq = [];
      brandDupl.forEach((vl, i) => {
        if (i && brandDupl[i - 1].value !== vl.value) brandUniq.push(vl);
      });
      this.options.brand = brandUniq;
      this.searchValues.brand = searchValues;
    });
  }

  private modelOptionsSet(searchValues: { brand: string, }, uid: TsXmlUid): Promise<void> {
    if (JSON.stringify(this.searchValues.model) === JSON.stringify(searchValues)) return Promise.resolve();
    return this.setCd5137Data(uid).then(() => {
      const options = this.cdsData[5137].filter(cd => cd.brand === searchValues.brand);
      this.options.model = this._getFilteredOptions(options.map(opt => {
        return {
          value: opt.model, text: opt.modelTxt
        };
      }));
      this.searchValues.model = searchValues;
    });
  }

  private modelYearOptionsSet(searchValues: { brand: string, model: string }, uid: TsXmlUid): Promise<void> {
    if (JSON.stringify(this.searchValues.modelYear) === JSON.stringify(searchValues)) return Promise.resolve();
    if (this._searchValuesIsNotValid(searchValues)) return Promise.resolve();
    const columnData = [
      { code: 'ZNACKA', value: searchValues.brand },
      { code: 'MODEL', value: searchValues.model }
    ];
    return this.restServ.myParCodelistsEntriesByColumnsPost('5118', columnData, uid).then(cd5118 => {
      const options = cd5118.entry?.map(ent => {
        const modelYear = ent.gclColumn.find(clm => clm.columnCode === 'MODELOVY_ROK').stringValue;
        const brand = ent.gclColumn.find(clm => clm.columnCode === 'ZNACKA')?.stringValue;
        const model = ent.gclColumn.find(clm => clm.columnCode === 'MODEL')?.stringValue;
        if (brand !== searchValues.brand || model !== searchValues.model) return null;
        return {
          text: modelYear,
          value: modelYear
        };
      });
      if (options?.length)
        options.push({ text: 'Ostatní', value: 'Ostatní' });
      this.options.modelYear = this._getFilteredOptions(options);
      this.searchValues.modelYear = searchValues;
    });
  }

  private _searchValuesIsNotValid(
    searchValues: { [key: string]: string }
  ) {
    const keys = Object.keys(searchValues);
    for (const key of keys) {
      if (!searchValues[key]) {
        return true;
      }
    }
    return false;
  }

  private engineOptionsSet(searchValues: { brand: string, model: string, modelYear: string }, uid: TsXmlUid): Promise<void> {
    if (
      JSON.stringify(this.searchValues.engine) === JSON.stringify(searchValues) ||
      searchValues.modelYear === 'Ostatní'
    ) return Promise.resolve();
    if (this._searchValuesIsNotValid(searchValues)) return Promise.resolve();

    const columnData = [
      { code: 'ZNACKA', value: searchValues.brand },
      { code: 'MODEL', value: searchValues.model },
      { code: 'MODELOVY_ROK', value: searchValues.modelYear }

    ];
    return this.restServ.myParCodelistsEntriesByColumnsPost('5118', columnData, uid).then(cd5118 => {
      const options = cd5118.entry?.map(ent => {
        const engine = ent.gclColumn.find(clm => clm.columnCode === 'MOTOR').stringValue;
        const brand = ent.gclColumn.find(clm => clm.columnCode === 'ZNACKA')?.stringValue;
        const modelYear = ent.gclColumn.find(clm => clm.columnCode === 'MODELOVY_ROK')?.stringValue;
        const model = ent.gclColumn.find(clm => clm.columnCode === 'MODEL')?.stringValue;
        if (brand !== searchValues.brand || model !== searchValues.model || modelYear !== searchValues.modelYear) return null;
        return {
          text: engine,
          value: engine
        };
      });
      if (options?.length)
        options.push({ text: 'Ostatní', value: 'Ostatní' });
      this.options.engine = this._getFilteredOptions(options);
      this.searchValues.engine = searchValues;
    });
  }

  private bodyworkOptionsSet(
    searchValues: {
      brand: string, model: string, modelYear: string, engine: string
    },
    uid: TsXmlUid
  ): Promise<void> {
    if (
      JSON.stringify(this.searchValues.bodywork) === JSON.stringify(searchValues) ||
      searchValues.modelYear === 'Ostatní' || searchValues.engine === 'Ostatní'
    ) return Promise.resolve();
    if (this._searchValuesIsNotValid(searchValues)) return Promise.resolve();

    const columnData = [
      { code: 'ZNACKA', value: searchValues.brand },
      { code: 'MODEL', value: searchValues.model },
      { code: 'MODELOVY_ROK', value: searchValues.modelYear },
      { code: 'MOTOR', value: searchValues.engine }
    ];
    return this.restServ.myParCodelistsEntriesByColumnsPost('5118', columnData, uid).then(cd5118 => {
      const options = cd5118.entry?.map(ent => {
        const bodywork = ent.gclColumn.find(clm => clm.columnCode === 'VARIANTA').stringValue;
        const brand = ent.gclColumn.find(clm => clm.columnCode === 'ZNACKA').stringValue;
        const model = ent.gclColumn.find(clm => clm.columnCode === 'MODEL').stringValue;
        const modelYear = ent.gclColumn.find(clm => clm.columnCode === 'MODELOVY_ROK').stringValue;
        const engine = ent.gclColumn.find(clm => clm.columnCode === 'MOTOR').stringValue;
        if (
          brand !== searchValues.brand ||
          model !== searchValues.model ||
          modelYear !== searchValues.modelYear ||
          engine !== searchValues.engine
        ) return null;

        return {
          text: bodywork,
          value: bodywork
        };
      });
      if (options?.length)
        options.push({ text: 'Ostatní', value: 'Ostatní' });
      this.options.bodywork = this._getFilteredOptions(options);
      this.searchValues.bodywork = searchValues;
    });
  }

  private fuelOptionsSet(uid: TsXmlUid): Promise<void> {
    if (this.options.fuel) return Promise.resolve();
    return this.restServ.parCodelistsEntriesGet('85', uid).then(resp => {
      const options = resp.entry.map(ent => {
        return {
          value: ent.id,
          text: ent.sclDescription
        };
      });
      this.options.fuel = this._getFilteredOptions(options);
    });
  }

  private expectedKmOptionsSet(searchValues: { vehicleType: string, userPartyType: string }, uid: TsXmlUid): Promise<void> {
    if (JSON.stringify(this.searchValues.expectedKm) === JSON.stringify(searchValues)) return Promise.resolve();
    return this.setVehicleGroups(uid).then(() => {
      const group = this.cdsData[5079][searchValues.vehicleType || 'OA'] || 'OS';
      const columnData = [
        { code: 'SKUPINA_DRUHU', value: group },
        { code: 'TYP_STRANY', value: searchValues.userPartyType },
        // { code: 'TYP_VOZIDLA', value: searchValues.vehicleType || 'OA' }
      ];
      return this.restServ.myParCodelistsEntriesByColumnsPost('5111', columnData, uid).then(resp => {
        const options = [];
        const min = resp.entry[0].gclColumn.find(clm => clm.columnCode === 'MIN')?.intValue;
        const max = resp.entry[0].gclColumn.find(clm => clm.columnCode === 'MAX')?.intValue;
        const step: number = max >= 100000 ? 5000 : 1000;
        for (let i = min; i <= max; i += step) {
          if (i === max) {
            options.push({ text: i + ' ' + $localize`km a více`, value: i });
          } else if (i === min) {
            options.push({ text: i + ' ' + $localize`km a méně`, value: i });
          } else {
            options.push({ text: `${i} km`, value: i });
          }
        }
        this.options.expectedKm = options;
        this.searchValues.expectedKm = searchValues;
      });
    });
  }

  private vehicleUseOptionsSet(uid: TsXmlUid): Promise<void> {
    if (this.options.vehicleUse) return Promise.resolve();
    return this.restServ.parCodelistsEntriesGet('5132', uid).then(resp => {
      // this.ap1Service.vehicleUses = resp.entry;
      // this.ap1Service.vehicleUses.sort((val1, val2) => {
      //   return val1.sclDescription.localeCompare(val2.sclDescription);
      // });
      let options = resp.entry.map(ent => {
        return {
          value: ent.id,
          text: ent.gclColumn.find(cl => cl.columnCode === 'POPIS_LOK')?.stringValue
        };
      });
      options = options.filter(op => op.value !== '12');
      this.options.vehicleUse = this._getFilteredOptions(options);
    });
  }

  public loadVehicle(licensePlate: string, uid: TsXmlUid): Promise<TsXmlParAp1Vehicle> {
    return this.restServ.parCkpVehicleGet(licensePlate, uid).then((vehicle: any) => {
      return this.editSearchedVehicle(vehicle, uid);
    });
  }

  private editSearchedVehicle(vehicle: any, uid: TsXmlUid): Promise<TsXmlParAp1Vehicle> {
    return new Promise((resolve) => {
      vehicle.vIN = vehicle.vin;
      if (vehicle.vehicleKind) {
        const vehicleKind = vehicle.vehicleKind;
        vehicle.vehicleType = this.cdsData[5130][vehicleKind].find(
          dt => !vehicle.weight || (dt.weight.since < vehicle.weight && dt.weight.till > vehicle.weight)
        ).vehicleType;
      }
      if (vehicle.vehicleType !== 'OA') {
        vehicle.brand = 'NEUVEDENA';
        vehicle.model = '529';
        vehicle.vehicleName = vehicle.brandModelTxt;

        if (['PV', 'PN'].includes(vehicle.vehicleType)) {
          vehicle.fuel = 'JI';
          vehicle.engineVolume = 0;
          vehicle.enginePower = 0;
          vehicle.numberOfSeats = 0;
        } else if (vehicle.fuel) {
          return this.restServ.myParCodelistsEntriesByColumnsPost(
            '5122',
            [{ code: 'CKP', value: vehicle.fuel }],
            uid
          ).then(resp => {
            vehicle.fuel = resp.entry[0].gclColumn.find(ent => ent.columnCode === 'C_85').stringValue;
            resolve(this.emptyNotWanted(vehicle));
          });
        }
      }

      resolve(this.emptyNotWanted(vehicle));
    });
  }

  private emptyNotWanted(vehicle: TsXmlParAp1Vehicle): TsXmlParAp1Vehicle {
    const wanted = ['vehicleType', 'brand', 'model', 'technicalCertificateNumber', 'vIN', 'weight'];
    if (vehicle.vehicleType !== 'OA') {
      wanted.push('firstRegistrationDate', 'vehicleName');
      if (['PV', 'PN'].includes(vehicle.vehicleType)) {
        wanted.push('fuel', 'engineVolume', 'enginePower', 'numberOfSeats');
      } else {
        if (vehicle.fuel) wanted.push('fuel');
        wanted.push('engineVolume', 'enginePower', 'numberOfSeats');
      }
    }
    const output: TsXmlParAp1Vehicle = {} as any;
    wanted.forEach(key => {
      output[key] = vehicle[key];
    });
    return output;
  }

  public getVehicleFuel(
    data: { brand: string, model: string, modelYear: string },
    uid: TsXmlUid
  ): Promise<string> {
    if (data.modelYear === 'Ostatní') return Promise.resolve(null);
    return new Promise((resolve) => {
      const columnData = [
        { code: 'ZNACKA', value: data.brand },
        { code: 'MODEL', value: data.model },
        { code: 'MODELOVY_ROK', value: data.modelYear }
      ].filter(dt => dt.value);
      this.restServ.myParCodelistsEntriesByColumnsPost(
        '5118',
        columnData,
        uid
      ).then(cd5118 => {
        const entry = cd5118.entry.find(cl => cl.gclColumn.find(it => it.columnCode === 'MODELOVY_ROK').stringValue === data.modelYear);
        const fuel = entry.gclColumn.find(clm => clm.columnCode === 'PALIVO').stringValue;
        resolve(fuel);
      });
    });
  }

  public getEngineValues(
    data: { brand: string, model: string, modelYear: string, engine: string },
    uid: TsXmlUid
  ): Promise<{ power: number, volume: number }> {
    if (data.modelYear === 'Ostatní' || data.engine === 'Ostatní')
      return Promise.resolve(null);
    return new Promise((resolve) => {
      const columnData = [
        { code: 'ZNACKA', value: data.brand },
        { code: 'MODEL', value: data.model },
        { code: 'MODELOVY_ROK', value: data.modelYear },
        { code: 'MOTOR', value: data.engine }
      ].filter(dt => dt.value);
      this.restServ.myParCodelistsEntriesByColumnsPost(
        '5118',
        columnData,
        uid
      ).then(cd5118 => {
        let power = null;
        let volume = null;
        if (cd5118 && cd5118.entry) {
          power = cd5118.entry[0].gclColumn.find(clm => clm.columnCode === 'VYKON').intValue;
          volume = cd5118.entry[0].gclColumn.find(clm => clm.columnCode === 'OBJEM').intValue;
        }
        resolve({ power, volume });
      });
    });
  }

  public getNumberOfSeats(
    data: { brand: string, model: string, modelYear: string, engine: string, bodywork: string },
    uid: TsXmlUid
  ): Promise<number> {
    if (data.modelYear === 'Ostatní' || data.engine === 'Ostatní' || data.bodywork === 'Ostatní')
      return Promise.resolve(null);
    return new Promise((resolve) => {
      const columnData = [
        { code: 'ZNACKA', value: data.brand },
        { code: 'MODEL', value: data.model },
        { code: 'MODELOVY_ROK', value: data.modelYear },
        { code: 'MOTOR', value: data.engine },
        { code: 'VARIANTA', value: data.bodywork }
      ].filter(dt => dt.value);
      this.restServ.myParCodelistsEntriesByColumnsPost(
        '5118',
        columnData,
        uid
      ).then(cd5118 => {
        const seats = cd5118.entry[0].gclColumn.find(clm => clm.columnCode === 'POCET_MIST').intValue;
        resolve(seats);
      });
    });
  }

}
