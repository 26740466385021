import { AbstractControl, ValidatorFn } from '@angular/forms';

import { TDate } from 'src/app/models/date.model';


export const licensePlateVal = (control: AbstractControl): { [key: string]: string } | null => {
    if (!control.disabled && control.value) {
        if (
            !control.value.match('.*[0-9].*') ||
            control.value.length < 5 ||
            control.value.length > 8
        ) {
            return { message: $localize`SPZ je ve špatném formátu. Prosíme o kontrolu.` };
        }
    }
    return null;
};

export const engineVolumeVal = (control: AbstractControl): { [key: string]: string } | null => {
    if (!control.disabled && (control.value || control.value === 0)) {
        const min = 0;
        const max = 32000;
        if (control.value < min || control.value > max) {
            return { message: $localize`Objem je mimo rozsah 0 - 32 000 ccm.` };
        }
    }
    return null;
};

export const enginePowerVal = (control: AbstractControl): { [key: string]: string } | null => {
    if (!control.disabled && (control.value || control.value === 0)) {
        const min = 0;
        const max = 10000;
        if (control.value < min || control.value > max) {
            return { message: $localize`Výkon je mimo rozsah 0 - 10 000 kW.` };
        }
    }
    return null;
};

export const numberOfSeatsVal = (control: AbstractControl): { [key: string]: string } | null => {
    if (!control.disabled && (control.value || control.value === 0)) {
        const min = 0;
        const max = 1000;
        if (control.value < min || control.value > max) {
            return { message: $localize`Počet míst k sezení je mimo rozsah 0 - 1 000.` };
        }
    }
    return null;
};

export const weightVal = (vehicleTypeFC: AbstractControl): ValidatorFn => {
    return (control: AbstractControl): { [key: string]: string } | null => {
        if (!control.disabled && (control.value || control.value === 0)) {
            let min = 5;
            let max = 1000000;

            if (vehicleTypeFC.value === 'OA') {
                max = 3500;
            } else if (vehicleTypeFC.value === 'NA') {
                min = 3500;
            }

            if (control.value < min || control.value > max) {
                return { message: $localize`Celková hmotnost je mimo rozsah ${min}:INTERPOLATION: - ${max}:INTERPOLATION: kg.` };
            }
        }

        return null;
    };
};

export const productionYearVal = (max: TDate): ValidatorFn => {
    return (control: AbstractControl): { [key: string]: string } | null => {
        if (!control.disabled && (control.value || control.value === 0) && max) {
            if (control.value <= 1900 || control.value > max.getFullYear()) {
                return { message: $localize`Rok výroby je mimo povolený rozsah.` };
            }
        }
        return null;
    };
};

export const clientsVehiclePriceVal = (control: AbstractControl): { [key: string]: string } | null => {
    if (!control.disabled && (control.value || control.value === 0)) {
        const min = 0;
        const max = 9999999;
        if (control.value < min || control.value > max) {
            return { message: $localize`Cena vozidla je mimo rozsah 0 - 9 999 999 Kč.` };
        }
    }
    return null;
};
