import { Component, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterLinkActive, RouterLink } from '@angular/router';
import { Subscription } from 'rxjs';

import { AppService } from 'src/app/services/app.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { NgClass, NgIf } from '@angular/common';
import { environment } from 'src/environments/environment';


@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    imports: [NgClass, NgIf, RouterLinkActive, RouterLink]
})
export class FooterComponent implements OnInit, OnDestroy {

  private subs: Subscription[] = [];
  protected userType: number;
  protected simplified: boolean;
  protected privacyLink: string;

  constructor(
    private authServ: AuthenticationService,
    private router: Router,
    public appService: AppService,
  ) {
  }

  ngOnInit(): void {
    this.privacyLink = environment.language === 'cs' ? 'https://www.pillow.cz/soukromi/' : 'https://www.mypillow.sk/sukromie/';
    this.subs.push(this.authServ.currentUserValueObservable.subscribe(user => {
      this.userType = user.userType;
      if (this.simplified && this.userType !== 0) this.simplified = false;
    }));

    this.subs.push(this.router.events.subscribe(ev => {
      if (!(ev instanceof NavigationEnd)) return;
      this.simplified = this.userType === 0;
    }));
  }

  ngOnDestroy(): void {
    this.subs?.forEach(sub => sub?.unsubscribe());
  }
}
