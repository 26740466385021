export class Locale {
  public code: 'cs' | 'sk' | 'en';
  public label: string;
  public path: string;
  public iconClass: string;

  constructor(code: 'cs' | 'sk' | 'en', label: string, path: string, iconClass: string) {
    this.code = code;
    this.label = label;
    this.path = path;
    this.iconClass = iconClass;
  }
}