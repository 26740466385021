import { TsXmlUid } from 'src/app/tsXml/tsXmlUid.model';
import { TsXmlOperator } from 'src/app/tsXml/tsXmlOperator.model';
import { TsXmlParParty } from 'src/app/tsXml/tsXmlParParty.model';

export enum UserType {
  A_UNAUTH = 0,
  B_USER,
  C_AGENT,
  D_OPER,
}

export class User {
  uid: TsXmlUid = null;
  operator: TsXmlOperator = null;
  party: TsXmlParParty = null;
  userType: UserType = UserType.A_UNAUTH;
  agentId1 = '111';
  agentId2: string = null;
}
