<div class="everything">
  @if (!inFrame) {
    <app-header id="header-ie" [amISmall]="amISmall" class="moj-header"></app-header>
  }

  <div id="container-ie" #scroller class="moj-container" (scroll)="onScroll($event)" *ngIf="!myErrorHandler.captcha">
    <div class="rou">
      <router-outlet class="rou-rou-rou" *ngIf="!myErrorHandler.captcha"></router-outlet>
      @if (!isOnCookiesPage && !cookieService.cookiesIsSet && !inFrame) {
        <div class="cks">
          <div class="clm-1">
            <span class="head" i18n>
              Chráníme i vaše soukromí
            </span>
            <span class="text" i18n>
              Pro správné fungování polštářových stránek potřebujeme váš souhlas. Pouze s ním můžeme zaznamenávat, jak se
              vám u nás líbí, poskytnout vám tu nejlepší službu a stále se zlepšovat.
            </span>
          </div>
          <div class="clm-2">
            <div class="u-btn">
              <button (click)="onCookies(true)" i18n>Souhlasím</button>
            </div>
            <div class="u-button-shadowless" (click)="onCookies(false)" tabindex="0" (keydown.enter)="onCookies(false)">
              <u i18n>Detailní nastavení Cookies</u>
            </div>
          </div>
        </div>
      }
      <app-captcha #captchaComponent *ngIf="myErrorHandler.captcha"></app-captcha>
    </div>

    @if (!inFrame) {
      <app-footer class="moj-footer"></app-footer>
    }
  </div>

  <app-loading-screen></app-loading-screen>
</div>
