import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { NativeDateAdapter } from '@angular/material/core';

import { UtilsService } from './utils.service';


@Injectable()
export class AppDateAdapter extends NativeDateAdapter {
  constructor(
    @Inject(LOCALE_ID) public override locale: string,
  ) {
    super(locale);
  }

  override parse(value: any): Date | null | undefined {
    if (!UtilsService.isNull(value) && typeof value === 'string') {
      let year = NaN;
      let month = NaN;
      let date = NaN;
      if (value.indexOf('.') > -1) {
        const str = value.split('.');
        if (str.length === 3) {
          date = Number(str[0]);
          month = Number(str[1]) - 1;
          year = Number(str[2]);
        }
      } else if (value.indexOf('/') > -1) {
        const str = value.split('/');
        if (str.length === 3) {
          month = Number(str[0]) - 1;
          date = Number(str[1]);
          year = Number(str[2]);
        }
      }
      if (isNaN(year) || isNaN(month) || isNaN(date)) {
        return new Date(undefined);
      } else {
        return new Date(year, month, date);
      }
    }

    return null;
  }

  override format(date: Date): string {
    const options: any = {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    };
    return date?.toLocaleDateString(this.locale, options)?.replace(/ /g, '');
  }

  override getFirstDayOfWeek(): number {
    return 1;
  }
}
