import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { Observable } from 'rxjs';

import { AuthenticationService } from './authentication.service';
import { ActiveProductsService } from 'src/app/services/active-products.service';


@Injectable({
  providedIn: 'root'
})
export class FormGuardGuardProduts {

  constructor(
    private actPrServ: ActiveProductsService,
    private authServ: AuthenticationService,
    private router: Router,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return new Promise<boolean>(async (resolve) => {
      const nm = state.url.substring(1).replace(/[/?][^/]*/, '');
      const activeProducts = await this.actPrServ.getActiveProducts();
      if (!activeProducts.includes(nm.toUpperCase())) {
        const userType = this.authServ.currentUserValue.userType;
        this.router.navigate([ userType === 2 ? '/uran-copy-agent' : '/notfound']);
        resolve(false); return false;
      }
      resolve(true); return true;
    });
  }

}
