import { AfterViewInit, Component, ElementRef, Input, Renderer2, ViewChild } from '@angular/core';
import { OuhaService } from 'src/app/services/ouha.service';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-tooltip',
    templateUrl: './tooltip.component.html',
    styleUrls: ['./tooltip.component.scss'],
    imports: [NgIf]
})
export class TooltipComponent implements AfterViewInit {
  @Input() tooltip:{
    icon?: string,
    headline?: string,
    text?: string | (string | { text: string, link: string })[],
    img?: string,
    imgBottom?: string,
    hued?: boolean,
    lines?: (string | (string | { text: string, link: string })[])[],
  };
  @Input() top: string;
  @Input() hued?: boolean;

  @ViewChild('questionMarkRef', { static: false }) questionMarkRef: ElementRef;

  _questionVisible: boolean;

  constructor(
    private renderer: Renderer2,
    private ouhaServ: OuhaService,
  ) { }

  ngAfterViewInit(): void {
    if (this.tooltip) {
      this._questionVisible = true;

      setTimeout(() => {
        if (this.top && this.questionMarkRef) {
          this.renderer.setStyle(this.questionMarkRef?.nativeElement, 'top', this.top);
        }
      }, 100);
    }
  }

  _htmlFunTooltip() {
    this.ouhaServ.tooltip(this.tooltip);
  }

}
