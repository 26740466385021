import { TDate } from 'src/app/models/date.model';
import { TsXmlPhone } from 'src/app/tsXml/tsXmlPhone.model';
import { TsXmlAuthorizationInstrumentExtAuthority } from 'src/app/tsXml/tsXmlAuthorizationInstrumentExtAuthority.model';


/**
 * GwtXmlAuthorizationInstrument (AIS.Core / C0A81465-0BB8-0007-000001739449DADE-7A736B72646C6F76) 28.7.2020
*/
export class TsXmlAuthorizationInstrument {
    objId: number;
    state: string;
    type: 'QUESTION' | 'MATRIX' | 'EMAIL' | 'PHONE' | 'EXTERNAL_AUTHORITY' | 'BIO_SIGN';
    /** Extension for type=EXTERNAL_AUTHORITY */
    externalAuthority?: TsXmlAuthorizationInstrumentExtAuthority;
    dateValidated?: TDate;
    dateInvalidated?: TDate;
    /** Extension for type=PHONE */
    phone?: TsXmlPhone;

    constructor(it: TsXmlAuthorizationInstrument) {
        this.objId = it?.objId;
        this.state = it?.state;
        this.type = it?.type;
        this.externalAuthority = it?.externalAuthority;
        this.dateValidated = it?.dateValidated ? new TDate(new Date(it.dateValidated)) : undefined;
        this.dateInvalidated = it?.dateInvalidated ? new TDate(new Date(it.dateInvalidated)) : undefined;
        this.phone = it?.phone;
    }
}

