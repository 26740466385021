export class TTDate {
  public static getTimezone(): string {
    const now = new Date();
    const diff = now.getTimezoneOffset() / 60;
    let s: string;
    if (diff < 0) {
      s = '+' + Math.abs(diff);
    } else {
      s = '-' + Math.abs(diff);
    }
    return 'GMT' + s;
  }

  public static toJSON(date: TDate): string {
    if (date) {
      const ret =
        date.getFullYear() +
        '-' +
        TTDate.to2Characters(date.getMonth() + 1) +
        '-' +
        TTDate.to2Characters(date.getDate()) +
        'T' +
        TTDate.to2Characters(date.getHours()) +
        ':' +
        TTDate.to2Characters(date.getMinutes()) +
        ':' +
        TTDate.to2Characters(date.getSeconds());

      return ret;
    } else {
      return null;
    }
  }

  public static to2Characters(number: number) {
    let ret = number.toString();
    if (ret.length === 1) {
      ret = '0' + ret;
    }
    return ret;
  }

  /**
   * Same like diffDates, but only for days (e.g. 20 days, 16 hours => 21 days)
   * @param d1 first day
   * @param d2 sedond day
   */
  public static diffDatesOnDays(d1: TDate, d2: TDate): {days: number} {
    const dateObj = this.diffDates(d1, d2);
    return { days: dateObj.days };
  }

  // doesn't care which date comes first
  public static diffDates(d1: TDate, d2: TDate): {
    years: number, days: number, hours: number, minutes: number, seconds: number, milliseconds: number
  } {
    let diff = Math.abs(d1.getTime() - d2.getTime());

    const _second = 1000;
    const _minute = 60 * _second;
    const _hour = 60 * _minute;
    const _day = 24 * _hour;
    const _year = 365.25 * _day;

    let years = 0;
    let days = 0;
    let hours = 0;
    let minutes = 0;
    let seconds = 0;
    let milliseconds = 0;

    if (diff > _year) {
      years = Math.floor(diff / _year);
      diff = diff - (years * _year);
    }
    if (diff > _day) {
      days = Math.floor(diff / _day);
      diff = diff - (days * _day);
    }
    if (diff > _hour) {
      hours = Math.floor(diff / _hour);
      diff = diff - (hours * _hour);
    }
    if (diff > _minute) {
      minutes = Math.floor(diff / _minute);
      diff = diff - (minutes * _minute);
    }
    if (diff > _second) {
      seconds = Math.floor(diff / _second);
      diff = diff - (seconds * _second);
    }
    milliseconds = diff;

    return { years, days, hours, minutes, seconds, milliseconds };
  }
}

export class TDate extends Date {
  constructor(value: number | string | Date) {
    super();
    if (value) {
      if (typeof value === 'string') {
        let dateTime = value.split('T');
        if (dateTime.length === 0) {
          dateTime = [value];
        }

        if (dateTime.length > 0) {
          const ymd = dateTime[0].split('-');
          if (ymd.length === 3) {
            this.setFullYear(+ymd[0]);
            this.setMonth(+ymd[1] - 1);
            this.setDate(+ymd[2]);
          }
        }

        if (dateTime.length > 1) {
          const hms = dateTime[1].split(':');
          if (hms.length === 3) {
            this.setHours(+hms[0]);
            this.setMinutes(+hms[1]);
            this.setSeconds(+hms[2]);
          }
        }
      } else if (typeof value === 'number') {
        this.setTime(value);
      } else if (typeof value === 'object' && value instanceof Date) {
        this.setTime(value.getTime());
      }
    }
  }

  override toJSON(): string {
    return TTDate.toJSON(this);
  }

  override toString(): string {
    return this.toJSON();
  }
}

export class TDateD extends TDate {
  constructor(value: number | string | Date) {
    super(value);
    this.setHours(0);
    this.setMinutes(0);
    this.setSeconds(0);
    this.setMilliseconds(0);
  }

  override toJSON(): string {
    return TTDate.toJSON(this).replace(/T.*/, '');
  }
}

export class TDateN extends TDateD {
  constructor(value: string | Date) {
    if (typeof value === 'string') {
      value = new Date(value);
    }
    super(value);
  }

  override toJSON(): string {
    return TTDate.toJSON(this).replace(/T.*/, 'T00:00:00');
  }

  override toString(): string {
    return this.toJSON();
  }
}
