import { TDate } from '../models/date.model';
import { TsXmlCancelTypeInfo } from './tsXmlCancelTypeInfo.model';

export class TsXmlParCancelType {
    id: string; // TStr20
    /** Název druhu storna */
    name: string; // TStr50
    /** přednastavené datum účinnosti storna */
    presetCancelEffectDate: TDate;
    /** Zda může být modifikován */
    cancelEffectDateCanBeModified: boolean;
    /** Jedna hodnota z výčtu POLICYHOLDER_OR_INSURER, INSURER, POLICYHOLDER */
    canClientAccept: string;
    /** Minimálné možné datum účinnosti storna, které může ručně nastavit uživatel */
    cancelEffectDateBegins: TDate;
    /** Maximální možné datum účinnosti storna, které může ručně nastavit uživatel */
    cancelEffectDateEnds: TDate;
    /** Obsahuje seznam přídavných informací k typu storna.
     *   Pro storna smluv produktu AP1 může obsahovat následující klíče:
     *   KM = pro vytvoření storna je nutné zadat nájezd kilometrů
     */
    info: TsXmlCancelTypeInfo[];

    constructor(dt: TsXmlParCancelType) {
      this.id = dt?.id;
      this.name = dt?.name;
      this.presetCancelEffectDate = dt?.presetCancelEffectDate ? new TDate(new Date(dt.presetCancelEffectDate)) : null;
      this.cancelEffectDateCanBeModified = dt?.cancelEffectDateCanBeModified;
      this.canClientAccept = dt?.canClientAccept;
      this.cancelEffectDateBegins = dt?.cancelEffectDateBegins ? new TDate(new Date(dt.cancelEffectDateBegins)) : null;
      this.cancelEffectDateEnds = dt?.cancelEffectDateEnds ? new TDate(new Date(dt.cancelEffectDateEnds)) : null;
      this.info = dt?.info;
    }
}
