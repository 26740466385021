<div class="content">
  <span mat-button mat-dialog-close class="icon-krizek pilic kill"></span>
  <span *ngIf="data.icon" class="pilic pilic-33 {{ data.icon }} icon"></span>
  <h2 class="center-text" *ngIf="data.header" mat-dialog-title>{{ data.header }}</h2>
  <mat-dialog-content class="line"><span class="go-to-center">{{ data.message }}</span>
  </mat-dialog-content>
  <mat-dialog-actions class="line">
    <div class="u-btn">
      <button mat-button mat-dialog-close mat-dialog-close="false">
        {{ onGetText(data.cancelBuText, 0) }}
      </button>
    </div>
    <div class="u-btn">
      <button mat-button mat-dialog-close mat-dialog-close="true">
        {{ onGetText(data.okBuText, 1) }}
      </button>
    </div>
  </mat-dialog-actions>
</div>