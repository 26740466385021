import { Injectable } from "@angular/core";

import { RestService } from "./rest.service";
import { AuthenticationService } from "./authentication.service";

import { TsXmlCodelistEntries } from "../tsXml/tsXmlCodelistEntries.model";

import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ActiveProductsService {

  private readonly accessibleProducts = Object.freeze({
    cs: ['AP1', 'RP1', 'PM1'],
    sk: ['AP2'],
    en: []
  });

  private readonly obj = {
    PILLOW_CZ: 'cs',
    PILLOW_SK: 'sk'
  };

  private uid: string;
  private products: string[];

  constructor(
    private authServ: AuthenticationService,
    private restServ: RestService,
  ) {
    this.uid = this.authServ.currentUserUidValue?.uid;
  }

  public async getActiveProducts(): Promise<string[]> {
    if (this.authServ.currentUserUidValue?.uid !== this.uid) this.products = null;
    if (this.products) return this.products;
    const comp = this.authServ.currentUserValue?.party?.insuranceCompany;
    const lang = comp ? this.obj[comp] : environment.language;
    const products = [...this.accessibleProducts[lang]];
    const userType = this.authServ.currentUserValue.userType;
    if (userType === 3) {
      Object.keys(this.accessibleProducts).filter(it => it !== lang).forEach(lg => {
        products.push(...this.accessibleProducts[lg]);
      });
    }

    const output = [];
    for (const product of products) {
      if (userType < 2 || await this.isProductAccessible(product)) {
        output.push(product);
      }
    }
    this.products = output;
    return this.products;
  }

  private async isProductAccessible(product: string): Promise<boolean> {
    if (!['AP1', 'AP2', 'RP1', 'PM1'].includes(product)) throw new Error(`isProductAccessible wrong input ${product}`);

    let resp: TsXmlCodelistEntries;
    try {
      resp = await this.restServ.myParCodelistsEntriesByColumnsPost(
        '5225',
        [
          { code: 'PRODUKT', value: product },
          { code: 'AGNT', value: this.authServ.currentUserValue.agentId1 }
        ],
        this.authServ.currentUserUidValue
      );
    } catch {
      return false;
    }
    let output = !!resp?.entry?.length;
    if (output) {
      const since = new Date(resp.entry[0].gclColumn.find(it => it.columnCode === 'PLOD').dateValue);
      const till = new Date(resp.entry[0].gclColumn.find(it => it.columnCode === 'PLDO').dateValue);
      const now = await this.restServ.currentTimeJBossGet(this.authServ.currentUserUidValue);
      if (since.getTime() > now.getTime() || till.getTime() < now.getTime())
        output = false;
    }
    return output;
  }

  public getLocation(product: string) {
    if (this.authServ.currentUserValue.userType < 3) return null;
    if (this.accessibleProducts.cs.includes(product) && this.hasATwin(product, 'cs')) return 'cs';
    if (this.accessibleProducts.sk.includes(product) && this.hasATwin(product, 'sk')) return 'sk';
    if (this.accessibleProducts.en.includes(product) && this.hasATwin(product, 'en')) return 'en';
    return null;
  }

  private hasATwin(product: string, lang: string): boolean {
    for (const lg of Object.keys(this.accessibleProducts).filter(it => it !== lang)) {
      if (this.accessibleProducts[lg].some((it: string) => it.startsWith(product.slice(0, -1)))) return true;
    }
    return false;
  }

  public getAllProducts(insuranceCompany: 'PILLOW_CZ' | 'PILLOW_SK') {
    return this.accessibleProducts[this.obj[insuranceCompany]];
  }
}

