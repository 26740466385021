import { Component, ViewChild, AfterViewInit } from '@angular/core';
import { NgForm, AbstractControl, FormsModule } from '@angular/forms';

import { RestService } from 'src/app/services/rest.service';
import { AppService } from 'src/app/services/app.service';
import { MyErrorHandler } from 'src/app/services/error.service';

import { TsXmlResponse } from 'src/app/tsXml/tsXmlResponse.model';
import { TsXmlRestError } from 'src/app/tsXml/tsXmlRestError.model';

import { u3RestPrefix } from 'src/app/variables/variables';
import { MatFormField, MatError } from '@angular/material/form-field';
import { NgClass, NgIf } from '@angular/common';
import { MatInput } from '@angular/material/input';


@Component({
    selector: 'app-captcha',
    templateUrl: './captcha.component.html',
    styleUrls: ['./captcha.component.scss'],
    imports: [
        FormsModule,
        MatFormField,
        NgClass,
        MatInput,
        NgIf,
        MatError,
    ]
})

export class CaptchaComponent implements AfterViewInit {
  @ViewChild('f', { static: true }) f: NgForm;
  msgCodes = {
    msgCaptchaInvalidCode: 'captchaInvalidCode',
    msgCaptchaCallError: 'captchaCallError',
  };

  captchaImgUrl: string = null;
  captchaHandle: string = null;

  constructor(
    private appService: AppService,
    private myErrorHandler: MyErrorHandler,
    private restService: RestService,
  ) { }

  ngAfterViewInit() {
    this.onCreateCaptchaHandle();
  }

  onCreateCaptchaHandle() {
    this.appService.startLoading();
    this.restService.systemCaptchaCreateToken().then((responseData) => {
      this.appService.stopLoading();
      const r: TsXmlResponse = responseData as TsXmlResponse;
      this.captchaHandle = r.code;
      this.captchaImgUrl = u3RestPrefix + 'system/captcha/' + this.captchaHandle;
    }).catch((error) => {
      this.appService.stopLoading();
      this.setErrors(this.f, error);
    });
  }

  onNext(form: NgForm) {
    if (form.valid && form.touched) {
      this.appService.startLoading();
      this.restService.systemCaptchaVerifyToken(this.captchaHandle, form.value.captchaCode).then(responseData => {
        this.appService.stopLoading();
        const r: TsXmlResponse = responseData as TsXmlResponse;
        if (r.code === 'OK') {
          this.myErrorHandler.captcha = false;
        } else {
          const captchaCodeCtrl: AbstractControl = form.control.get('captchaCode');
          captchaCodeCtrl.setErrors({ errorCode: this.msgCodes.msgCaptchaInvalidCode });
        }
      }).catch(error => {
        this.appService.stopLoading();
        this.setErrors(this.f, error);
      });
    } else {
      form.control.markAllAsTouched();
    }
  }

  onCancel() {
    this.myErrorHandler.captcha = false;
  }

  private setErrors(form: NgForm, error: Error) {
    const tsXmlRestError: TsXmlRestError = this.myErrorHandler.handleError(error);
    const captchaCodeCtrl: AbstractControl = form.control.get('captchaCode');
    if (tsXmlRestError == null || tsXmlRestError.runtimeMsgs == null) {
      captchaCodeCtrl.setErrors({ errorCode: this.msgCodes.msgCaptchaCallError });
    } else {
      captchaCodeCtrl.setErrors({ errorCode: this.msgCodes.msgCaptchaCallError });
    }
  }
}
