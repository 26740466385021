import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';

import { TsXmlCodelistColumn } from '../tsXml/tsXmlCodelistColumn.model';
import { TsXmlCodelistEntry } from '../tsXml/tsXmlCodelistEntry.model';
import { TsXmlParAddress } from '../tsXml/tsXmlParAddress.model';
import { TsXmlParParty } from '../tsXml/tsXmlParParty.model';
import { TDate, TDateN } from '../models/date.model';

import * as uuid from 'uuid';


/** Checksum weights */
const accountControlSumCoefficients: number[] = [1, 2, 4, 8, 5, 10, 9, 7, 3, 6];
/** RegExp of JSON date string format */
const jsonDateFormat = new RegExp(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$|^\d{4}-\d{2}-\d{2}$/);

/**
 * Utils service
 */
@Injectable()
export class UtilsService {
  public static isNull(object: any): boolean {
    if (object === null || object === undefined || Number.isNaN(object)) { // object.isNaN() ??
      return true;
    } else if (typeof object === 'string') {
      return object.length === 0;
    } else {
      return false;
    }
  }

  public static getAge(birthDate: Date, relativeDate: Date): number {
    if (!birthDate || !relativeDate) {
      return null;
    }

    const tmp = new Date(relativeDate.getTime());
    const thisYear = tmp.getFullYear();
    tmp.setFullYear(birthDate.getFullYear());
    if (tmp.getTime() > birthDate.getTime()) {
      return thisYear - birthDate.getFullYear() + 1;
    } else {
      return thisYear - birthDate.getFullYear();
    }
  }

  /**
   * Method return {@code True} if the person is male, {@code False} if it is a female,
   * {@code Null} if it can't be determined
   *
   * @param identification person id
   * @return see description
   */
  public static isMale(identification: string): boolean {
    if (identification == null) {
      return null;
    }

    if (identification != null && identification.length >= 6 && !identification.match('[^\\d]')) {
      return Number(identification.substring(2, 4)) <= 50;
    } else {
      return null;
    }
  }

  /**
   * Whether the given ICO is well formatted
   *
   * @param ico ICO to validate
   * @return see description
   */
  public static validateIcoFormat(ico: string): boolean {
    // = All we need to validate is length of the input

    if (ico == null || !ico.match('[0-9]{8}')) {
      return false;
    }

    let suma = 0;

    for (let i = 0; i < 7; i++) {
      const n = +ico.charAt(i);
      suma += (8 - i) * n;
    }

    const zbytek: number = suma % 11;

    const c: number = +ico.charAt(7);

    return (11 - zbytek) % 10 === c;
  }

  /**
   * Whether the RC given is well formatted
   *
   * @param rc RC to validate
   * @return see description
   */
  public static validateRcFormat(rc: string): boolean {
    let ret = true;

    if (rc != null) {
      if (!rc.match('\\d{9,10}') && !rc.match('\\d{6}/\\d{3,4}')) {
        ret = false; // only 9-10 digits are allowed
      } else {
        rc = rc.replace('/', '');
        const rcFields = UtilsService.parseRcFields(rc);

        if (rcFields != null) {
          if (rcFields[1] <= 0 || rcFields[1] > 12) {
            ret = false; // we only have 12 months
          } else if (rc.length === 10) {
            let mod = Number(rc.substring(0, 9)) % 11;

            if (mod === 10 && rc.charAt(9) === '0') {
              mod = 0;
            }
            // If RC is not congruent to 0 under modulo 11
            if (mod !== Number(rc.substring(9)) /* && !rc.substring(6).match('0000') special format unsupported */) {
              ret = false;
            }
          } else if (rcFields[2] >= 1954) {
            // only RC sooner than 1953 have 9 digits
            ret = false;
          }
        } else {
          ret = false; // digits cannot be separated
        }
      }
    }
    return ret;
  }

  /**
   * Birth day parsed from the personal identification number. Null, if the number is formatted wrong
   *
   * @param rc Person id to parse*
   * @return see description
   */
  public static getBirthDate(rc: string): TDate {
    if (rc == null) {
      return null;
    }

    if (!UtilsService.validateRcFormat(rc)) {
      return null;
    }

    const rcFields = UtilsService.parseRcFields(rc);
    if (rcFields == null) {
      return null;
    }


    const ret = new TDate(new Date(rcFields[2], rcFields[1] - 1, rcFields[0]));

    return ret;
  }

  public static getBirthDateFromParty(party: TsXmlParParty): TDate {
    if (party.birthdate) {
      return new TDate(party.birthdate);
    } else if (party.personalIdentification) {
      return this.getBirthDateFromRC(party.personalIdentification);
    }
    return;
  }

  public static getBirthDateFromRC(rc: string): TDateN {
    if (!rc) return null;
    const [day, month, year] = UtilsService.parseRcFields(rc);
    return new TDate(new Date(year, month - 1, day));
  }

  /**
   * Parse houseNr to popisne and orientacne
   *
   * @param houseNr popisne + orientacne as string
   * @return array[] {popisne, orientacne}
   */
  public static parseHouseNr(houseNr: string): string[] {
    let ret: string[] = [null, null];

    if (UtilsService.isNull(houseNr)) {
      if (houseNr.indexOf('/') > 0) {
        ret = houseNr.split('/');
      } else if (houseNr.length > 0) {
        const last = houseNr.charAt(houseNr.length - 1);
        if (last.toLowerCase() !== last.toUpperCase()) {
          ret[1] = houseNr;
        } else {
          ret[0] = houseNr;
        }
      }
    }

    return ret;
  }

  /**
   * Return date as array [day, month, year]
   *
   * @param rc person identification
   * @return see description
   */
  public static parseRcFields(rc: string): number[] {
    if (rc == null || rc.length < 6) {
      return null;
    } else if (rc.match('[^\\d/]')) {
      return null;
    }

    // == Year
    let year = Number(rc.substring(0, 2));
    if (year < 54 && rc.replace('/', '').length === 10) {
      year = 2000 + year;
    } else {
      year = 1900 + year;
    }

    // == Month
    let month = Number(rc.substring(2, 4));
    if (month > 12) {
      month -= 50;
    }

    // == Day
    const day = Number(rc.substring(4, 6));

    return [day, month, year];
  }

  public static randomId(): string {
    const uint32 = crypto.getRandomValues(new Uint32Array(1))[0];
    return uint32.toString(16);
  }

  public static getColumnByCode(entry: TsXmlCodelistEntry, columnCode: string): TsXmlCodelistColumn {
    if (!UtilsService.isNull(entry)) {
      for (const c of entry.gclColumn) {
        if (c.columnCode === columnCode) {
          return c;
        }
      }
    }
    return null;
  }

  public static getEntryById(entries: TsXmlCodelistEntry[], id: string): TsXmlCodelistEntry {
    if (!UtilsService.isNull(entries)) {
      for (const e of entries) {
        if (e.id === id) {
          return e;
        }
      }
    }

    return null;
  }

  public static myGetEntriesByColumns(
    entries: TsXmlCodelistEntry[],
    columnData: { code: string, value: string }[],
  ): TsXmlCodelistEntry[] {
    const columns: TsXmlCodelistColumn[] = [];
    columnData.forEach(data => {
      const column = new TsXmlCodelistColumn();
      column.columnCode = data.code;
      column.stringValue = data.value;
      columns.push(column);
    });
    return this.getEntriesByColumns(entries, columns);
  }

  public static getEntriesByColumns(
    entries: TsXmlCodelistEntry[],
    columns: TsXmlCodelistColumn[]
  ): TsXmlCodelistEntry[] {
    const ret: TsXmlCodelistEntry[] = [];
    if (entries) {
      for (const e of entries) {
        let equal = false;
        for (const column of columns) {
          const c: TsXmlCodelistColumn = UtilsService.getColumnByCode(e, column.columnCode);
          if (c != null) {
            if (c.booleanValue != null && c.booleanValue === column.booleanValue) {
              equal = true;
            } else if (c.bigDecimalValue != null && c.bigDecimalValue === column.bigDecimalValue) {
              equal = true;
            } else if (c.intValue != null && c.intValue === column.intValue) {
              equal = true;
            } else if (c.stringValue != null && c.stringValue === column.stringValue) {
              equal = true;
            } else {
              equal = false;
            }
          } else {
            equal = false;
          }
          if (!equal) {
            break;
          }
        }
        if (equal) {
          ret.push(e);
        }
      }
    }

    return ret;
  }

  public static getNumber(value: any): number {
    let ret: number = null;
    if (!UtilsService.isNull(value)) {
      ret = +value;
    }
    return ret;
  }

  public static safeAddNumber(val1: number, val2: number): number {
    let ret = 0;
    if (!UtilsService.isNull(val1)) {
      ret = ret + val1;
    }
    if (!UtilsService.isNull(val2)) {
      ret = ret + val2;
    }

    return ret;
  }

  /**
   * Zaescapuje predany retezec pro prenos XML.
   *
   * @param str retezec.
   * @return String upraveny retezec.
   */
  public static xmlEscape(str: string): string {
    if (UtilsService.isNull(str)) {
      return '';
    }

    let result = '';

    for (let i = 0, n = str.length; i < n; i++) {
      const ch = str.charAt(i);
      switch (ch) {
        case '<':
          result += '&lt;';
          break;
        case '>':
          result += '&gt;';
          break;
        case '&':
          result += '&amp;';
          break;
        case '"':
          result += '&quot;';
          break;
        default:
          result += ch;
          break;
      }
    }
    return result;
  }

  public static monthDiff(val1: Date, val2: Date) {
    const ret: number =
      Math.abs(val1.getFullYear() - val2.getFullYear()) * 12 + Math.abs(val1.getDate() - val2.getDate());

    return ret;
  }

  public static getNamesAsString(separator: string, ...names: string[]) {
    let ret = '';

    for (let i = 0; i < names.length; i++) {
      const n = names[i];
      ret += UtilsService.getNotNullString(n);

      if (!UtilsService.isNull(ret) && i !== names.length - 1) {
        ret += separator;
      }
    }

    return ret;
  }

  public static mergeStringListWithDelimeter(strings: string[], delimeter: string) {
    let output = '';

    for (const [index, str] of strings.entries()) {
      output += UtilsService.getNotNullString(str);

      if (!UtilsService.isNull(output) && index !== strings.length - 1) {
        output += delimeter;
      }
    }

    return output;
  }

  /**
   * If value is nulll, return empty srting, else retun value
   *
   * @param value value
   * @return see description
   */
  public static getNotNullString(value: string) {
    return UtilsService.isNull(value) ? '' : value;
  }

  /**
   * Get houseNr as one field from popisne and orientacne
   *
   * @param landRegistryNumber popisne
   * @param houseNumber orientacne
   * @return houseNr as one field
   */
  public static houseNr(landRegistryNumber: string, houseNumber: string): string {
    let ret = '';

    if (UtilsService.isNull(landRegistryNumber)) {
      ret = UtilsService.getNotNullString(houseNumber);
    } else {
      ret = landRegistryNumber;
      if (!UtilsService.isNull(houseNumber)) {
        ret += '/' + houseNumber;
      }
    }

    return ret;
  }

  public static getAddressAsString(value: TsXmlParAddress) {
    const ret = UtilsService.getNamesAsString(
      ', ',
      value.city,
      UtilsService.getNamesAsString(
        ' ',
        value.street,
        UtilsService.houseNr(value.landRegistryNumber, value.houseNumber)
      ),
      value.country
    );
    return ret;
  }

  public static getPartyNameAsString(value: TsXmlParParty) {
    let ret = '';
    if (value != null) {
      if ('PO' === value.partyType) {
        ret = value.companyName;
      } else {
        ret = UtilsService.getNamesAsString(' ', value.forename, value.surname);
      }
    }

    return UtilsService.isNull(ret) ? null : ret;
  }

  /**
   * Check control sum of account number or prefix.
   *
   * @param num account number or prefix
   * @return Whether the control sum is valid
   */
  public static controlSumOfAccountNum(num: string): boolean {
    if (UtilsService.isNull(num)) {
      return true;
    }

    let sum = 0;

    for (let i = 0; i < num.length; i++) {
      const c: number = +num.substring(num.length - 1 - i, num.length - i);
      sum += c * accountControlSumCoefficients[i];
    }

    return sum % 11 === 0;
  }

  public static enableDisableControl(enableDisable: boolean, c: AbstractControl) {
    if (!UtilsService.isNull(c)) {
      if (enableDisable) {
        if (c.disabled) {
          c.enable();
        }
      } else {
        if (c.enabled) {
          c.disable();
        }
      }
    }
  }

  public static tooltipImgLinkWithClose(
    title: string,
    img: string,
    textBeforeLink: string,
    link: string,
    linkTitle: string,
    textAfterLink: string
  ) {
    const html =
      '<div class="overlay">' +
      '<div class="tooltip-outer pb-5 pt-5 pl-3 pr-3 pl-sm-5 pr-sm-5">' +
      '<button class="tooltip-close">' +
      '<span class="pilic icon-krizek"></span>' +
      '</button>' +
      '<div class="row align-items-center">' +
      (UtilsService.isNull(img)
        ? ''
        : '<div class="col-12 col-sm-3 text-center pb-4 pb-sm-0">' +
        '<img src="../../assets/img/' +
        img +
        '" alt="" />' +
        '</div>') +
      '<div class="col-12 ' +
      (UtilsService.isNull(img) ? '' : 'col-sm-9 ') +
      'text-justify">' +
      '<strong>' +
      title +
      '</strong>' +
      '<br>' +
      UtilsService.getNotNullString(textBeforeLink) +
      (UtilsService.isNull(link)
        ? ''
        : '<a class="tooltip-link" href="../../assets/docs/' + link + '" target="_blank"> ' + linkTitle + '</a>.') +
      UtilsService.getNotNullString(textAfterLink) +
      '</div>' +
      '</div>' +
      '</div>' +
      '</div>';

    document.querySelector('body').insertAdjacentHTML('beforeend', html);
    document.querySelector('.tooltip-close').addEventListener('click', () => {
      document.querySelector('.tooltip-close').closest('.overlay').remove();
    });
    document.querySelector('.overlay').addEventListener('click', (e) => {
      if (e.target !== document.querySelector('.overlay')) {
        return false;
      }
      document.querySelector('.overlay').remove();
      return true;
    });

    if (!UtilsService.isNull(link)) {
      document.querySelector('.tooltip-link').addEventListener('click', () => {
        window.open('../../assets/docs/' + link, '_blank');
      });
    }
  }

  public static tooltipImgWithClose(title: string, img: string, text: string) {
    UtilsService.tooltipImgLinkWithClose(title, img, text, null, null, null);
  }

  public static tooltipWithClose(title: string, text: string) {
    const html =
      '<div class="overlay">' +
      '<div class="tooltip-outer pb-5 pt-5 pl-3 pr-3 pl-sm-5 pr-sm-5">' +
      '<button class="tooltip-close">' +
      '<span class="pilic icon-krizek"></span>' +
      '</button>' +
      '<div class="row align-items-center">' +
      '<div class="col-12 col-sm-12 text-justify">' +
      '<strong>' +
      title +
      '</strong>' +
      '<br>' +
      text +
      '</div>' +
      '</div>' +
      '</div>' +
      '</div>';

    document.querySelector('body').insertAdjacentHTML('beforeend', html);
    document.querySelector('.tooltip-close').addEventListener('click', () => {
      document.querySelector('.tooltip-close').closest('.overlay').remove();
    });
    document.querySelector('.overlay').addEventListener('click', (e) => {
      if (e.target !== document.querySelector('.overlay')) {
        return false;
      }
      document.querySelector('.overlay').remove();
      return true;
    });
  }

  /**
   * Nepouzivat, pouzivat WarningDialogComponent
   */
  public static tooltipWithButton(title: string, text: string, btnText: string) {
    const html =
      '<div class="overlay">' +
      '<div class="tooltip-outer pb-5 pt-5 pl-3 pr-3 pl-sm-5 pr-sm-5">' +
      '<div class="row align-items-center">' +
      '<div class="col-12 col-sm-12 text-center">' +
      '<strong>' +
      title +
      '</strong>' +
      '<br><br>' +
      text +
      '<br><br>' +
      '</div>' +
      '</div>' +
      '<div class="align-items-center">' +
      '<div class="col-12 col-sm-12 text-center">' +
      '<button class="tooltip-btn-close btn btn-smaller">' +
      btnText +
      '</button>' +
      '</div>' +
      '</div>' +
      '</div>' +
      '</div>';

    document.querySelector('body').insertAdjacentHTML('beforeend', html);
    document.querySelector('.tooltip-btn-close').addEventListener('click', () => {
      document.querySelector('.tooltip-btn-close').closest('.overlay').remove();
    });

    document.querySelector('.overlay').addEventListener('click', (e) => {
      if (e.target !== document.querySelector('.overlay')) {
        return false;
      }
      document.querySelector('.overlay').remove();
      return true;
    });
  }

  /**
   * Method to check JSON date string format
   * @param value json date string
   * @returns true, if value is regualr json date string
   */
  public static isJsonDateString(value: any): boolean {
    if (value === null || value === undefined) {
      return false;
    }
    if (typeof value === 'string') {
      return jsonDateFormat.test(value);
    }
    return false;
  }

  /**
   * Convert all json's date strings to regular Dates
   * @param jsonObject JSON object
   * @returns JSON object with regular Dates instead of dates as string
   */
  public static convertAllJsonDateStrings(jsonObject: any): any {
    if (jsonObject === null || jsonObject === undefined) {
      return jsonObject;
    }

    if (typeof jsonObject !== 'object') {
      return jsonObject;
    }
    for (const key of Object.keys(jsonObject)) {
      const value = jsonObject[key];
      if (this.isJsonDateString(value)) {
        jsonObject[key] = new TDate(new Date(value));
      } else if (typeof value === 'object') {
        this.convertAllJsonDateStrings(value);
      }
    }
    return jsonObject;
  }

  public static convertDateToUTC(date: Date): Date {
    return new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours(),
      date.getUTCMinutes(),
      date.getUTCSeconds(),
      date.getUTCMilliseconds()
    );
  }

  public static isEmailValid(email: string): boolean {
    const re = new RegExp('^[_a-zA-Z0-9\\.\\-]+@[_a-zA-Z0-9\\.\\-]+\\.[a-zA-Z]{2,}$');
    return re.test(email);
  }

  public static getUUID(): string {
    return uuid.v4();
  }

  /**
   * Format string value to max length and add '..' to the end.
   * @param value string
   * @param maxLength max length of string
   * @returns ret
   */
  public static formatToMaxLength(value: string, maxLength: number) {
    let ret = value;
    const regExp = new RegExp('[a-žA-Ž0-9]$');
    if (value && value.length > maxLength) {
      ret = value.slice(0, maxLength);
      // returned substring must ending with letter or digit
      while (ret.length > 0 && !ret.match(regExp)) {
        ret = ret.slice(0, -1);
      }
      ret += '..';
    }

    return UtilsService.isNull(ret) ? null : ret;
  }

}
