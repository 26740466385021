<div class="cont">
  <i class="logo-footer pilic icon-logo-icon pilic-33" [ngClass]="{'marged': userType !== 0 || simplified}"></i>
  <ng-container *ngIf="userType === 0 && !simplified">

    <div class="card-inner">
      <h3 i18n>Užitečné</h3>
      <div class="card-inner__links">
        <a class="u-underline-anim" i18n routerLinkActive="active" routerLink="/dokumenty">Dokumenty</a>
        <a class="u-underline-anim" i18n routerLinkActive="active" routerLink="/aplikace">Aplikace</a>
        <a class="u-underline-anim" i18n routerLinkActive="active" routerLink="/foto_kilometry">Najeté kilometry</a>
        <a class="u-underline-anim" i18n routerLinkActive="active" routerLink="/foto_vozidlo">Focení vozidla</a>
        <!-- <a class="u-underline-anim" i18n routerLinkActive="active" routerLink="/kontakty"
            fragment="page04_part2_id">Oznámení škody</a> -->
        <a class="u-underline-anim" i18n href="https://klubovyportal.pillow.cz">Klubový portál</a>
        <a class="u-underline-anim" i18n href="https://blog.pillow.cz/slovnik-pojmu/">Slovník pojmů</a>
      </div>

    </div>

    <div class="card-inner">
      <h3 i18n>Povinné</h3>
      <div class="card-inner__links">
        <a class="u-underline-anim" i18n routerLinkActive="active" routerLink="/informace">Informace</a>
        <a class="u-underline-anim" i18n routerLinkActive="active" href="https://blog.pillow.cz/vykazy/"
          target="_blank">
          Výkazy
        </a>
        <a class="u-underline-anim" i18n routerLinkActive="active" routerLink="/soukromi">Soukromí</a>
        <a class="u-underline-anim" i18n routerLinkActive="active" routerLink="/cookies">Cookies</a>
      </div>
    </div>
    <div class="card-inner">
      <h3 i18n>Společnost</h3>
      <div class="card-inner__links">
        <a class="u-underline-anim" i18n routerLinkActive="active" routerLink="/o_nas">O nás</a>
        <a class="u-underline-anim" i18n href="https://blog.pillow.cz">Blog</a>
        <a class="u-underline-anim" i18n routerLinkActive="active" routerLink="/press">Pro novináře</a>
        <a class="u-underline-anim" i18n routerLinkActive="active" routerLink="/login">Pro partnery</a>
        <a class="u-underline-anim" i18n routerLinkActive="active" routerLink="/kontakty">Kontakty</a>
      </div>
    </div>
    <div class="card-inner">
      <h3 i18n>Aplikace</h3>
      <div class="card-inner__apps">
        <a href="https://apps.apple.com/us/app/pillow/id1484946909?l={{ appService.locale.code }}" target="_blank">
          <span class="pilic icon-download-app-store pilic-26"></span>
        </a>
        <a href="https://play.google.com/store/apps/details?id=cz.mypillow.pillow" target="_blank">
          <span class="pilic icon-download-google-play pilic-26"></span>
        </a>
      </div>
    </div>
    <div class="back"></div>
  </ng-container>
</div>

<div class="footer">
  <p i18n class="footer__headline">Pillow pojišťovna, a. s.</p>
  <p i18n class="footer__text">
    Líbalova 2348/1, 149&nbsp;00 Praha&nbsp;4 - Chodov, IČO 042 57 111, zapsaná v OR vedeným Městským soudem v
    Praze, sp. zn. B 20807
  </p>
  <a [href]="privacyLink" i18n>Zpracování osobních údajů</a>
</div>
