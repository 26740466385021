import { TDate } from '../models/date.model';
import { TBigDecimal } from '../models/number.model';

export class TsXmlParAp2Vehicle {
  bodywork: string;
  brand: string;
  brandModelCode: string;
  clientsNewVehiclePrice: TBigDecimal;
  clientsVehiclePrice: TBigDecimal;
  colour: string;
  contractualService: boolean;
  contractualAuthorizedService: boolean;
  engine: string;
  enginePower: number;
  engineVolume: number;
  equipment: string;
  expectedKm: number;
  firstRegistrationDate: TDate;
  fuel: string;
  garaged: boolean;
  licensePlate: string;
  model: string;
  modelYear: string;
  noLicensePlate: boolean;
  noTechnicalCertificate: boolean;
  notRegistered: boolean;
  numberOfSeats: number;
  priceVerified: boolean;
  productionYear: TDate;
  technicalCertificateNumber: string;
  vehicleName: string;
  vehiclePrice: boolean;
  vehicleType: string;
  vehicleUse: string;
  vIN: string;
  weight: number;
  withoutVAT: boolean;
  x4x4: boolean;

  constructor(vehicle?: TsXmlParAp2Vehicle) {
    this.bodywork = vehicle?.bodywork;
    this.brand = vehicle?.brand;
    this.brandModelCode = vehicle?.brandModelCode;
    this.clientsNewVehiclePrice = vehicle?.clientsNewVehiclePrice;
    this.clientsVehiclePrice = vehicle?.clientsVehiclePrice;
    this.colour = vehicle?.colour;
    this.contractualService = vehicle?.contractualService;
    this.contractualAuthorizedService = vehicle?.contractualAuthorizedService;
    this.engine = vehicle?.engine;
    this.enginePower = vehicle?.enginePower;
    this.engineVolume = vehicle?.engineVolume;
    this.equipment = vehicle?.equipment;
    this.expectedKm = vehicle?.expectedKm;
    this.firstRegistrationDate = vehicle?.firstRegistrationDate ? new TDate(new Date(vehicle.firstRegistrationDate)) : vehicle?.firstRegistrationDate;
    this.fuel = vehicle?.fuel;
    this.garaged = vehicle?.garaged;
    this.licensePlate = vehicle?.licensePlate;
    this.model = vehicle?.model;
    this.modelYear = vehicle?.modelYear;
    this.noLicensePlate = vehicle?.noLicensePlate;
    this.noTechnicalCertificate = vehicle?.noTechnicalCertificate;
    this.notRegistered = vehicle?.notRegistered;
    this.numberOfSeats = vehicle?.numberOfSeats;
    this.priceVerified = vehicle?.priceVerified;
    this.productionYear = vehicle?.productionYear ? new TDate(new Date(vehicle.productionYear)) : vehicle?.productionYear;
    this.technicalCertificateNumber = vehicle?.technicalCertificateNumber;
    this.vehicleName = vehicle?.vehicleName;
    this.vehiclePrice = vehicle?.vehiclePrice;
    this.vehicleType = vehicle?.vehicleType;
    this.vehicleUse = vehicle?.vehicleUse;
    this.vIN = vehicle?.vIN;
    this.weight = vehicle?.weight;
    this.withoutVAT = vehicle?.withoutVAT;
    this.x4x4 = vehicle?.x4x4;
  }
}
