import { Injectable } from '@angular/core';
import { TsXmlParVehicleInspection } from 'src/app/tsXml/tsXmlParVehicleInspection.model';

@Injectable()
export class OdometerService {
  public step1 = false;
  public step2 = false;
  public approval = false;
  public photoDate = false;
  public documents: string = null;
  public imgSrc: string | ArrayBuffer = null;
  public imgName: string = null;
  public model: TsXmlParVehicleInspection = new TsXmlParVehicleInspection();
}
