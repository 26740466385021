/* eslint-disable no-useless-escape */
import { Injectable } from '@angular/core';

export type BrowsersInfo = {
  os: string,
  browser: string,
  device: 'tablet' | 'mobile' | 'desktop' | 'bot',
  osDetail: string,
  browserVersion: string,
  orientation: 'landscape' | 'portrait',
  userAgent: string,
};

@Injectable({
  providedIn: 'root'
})
export class BrowserInfoService {

  public getBrowsersDetails(): BrowsersInfo {
    const browser = this.getBrowserInfo();
    return {
      os: this.getPlatform(),
      browser: browser.brand,
      device: this.getDevice(),
      osDetail: this.getOsDetail(),
      browserVersion: browser.version,
      orientation: this.getOrientation(),
      userAgent: navigator.userAgent
    };
  }

  private getPlatform(): string {
    return navigator[`userAgentData`]?.platform || navigator?.platform || 'unknown';
  }

  private getBrowserInfo(): { brand: string, version: string } {
    // if (navigator[`userAgentData`]?.brands?.length)
    //   return navigator[`userAgentData`]?.brands.find(a => !/^Not.*Brand$/.test(a.brand));
    if (navigator.userAgent.indexOf("Opera") !== -1 || navigator.userAgent.indexOf('OPR') !== -1) {
      return {
        brand: 'Opera',
        version: navigator.userAgent.toLowerCase().replace(/.*opr[a-z]*\/([\d\.]+).*/, '$1')
      };
    }
    if (navigator.userAgent.indexOf("Edg") !== -1) {
      return {
        brand: 'Edge',
        version: navigator.userAgent.toLowerCase().replace(/.*edg[a-z]*\/([\d\.]+).*/, '$1')
      };
    }
    if (navigator.userAgent.indexOf("Firefox") !== -1 || navigator.userAgent.indexOf("FxiOS") !== -1) {
      return {
        brand: 'Firefox',
        version: navigator.userAgent.toLowerCase().replace(/.*(firefox|fxios)\/([\d\.]+).*/, '$2')
      };
    }
    if ((navigator.userAgent.indexOf("MSIE") !== -1) || (navigator.userAgent.indexOf("Trident") !== -1)) {
      return {
        brand: 'IE',
        version: navigator.userAgent.toLowerCase().replace(/.*(msie |rv:)([\d\.]+).*/, '$2')
      };
    }
    if (navigator.userAgent.indexOf("DuckDuckGo") !== -1) {
      return {
        brand: 'DuckDuckGo',
        version: navigator.userAgent.toLowerCase().replace(/.*duckduckgo\/([\d\.]+).*/, '$1')
      };
    }
    if (navigator.userAgent.indexOf("SamsungBrowser") !== -1) {
      return {
        brand: 'SamsungBrowser',
        version: navigator.userAgent.toLowerCase().replace(/.*samsungbrowser\/([\d\.]+).*/, '$1')
      };
    }
    if (navigator.userAgent.indexOf("Chrome") !== -1 || navigator.userAgent.indexOf("CriOS") !== -1) {
      return {
        brand: 'Chrome',
        version: navigator.userAgent.toLowerCase().replace(/.*(chrome|crios)\/([\d\.]+).*/, '$2')
      };
    }
    if (navigator.userAgent.indexOf("Safari") !== -1) {
      return {
        brand: 'Safari',
        version: navigator.userAgent.toLowerCase().replace(/.*version\/([\d\.]+).*/, "$1")
      };
    }
    return {
      brand: 'unknown',
      version: 'unknown'
    };
  }

  private getDevice(): 'tablet' | 'mobile' | 'desktop' | 'bot' {
    if (/(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(navigator.userAgent.toLowerCase()))
      return 'tablet';
    if (navigator[`userAgentData`]?.mobile || window.matchMedia("only screen and (max-width: 480px)").matches)
      return 'mobile';
    if (window.matchMedia("only screen and (min-width: 481px)").matches)
      return 'desktop';
    return 'bot';
  }

  private getOsDetail(): string {
    return navigator.userAgent.replace(/^[^(]*\(([^\)]*)\).*/, "$1");
  }

  private getOrientation(): 'landscape' | 'portrait' {
    return window.innerWidth > window.innerHeight ? 'landscape' : 'portrait';
  }

}
