import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { Observable } from 'rxjs';

import { AuthenticationService } from './authentication.service';


@Injectable({
  providedIn: 'root',
})
export class FormGuardGuard {
  private readonly _23 = ['/portal-home', '/portal-calculation', '/portal-changes', '/portal-clients'];
  private readonly _123 = ['/user-info', '/portal-policies'];
  private readonly permissioned = Object.freeze({
    3: [...this._123, ...this._23, '/portal-chat', '/konto'],
    2: [...this._123, ...this._23],
    1: [...this._123, '/smlouvy', '/qr', '/poslat', '/paid', '/online', '/konto', '/workflow', '/policy-change'],
    0: []
  });

  constructor(
    private authServ: AuthenticationService,
    private router: Router,
  ) { }

  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (nextState.url === '/login') return true;
    return component[`mayILeaveYou`]();
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const url = '/' + state.url.substring(1).replace(/[/?;].*$/, '');
    const mayI = this.permissioned[this.authServ.currentUserValue.userType].includes(url);
    if (!mayI) this.router.navigate(['/notfound']);
    return mayI;
  }
}
