import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { UtilsService } from 'src/app/services/utils.service';

import { Party } from './party.model';
import { TDate } from 'src/app/models/date.model';
import { UserType } from 'src/app/models/user.model';
import { Variant } from './step1/variant/variant.model';
import { TsXmlParAddress } from 'src/app/tsXml/tsXmlParAddress.model';
import { TsXmlParVoucher } from 'src/app/tsXml/tsXmlParVoucher.model';
import { TsXmlBankAccount } from 'src/app/tsXml/tsXmlBankAccount.model';
import { TsXmlCodelistEntry } from 'src/app/tsXml/tsXmlCodelistEntry.model';
import { TsXmlBusinessTransaction } from 'src/app/tsXml/tsXmlBusinessTransaction.model';
import { TsXmlParAp1SavePolicyReq } from 'src/app/tsXml/tsXmlParAp1SavePolicyReq.model';
import { TsXmlParAp1RatePolicyResp } from 'src/app/tsXml/tsXmlParAp1RatePolicyResp.model';
import { TsXmlParAp1VariantOfSubjectReq } from 'src/app/tsXml/tsXmlParAp1VariantOfSubjectReq.model';
import { TsXmlParAp1VariantOfSubjectResp } from 'src/app/tsXml/tsXmlParAp1VariantOfSubjectResp.model';


@Injectable({
  providedIn: 'root'
})
export class Ap1Service {
  private setDateIsLaunched: boolean;

  public userType: UserType = UserType.A_UNAUTH;

  public policyId: string;

  public step1 = false;
  public step2 = false;
  public step3 = false;

  public step1Party1: Party;
  public policyHolderMailingAddress: TsXmlParAddress;

  public step2PolicyHolderParty: Party;
  public step2VehicleOwnerParty: Party;
  public step2VehicleHolderParty: Party;

  public noTooltip = false;

  public calculated = false;

  vehicleTypes: TsXmlCodelistEntry[];
  vehicleTypesCkp: TsXmlCodelistEntry[];
  vehicleGroups: TsXmlCodelistEntry[];
  brandsModels: TsXmlCodelistEntry[];
  brands: TsXmlCodelistEntry[];
  models: TsXmlCodelistEntry[];
  modelYears: Map<string, string>;
  engines: Map<string, number[]>;
  bodyWorks: Map<string, number>;
  fuels: TsXmlCodelistEntry[];
  fuelsCkp: TsXmlCodelistEntry[];
  vehicleUses: TsXmlCodelistEntry[];
  expectedKm: TsXmlCodelistEntry;

  public variant1 = new Variant(1);
  public variant2 = new Variant(2);
  public variant3 = new Variant(3);

  public variantForm: UntypedFormGroup;

  public pr1Discount: number;
  public sk1Discount: number;
  public hp1Discount: number;
  public sz1Discount: number;
  public zv1Discount: number;
  public ov1Discount: number;
  public va1Discount: number;
  public pl1Discount: number;
  public pa1Discount: number;
  public as1Discount: number;
  public za1Discount: number;
  public ur1Discount: number;

  /** codelist 5071 */
  public paymentFrequencies: TsXmlCodelistEntry[];
  /** codelist 5084 */
  public riskVariants: TsXmlCodelistEntry[];
  /** codelist 5085 */
  public complicities: TsXmlCodelistEntry[];
  /** codelist 5114 */
  public discounts: TsXmlCodelistEntry[];

  public discountId: string;

  public complicityOptions: TsXmlCodelistEntry[];
  public pr1Options: TsXmlCodelistEntry[];
  public as1OptionsV1: TsXmlCodelistEntry[];
  public as1OptionsV2: TsXmlCodelistEntry[];
  public as1OptionsV3: TsXmlCodelistEntry[];
  public za1Options: TsXmlCodelistEntry[];
  public ur1Options: TsXmlCodelistEntry[];

  public variants: TsXmlParAp1VariantOfSubjectResp[];

  public gap: boolean;

  public variantsPR1: TsXmlParAp1VariantOfSubjectResp[];
  public variantsSK1: TsXmlParAp1VariantOfSubjectResp[];
  public variantsHP1: TsXmlParAp1VariantOfSubjectResp[];
  public variantsSZ1: TsXmlParAp1VariantOfSubjectResp[];
  public variantsZV1: TsXmlParAp1VariantOfSubjectResp[];
  public variantsOV1: TsXmlParAp1VariantOfSubjectResp[];
  public variantsVA1: TsXmlParAp1VariantOfSubjectResp[];
  public variantsPL1: TsXmlParAp1VariantOfSubjectResp[];
  public variantsPA1: TsXmlParAp1VariantOfSubjectResp[];
  public variantsAS1: TsXmlParAp1VariantOfSubjectResp[];
  public variantsZA1: TsXmlParAp1VariantOfSubjectResp[];
  public variantsUR1: TsXmlParAp1VariantOfSubjectResp[];

  public bankAccountSink: TsXmlBankAccount;

  public businessTransaction: TsXmlBusinessTransaction;

  public modelPattern: TsXmlParAp1SavePolicyReq = new TsXmlParAp1SavePolicyReq();

  public calculationId: string;

  public calculationV1Id: string;
  public calculationV2Id: string;
  public calculationV3Id: string;

  public variantOfSubjectV1: TsXmlParAp1VariantOfSubjectReq[];
  public variantOfSubjectV2: TsXmlParAp1VariantOfSubjectReq[];
  public variantOfSubjectV3: TsXmlParAp1VariantOfSubjectReq[];
  public voucher: TsXmlParVoucher;
  private _resp: TsXmlParAp1RatePolicyResp;
  public zipValues: { zip: string, city: string; };
  public cooperationValue = true;
  private _agentId: string;

  public get agentId(): string {
    return this._agentId;
  }
  public set agentId(value: string) {
    this._agentId = value;
  }

  public get resp(): TsXmlParAp1RatePolicyResp {
    return this._resp;
  }
  public set resp(value: TsXmlParAp1RatePolicyResp) {
    this._resp = value;
  }

  private _campaign: string;

  public get campaign(): string {
    return this._campaign;
  }
  public set campaign(value: string) {
    this._campaign = value;
  }


  constructor(
  ) {
    this.variantsPR1 = [];
    this.variantsSK1 = [];
    this.variantsHP1 = [];
    this.variantsSZ1 = [];
    this.variantsZV1 = [];
    this.variantsOV1 = [];
    this.variantsVA1 = [];
    this.variantsPL1 = [];
    this.variantsPA1 = [];
    this.variantsAS1 = [];
    this.variantsZA1 = [];
    this.variantsUR1 = [];
  }

  getVariantOfSubjectByVariant(
    variants: TsXmlParAp1VariantOfSubjectResp[],
    variant: string
  ): TsXmlParAp1VariantOfSubjectResp {
    for (const vos of variants) {
      if (vos.variant === variant) {
        return vos;
      }
    }

    return null;
  }

  getGapRiskVariant(risk: string, gap: boolean, option: string): TsXmlCodelistEntry {
    for (const e of this.riskVariants) {
      if (
        risk === UtilsService.getColumnByCode(e, 'RIZIKO').stringValue &&
        (UtilsService.isNull(UtilsService.getColumnByCode(e, 'GAP').booleanValue) ||
          gap === UtilsService.getColumnByCode(e, 'GAP').booleanValue)
      ) {
        if (risk === 'HP1' || risk === 'VA1') {
          if (option === UtilsService.getColumnByCode(e, 'SPOLUUCAST').stringValue) {
            return e;
          }
        } else {
          return e;
        }
      }
    }

    return null;
  }

  public init(now: TDate) {
    this.modelPattern.calculationId = null;

    this.calculationId = null;

    this.calculationV1Id = null;
    this.calculationV2Id = null;
    this.calculationV3Id = null;

    this.businessTransaction = null;

    this.modelPattern.accountNumber = null;
    this.modelPattern.bankCode = null;
    this.modelPattern.emailForSendingOffer = null;

    this.modelPattern.firstPaymentMethod = 'BANK';

    if (this.bankAccountSink == null) {
      this.bankAccountSink = new TsXmlBankAccount();
    } else {
      this.bankAccountSink.accountNumber = null;
      this.bankAccountSink.bank = null;
    }

    this.modelPattern.paymentMethod = null;
    this.modelPattern.paymentFrequency = '1';

    const policyBeginning = new TDate(new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() + 1
    ));
    this.modelPattern.policyBeginning = new TDate(policyBeginning);



    if (this.step1Party1 == null) {
      this.modelPattern.policyHolder.partyType = 'FORC';
      this.modelPattern.policyHolder.personalIdentification = null;
      this.modelPattern.policyHolder.address.country = 'CZE';
      this.modelPattern.policyHolder.mailingAddress.country = 'CZE';
    } else {
      this.step1Party1.initModel();
    }

    if (this.step2PolicyHolderParty != null) {
      this.step2PolicyHolderParty.initModel();
    }

    this.modelPattern.policyHolderSameAsVehicleHolder = null;
    this.modelPattern.policyHolderSameAsVehicleOwner = true;

    if (this.step2VehicleOwnerParty == null) {
      this.modelPattern.vehicleOwner.partyType = 'FORC';
      this.modelPattern.vehicleOwner.personalIdentification = null;
      this.modelPattern.vehicleOwner.forename = null;
      this.modelPattern.vehicleOwner.surname = null;
      this.modelPattern.vehicleOwner.address.country = 'CZE';
      this.modelPattern.vehicleOwner.address.zipCode = null;
      this.modelPattern.vehicleOwner.address.city = null;
      this.modelPattern.vehicleOwner.address.street = null;
      this.modelPattern.vehicleOwner.address.landRegistryNumber = null;
      this.modelPattern.vehicleOwner.address.houseNumber = null;
    } else {
      this.step2VehicleOwnerParty.initModel();
    }

    if (this.step2VehicleHolderParty == null) {
      this.modelPattern.vehicleHolder.partyType = 'FORC';
      this.modelPattern.vehicleHolder.personalIdentification = null;
      this.modelPattern.vehicleHolder.forename = null;
      this.modelPattern.vehicleHolder.surname = null;
      this.modelPattern.vehicleHolder.address.country = 'CZE';
      this.modelPattern.vehicleHolder.address.zipCode = null;
      this.modelPattern.vehicleHolder.address.city = null;
      this.modelPattern.vehicleHolder.address.street = null;
      this.modelPattern.vehicleHolder.address.landRegistryNumber = null;
      this.modelPattern.vehicleHolder.address.houseNumber = null;
    } else {
      this.step2VehicleHolderParty.initModel();
    }

    this.modelPattern.policyHolder.marketingApproval = false;

    this.modelYears = new Map<string, string>();
    this.engines = new Map<string, number[]>();
    this.bodyWorks = new Map<string, number>();

    this.noTooltip = false;
    this.calculated = false;
  }

  isGap(variantOfSubject: TsXmlParAp1VariantOfSubjectReq[]): boolean {
    if (variantOfSubject) {
      for (const vos of variantOfSubject) {
        const e = UtilsService.getEntryById(this.riskVariants, vos.variant);

        if (e) {
          const column = UtilsService.getColumnByCode(e, 'GAP');
          if (column && column.booleanValue) {
            return true;
          }
        }
      }
    }
    return false;
  }

  public emptyMe() {
    this.setDateIsLaunched = null;
    this.userType = UserType.A_UNAUTH;
    this.policyId = null;
    this.step1 = false;
    this.step2 = false;
    this.step3 = false;
    this.step1Party1 = null;
    this.step2PolicyHolderParty = null;
    this.step2VehicleOwnerParty = null;
    this.step2VehicleHolderParty = null;
    this.noTooltip = false;
    this.calculated = false;
    this.vehicleTypes = null;
    this.vehicleTypesCkp = null;
    this.vehicleGroups = null;
    this.brandsModels = null;
    this.brands = null;
    this.models = null;
    this.modelYears = null;
    this.engines = null;
    this.bodyWorks = null;
    this.fuels = null;
    this.fuelsCkp = null;
    this.vehicleUses = null;
    this.expectedKm = null;
    this.variant1 = new Variant(0);
    this.variant2 = new Variant(1);
    this.variant3 = new Variant(2);
    this.variantForm = null;
    this.pr1Discount = null;
    this.sk1Discount = null;
    this.hp1Discount = null;
    this.sz1Discount = null;
    this.zv1Discount = null;
    this.ov1Discount = null;
    this.va1Discount = null;
    this.pl1Discount = null;
    this.pa1Discount = null;
    this.as1Discount = null;
    this.za1Discount = null;
    this.ur1Discount = null;
    this.paymentFrequencies = null;
    this.riskVariants = null;
    this.complicities = null;
    this.discounts = null;
    this.discountId = null;
    this.complicityOptions = null;
    this.pr1Options = null;
    this.as1OptionsV1 = null;
    this.as1OptionsV2 = null;
    this.as1OptionsV3 = null;
    this.za1Options = null;
    this.ur1Options = null;
    this.variants = null;
    this.gap = null;
    this.variantsPR1 = null;
    this.variantsSK1 = null;
    this.variantsHP1 = null;
    this.variantsSZ1 = null;
    this.variantsZV1 = null;
    this.variantsOV1 = null;
    this.variantsVA1 = null;
    this.variantsPL1 = null;
    this.variantsPA1 = null;
    this.variantsAS1 = null;
    this.variantsZA1 = null;
    this.variantsUR1 = null;
    this.bankAccountSink = null;
    this.businessTransaction = null;
    this.modelPattern = new TsXmlParAp1SavePolicyReq();
    this.calculationId = null;
    this.calculationV1Id = null;
    this.calculationV2Id = null;
    this.calculationV3Id = null;
    this.variantOfSubjectV1 = null;
    this.variantOfSubjectV2 = null;
    this.variantOfSubjectV3 = null;
    this.zipValues = null;
    this.voucher = null;
    this.policyHolderMailingAddress = null;
    this.resp = null;
    this._agentId = null;
    this._campaign = null;
    this.cooperationValue = null;
  }
}
