import { TDate } from 'src/app/models/date.model';
import { TBigDecimal } from '../models/number.model';

/** Oprávněná osoba / Obmyšlená osoba. */
export class TsXmlParAuthorizedPerson {
    /** Jméno. */
    forename: string; // TStr20Null
    /** Příjmení. */
    surname: string; // TStr50Null
    /** Datum narození. */
    birthdate: TDate; // TDatumDenNull
    /** Podíl. */
    share: TBigDecimal; // TDec8x6Null
    /** Popis. */
    description: string; // TStr2000Null

    constructor(person?: TsXmlParAuthorizedPerson) {
      this.forename = person?.forename;
      this.surname = person?.surname;
      this.birthdate = person?.birthdate ? new TDate(new Date(person?.birthdate)) : person?.birthdate;
      this.share = person?.share;
      this.description = person?.description;
    }
}
