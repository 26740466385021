import { Pipe, PipeTransform } from '@angular/core';
import { parsePhoneNumber, PhoneNumber } from 'libphonenumber-js';


@Pipe({
  name: 'phone',
  pure: false,
  standalone: true
})
export class PhonePipe implements PipeTransform {

  transform(value: string, type?: 'uri' | 'text'): any {
    let phoneNumber: PhoneNumber;
    try {
      phoneNumber = parsePhoneNumber(value) as PhoneNumber;
      if (!phoneNumber) return value;
    } catch {
      return value;
    }
    if (type === 'uri') return phoneNumber.getURI();
    else return phoneNumber.formatInternational();
  }

}
