import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogClose, MatDialogContent } from '@angular/material/dialog';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { NgClass, NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-tooltip-dialog',
    templateUrl: './tooltip.component.html',
    styleUrls: ['./tooltip.component.scss'],
    imports: [MatDialogClose, CdkScrollable, MatDialogContent, NgClass, NgIf, NgFor]
})
export class TooltipDialogComponent implements OnInit {
  protected _textIsArray: boolean;
  protected lines: (string | (string | { text: string, link: string })[])[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      text: string | (string | { text: string, link: string })[],
      headline?: string,
      icon?: string,
      img?: string,
      imgButtom?: string,
      hued?: boolean,
      lines?: (string | (string | { text: string, link: string })[])[],
      hybrid?: any
    }
  ) { }

  ngOnInit(): void {
    if (this.data.lines) this.lines = this.data.lines;
    if (this.data?.text && Array.isArray(this.data.text)) {
      this._textIsArray = true;
    } else {
      if (typeof this.data.text !== 'string' || !this.data.text.includes('•')) return;
      this.lines = this.data.text.split('•').map(it => it.trim()).filter(it => !!it);
    }
  }


  _htmlFunIsString(obj: string | { text: string, link: string }): boolean {
    return typeof obj === 'string';
  }

  _htmlFunGo2Link(url: string) {
    if (!url) return;
    window.open(url, '_blank');
  }

}
