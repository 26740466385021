import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

import { environment } from 'src/environments/environment';

import { OuhaService } from './ouha.service';
import { LoginService } from '../apps/login/login.service';
import { BrowserInfoService } from 'src/app/services/browser-info.service';

import { TDate } from '../models/date.model';
import { TsXmlBugReport } from '../tsXml/tsXmlBugReport.model';
import { TsXmlRestError } from '../tsXml/tsXmlRestError.model';
import { TsXmlRestRuntimeMessage } from '../tsXml/tsXmlRestRuntimeMessage.model';

import { u3RestPrefix } from '../variables/variables';


@Injectable({
  providedIn: 'root'
})
export class MyErrorHandler implements ErrorHandler {
  private _userType: 0 | 1 | 2 | 3;
  private imNotFirst: boolean;

  public captcha = false;

  constructor(
    private browserInfoServ: BrowserInfoService,
    private cookieServ: CookieService,
    private http: HttpClient,
    private loginServ: LoginService,
    private ouhaServ: OuhaService,
    private router: Router,
    private zone: NgZone,
  ) { }

  public set userType(value: 0 | 1 | 2 | 3) {
    this._userType = value;
  }

  handleError(error: Error | HttpErrorResponse): TsXmlRestError {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (chunkFailedMessage.test(error?.message || '') && !this.cookieServ.get('chunk')) {
      this.cookieServ.set('chunk', '1', 1);
      window.location.reload();
      return;
    }
    if (!error?.message?.includes('ExpressionChangedAfterItHasBeenCheckedError')) {
      console.error(error);
    }
    return this.handleErrorIgnoreBugReportForHttpErrorStatus(error);
  }

  handleErrorIgnoreBugReportForHttpErrorStatus(error: Error | HttpErrorResponse, ...status: number[]): TsXmlRestError {
    if (this.imNotFirst) return;
    this.imNotFirst = !this.imNotFirst;
    if (!status) {
      status = [];
    }
    let gwtXmlRestError: TsXmlRestError = null;
    if (error instanceof HttpErrorResponse) {
      if (error.status === 429) {
        this.captcha = true;
      } else if (error.status === 401) {
        try {
          sessionStorage.removeItem('currentUser');
        } catch {
          alert($localize`Vaše nastavení prohlížeče blokuje veškeré cookies (i ty nezbytné) a brání tím správné funkci pillow.cz.`);
          return;
        }
        this.loginServ.timeout = true;
        this.router.navigate(['/login']);
      } else if (error.status === 404 && status.indexOf(404) === -1) { // IE doesn't support includes
        this.navigate('/notfound');
      } else if (error.status === 503) {
        this.navigate('/outage');
      } else if (error.status === 400 && error.error.runtimeMsgs?.length && error.error.runtimeMsgs[0].msgCode === '622539') {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.router.navigate([`/ap1/step1`]);
          this.ouhaServ.ouha({
            header: $localize`STOP, dál to nejede`,
            message: `${error.error.runtimeMsgs[0].msgText}: ` + $localize`Bohužel u tohoto vozidla není možné dokončit automatický proces sjednání pojištění. Pokud budete i tak chtít pojištění pro toto vozidlo od Pillow, bude třeba individuálně posoudit rizikovost a stanovit vyšší cenu zahrnující náklady za manuální zpracování smlouvy.`,
            button: $localize`Raději pojistím jinde`
          });
        });
      } else {
        if (typeof error.error === 'string') {
          gwtXmlRestError = new TsXmlRestError();
          const tsXmlRestRuntimeMessage = new TsXmlRestRuntimeMessage();
          tsXmlRestRuntimeMessage.msgText = error.error;
          gwtXmlRestError.runtimeMsgs = [tsXmlRestRuntimeMessage];
          gwtXmlRestError.statusCode = error.status;
        } else {
          gwtXmlRestError = { ...error.error, headers: error.headers};
        }

        if (environment.production && status.indexOf(error.status) === -1) { // IE doesn't support includes
          this.submitBugReport(error);
        }
      }
    } else if (
      error &&
      error.message &&
      error.message.startsWith('Uncaught (in promise): Error: Cannot match any routes.')
    ) {
      this.navigate('/notfound');
    } else if (environment.production) {
      this.submitBugReport(error);
    } else if (
      error &&
      error.message &&
      error.message.indexOf('ExpressionChangedAfterItHasBeenCheckedError') === -1 // angular 11
    ) {
      throw error;
    }
    this.imNotFirst = !this.imNotFirst;
    return gwtXmlRestError;
  }

  navigate(url: string) {
    this.zone.run(() => this.router.navigate([url]));
  }

  public submitBugReport(error: Error) {
    const bugReport = new TsXmlBugReport();
    // const deviceInfo = JSON.stringify(this.deviceDetector.getDeviceInfo());
    // const device = JSON.stringify({
    //   desktop: this.deviceDetector.isDesktop(), mobile: this.deviceDetector.isMobile(), tablet: this.deviceDetector.isTablet()
    // });
    // bugReport.browser = deviceInfo + device;
    bugReport.browser = window.navigator.userAgent;

    // const d = new Date();
    // const utcDate = new Date(Date.UTC(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate(),
    //   d.getUTCHours(), d.getUTCMinutes(), d.getUTCSeconds()));
    bugReport.date = new TDate(new Date());
    // bugReport.date = utcDate;
    const browserInfo = JSON.stringify(this.browserInfoServ.getBrowsersDetails());
    bugReport.exceptionMessage = `${error.message}; browserInfo: ${browserInfo}; userType: ${this._userType}`;
    bugReport.exceptionStacktrace = [error.stack];
    bugReport.kind = 'PILLOW';
    bugReport.url = window.location.href;
    if (error instanceof HttpErrorResponse) {
      if (error.error?.restCallId || error.error?.callId) { // TODO restCallId till core 151; callId since core 152
        bugReport.restCallId = error.error.restCallId || null;
        bugReport.callId = error.error.callId || null;
      } else if (error.headers) {
        bugReport.restCallId = error.headers.get('RestCallId') || null;
        bugReport.callId = error.headers.get('CallId') || null;
      }
    }
    bugReport.callIdType = 'REST';
    this.http.post(u3RestPrefix + 'system/bugReports/', bugReport).subscribe();
  }

  public getErrorMessage(error: TsXmlRestError): string {
    let output = null;
    if (error?.statusCode === 400 && error?.runtimeMsgs?.length) {
      switch (error?.runtimeMsgs[0].msgCode) {
        case '621422':
          output = $localize`E-mail patří jinému klientovi.`;
          break;
        case '621102':
          output = $localize`Nemáte povoleno sjednávat smlouvy. Pokud se jedná o chybu, kontaktujte prosím svou mateřskou společnost, aby Vám možnost sjednání aktivovala.`;
          break;
        case '621103':
          output = $localize`Počátek pojištění musí být v budoucnosti a lze ho odložit maximálně o 2 měsíce.`;
          break;
        case '210962':
        case '621667':
          output = error.runtimeMsgs[0].msgText;
          break;
      }
    }
    return output;
  }

  public selectError(fg: FormGroup) {
    if (!fg) return;
    fg.markAllAsTouched();
    setTimeout(() => {
      const el = document.querySelector('.form-group--error');
      if (el) {
        el.scrollIntoView({ behavior: 'smooth', block: 'center' });
        el.querySelector('input')?.focus();
      } else {
        const er = new Error();
        er.name = 'FormGroup error';
        er.message = JSON.stringify(fg.value);
        this.handleError(er);
      }
    }, 100);
  }
}
