import { DecimalPipe } from '@angular/common';
import { Directive, ElementRef, HostListener, Input, OnInit, Optional, Renderer2, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';

import { BehaviorSubject } from 'rxjs';

import { AppService } from 'src/app/services/app.service';

import { parseIncompletePhoneNumber, formatIncompletePhoneNumber, validatePhoneNumberLength } from 'libphonenumber-js';
import { codes } from 'src/app/modules/phone/country-codes';

@Directive({
  selector: '[appMyInput]',
  standalone: true,
  providers: [DecimalPipe]
})
export class InputDirective implements OnInit, ControlValueAccessor {
  @Input({ required: true }) what: 'IBAN' | 'number' | 'phone';
  @Input() digitInfo: string;
  @Input() maxlength: number = 29;
  @Input() phonePrefix: BehaviorSubject<string>;
  private onTouched: () => void;
  private value: string;
  private countryCode: string;


  constructor(
    private elRef: ElementRef<HTMLInputElement>,
    private renderer: Renderer2,
    private appServ: AppService,
    private decimalPipe: DecimalPipe,
    @Self() @Optional() private controlDir: NgControl
  ) {
    controlDir.valueAccessor = this;
  }
  ngOnInit(): void {
    this.renderer.setAttribute(this.elRef.nativeElement, 'maxLength', this.maxlength + '');
    this.phonePrefix?.subscribe(prefix => {
      this.countryCode = codes[prefix];
      this.blurAction();
    });
  }

  writeValue(obj: any): void {
    this.updateValue(obj, this.elRef.nativeElement.selectionStart);
  }

  registerOnChange(fn: any): void {
    this.onInput = (value: string) => {
      if (this.value !== value) this.updateValue(value, this.elRef.nativeElement.selectionStart);
      fn(this.getRealValue());
    };
  }

  private getRealValue() {
    const value = this.elRef?.nativeElement?.value;
    if (!value) return value;
    if (this.what === 'IBAN') return value?.replace(/\s/g, '');
    if (this.what === 'number') {
      if (this.appServ.locale.code === 'en') return parseFloat(value.replace(/,/g, ''));
      return parseFloat(value.replace(/\s/g, '').replace(',', '.'));
    }
    if (this.what === 'phone') return parseIncompletePhoneNumber(value);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.renderer.setAttribute(
        this.elRef?.nativeElement,
        'disabled',
        String(isDisabled)
      );
    } else {
      this.renderer.removeAttribute(this.elRef?.nativeElement, 'disabled');
    }
  }

  @HostListener('input', ['$event.target.value'])
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onInput = (_: any) => { };

  @HostListener('keydown', ['$event'])
  keydown(event: KeyboardEvent) {
    if (!event.ctrlKey && !event.metaKey) {
      if (event.key?.length === 1) {
        event.preventDefault();
        const el = this.elRef?.nativeElement;
        const divide = el.selectionEnd - el.selectionStart;
        if ((el.value?.length - divide) !== 24) {
          let val = event.key;
          if (this.what === 'number' && this.appServ.locale.code !== 'en' && val === '.') val = ',';
          this.addKeys(val);
        }
      } else if (['Delete', 'Backspace'].includes(event.key)) {
        event.preventDefault();
        this.backspace(event.key as any);
      }
    }
  }

  private addKeys(keys: string) {
    const el = this.elRef?.nativeElement;
    const part1 = el.value.substring(0, el.selectionStart);
    const value = part1 + keys + el.value.substring(el.selectionEnd);
    this.updateValue(value, part1.length + keys.length);
  }

  private backspace(key: string) {
    const el = this.elRef?.nativeElement;
    let chgB = 0;
    let chgD = 0;
    const selectedText = el.value.substring(el.selectionStart, el.selectionEnd);
    if (selectedText === ' ') {
      if (key === 'Backspace') {
        el.selectionEnd--;
      } else if (key === 'Delete') {
        el.selectionStart++;
      }
      return;
    }

    if (key === 'Backspace' && el.selectionStart === el.selectionEnd) {
      if (/\s/.test(el.value[el.selectionStart - 1])) {
        el.selectionStart--;
        el.selectionEnd--;
        // return;
      } else {
        chgB = -1;
      }
    }
    if (key === 'Delete' && el.selectionStart === el.selectionEnd) {
      if (/\s/.test(el.value[el.selectionStart])) {
        el.selectionStart++;
        // el.selectionEnd++;
        // return;
      } else {
        chgD = 1;
      }
    }

    const part1 = el.value.substring(0, el.selectionStart + chgB);
    const value = part1 + el.value.substring(el.selectionEnd + chgD);
    this.updateValue(value, part1.length);
  }

  private updateValue(val: string, sel: number) {
    const el = this.elRef.nativeElement;
    if (!val) {
      if (el.value) {
        this.setValue(null);
        el.selectionStart = 0;
        el.selectionEnd = 0;
      }
      return;
    }
    val = String(val || '').slice(0, this.maxlength);
    if (el.value === val) return;
    const updatedVal = this.getUpdatedValue(val);
    this.setValue(updatedVal);
    this.setSelection(updatedVal, val.slice(0, sel));
  }

  private getUpdatedValue(val: string, final?: boolean): string {
    if (this.what === 'IBAN') return val.toUpperCase().replace(/[^A-Z\d]/g, '').replace(/(.{4})/g, '$1 ').trim();
    if (this.what === 'number') return this.getNumber(val, final);
    if (this.what === 'phone') return this.getPhone(val);
    return '';
  }

  private getNumber(val: string, final?: boolean): string {
    if (val === '-') return final ? '' : val;
    if (/^[.,]$/.test(val)) return '0' + val;
    let output: string;
    val = String(val || '');
    if (!val) return '';
    if (this.appServ.locale.code === 'en') output = val.replace(/[^\d.+-]/g, '').replace(/(?!^)[-+]/g, '').replace(/[.](?=.*[.])/g, '');
    else output = val.replace(/[^\d,.+-]/g, '').replace('.', ',').replace(/(?!^)[-+]/g, '').replace(/[,](?=.*[,])/g, '').replace(',', '.');
    if (this.digitInfo.endsWith('0-0')) output = output.replace(/[,.]/g, '');
    if (!final) {
      const decimals = +this.digitInfo.split(/[^\d]/)[2];
      let suffix = output.match(/\.[\d]*/)?.at(0).slice(0, decimals + 1) || '';
      if (this.appServ.locale.code !== 'en') suffix = suffix?.replace('.', ',');
      // const nulls = +this.digitInfo.split(/[^\d]/)[1];
      // if (suffix.length > nulls)
      //   suffix = suffix.slice(0, nulls) + suffix.slice(nulls).replace(/[0]+$/, '');
      return (this.decimalPipe.transform(Math.floor(+output), '1.0-0', this.appServ.locale.code) || '') + suffix;
    } else {
      return (this.decimalPipe.transform(output, this.digitInfo, this.appServ.locale.code) || '');
    }
  }

  private getPhone(val: string): string {
    if (!val) return '';
    if (['CZ', 'SK'].includes(this.countryCode) && val.replace(/[^\d]/g, '').length < 10)
      return val.replace(/[^\d]/g, '').replace(/(.{3})/g, '$1 ').trim();
    const phone = formatIncompletePhoneNumber(parseIncompletePhoneNumber(val), this.countryCode as any);
    const invalidLength = validatePhoneNumberLength(phone, this.countryCode as any);
    return invalidLength || /[^\d]/.test(phone) ? phone : phone.replace(/(.{3})/g, '$1 ').trim();
  }

  private setValue(val: string) {
    this.renderer.setAttribute(
      this.elRef.nativeElement,
      'value',
      val || ''
    );
    if (this.elRef.nativeElement.value !== val) this.elRef.nativeElement.value = val;
    this.value = val;
    this.onInput(val);
  }

  private setSelection(updatedVal: string, val: string) {
    const selection = this.getSelectionIndex(updatedVal, val); // val.toUpperCase().substring(0, sel)?.replace(/[^A-Z\d]/g, '')?.replace(/(.{4})/g, '$1 ')?.length || 0;
    this.elRef.nativeElement.selectionStart = selection;
    this.elRef.nativeElement.selectionEnd = selection;
  }

  private getSelectionIndex(updatedVal: string, val: string): number {
    let value = '';
    if (this.what === 'IBAN') {
      value = val.toUpperCase()?.replace(/[^A-Z\d]/g, '');
    } else if (this.what === 'number') {
      if (this.appServ.locale.code === 'en') value = val.replace(/[^\d.+-]/g, '').replace(/(?!^)[-+]/g, '').replace(/[.](?=.*[.])/g, '');
      else value = val.replace(/[^\d,+-]/g, '').replace(/(?!^)[-+]/g, '').replace(/[,](?=.*[,])/g, '').replace(',', '.');
    } else if (this.what === 'phone') {
      value = parseIncompletePhoneNumber(val);
    }
    let index = 0;

    for (const char of value) {
      for (let i = index; i < updatedVal.length; i++) {
        if (char === updatedVal[i]) {
          index = i + 1;
          break;
        }
      }
    }
    if (this.what !== 'phone' && /\S/.test(updatedVal.slice(-1))) index++;
    if (this.what === 'number' && /^0[.,]$/.test(updatedVal) && /^[.,]$/.test(val)) return 2;
    return index;
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    event.preventDefault();
    this.addKeys(event.clipboardData.getData('text'));
  }

  @HostListener('blur')
  onBlur() {
    this.onTouched();
    this.blurAction();
  }

  private blurAction() {
    const val = this.elRef?.nativeElement.value || '';
    let newVal = this.getUpdatedValue(val, true);
    if (this.what === 'phone' && this.countryCode === 'SK' && val?.replace(/\s/g, '')?.length === 10 && (val && val[0] === '0')) {
      newVal = newVal.slice(1);
    }
    if (val !== newVal) this.setValue(newVal);
  }

}
