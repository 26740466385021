import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';




declare let gtag;
declare let fbq;
declare let rc;

@Injectable({
  providedIn: 'root'
})
export class CookiesService {
  private _cookiesIsSet: boolean;
  private _performance: boolean;
  private _advertising: boolean;

  constructor(
    private cookieServ: CookieService,
  ) {
    this._cookiesIsSet = this.cookieServ.get('cookiesMode') === 'true';
    this._performance = this.cookieServ.get('performanceCookies') === 'true';
    this._advertising = this.cookieServ.get('advertisingCookies') === 'true';
  }

  public get cookiesIsSet(): boolean {
    return this._cookiesIsSet;
  }
  public set cookiesIsSet(value: boolean) {
    this._cookiesIsSet = value;
    this.cookieServ.set('cookiesMode', String(value), 30);
  }

  public get functional(): boolean {
    return true;
  }

  public get performance(): boolean {
    return this._performance;
  }
  public set performance(value: boolean) {
    this._performance = value;
    this.cookieServ.set('performanceCookies', String(value), 30);
  }

  public get advertising(): boolean {
    return this._advertising;
  }
  public set advertising(value: boolean) {
    try {
      if (value) {
        this.allowStalkers();
      } else {
        this.forbidStalkers();
      }
    } catch (e) {
      console.error(e);
    }
    this._advertising = value;
    this.cookieServ.set('advertisingCookies', String(value), 30);
  }

  public allowStalkers() {
    fbq('consent', 'grant');
    gtag('consent', 'update', {
      'ad_user_data': 'granted',
      'ad_personalization': 'granted',
      'ad_storage': 'granted',
      'analytics_storage': 'granted'
    });
    try {
      if (environment.language === 'cs') rc?.retargetingHit?.({ rtgId: 43212, consent: 1 });
    } catch (e) {
      console.error(e);
    }
  }

  private forbidStalkers() {
    fbq('consent', 'revoke');
    gtag('consent', 'update', {
      'ad_storage': 'denied',
      'ad_user_data': 'denied',
      'ad_personalization': 'denied',
      'analytics_storage': 'denied',
    });

    try {
      if (environment.language === 'cs') rc?.retargetingHit?.({ rtgId: 43212, consent: 0 });
    } catch (e) {
      console.error(e);
    }
  }

  public notifyGoogleStalker(path: string) {
    if (!this.advertising) return;
    // let id = environment.language === 'sk' ? 'G-SP7RWY13QD' : 'UA-153536118-1';
    // if (/ap1|pm1|rp1|ap2/.test(path)) id = 'AW-11072208541';
    gtag('config', 'AW-11072208541', {
      // eslint-disable-next-line camelcase
      page_path: path,
    });
  }
}
