import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { LocationStrategy, NgIf } from '@angular/common';
import { Meta, Title } from '@angular/platform-browser';
import { NavigationEnd, Router, ActivatedRoute, RoutesRecognized, RouterOutlet } from '@angular/router';

import { BehaviorSubject, pairwise } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';

import { CookieService } from 'ngx-cookie-service';
import { MyErrorHandler } from './services/error.service';
import { PreviousUrlService } from './previous-url.service';
import { CookiesService } from './www/cookies/cookies.service';
import { StorageService } from 'src/app/services/storage.service';
import { AuthenticationService } from './services/authentication.service';
import { AppService, LOCALE_CS, LOCALE_EN, LOCALE_SK } from './services/app.service';

import { environment } from 'src/environments/environment';
import { CaptchaComponent } from './modules/captcha/captcha.component';
import { FooterComponent } from './www/footer/footer.component';
import { HeaderComponent } from './www/header/header.component';
import { LoadingScreenComponent } from 'src/app/modules/loading-screen/loading-screen.component';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    imports: [
        NgIf,
        RouterOutlet,
        CaptchaComponent,
        FooterComponent,
        HeaderComponent,
        LoadingScreenComponent,
    ]
})

export class AppComponent implements OnInit {
  @ViewChild('scroller', { static: false }) scroller: ElementRef;
  @HostListener('document:mousemove', ['$event'])
  onMouseMove(e: MouseEvent) {
    if (window.innerWidth < 600) return;
    if (window.innerWidth - e.screenX < 20) {
      if (!this.isCursorOverScroller) {
        this.isCursorOverScroller = true;
      }
      if (!this.isScrolling) this.scroller?.nativeElement?.classList.add('scrolling');

    } else {
      if (this.isCursorOverScroller) {
        this.isCursorOverScroller = false;
      }
      if (!this.isScrolling) this.scroller?.nativeElement?.classList.remove('scrolling');
    }
  }
  @HostListener('window:beforeunload', ['$event'])
  onbeforeunload() {
    if (!this.cookieService.performance) {
      this.storageServ.clear();
    }
  }

  private scrolled: boolean;
  private isScrolling: boolean;
  private isCursorOverScroller: boolean;
  interval: ReturnType<typeof setTimeout>;

  amISmall: BehaviorSubject<boolean> = new BehaviorSubject(false);
  protected isOnCookiesPage: boolean;
  protected inFrame: boolean;


  constructor(
    private activatedRoute: ActivatedRoute,
    private appService: AppService,
    private authServ: AuthenticationService,
    private cookieServ: CookieService,
    private locationStrategy: LocationStrategy,
    private metaService: Meta,
    private prevUrlServ: PreviousUrlService,
    private router: Router,
    private storageServ: StorageService,
    private titleService: Title,
    protected cookieService: CookiesService,
    protected myErrorHandler: MyErrorHandler,
  ) {
    this.inFrame = window.self !== window.top;
    if (this.inFrame) window.document.body.classList.add('iframe-blue');

    if (this.cookieService.advertising) {
      setTimeout(() => {
        this.cookieService.allowStalkers();
      });
    }

    const navEndEvents = router.events.pipe(filter((event) => event instanceof NavigationEnd));
    navEndEvents.subscribe((event: NavigationEnd) => {
      if (this.inFrame && (!event.url.startsWith('/rp1/') && !event.url.startsWith('/notfound')))
          this.router.navigate(['/notfound']);
      // this.cookieService.notifyGoogleStalker(event.urlAfterRedirects);
      this.isOnCookiesPage = event.url === '/cookies';
    });
  }

  ngOnInit() {
    if (!this.cookieService.advertising) {
      window[`fbq`]('consent', 'revoke');
      if (environment.language === 'sk')
        window['ga-disable-G-SP7RWY13QD'] = true;
      else
        window['ga-disable-UA-153536118-1'] = true;
    }
    let language = this.locationStrategy.getBaseHref();
    if (language === '/') language = `/${environment.language}/`;
    if (language === '/en/' || window.location.port === '4400') {
      this.appService.locale = LOCALE_EN;
    } else if (language === '/sk/' || window.location.port === '4600') {
      this.appService.locale = LOCALE_SK;
    } else {
      this.appService.locale = LOCALE_CS;
    }
    this.router.events.pipe(
      filter((evt: any) => evt instanceof RoutesRecognized),
      pairwise()
    ).subscribe((events: RoutesRecognized[]) => {
      this.prevUrlServ.prevUrl = events[0].urlAfterRedirects;
    });
    this.activatedRoute.queryParams.subscribe(qp => {
      if (this.authServ.currentUserValue.userType < 2 && qp.utm_source && qp.utm_medium && qp.utm_campaign) {
        const _obj = `${qp.utm_source}-${qp.utm_medium}-${qp.utm_campaign}`.substring(0, 20);
        this.cookieServ.set(
          'campaign',
          _obj,
          30
        );
      }
    });
    const isEn = this.appService.locale.code === 'en';
    this.titleService.setTitle('Pillow pojišťovna');
    this.metaService.addTags([
      {
        name: 'keywords',
        content: 'Pillow, pillow.cz, mypillow.cz, Pillow pojišťovna, pojištění, Férové a srozumitelné pojištění',
      },
      { name: 'robots', content: 'index, follow' },
      { name: 'viewport', content: 'width=device-width, initial-scale=1' },
      { name: 'theme-color', content: '#ffffff' },
      { name: 'msapplication-TileColor', content: '#ffffff' },
      { name: 'msapplication-TileImage', content: 'assets/img/favicon/ms-icon-144x144.png' },
      { name: 'theme-color', content: '#ffffff' },
      {
        name: 'description',
        content:
          'Pillow pojišťovna. Férové a srozumitelné pojištění, bez háčků a nesmyslných výluk. Zkuste pojištění vozidel s férovou cenou dle ujetých kilometrů.',
      },
      { property: 'og:title', content: 'Pillow pojišťovna' },
      { property: 'og:site_name', content: 'Pillow pojišťovna' },
      { charset: 'UTF-8' },
    ]);
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data)
    ).subscribe((event) => {
      setTimeout(() => {
        if (this.scroller?.nativeElement?.scrollTop) this.scroller.nativeElement.scrollTop = 0;
      }, 100);
      this.metaService.updateTag({ property: 'og:title', content: event[isEn ? 'titleEn' : 'title'] }, "property='og:title'");
      this.metaService.updateTag({ name: 'description', content: event[isEn ? 'descriptionEn' : 'description'] });
    });

  }

  onScroll(e: any) {
    if (!e) return;
    this.setScrolling();
    this.isScrolling = true;
    const screen = e.target.parentElement.getBoundingClientRect();
    const insides = e.target.firstElementChild.getBoundingClientRect();
    if (insides.height > (screen.height - 210)) {
      const scrollTop = e.target.scrollTop;
      if (!!scrollTop !== this.scrolled) {
        this.scrolled = !!scrollTop;
        this.amISmall.next(this.scrolled);
      }
    }
  }

  private setScrolling() {
    if (window.innerWidth < 600) return;
    if (!this.isScrolling && !this.isCursorOverScroller) {
      this.scroller?.nativeElement?.classList.add('scrolling');
    }
    clearInterval(this.interval);
    this.interval = setInterval(() => {
      if (!this.isCursorOverScroller && this.isScrolling) {
        this.scroller?.nativeElement?.classList.remove('scrolling');
        this.isScrolling = false;
      }
    }, 1000);
  }

  protected onCookies(allow: boolean) {
    if (allow) {
      this.cookieService.cookiesIsSet = true;
      ['performance', 'advertising'].forEach(key => {
        this.cookieService[key] = true;
      });
    } else {
      this.router.navigate(['/cookies']);
    }
  }
}
