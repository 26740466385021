import { Injectable } from '@angular/core';

import { TsXmlParCreateOperator } from 'src/app/tsXml/tsXmlParCreateOperator.model';


const enum PAGE_MODE {
  AGENT = 'agent',
  CUSTOMER = 'customer',
  NEWBIE = 'newbie'
}
@Injectable()
export class RegistrationService {
  public mode?: string;
  // public dataCreateAccount: { login: string; password: string } = { login: null, password: null };
  // public dataPhoneVerify: { code: string; emailAuthCode: string; anonymizedPhone: string } = {
  //   code: null,
  //   emailAuthCode: null,
  //   anonymizedPhone: null,
  // };

  private parCreateOperator: TsXmlParCreateOperator;
  private anonimizedPhone: string;
  // public dataEmailVerify: { id: string; email: string; partyType: PartyType } = {
  //   id: null,
  //   email: null,
  //   partyType: null,
  // };
  public insuranceCompany: 'PILLOW_CZ' | 'PILLOW_SK';

  public STEPS: {
    EMAIL_VERIFY: boolean;
    PHONE_VERIFY: boolean;
    CREATE_ACCOUNT: boolean;
    DONE: boolean,
    FILL_NAME_PHONE: boolean,
    ADDRESS: boolean } = {
      EMAIL_VERIFY: false,
      PHONE_VERIFY: false,
      CREATE_ACCOUNT: false,
      DONE: false,
      FILL_NAME_PHONE: false,
      ADDRESS: false,
  };

  // public party = new Party();

  public get isAgent() {
    return this.mode === PAGE_MODE.AGENT;
  }

  public get isCustomer() {
    return this.mode === PAGE_MODE.CUSTOMER;
  }

  public get isNewbie() {
    return this.mode === PAGE_MODE.NEWBIE;
  }

  public setParCreateOperator(parCreateOperator: TsXmlParCreateOperator) {
    this.parCreateOperator = parCreateOperator;
  }

  public getParCreateOperator(): TsXmlParCreateOperator {
    if (!this.parCreateOperator) {
      this.parCreateOperator = new TsXmlParCreateOperator();
    }
    return this.parCreateOperator;
  }

  public getAnonimizedPhone(): string {
    return this.anonimizedPhone;
  }

  public setAnonimizedPhone(phoneNum: string) {
    this.anonimizedPhone = phoneNum;
  }

  resetSteps() {
    this.STEPS.EMAIL_VERIFY = false;
    this.STEPS.PHONE_VERIFY = false;
    this.STEPS.CREATE_ACCOUNT = false;
    this.STEPS.DONE = false;
  }
}
