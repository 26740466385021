import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { Location, NgIf } from '@angular/common';

import { Meta } from '@angular/platform-browser';

import { links } from './data';


@Component({
    selector: 'app-notfound',
    templateUrl: './notfound.component.html',
    styleUrls: ['./notfound.component.scss'],
    imports: [NgIf, RouterLink]
})
export class NotfoundComponent {
  protected initialized: boolean;

  constructor(
    private location: Location,
    private meta: Meta,
  ) {
    const link = links[window.location.pathname];
    if (link) {
      window.location.href = link;
      return;
    } else if (this.location.path() !== '/notfound'){
      this.location.replaceState('/notfound');
    } else {
      this.meta.addTags([
        { name: 'robots', content: 'noindex' }
      ], true);
    }
    this.initialized = true;
  }
}
