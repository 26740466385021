import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PreviousUrlService {

  private _prevUrl: string;

  public get prevUrl(): string {
    return this._prevUrl;
  }
  public set prevUrl(value: string) {
    this._prevUrl = value;
  }
}
