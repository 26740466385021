import { Injectable } from '@angular/core';

import { Locale } from '../models/locale.model';

export const LOCALE_CS: Locale = new Locale('cs', 'Česky', '/cs', 'pilic icon-language-cs');
export const LOCALE_SK: Locale = new Locale('sk', 'Slovensky', '/sk', 'pilic icon-language-sk');
export const LOCALE_EN: Locale = new Locale('en', 'English', '/en', 'pilic icon-language-en');


@Injectable()
export class AppService {
  public locale: Locale;
  public loading = false;

  startLoading() {
    this.loading = true;
  }

  stopLoading() {
    this.loading = false;
  }

}
