export class TsXmlBugReport {
  appState: string;
  browser: string;
  date: Date;
  exceptionMessage: string;
  exceptionStacktrace: string[];
  gwtApp: string;
  gwtPermutation: string;
  gwtScriptBase: string;
  kind: string;
  restCallId: string; // TODO only till core 151
  callId: string; // only since core 152
  callIdType: string; // only since core 152
  url: string;
}
