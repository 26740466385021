<div
    class="numeric flexed"
    [ngClass]="{
        disabled: _amIDisabled,
    }"
    [attr.aria-labelledby]="_formField?.getLabelId() || ''"
    (focusin)="onFocusIn()"
    (focusout)="onFocusOut($event)">
    <input
        #inputRef
        appMyInput
        class="numeric__input"
        type="text"
        what="number"
        autocomplete="off"
        [formControl]="numInputCtrl"
        [maxlength]="maxLength ? maxLength : 18"
        (input)="doInput()"
        (blur)="doBlur()"
        [attr.disabled]="_amIDisabled ? 'true' : null"
        [digitInfo]="'1.0-' + numOfDecimals" />
    @if (unitAlwaysVisible || unit || nullAsA) {
        <label class="numeric__label" [ngClass]="{ grayed: unitText === nullAsA }" [for]="inputRef">
            {{ unitText }}
            <sup *ngIf="unitSub && ((unit && unitText) || !unit)">{{ unitSub }}</sup>
        </label>
    } @else {
        <ng-content></ng-content>
    }
</div>
