import { TsXmlParCancelType } from './tsXmlParCancelType.model';

export class TsXmlParCancelTypeList {
    /** Celkový počet položek */
    totalEntries: number;
    /** pole vrácených typů storna */
    items: TsXmlParCancelType[];

    constructor(dt: TsXmlParCancelTypeList) {
      this.totalEntries = dt?.totalEntries;
      this.items = dt?.items ? dt.items.map(it => new TsXmlParCancelType(it)) : null;
    }
}
