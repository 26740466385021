export class TsXmlRestRuntimeMessage {
  /** Atribut HttpStatusCode. */
  httpStatusCode: number;
  /** Atribut MsgText. */
  msgText: string;
  /** Atribut MsgCode. */
  msgCode: string;
  /** Atribut SourceClass. */
  sourceClass: string;
  /** Atribut SourceAttribute. */
  sourceAttribute: string;
}