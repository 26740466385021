import { Injectable } from '@angular/core';

import { RestService } from 'src/app/services/rest.service';
import { AuthenticationService } from 'src/app/services/authentication.service';


@Injectable()
export class DemandsService {

  constructor(
    private restServ: RestService,
    private authServ: AuthenticationService
  ) { }

  private _demands: {
    question1: boolean;
    question2: boolean;
    question3: boolean;
    question4: boolean;
    question5: string;
    question6: string;
    clientNote?: string;
    note?: string;
  };

  private _q5Entries: { text: string; id: string; }[];
  private _q6Entries: { text: string; id: string; }[];


  public get q5Entries(): { text: string; id: string; }[] {
    return this._q5Entries;
  }

  public get q6Entries(): { text: string; id: string; }[] {
    return this._q6Entries;
  }

  public get demands(): {
    question1: boolean;
    question2: boolean;
    question3: boolean;
    question4: boolean;
    question5: string;
    question6: string;
    clientNote?: string;
    note?: string;
  } {
    return this._demands;
  }
  public set demands(value: {
    question1: boolean;
    question2: boolean;
    question3: boolean;
    question4: boolean;
    question5: string;
    question6: string;
    clientNote?: string;
    note?: string;
  }) {
    this._demands = value;
  }

  public prepareCodelists(): Promise<[void, void]> {
    const uid = this.authServ.currentUserUidValue;
    if (this._q5Entries && this._q6Entries) return Promise.resolve([null, null]);
    return Promise.all([
      this.restServ.parCodelistsEntriesGet('5139', uid).then(cd5139 => {
        this._q5Entries = cd5139.entry.map(dt => {
          return {
            text: dt.gclColumn.find(clm => clm.columnCode === 'ODPOVED').stringValue,
            id: dt.id
          };
        });
      }),

      this.restServ.parCodelistsEntriesGet('5140', uid).then(cd5140 => {
        this._q6Entries = cd5140.entry.map(dt => {
          return {
            text: dt.gclColumn.find(clm => clm.columnCode === 'ODPOVED').stringValue,
            id: dt.id
          };
        });
      })
    ]);
  }

  public emptyMe() {
    this._demands = null;
    this._q5Entries = null;
    this._q6Entries = null;
  }
}
