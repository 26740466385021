import { TsXmlParAp2PrintVariantOfSubjectReq } from './tsXmlParAp2PrintVariantOfSubjectReq.model';

export class TsXmlParAp2PrintCalculationReq {
  /** Seznam předmětů pro tisk - varianta 1. */
  printedVariant1: TsXmlParAp2PrintVariantOfSubjectReq[];
  /** Seznam předmětů pro tisk - varianta 2. */
  printedVariant2: TsXmlParAp2PrintVariantOfSubjectReq[];
  /** Seznam předmětů pro tisk - varianta 3. */
  printedVariant3: TsXmlParAp2PrintVariantOfSubjectReq[];

  /**
   * Metoda prevede instanci do XML V2.
   *
   * @param attributes xml attributes.
   * @return String xml.
   */
  public toXmlV2(attributes: string): string {

    let result = '<xml-par-ap2-print-calculation-req';
    if (attributes) {
      result += ' ' + attributes;
    }
    result += '>';

    if (this.printedVariant1) {
      for (const val of this.printedVariant1) {
        if (val) {
          result += '<printedVariant1>';
          result += val.toXmlV2(null);
          result += '</printedVariant1>';
        }
      }
    }

    if (this.printedVariant2) {
      for (const val of this.printedVariant2) {
        if (val) {
          result += '<printedVariant2>';
          result += val.toXmlV2(null);
          result += '</printedVariant2>';
        }
      }
    }

    if (this.printedVariant3) {
      for (const val of this.printedVariant3) {
        if (val) {
          result += '<printedVariant3>';
          result += val.toXmlV2(null);
          result += '</printedVariant3>';
        }
      }
    }

    result += '</xml-par-ap2-print-calculation-req>';
    return result;
  }
}
