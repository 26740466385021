import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

import { BehaviorSubject, Observable } from 'rxjs';


import { UtilsService } from './utils.service';

import { TsXmlUid } from '../tsXml/tsXmlUid.model';
import { User, UserType } from '../models/user.model';
import { TsXmlResponse } from '../tsXml/tsXmlResponse.model';
import { TsXmlOperator } from '../tsXml/tsXmlOperator.model';
import { TsXmlParParty } from '../tsXml/tsXmlParParty.model';

import { u3RestPrefix } from '../variables/variables';
import { MyErrorHandler } from './error.service';

Injectable({ providedIn: 'root' });
@Injectable()
export class AuthenticationService {
  public currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  public subs = {};

  constructor(
    private router: Router,
    private http: HttpClient,
    private errorHandler: MyErrorHandler,
  ) {
    this.init();
  }

  public get currentUserUidValue(): TsXmlUid {
    return this.currentUserSubject && this.currentUserSubject.value ? this.currentUserSubject.value.uid : null;
  }

  public get currentUserValue(): User {
    return this.currentUserSubject ? this.currentUserSubject.value : null;
  }

  public get currentUserValueObservable(): Observable<User> {
    return this.currentUserSubject.asObservable();
  }

  init() {
    try {
      let currentUser = JSON.parse(sessionStorage.getItem('currentUser'));
      const localUser = localStorage.getItem('currentUser');
      if (UtilsService.isNull(currentUser)) {
        if (localUser) {
          currentUser = JSON.parse(localUser);
          sessionStorage.setItem('currentUser', localUser);
        } else currentUser = this.createUser(null, null, null);
      }
      if (localUser) localStorage.removeItem('currentUser');
      this.currentUserSubject = new BehaviorSubject<User>(currentUser);
      this.currentUser = this.currentUserSubject.asObservable();
    } catch {
      alert($localize`Vaše nastavení prohlížeče blokuje veškeré cookies (i ty nezbytné) a brání tím správné funkci pillow.cz.`);
      return;
    }
  }

  public updateLogin(login: string): void {
    const currentUser: User = JSON.parse(sessionStorage.getItem('currentUser'));
    currentUser.operator.login = login;
    sessionStorage.setItem('currentUser', JSON.stringify(currentUser));
    this.currentUserValue.operator.login = login;
  }

  setUser(uid: TsXmlUid, operator: TsXmlOperator, party: TsXmlParParty) {
    const user = this.createUser(uid, operator, party);
    this.currentUserSubject.next(user);
  }

  logoutInternal() {
    try {
      sessionStorage.removeItem('currentUser');
    } catch {
      alert($localize`Vaše nastavení prohlížeče blokuje veškeré cookies (i ty nezbytné) a brání tím správné funkci pillow.cz.`);
      return;
    }
    const unathUser = this.createUser(null, null, null);
    this.currentUserSubject.next(unathUser);
    this.router.navigate(['/login']);
  }

  private cancelAllCalls(): void {
    if (!this.subs) this.subs = {};
    Object.keys({ ...this.subs }).forEach(key => {
      try {
        if (!this.subs[key].closed && this.subs[key].unsubscribe) this.subs[key]?.unsubscribe();
        delete this.subs[key];
      } catch (e) {
        console.log(e);
      }
    });
  }

  logout(uid?: TsXmlUid) {
    this.cancelAllCalls();
    this.logoutPost(this.currentUserUidValue || uid);
    this.logoutInternal();
  }

  /**
   * Logout and return TsXmlResponse
   * @param uid rest uid
   * @return Promise<TsXmlResponse>
   */
  logoutPost(uid: TsXmlUid): Promise<TsXmlResponse> {
    return new Promise<TsXmlResponse>((resolve, reject) => {
      const httpOptions = {
        headers: new HttpHeaders({
          RestUID: uid?.uid || '',
        }),
      };
      this.http.post(u3RestPrefix + 'logout', null, httpOptions).subscribe({
        next: (resp) => {
          resolve(resp as TsXmlResponse);
        },
        error: (error: HttpErrorResponse) => {
          error[<any>'message'] = 'function: logoutPost; ' + error.message;
          if (error.status === 401)
            reject(error);
          else
            reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 404));
        }
      });
    });
  }

  private createUser(uid: TsXmlUid, operator: TsXmlOperator, party: TsXmlParParty): User {
    const user = new User();
    if (uid && operator && party) {
      user.uid = uid;
      user.operator = operator;
      user.party = party;
      if (user.party.partyType === 'ZIS') {
        // extern service operator (Agent2)
        user.userType = UserType.C_AGENT;
        const agents = user.party.personalIdentification.split('-');
        if (agents.length === 2) {
          user.agentId1 = agents[0];
        }
        user.agentId2 = user.party.personalIdentification;
      } else {
        // portal operator
        user.userType = UserType.B_USER;
        user.agentId1 = '111';
      }

      if (user.operator.type === 'S' && (user.agentId1 === '5' || user.agentId1 === '111')) {
        // service operator (Agent0)
        user.userType = UserType.D_OPER;
      }
      try {
        sessionStorage.setItem('currentUser', JSON.stringify(user));
      } catch {
        alert($localize`Vaše nastavení prohlížeče blokuje veškeré cookies (i ty nezbytné) a brání tím správné funkci pillow.cz.`);
        return;
      }
    } else {
      // unauthorized
      user.userType = UserType.A_UNAUTH;
      user.agentId1 = '111';
    }
    this.errorHandler.userType = user.userType;
    return user;
  }
}
