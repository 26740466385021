import { Component, Input, OnInit, OnDestroy, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';

import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { Step3Service } from '../../step3/step3.service';
import { AppService } from 'src/app/services/app.service';
import { VehicleDataService } from './vehicle-data.service';
import { OuhaService } from 'src/app/services/ouha.service';
import { OtherService } from 'src/app/services/other.service';
import { optionsNames, VehicleOptionsService } from './vehicle-options.service';
import { AuthenticationService } from 'src/app/services/authentication.service';

import { disallowedCharactersValidators } from 'src/app/validators/disallowedCharacters.validator';

import {
  licensePlateVal, clientsVehiclePriceVal, engineVolumeVal,
  enginePowerVal, numberOfSeatsVal, weightVal, productionYearVal
} from './vehicle.validators';

import { TDate } from 'src/app/models/date.model';
import { TsXmlUid } from 'src/app/tsXml/tsXmlUid.model';
import { TBigDecimal } from 'src/app/models/number.model';
import { TsXmlParAp1Vehicle } from 'src/app/tsXml/tsXmlParAp1Vehicle.model';
import { NgIf, NgFor, NgClass, NgTemplateOutlet } from '@angular/common';
import { TooltipComponent } from '../../../../modules/tooltip/tooltip.component';
import { MatFormField, MatError, MatSuffix } from '@angular/material/form-field';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { MatInput } from '@angular/material/input';
import { NumInputComponent } from '../../../../modules/num-input/num-input.component';
import { MatDatepickerInput, MatDatepicker, MatDatepickerToggle, MatDatepickerToggleIcon } from '@angular/material/datepicker';
import { MatRadioGroup, MatRadioButton } from '@angular/material/radio';


export enum spzVal {
  have = 0,
  new,
  import,
  never
}

@Component({
    selector: 'app-vehicle',
    templateUrl: './vehicle.component.html',
    styleUrls: ['./vehicle.component.scss'],
    imports: [
        NgIf,
        NgFor,
        TooltipComponent,
        MatFormField,
        NgClass,
        MatSelect,
        FormsModule,
        ReactiveFormsModule,
        MatOption,
        MatError,
        NgTemplateOutlet,
        MatInput,
        NumInputComponent,
        MatDatepickerInput,
        MatDatepicker,
        MatDatepickerToggle,
        MatSuffix,
        MatDatepickerToggleIcon,
        MatRadioGroup,
        MatRadioButton,
    ]
})
export class VehicleComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() now: TDate;
  @Input() clientsUserType: string;
  @Input() buVisible: boolean;
  @Input() vehicle: TsXmlParAp1Vehicle;
  @Input() loadedVehicleParams: { vehicleType: string };
  @Output() doneEvent: EventEmitter<{
    sameAsUser: boolean;
    vehicleUseTxt: string;
    vehicleValue: any;
  }> = new EventEmitter();
  @Output() rateEvent: EventEmitter<TsXmlParAp1Vehicle> = new EventEmitter();
  @Output() validityChangedEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() changedEvent: EventEmitter<void> = new EventEmitter();
  @Output() justGoOnEvent: EventEmitter<{
    sameAsUser: boolean;
    vehicleUseTxt: string;
    vehicleValue: any;
    iAmTiredOfIt?: boolean;
  }> = new EventEmitter();
  @Output() policyHolderSameAsVehicleHolderEvent: EventEmitter<boolean> = new EventEmitter();
  @Output() policyHolderSameAsVehicleOwnerEvent: EventEmitter<boolean> = new EventEmitter();

  private subs: Subscription[] = [];

  vehicleForm: FormGroup<{
    noLicensePlate: FormControl<boolean>,
    notRegistered: FormControl<boolean>,
    licensePlate: FormControl<string>,
    vehicleType: FormControl<string>,
    brand: FormControl<string>,
    model: FormControl<string>,
    modelYear: FormControl<string>,
    engine: FormControl<string>,
    bodywork: FormControl<string>,
    fuel: FormControl<string>,
    engineVolume: FormControl<number>,
    enginePower: FormControl<number>,
    numberOfSeats: FormControl<number>,
    firstRegistrationDate: FormControl<TDate>,
    weight: FormControl<number>,
    vehicleName: FormControl<string>,
    productionYear: FormControl<TDate>,
    vehiclePrice: FormControl<boolean>,
    clientsVehiclePrice: FormControl<TBigDecimal>,
    withoutVAT: FormControl<boolean>,
    garaged: FormControl<boolean>,
    expectedKm: FormControl<number>,
    vehicleUse: FormControl<string>,
    technicalCertificateNumber: FormControl<string>,
    vIN: FormControl<string>,
    noTechnicalCertificate: FormControl<boolean>,
    contractualService: FormControl<boolean>,
    contractualAuthorizedService: FormControl<boolean>
  }>;
  fgModel: {
    noLicensePlate: FormControl<boolean>,
    notRegistered: FormControl<boolean>,
    licensePlate: FormControl<string>,
    vehicleType: FormControl<string>,
    brand: FormControl<string>,
    model: FormControl<string>,
    modelYear: FormControl<string>,
    engine: FormControl<string>,
    bodywork: FormControl<string>,
    fuel: FormControl<string>,
    engineVolume: FormControl<number>,
    enginePower: FormControl<number>,
    numberOfSeats: FormControl<number>,
    firstRegistrationDate: FormControl<TDate>,
    weight: FormControl<number>,
    vehicleName: FormControl<string>,
    productionYear: FormControl<TDate>,
    vehiclePrice: FormControl<boolean>,
    clientsVehiclePrice: FormControl<TBigDecimal>,
    withoutVAT: FormControl<boolean>,
    garaged: FormControl<boolean>,
    expectedKm: FormControl<number>,
    vehicleUse: FormControl<string>,
    technicalCertificateNumber: FormControl<string>,
    vIN: FormControl<string>,
    noTechnicalCertificate: FormControl<boolean>,
    contractualService: FormControl<boolean>,
    contractualAuthorizedService: FormControl<boolean>
  };
  _htmlData: { name: string; title: string, type: string; extras?: any; }[];
  tooltips: {
    [key: string]: {
      headline: string;
      text: string;
    };
  };
  spz: FormControl<0 | 1 | 2 | 3>;
  sameAsUser: FormControl = new FormControl(true);

  options: {
    [key: string]: { text: string, value: any; }[];
  };
  minDate: TDate;
  maxDate: TDate;

  visibilities: { [key: string]: boolean; };
  markTouchedBuVisible: boolean;
  hideAllAfterSPZ: boolean;
  protected theseAreUnderMyProtection = ['vehicleType', 'technicalCertificateNumber', 'vIN', 'contractualService', 'contractualAuthorizedService'];
  valid: boolean;
  // finishHimBuVisible: boolean;

  private uid: TsXmlUid;
  private iDareYouIDoubleDareYouMtfk: boolean;
  protected sameAsUserText: string = '';
  protected isElectro: boolean;

  constructor(
    private appServ: AppService,
    private authServ: AuthenticationService,
    private dataServ: VehicleDataService,
    private optionsServ: VehicleOptionsService,
    private othersServ: OtherService,
    private ouhaServ: OuhaService,
    private step3Serv: Step3Service,
  ) { }

  ngOnInit(): void {
    this.isElectro = ['EV', 'EK'].includes(this.dataServ.savedData?.vehicleValue?.vehicleType);
    this.uid = this.authServ.currentUserUidValue;
    this.initialize();
    this.setFormGroup();
    this.options = this.optionsServ.options;
    this.loadData();
    this.setVisibilities();
    this.initializeSubs();
    if (!this.vehicleForm.valid) this.markTouchedBuVisible = true;
  }

  ngAfterViewInit() {
    if (this.vehicleForm.valid) {
      this.prepareData(true).then(data => {
        this.justGoOnEvent.emit({ ...data, iAmTiredOfIt: true });
      });
    }
  }

  private initialize() {
    this.hideAllAfterSPZ = !this.dataServ.savedData && this.isVehicleEmpty(this.vehicle);
    this.minDate = new TDate(new Date('1900,1,1'));
    this.maxDate = this.now;
    this._htmlData = this.dataServ.vehicleHtmlData;
    this.tooltips = this.dataServ.tooltips;
    if (!this.vehicle) this.vehicle = new TsXmlParAp1Vehicle();
  }

  private isVehicleEmpty(vehicle: TsXmlParAp1Vehicle): boolean {
    return !vehicle || typeof vehicle !== 'object' || !Object.values(vehicle).filter(it => !!it).length;
    // (Object.keys(vehicle).length === 2 && vehicle.vehicleUse === '10' && vehicle.vehicleType === 'OA');
  }

  private setFormGroup() {
    this.spz = new FormControl(this.getSpzVal());
    const vehicleTypeFC = new FormControl(this.getDfVal('vehicleType') || this.loadedVehicleParams?.vehicleType || 'OA');
    this.fgModel = {
      noLicensePlate: new FormControl(this.getDfVal('noLicensePlate')),
      notRegistered: new FormControl(this.getDfVal('notRegistered')),
      licensePlate: new FormControl(this.getDfVal('licensePlate'), licensePlateVal),
      vehicleType: vehicleTypeFC,
      brand: new FormControl(this.getDfVal('brand')),
      model: new FormControl(this.getDfVal('model')),
      modelYear: new FormControl(this.getDfVal('modelYear')),
      engine: new FormControl(this.getDfVal('engine')),
      bodywork: new FormControl(this.getDfVal('bodywork')),
      fuel: new FormControl(this.getDfVal('fuel')),
      engineVolume: new FormControl(this.getDfVal('engineVolume'), engineVolumeVal),
      enginePower: new FormControl(this.getDfVal('enginePower'), enginePowerVal),
      numberOfSeats: new FormControl(this.getDfVal('numberOfSeats'), numberOfSeatsVal),
      firstRegistrationDate: new FormControl(this.getDfVal('firstRegistrationDate')),
      weight: new FormControl(this.getDfVal('weight'), weightVal(vehicleTypeFC)),
      vehicleName: new FormControl(this.getDfVal('vehicleName')),
      productionYear: new FormControl(
        this.getDfVal('productionYear'),
        [productionYearVal(this.now), disallowedCharactersValidators.disallowedCharacters(/[^\d]/)]
      ),
      vehiclePrice: new FormControl(this.getDfVal('vehiclePrice')),
      clientsVehiclePrice: new FormControl(this.getDfVal('clientsVehiclePrice'), clientsVehiclePriceVal),
      withoutVAT: new FormControl(this.getDfVal('withoutVAT')),
      garaged: new FormControl(this.getDfVal('garaged')),
      expectedKm: new FormControl(this.getDfVal('expectedKm')),
      vehicleUse: new FormControl(this.getDfVal('vehicleUse') === '12' ? null : this.getDfVal('vehicleUse')),
      technicalCertificateNumber: new FormControl(this.getDfVal('technicalCertificateNumber')),
      vIN: new FormControl(this.getDfVal('vIN')),
      noTechnicalCertificate: new FormControl(this.getDfVal('noTechnicalCertificate')),
      contractualService: new FormControl(this.vehicle?.contractualService || null),
      contractualAuthorizedService: new FormControl(this.vehicle?.contractualAuthorizedService || null),
    };
    this.vehicleForm = new FormGroup(this.fgModel);
    this.setVisibilities();
    this.sameAsUserText = ['EV', 'EK'].includes(this.fgModel.vehicleType.value) ?
      $localize`Vlastník vozidla` :
      $localize`Provozovatel vozidla`;
  }

  private getSpzVal(): 0 | 1 | 2 {
    if (!this.vehicle) return 0;
    if (!this.vehicle.noLicensePlate && !this.vehicle.notRegistered) return 0;
    if (this.vehicle.noLicensePlate && this.vehicle.notRegistered) return 1;
    if (this.vehicle.noLicensePlate && !this.vehicle.notRegistered) return 2;
  }

  private getDfVal(key: string): any {
    let val = this.vehicle[key] || this.dataServ.defaultData[key];
    if (key === 'productionYear' && this.vehicle.productionYear)
      val = this.vehicle.productionYear.getFullYear();
    return val;
  }

  private loadData() {
    const ds = this.dataServ.savedData;
    if (ds) {
      this.spz.setValue(ds.spz);
      this.sameAsUser.setValue(ds.sameAsUser);
      this.theseAreUnderMyProtection = ds.theseAreUnderMyProtection;
      Object.entries(this.vehicleForm.controls).forEach(dt => {
        const fc = dt[1];
        const name = dt[0];
        if (name in ds.vehicleValue) {
          if (name === 'productionYear' && ds.vehicleValue[name]) {
            const val = ds.vehicleValue[name];
            const productionYear: any = val instanceof Date ? val.getFullYear() : val;
            fc.setValue(productionYear as never);
          } else {
            fc.setValue(ds.vehicleValue[name] as never);
          }
        }
      });
      if (!ds.vehicleValue.modelYear && ds.vehicleValue.fuel)
        this.vehicleForm.get('modelYear').setValue('Ostatní', { emitEvent: false });
      if (this.vehicleForm.get('modelYear').value !== 'Ostatní' && !ds.vehicleValue.engine && ds.vehicleValue.enginePower)
        this.vehicleForm.get('engine').setValue('Ostatní', { emitEvent: false });
      if (!ds.vehicleValue.bodywork && ds.vehicleValue.numberOfSeats)
        this.vehicleForm.get('bodywork').setValue('Ostatní', { emitEvent: false });

      this.setVisibilities();
      this.validityChangedEvent.emit(true);
    }
  }

  private setVisibilities() {
    if (!this.visibilities) this.visibilities = {};
    this._htmlData.forEach(dt => {
      const visibleNew = this.isFCVisible(dt.name);
      const visibleOld = this.visibilities[dt.name];
      if (
        visibleNew &&
        [
          'spz', 'vehicleType', 'brand', 'model', 'modelYear', 'engine', 'bodywork', 'fuel',
          'expectedKm', 'vehicleUse'
        ].includes(dt.name)
      ) {
        this.updateOptionsFor(dt.name);
      }
      if (visibleNew !== visibleOld) {
        this.visibilities[dt.name] = visibleNew;
      }
      setTimeout(() => {
        if (visibleOld && !visibleNew) {
          this.vehicleForm.get(dt.name).setErrors(null);
          if (!this.theseAreUnderMyProtection.includes(dt.name) && this.vehicleForm.get(dt.name).value) {
            this.vehicleForm.get(dt.name).setValue(null, { emitEvent: false });
          }
        }
      });
    });
  }

  private updateOptionsFor(fc: string) {
    if (fc === 'brand') {
      const vehicleType = this.vehicleForm.get('vehicleType').value;
      this.optionsServ.setOptionsFor('brand', this.uid, { vehicleType });
    } else {
      const searchValues = this.getSearchValues(fc);
      this.optionsServ.setOptionsFor(fc as optionsNames, this.uid, searchValues);
    }
  }

  private getSearchValues(fc: string) {
    if (fc === 'expectedKm') {
      const vehicleType = this.vehicleForm.get('vehicleType').value;
      const userPartyType = ['OSVC', 'PO'].includes(this.clientsUserType) ? 'IC' : 'RC';
      return { vehicleType, userPartyType };
    }

    const list = ['brand', 'model', 'modelYear', 'engine', 'bodywork'];
    if (!list.includes(fc)) return null;
    const arr = list.slice(0, list.indexOf(fc));
    const output = {};
    arr.forEach(key => {
      output[key] = this.vehicleForm.get(key).value;
    });
    return output;
  }

  private isFCVisible(fc: string): boolean {
    let visible = false;
    const modelVisible = this.vl('vehicleType') === 'OA' && !!this.vl('brand') && !['OSTATNE', 'NEUVEDENA'].includes(this.vl('brand'));
    switch (fc) {
      case 'spz':
        visible = true;
        break;
      case 'licensePlate':
        visible = this.spz.value === spzVal.have;
        break;
      case 'vehicleType':
        visible = !!this.spz.value || this.spz.value === 0;
        break;
      case 'brand':
        visible = this.vl('vehicleType') === 'OA';
        break;
      case 'model':
        visible = modelVisible && (!this.options?.model || !!this.options?.model?.length);
        break;
      case 'modelYear':
        visible = modelVisible && (!this.options?.modelYear || !!this.options?.modelYear?.length);
        break;
      case 'engine':
        visible = modelVisible && !!this.vl('model') && (!!this.vl('modelYear') &&
          this.vl('modelYear') !== 'Ostatní') && (!this.options?.engine || !!this.options?.engine?.length);
        break;
      case 'bodywork':
        visible = modelVisible && !!this.vl('model') &&
          (!!this.vl('modelYear') && this.vl('modelYear') !== 'Ostatní') &&
          (!!this.vl('engine') && this.vl('engine') !== 'Ostatní') &&
          (!this.options?.bodywork || !!this.options?.bodywork?.length);
        break;
      case 'fuel':
        visible = (!this.vl('modelYear') || this.vl('modelYear') === 'Ostatní') && !['PV', 'PN', 'EV', 'EK'].includes(this.vl('vehicleType'));
        break;
      case 'engineVolume':
        visible = (!this.vl('engine') || this.vl('engine') === 'Ostatní') &&
          !['PV', 'PN', 'EV', 'EK'].includes(this.vl('vehicleType')) && this.vl('fuel') !== 'EP';
        break;
      case 'enginePower':
        visible = (!this.vl('engine') || this.vl('engine') === 'Ostatní')
          && !['PV', 'PN'].includes(this.vl('vehicleType'));
        break;
      case 'numberOfSeats':
        visible = (!this.vl('bodywork') || this.vl('bodywork') === 'Ostatní') &&
          !['PV', 'PN', 'EV', 'EK'].includes(this.vl('vehicleType'));
        break;
      case 'weight':
        visible = !!this.spz.value || this.spz.value === 0;
        break;
      case 'firstRegistrationDate':
        visible = this.spz.value === spzVal.have;
        break;
      case 'vehicleName':
        visible = (
          !['OA'].includes(this.vl('vehicleType')) ||
          !this.vl('brand') ||
          !this.vl('model') ||
          ['579', '529'].includes(this.vl('model')) ||
          (this.options?.model?.length && this.options.model.find(br => br.value === this.vl('model'))?.text === 'Ostatní')
        );
        break;
      case 'productionYear':
        visible = this.spz.value === spzVal.never || this.spz.value === spzVal.import;
        break;
      case 'clientsVehiclePrice':
        const _240Mnth = this.othersServ.getDateChangedBy(this.now, 0, -240);
        let age = this.vl('productionYear') || this.vl('firstRegistrationDate');
        if (typeof age === 'number') age = new Date(age + '');
        else if (typeof age === 'string') age = new Date(age);
        const tooOld = age && age.getTime() < _240Mnth.getTime();
        visible = !tooOld && !this.vl('vehiclePrice') && !['EV', 'EK'].includes(this.vl('vehicleType'));
        break;
      case 'withoutVAT':
        visible = ['PO', 'OSVC'].includes(this.clientsUserType);
        break;
      case 'garaged':
        visible = (!!this.spz.value || this.spz.value === 0) && !['EV', 'EK'].includes(this.vl('vehicleType'));
        break;
      case 'expectedKm':
        visible = !['PV', 'SPS', 'VZV', 'TRA', 'PN', 'EV', 'EK'].includes(this.vl('vehicleType'));
        break;
      case 'vehicleUse':
        visible = !!this.spz.value || this.spz.value === 0;
        break;
    }
    if (!['spz', 'licensePlate'].includes(fc)) {
      visible = visible && !this.hideAllAfterSPZ;
    }
    return visible;
  }

  private vl(fcName: string): any {
    return this.vehicleForm.get(fcName).value;
  }

  private initializeSubs() {
    this.subs.push(this.spz.valueChanges.subscribe(val => {
      if (val === spzVal.have) {
        this.vehicleForm.get('noLicensePlate').setValue(false, { emitEvent: false });
        this.vehicleForm.get('notRegistered').setValue(false, { emitEvent: false });
      } else if (val === spzVal.new) {
        this.vehicleForm.get('noLicensePlate').setValue(true, { emitEvent: false });
        this.vehicleForm.get('notRegistered').setValue(true, { emitEvent: false });
      } else {
        this.vehicleForm.get('noLicensePlate').setValue(true, { emitEvent: false });
        this.vehicleForm.get('notRegistered').setValue(false, { emitEvent: false });
      }
      if (val !== spzVal.have)
        this.hideAllAfterSPZ = false;

      this.theseAreUnderMyProtection = ['vehicleType', 'colour', 'technicalCertificateNumber', 'vIN', 'contractualService', 'contractualAuthorizedService'];
      this.nullBelow('licensePlate', ['vehicleType']);
      this.setVisibilities();
    }));
    this._htmlData.forEach(htmlDt => {
      if (htmlDt.name !== 'spz') {
        this.subs.push(this.vehicleForm.get(htmlDt.name).valueChanges.pipe(debounceTime(200)).subscribe(() => {
          if (this.iDareYouIDoubleDareYouMtfk) return;
          if (!['weight', 'withoutVAT'].includes(htmlDt.name)) this.nullBelow(htmlDt.name, this.theseAreUnderMyProtection);
          this.setVisibilities();
        }));
      }
    });
    this.subs.push(this.vehicleForm.get('vehicleType').valueChanges.pipe(debounceTime(300)).subscribe(val => {
      this.isElectro = ['EV', 'EK'].includes(val);
      this.sameAsUserText = this.isElectro ?
        $localize`Vlastník vozidla` :
        $localize`Provozovatel vozidla`;
      this.nullBelow('vehicleType');
      if (this.iDareYouIDoubleDareYouMtfk) return;
      this.appServ.startLoading();
      this.vehicleTypeChanged().then(() => {
        const fc = this.vehicleForm.get('weight');
        if (fc.value) {
          fc.updateValueAndValidity();
          fc.markAllAsTouched();
        }
        this.setVisibilities();
        this.appServ.stopLoading();
      });
    }));

    this.subs.push(this.vehicleForm.get('brand').valueChanges.pipe(debounceTime(300)).subscribe(() => {
      if (this.iDareYouIDoubleDareYouMtfk) return;
      this.appServ.startLoading();
      this.brandChanged().then(() => {
        this.setVisibilities();
        this.appServ.stopLoading();
      });
    }));

    this.subs.push(this.vehicleForm.get('engine').valueChanges.pipe(debounceTime(300)).subscribe(() => {
      if (this.iDareYouIDoubleDareYouMtfk) return;
      this.appServ.startLoading();
      this.engineChanged().then(() => {
        this.setVisibilities();
        this.appServ.stopLoading();
      });
    }));

    this.subs.push(this.vehicleForm.get('model').valueChanges.pipe(debounceTime(300)).subscribe(() => {
      if (this.iDareYouIDoubleDareYouMtfk) return;
      this.appServ.startLoading();
      this.modelChanged().then(() => {
        this.setVisibilities();
        this.appServ.stopLoading();
      });
    }));

    this.subs.push(this.vehicleForm.get('modelYear').valueChanges.pipe(debounceTime(300)).subscribe(() => {
      if (this.iDareYouIDoubleDareYouMtfk) return;
      this.appServ.startLoading();
      this.modelYearChanged().then(() => {
        this.setVisibilities();
        this.appServ.stopLoading();
      });
    }));
    this.subs.push(this.vehicleForm.get('licensePlate').valueChanges.subscribe(() => {
      if (this.iDareYouIDoubleDareYouMtfk) return;
      this.hideAllAfterSPZ = true;
    }));

    this.subs.push(this.sameAsUser.valueChanges.subscribe(val => {
      if (this.iDareYouIDoubleDareYouMtfk) return;
      if (['EV', 'EK'].includes(this.vehicleForm.controls.vehicleType.value)) {
        this.policyHolderSameAsVehicleOwnerEvent.emit(val);
      } else {
        this.policyHolderSameAsVehicleHolderEvent.emit(val);
      }
      this.valid = true;
      this.changedEvent.emit();
    }));
    this.subs.push(this.vehicleForm.valueChanges.subscribe(() => {
      if (this.iDareYouIDoubleDareYouMtfk) return;
      if (!this.markTouchedBuVisible) this.markTouchedBuVisible = true;
      if (this.valid !== this.vehicleForm.valid) {
        if (this.vehicleForm.valid && this.hideAllAfterSPZ) return;
        this.valid = this.vehicleForm.valid;
        this.validityChangedEvent.emit(this.valid);
      }
      this.changedEvent.emit();
    }));
  }

  onGetPriceOrSmth(firstRegistrationDate: TDate) {
    if (this.iDareYouIDoubleDareYouMtfk) return;
    if (this.isAllBeforeFcValid('firstRegistrationDate') && firstRegistrationDate && this.vehicleForm.get('firstRegistrationDate').valid) {
      this.youShouldIdontKnowGetPriceOrSomething();
    }
  }

  private isAllBeforeFcValid(fcName: string): boolean {
    for (const key of Object.keys(this.fgModel)) {
      if (key === fcName) return true;
      if (this.visibilities[key] && !this.fgModel[key].valid) return false;
    }
    return true;
  }

  private vehicleTypeChanged() {
    const vehicleType = this.vehicleForm.get('vehicleType').value;
    return this.optionsServ.setOptionsFor('brand', this.uid, { vehicleType });
  }

  private brandChanged() {
    const brand = this.vehicleForm.get('brand').value;
    const model = this.vehicleForm.get('model').value;
    const modelYear = this.vehicleForm.get('modelYear').value;
    const engine = this.vehicleForm.get('engine').value;

    return Promise.all([
      this.optionsServ.setOptionsFor('model', this.uid, { brand }),
      this.optionsServ.setOptionsFor('modelYear', this.uid, { brand, model }),
      this.optionsServ.setOptionsFor('engine', this.uid, { brand, model, modelYear }),
      this.optionsServ.setOptionsFor('bodywork', this.uid, { brand, model, modelYear, engine }),
    ]);
  }

  private modelChanged() {
    const brand = this.vehicleForm.get('brand').value;
    const model = this.vehicleForm.get('model').value;
    const modelYear = this.vehicleForm.get('modelYear').value;
    const engine = this.vehicleForm.get('engine').value;

    return Promise.all([
      this.optionsServ.setOptionsFor('modelYear', this.uid, { brand, model }),
      this.optionsServ.setOptionsFor('engine', this.uid, { brand, model, modelYear }),
      this.optionsServ.setOptionsFor('bodywork', this.uid, { brand, model, modelYear, engine }),
    ]);
  }

  private modelYearChanged() {
    const brand = this.vehicleForm.get('brand').value;
    const model = this.vehicleForm.get('model').value;
    const modelYear = this.vehicleForm.get('modelYear').value;
    const engine = this.vehicleForm.get('engine').value;

    return Promise.all([
      this.optionsServ.setOptionsFor('engine', this.uid, { brand, model, modelYear }),
      this.optionsServ.setOptionsFor('bodywork', this.uid, { brand, model, modelYear, engine }),
    ]);
  }

  private engineChanged() {
    const brand = this.vehicleForm.get('brand').value;
    const model = this.vehicleForm.get('model').value;
    const modelYear = this.vehicleForm.get('modelYear').value;
    const engine = this.vehicleForm.get('engine').value;

    return this.optionsServ.setOptionsFor(
      'bodywork',
      this.uid,
      { brand, model, modelYear, engine }
    );
  }

  private nullBelow(fcName: string, keepUnchanged?: string[]) {
    keepUnchanged = keepUnchanged ? keepUnchanged : [];
    let list = Object.keys(this.vehicleForm.controls);
    list = list.slice(list.indexOf(fcName) + 1);
    list.forEach(fc => {
      if (!keepUnchanged.includes(fc)) {
        this.vehicleForm.get(fc).setValue(this.dataServ.defaultData[fc], { emitEvent: false });
      }
    });
    this.setVisibilities();
  }

  private launchChaines(
    data: { fcName: string, controls: string[]; },
    vehicle: TsXmlParAp1Vehicle
  ) {
    return this.chaines(data.controls.slice(data.controls.indexOf(data.fcName)), vehicle);
  }

  private chaines(list: string[], vehicle: TsXmlParAp1Vehicle) {
    if (!list.length) {
      return Promise.resolve();
    }
    const current = list[0];
    const unknownOption = this.options[current] && !this.options[current].some(op => op.value === vehicle[current]);
    if (!vehicle[current] || unknownOption)
      return this.chaines(list.slice(1), vehicle);
    this.vehicleForm.get(current).setValue(vehicle[current], { emitEvent: false });
    if (current === 'firstRegistrationDate') this.youShouldIdontKnowGetPriceOrSomething();
    if (this[current + 'Changed']) {
      return this[current + 'Changed']().then(() => {
        return this.chaines(list.slice(1), vehicle);
      });
    } else {
      return this.chaines(list.slice(1), vehicle);
    }
  }

  onFocus(el: any, type: string) {
    if (['select', 'date'].includes(type)) el.open();
    else if (['input', 'number'].includes(type)) el.focus();
  }

  onBlur(fc: string) {
    if (fc === 'licensePlate') {
      const ctrl = this.vehicleForm.get(fc);
      if (ctrl.valid && this.hideAllAfterSPZ) {
        ctrl.setValue(ctrl.value.toUpperCase(), { emitEvent: false });
        this.appServ.startLoading();
        this.optionsServ.loadVehicle(ctrl.value, this.uid).then(vehicle => {
          this.theseAreUnderMyProtection = vehicle.weight ?
            ['vehicleType', 'technicalCertificateNumber', 'vIN', 'noTechnicalCertificate', 'weight', 'contractualService', 'contractualAuthorizedService'] :
            ['vehicleType', 'technicalCertificateNumber', 'vIN', 'noTechnicalCertificate', 'contractualService', 'contractualAuthorizedService'];
          if (vehicle.vehicleName) this.theseAreUnderMyProtection.push('vehicleName');
          this.setLoadedVehicle(vehicle);
        }).finally(() => {
          this.hideAllAfterSPZ = false;
          this.setVisibilities();
          this.appServ.stopLoading();
        });
      }
    } else if (fc === 'weight') {
      if (this.iDareYouIDoubleDareYouMtfk) return;
      if (this.isAllBeforeFcValid('weight') && this.spz.value === 1) {
        this.youShouldIdontKnowGetPriceOrSomething();
      }
    } else if (fc === 'productionYear') {
      if (this.iDareYouIDoubleDareYouMtfk) return;
      if (this.isAllBeforeFcValid('productionYear') && this.spz.value) {
        this.youShouldIdontKnowGetPriceOrSomething();
      }
    }
  }

  private youShouldIdontKnowGetPriceOrSomething() {
    if (this.vehicleForm.get('firstRegistrationDate').valid && !this.isElectro) {
      this.getRealOutput(this.vehicleForm.value).then(vehicle => {
        if (!vehicle.weight) vehicle.weight = 5;
        this.rateEvent.emit(vehicle);
      });
    }
  }

  private setLoadedVehicle(vehicle: TsXmlParAp1Vehicle) {
    this.appServ.startLoading();

    const controls = Object.keys(this.vehicleForm.controls).filter(fc => Object.keys(vehicle).includes(fc));
    this.iDareYouIDoubleDareYouMtfk = true;
    this.launchChaines({ fcName: 'vehicleType', controls }, vehicle).then(() => {
      this.setVisibilities();
      if (this.valid !== this.vehicleForm.valid) {
        this.valid = this.vehicleForm.valid;
      }
    }).finally(() => {
      setTimeout(() => {
        this.iDareYouIDoubleDareYouMtfk = false;
        this.appServ.stopLoading();
      }, 501);
    });
  }

  private async save2Service() {
    const data = await this.prepareData();
    data.vehicleValue.productionYear = this.vehicleForm.get('productionYear').value;
    const theseAreUnderMyProtection = this.theseAreUnderMyProtection;
    this.dataServ.savedData = { ...data, spz: this.spz.value, theseAreUnderMyProtection };
  }

  private async prepareData(final?: boolean): Promise<{
    sameAsUser: boolean;
    vehicleValue: any;
    vehicleUseTxt: string;
  }> {
    const vehicleUseTxt = this.options.vehicleUse.find(it => it.value === this.vehicleForm.controls.vehicleUse.value)?.text || '';
    const data = {
      sameAsUser: this.sameAsUser.value,
      vehicleValue: final ? await this.getRealOutput(this.vehicleForm.value) : this.vehicleForm.value,
      vehicleUseTxt,
    };
    return data;
  }

  private async getRealOutput(vehicleValue: any) {
    if (!this.visibilities.brand) vehicleValue.brand = 'NEUVEDENA';
    if (!this.visibilities.model) {
      vehicleValue.model = vehicleValue.vehicleType === 'OA' && vehicleValue.brand === 'OSTATNE' ? '579' : '529';
    }
    if (!this.visibilities.withoutVAT) vehicleValue.withoutVAT = false;
    if (!this.visibilities.expectedKm) vehicleValue.expectedKm = -1;
    const inPVPN = ['PV', 'PN'].includes(vehicleValue.vehicleType);
    if (!this.visibilities.fuel) {
      if (inPVPN) {
        vehicleValue.fuel = 'JI';
      } else if (this.isElectro) {
        vehicleValue.fuel = 'EP';
      } else {
        vehicleValue.fuel = await this.optionsServ.getVehicleFuel(
          {
            brand: this.vehicleForm.get('brand').value,
            model: this.vehicleForm.get('model').value,
            modelYear: this.vehicleForm.get('modelYear').value
          },
          this.uid
        );
      }
    }
    let engineVal: { volume: number, power: number; };
    if (!inPVPN && (!this.visibilities.engineVolume || !this.visibilities.enginePower) && this.visibilities.engine) {
      engineVal = await this.optionsServ.getEngineValues(
        {
          brand: this.vehicleForm.get('brand').value,
          model: this.vehicleForm.get('model').value,
          modelYear: this.vehicleForm.get('modelYear').value,
          engine: this.vehicleForm.get('engine').value
        },
        this.uid
      );
    }
    if (!this.visibilities.engineVolume) {
      if (inPVPN || vehicleValue.fuel === 'EP') {
        vehicleValue.engineVolume = 0;
      } else {
        vehicleValue.engineVolume = engineVal?.volume;
      }
    }
    if (!this.visibilities.enginePower) {
      if (inPVPN) {
        vehicleValue.enginePower = 0;
      } else {
        vehicleValue.enginePower = engineVal?.power;
      }
    }
    if (!this.visibilities.numberOfSeats) {
      if (inPVPN || this.isElectro) {
        vehicleValue.numberOfSeats = 0;
      } else {
        vehicleValue.numberOfSeats = await this.optionsServ.getNumberOfSeats(
          {
            brand: this.vehicleForm.get('brand').value,
            model: this.vehicleForm.get('model').value,
            modelYear: this.vehicleForm.get('modelYear').value,
            engine: this.vehicleForm.get('engine').value,
            bodywork: this.vehicleForm.get('bodywork').value
          },
          this.uid
        );
      }
    }
    if (vehicleValue.clientsVehiclePrice || vehicleValue.clientsVehiclePrice === 0) {
      vehicleValue.clientsVehiclePrice = String(vehicleValue.clientsVehiclePrice);
    }
    if (!vehicleValue.equipment) vehicleValue.equipment = 'NA';
    if (typeof vehicleValue.clientsVehiclePrice === 'number')
      vehicleValue.clientsVehiclePrice = new TBigDecimal(vehicleValue.clientsVehiclePrice);
    if (!vehicleValue.vehicleName && this.options?.brand?.length && this.visibilities.model && this.options.model?.length) {
      const brandTxt = this.options.brand.find(br => br.value === this.vehicleForm.get('brand').value)?.text;
      const modelTxt = this.options.model.find(br => br.value === this.vehicleForm.get('model').value)?.text;
      vehicleValue.vehicleName = `${brandTxt} ${modelTxt}`;
    }

    vehicleValue = this.unOstatni(vehicleValue);

    if (!this.dataServ.savedData) {
      const theseAreUnderMyProtection = this.theseAreUnderMyProtection;
      this.dataServ.savedData = {
        sameAsUser: this.sameAsUser.value,
        spz: this.spz.value,
        theseAreUnderMyProtection,
        vehicleValue: null,
      };
    }
    this.dataServ.savedData.vehicleValue = vehicleValue;
    if (vehicleValue.productionYear) vehicleValue.productionYear = new TDate(new Date(String(vehicleValue.productionYear)));
    vehicleValue.withoutVAT = this.vehicleForm.controls.withoutVAT.value || false;
    if (this.isElectro) vehicleValue.garaged = false;
    return vehicleValue;
  }

  private unOstatni(vehicle: TsXmlParAp1Vehicle): TsXmlParAp1Vehicle {
    if (vehicle.modelYear === 'Ostatní') // TODO
      vehicle.modelYear = null;
    if (vehicle.engine === 'Ostatní')
      vehicle.engine = null;
    if (vehicle.bodywork === 'Ostatní')
      vehicle.bodywork = null;
    return vehicle;
  }

  _htmlFunIsFCInvalid(fcName: string): boolean {
    const fc = fcName === 'spz' ? this.spz : this.vehicleForm.get(fcName);
    return !fc.disabled && fc.touched && !fc.valid;
  }

  private done() {
    if (this.vehicleForm.valid) {
      this.prepareData(true).then(data => {
        this.doneEvent.emit(data);
      });
    } else {
      this.vehicleForm.markAllAsTouched();
      setTimeout(() => {
        const el = document.querySelector('.form-group--error');
        el?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        el?.querySelector('input')?.focus();
      }, 100);
    }
  }

  protected onFinishHim() {
    if (!this.vehicleForm.valid) {
      Object.keys(this.vehicleForm.controls).forEach(key => {
        if (!this.vehicleForm.controls[key].valid && !this.visibilities[key]) {
          this.vehicleForm.controls[key].setErrors(null, { emitEvent: false });
          this.vehicleForm.controls[key].setValue(null, { emitEvent: false });
        }
      });
    }
    if (this.vehicleForm.valid) {
      this.done();
    } else {
      if (!this.hideAllAfterSPZ) {
        this.vehicleForm.markAllAsTouched();
        setTimeout(() => {
          const el = document.querySelector('.form-group--error');
          el?.scrollIntoView({ behavior: 'smooth', block: 'center' });
          el?.querySelector('input')?.focus();
        }, 100);
      }
    }
  }

  protected onShowTooltip(name: string): void {
    if (!name || !this.tooltips[name]) return;
    this.ouhaServ.tooltip(this.tooltips[name]);
  }

  public setPrice(price: boolean) {
    if (this.vehicleForm.get('vehiclePrice').value !== price)
      this.vehicleForm.get('vehiclePrice').setValue(price, { emitEvent: false });
    if (price) {
      this.vehicleForm.get('clientsVehiclePrice').setValue(null, { emitEvent: false });
      this.vehicleForm.get('clientsVehiclePrice').setErrors(null, { emitEvent: false });
    }
    const _240Mnth = this.othersServ.getDateChangedBy(this.now, 0, -240);
    let age = this.vl('productionYear') || this.vl('firstRegistrationDate');
    if (typeof age === 'string') age = new Date(age);
    const tooOld = age && age.getTime() < _240Mnth.getTime();
    const visible = !tooOld && !this.vl('vehiclePrice') && !['EV', 'EK'].includes(this.vl('vehicleType'));
    this.visibilities.clientsVehiclePrice = visible && !price;
  }

  public getVehicle(): TsXmlParAp1Vehicle {
    return { ...this.dataServ.savedData.vehicleValue };
  }

  public validate(): boolean {
    this.vehicleForm.markAllAsTouched();
    return this.vehicleForm.valid;
  }

  ngOnDestroy() {
    this.subs?.forEach(sub => sub?.unsubscribe());
    this.step3Serv.vehicleUse = this.options?.vehicleUse?.find(
      op => op.value === this.vehicleForm.get('vehicleUse').value
    ).text || null;
    this.save2Service();
  }

}
