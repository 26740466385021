<div *ngIf="blob && blob !== 'nende'">
  <div class="grid">
    <div class="u-button-shadowless dialog_close">
      <span class="icon-circle-delete pilic-30 pilic text-orange" (click)="close()" tabindex="0"
        (keydown.enter)="close()"></span>
    </div>
    <div class="dialog row" *ngIf="!openableBlobTypeBoolean">
      <div class="text-center fail">
        <span class="pilic icon-smlouva pilic-40 pilic-line-60 text-orange"></span>
        <h3 i18n>Dokument nelze zobrazit.</h3>
        <p i18n>Ale můžete si jej uložit nebo otevřít v jiné aplikaci.</p>
      </div>
    </div>

    <div mat-dialog-actions class="btns">
      <div class="text-center save u-btn u-bu-bigger">
        <a *ngIf="fileName && currentFileType !== 'pdf'" [download]="fileName" [href]="currentFile" download i18n
          tabindex="-1">Uložit</a>
        <a *ngIf="!fileName && currentFileType !== 'pdf'" [href]="currentFile" download i18n tabindex="-1">Uložit</a>
        <button *ngIf="currentFileType === 'pdf'" (click)="download()" i18n>Uložit</button>
      </div>
      <div class="text-center u-btn u-bu-bigger" *ngIf="iOSChrome && currentFileType === 'pdf'">
        <button (click)="iOSChromeDownloadPdf()" i18n>Uložit</button>
      </div>
      <div *ngIf="openableBlobTypeBoolean" class="u-btn u-bu-bigger">
        <button (click)="open()" i18n tabindex="-1">
          Otevřít na nové kartě
        </button>
      </div>
    </div>
  </div>

  <div class="dialog pdf-cont" *ngIf="currentFileType === 'pdf'">
    <ngx-extended-pdf-viewer [src]="blob" height="90%" [filenameForDownload]="fileName || 'document.pdf'">
    </ngx-extended-pdf-viewer>
  </div>
  <div class="marginTop30px" *ngIf="currentFileType === 'image'">
    <img class="middle" [src]="currentFile" alt="detail image" />
  </div>

  <div class="marginTop30px" *ngIf="currentFileType === 'video'">
    <video class="middle" width="100%" height="100%" controls>
      <source [src]="currentFile" />
    </video>
  </div>
</div>
<youtube-player *ngIf="data.video?.videoId" [videoId]="data.video.videoId" suggestedQuality="highres"
  [height]="data.video.height" [width]="data.video.width" [playerVars]="{controls: 1}">
</youtube-player>

<div *ngIf="!blob && !data.video?.videoId" class="loader">
  <app-loading-screen-default></app-loading-screen-default>
</div>
<div class="grid" *ngIf="blob === 'nende'">
  <div class="row close-container u-button-shadowless">
    <span class="icon-circle-delete pilic-30 pilic text-orange dialog_close" (click)="close()" tabindex="0"
      (keydown.enter)="close()"></span>
  </div>
  <span i18n>Ouha. Něco se pokazilo.</span>
</div>