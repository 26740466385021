import { UtilsService } from '../services/utils.service';

export class TsXmlParAp1Demands {
    /** Potřebuji povinné ručení (nebudu ho mít u jiné pojišťovny) */
    question1: boolean;
    /** Potřebuji pojistit skla proti rozbití (např. odlétnutým kamenem) */
    question2: boolean;
    /** Potřebuji pojistit vozidlo v situacích, které nezaviním (krádež, vandalismus, střet se zvířetem, přírodní nebezpečí) */
    question3: boolean;
    /** Potřebuji pojistit vozidlo na škodu, kterou sám zaviním */
    question4: boolean;
    /** Jezdím s vozidlem - identifikátor záznamu z číselníku 5139 */
    question5: string;
    /** V autě zavazadla běžne - identifikátor záznamu z číselníku 5140 */
    question6: string;
    /** Tisk potreb */
    question8?: boolean;
    /** Poznámka klienta */
    clientNote: string;
    /** Odpoveď na poznámku */
    note: string;

    /**
     * Metoda prevede instanci do XML V2.
     *
     * @param attributes xml attributes.
     * @return String xml.
     */
    public toXmlV2?(attributes: string): string {
        let result = '<xml-par-ap1-demands';
        if (attributes) {
            result += ' ' + attributes;
        }
        result += '>';

        if (!UtilsService.isNull(this.question1)) {
            result += '<question1>';
            result += this.question1;
            result += '</question1>';
        }
        if (!UtilsService.isNull(this.question2)) {
            result += '<question2>';
            result += this.question2;
            result += '</question2>';
        }
        if (!UtilsService.isNull(this.question3)) {
            result += '<question3>';
            result += this.question3;
            result += '</question3>';
        }
        if (!UtilsService.isNull(this.question4)) {
            result += '<question4>';
            result += this.question4;
            result += '</question4>';
        }
        if (!UtilsService.isNull(this.question5)) {
            result += '<question5>';
            result += this.question5;
            result += '</question5>';
        }
        if (!UtilsService.isNull(this.question6)) {
            result += '<question6>';
            result += this.question6;
            result += '</question6>';
        }
        if (!UtilsService.isNull(this.clientNote)) {
            result += '<clientNote>';
            result += this.clientNote;
            result += '</clientNote>';
        }
        if (!UtilsService.isNull(this.note)) {
            result += '<note>';
            result += this.note;
            result += '</note>';
        }

        result += '</xml-par-ap1-demands>';
        return result;
    }
}
