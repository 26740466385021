/* eslint-disable camelcase */
import { Injectable } from '@angular/core';
import { formatDate } from '@angular/common';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { HttpClient, HttpHeaders, HttpParams, HttpParameterCodec } from '@angular/common/http';

import { Observable, Subscription } from 'rxjs';

import { AppService } from './app.service';
import { UtilsService } from './utils.service';
import { OtherService } from './other.service';
import { MyErrorHandler } from './error.service';
import { AuthenticationService } from './authentication.service';

import { TDate, TDateD } from '../models/date.model';
import { TsXmlUid } from '../tsXml/tsXmlUid.model';
import { TsXmlTask } from '../tsXml/tsXmlTask.model';
import { TBigDecimal } from '../models/number.model';
import { TsXmlResponse } from '../tsXml/tsXmlResponse.model';
// import { TsXmlAdresaU3 } from '../tsXml/tsXmlAdresaU3.model';
import { TsXmlOperator } from '../tsXml/tsXmlOperator.model';
import { TsXmlParParty } from '../tsXml/tsXmlParParty.model';
import { TsXmlRESEntry } from '../tsXml/tsXmlRESEntry.model';
import { TsXmlTaskList } from '../tsXml/tsXmlTaskList.model';
import { TsXmlWofMessage } from '../tsXml/tsXmlWofMessage.model';
import { TsXmlParDemands } from '../tsXml/tsXmlParDemands.model';
import { TsXmlBankAccount } from '../tsXml/tsXmlBankAccount.model';
import { TsXmlAgentACList } from '../tsXml/tsXmlAgentACList.model';
import { TsXmlPaymentData } from '../tsXml/tsXmlPaymentData.model';
import { TsXmlGeneralValue } from '../tsXml/tsXmlGeneralValue.model';
import { TsXmlParPartyAuth } from '../tsXml/tsXmlParPartyAuth.model';
import { TsXmlBoolResponse } from '../tsXml/tsXmlBoolResponse.model';
import { TsXmlParPartyList } from '../tsXml/tsXmlParPartyList.model';
import { TsXmlOnlinePayment } from '../tsXml/tsXmlOnlinePayment.model';
import { TsXmlParAp1Vehicle } from '../tsXml/tsXmlParAp1Vehicle.model';
import { TsXmlBPCalculation } from '../tsXml/tsXmlBPCalculation.model';
import { TsXmlCodelistEntry } from '../tsXml/tsXmlCodelistEntry.model';
import { TsXmlCodelistColumn } from '../tsXml/tsXmlCodelistColumn.model';
import { TsXmlWofMessageList } from '../tsXml/tsXmlWofMessageList.model';
import { TsXmlParPolicyChange } from '../tsXml/tsXmlParPolicyChange.model';
import { TsXmlParamStringList } from '../tsXml/tsXmlParamStringList.model';
import { TsXmlCodelistEntries } from '../tsXml/tsXmlCodelistEntries.model';
import { TsXmlParPolicyAgents } from '../tsXml/tsXmlParPolicyAgents.model';
import { TsXmlOperatorInfoList } from '../tsXml/tsXmlOperatorInfoList.model';
import { TsXmlParCancelRequest } from '../tsXml/tsXmlParCancelRequest.model';
import { TsXmlBusinessProposal } from '../tsXml/tsXmlBusinessProposal.model';
import { TsXmlParAccountPayment } from '../tsXml/tsXmlParAccountPayment.model';
import { TsXmlParCancelTypeList } from '../tsXml/tsXmlParCancelTypeList.model';
import { TsXmlContactPointPhone } from '../tsXml/tsXmlContactPointPhone.model';
import { TsXmlParCreateOperator } from '../tsXml/tsXmlParCreateOperator.model';
import { TsXmlParPm1SumsInsured } from '../tsXml/tsXmlParPm1SumsInsured.model';
import { TsXmlPaymentMethodList } from '../tsXml/tsXmlPaymentMethodList.model';
import { TsXmlParThePay20Response } from '../tsXml/tsXmlParThePay20Response.model';
import { TsXmlParRp1RatePolicyReq } from '../tsXml/tsXmlParRp1RatePolicyReq.model';
import { TsXmlParPolicyChangeList } from '../tsXml/tsXmlParPolicyChangeList.model';
import { TsXmlParRp1SavePolicyReq } from '../tsXml/tsXmlParRp1SavePolicyReq.model';
import { TsXmlBusinessTransaction } from '../tsXml/tsXmlBusinessTransaction.model';
import { TsXmlParAp1SavePolicyReq } from '../tsXml/tsXmlParAp1SavePolicyReq.model';
import { TsXmlParAp2SavePolicyReq } from '../tsXml/tsXmlParAp2SavePolicyReq.model';
import { TsXmlParAp2RatePolicyReq } from '../tsXml/tsXmlParAp2RatePolicyReq.model';
import { TsXmlParAp1RatePolicyReq } from '../tsXml/tsXmlParAp1RatePolicyReq.model';
import { TsXmlDocumentWithContent } from '../tsXml/tsXmlDocumentWithContent.model';
import { TsXmlHttpErrorResponse } from 'src/app/tsXml/tsXmlHttpErrorResponse.model';
import { TsXmlParReducedPolicyList } from '../tsXml/tsXmlParReducedPolicyList.model';
import { TsXmlParVoucherInfoForApp } from '../tsXml/tsXmlParVoucherInfoForApp.model';
import { TsXmlParRp1RatePolicyResp } from '../tsXml/tsXmlParRp1RatePolicyResp.model';
import { TsXmlParRp1SavePolicyResp } from '../tsXml/tsXmlParRp1SavePolicyResp.model';
import { TsXmlParAccountChangeList } from '../tsXml/tsXmlParAccountChangeList.model';
import { TsXmlParVehicleInspection } from '../tsXml/tsXmlParVehicleInspection.model';
import { TsXmlParAp1RatePolicyResp } from '../tsXml/tsXmlParAp1RatePolicyResp.model';
import { TsXmlParAp1SavePolicyResp } from '../tsXml/tsXmlParAp1SavePolicyResp.model';
import { TsXmlParCalculationIdList } from '../tsXml/tsXmlParCalculationIdList.model';
import { TsXmlParInsuranceTermList } from '../tsXml/tsXmlParInsuranceTermList.model';
import { TsXmlTaskPostCreateData } from 'src/app/tsXml/tsXmlTaskPostCreateData.model';
import { TsXmlParMyInsuranceAccount } from '../tsXml/tsXmlParMyInsuranceAccount.model';
import { TsXmlPolicyAgentRoleList } from 'src/app/tsXml/tsXmlPolicyAgentRoleList.model';
import { TsXmlParPm1SavePolicyReq } from 'src/app/tsXml/tsXmlParPm1SavePolicyReq.model';
import { TsXmlParPm1RatePolicyReq } from 'src/app/tsXml/tsXmlParPm1RatePolicyReq.model';
import { TsXmlParCancelRequestSTR_11 } from '../tsXml/tsXmlParCancelRequestSTR_11.model';
import { TsXmlParCancelRequestSTR_24 } from '../tsXml/tsXmlParCancelRequestSTR_24.model';
import { TsXmlParCancelRequestSTR_43 } from '../tsXml/tsXmlParCancelRequestSTR_43.model';
import { TsXmlParPolicyChangeRequest } from '../tsXml/tsXmlParPolicyChangeRequest.model';
import { TsXmlPortalAuthorizationWay } from '../tsXml/tsXmlPortalAuthorizationWay.model';
import { TsXmlAgentSuperordinateList } from '../tsXml/tsXmlAgentSuperordinateList.model';
import { TsXmlParPm1RatePolicyResp } from 'src/app/tsXml/tsXmlParPm1RatePolicyResp.model';
import { TsXmlParPm1SavePolicyResp } from 'src/app/tsXml/tsXmlParPm1SavePolicyResp.model';
import { TsXmlParCalculationEntryList } from '../tsXml/tsXmlParCalculationEntryList.model';
import { TsXmlBusinessTransactionList } from '../tsXml/tsXmlBusinessTransactionList.model';
import { TsXmlDocumentWithContentList } from '../tsXml/tsXmlDocumentWithContentList.model';
import { TsXmlParOperatorLoginNameAuth } from '../tsXml/tsXmlParOperatorLoginNameAuth.model';
import { TsXmlPortalAuthorizationToken } from '../tsXml/tsXmlPortalAuthorizationToken.model';
import { TsXmlParRuianAddressPointList } from '../tsXml/tsXmlParRuianAddressPointList.model';
import { TsXmlParPm1PrintCalculationReq } from '../tsXml/tsXmlParPm1PrintCalculationReq.model';
import { TsXmlDocumentTypeForEntityList } from '../tsXml/tsXmlDocumentTypeForEntityList.model';
import { TsXmlParAp1PrintCalculationReq } from '../tsXml/tsXmlParAp1PrintCalculationReq.model';
import { TsXmlParAp2PrintCalculationReq } from '../tsXml/tsXmlParAp2PrintCalculationReq.model';
import { TsXmlParRp1PrintCalculationReq } from '../tsXml/tsXmlParRp1PrintCalculationReq.model';
import { TsXmlParPolicyPrescriptionList } from '../tsXml/tsXmlParPolicyPrescriptionList.model';
import { TsXmlParCancelRequestSTR_30_31 } from '../tsXml/tsXmlParCancelRequestSTR_30_31.model';
import { TsXmlParAccountChangeCreateData } from '../tsXml/tsXmlParAccountChangeCreateData.model';
import { TsXmlParOnlinePaymentMethodList } from '../tsXml/tsXmlParOnlinePaymentMethodList.model';
import { TsXmlParOdometerStateResponse } from 'src/app/tsXml/tsXmlParOdometerStateResponse.model';
import { TsXmlAuthorizationInstrumentList } from '../tsXml/tsXmlAuthorizationInstrumentList.model';
import { TsXmlParCancelRequestSTR_22_23_25 } from '../tsXml/tsXmlParCancelRequestSTR_22_23_25.model';
import { TsXmlParCalculationSearchEntryList } from '../tsXml/tsXmlParCalculationSearchEntryList.model';
import { TsXmlParPolicyPaymentsReportList } from 'src/app/tsXml/tsXmlParPolicyPaymentsReportsList.model';
import { TsXmlParAp2PrintVariantOfSubjectReq } from '../tsXml/tsXmlParAp2PrintVariantOfSubjectReq.model';
import { TsXmlParPolicyOdometerReadingList } from 'src/app/tsXml/tsXmlParPolicyOdometerReadingList.model';
import { TsXmlParPolicyVehicleInspectionList } from 'src/app/tsXml/tsXmlParPolicyVehicleInspectionList.model';

import { PublicKeyCredentialCreationOptionsJSON, RegistrationResponseJSON, PublicKeyCredentialRequestOptionsJSON, AuthenticationResponseJSON } from '@simplewebauthn/browser';

import { u3RestPrefix } from '../variables/variables';
import { u3RestV2Prefix } from '../variables/variables';

import { deepCopy } from 'deep-copy-ts';
import { TsXmlParAp1Demands } from '../tsXml/tsXmlParAp1Demands.model';
import { TsXmlAuthorizationInstrument } from '../tsXml/tsXmlAuthorizationInstrument.model';


// export const u3RestPrefix = '/sirael/u3intf/json/v1/';
// export const u3RestV2Prefix = '/sirael/u3intf/json/v2/';

export const attributesXmlParAp1RatePolicyReq =
  'xmlns="www.ais.cz" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="www.ais.cz XmlParAp1RatePolicyReq.xsd"';
export const attributesXmlParAp1SavePolicyReq =
  'xmlns="www.ais.cz" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="www.ais.cz XmlParAp1SavePolicyReq.xsd"';

@Injectable()
export class CustomHttpParams extends HttpParams {
  constructor() {
    super({ encoder: new CustomHttpParameterCodec() });
  }
}

@Injectable()
export class CustomHttpParameterCodec implements HttpParameterCodec {
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }
  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }
  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }
  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }
}

@Injectable()
export class RestService {
  constructor(
    private appServ: AppService,
    private authServ: AuthenticationService,
    private errorHandler: MyErrorHandler,
    private http: HttpClient,
    private otherServ: OtherService,
    private sanitizer: DomSanitizer,
  ) { }

  private now: TDate;

  /*********************************************************************************************************/
  /* kontext MAIN ******************************************************************************************/
  /*********************************************************************************************************/

  /*********************************************************************************************************/
  /* agents ************************************************************************************************/

  /**
   * Get agents info
   * @param id agents id
   * @param uid rest uid
   * @return Promise<TsXmlAgentACList>
   */
  public agentsAutoCompleteGet(id: string, uid: TsXmlUid): Promise<TsXmlAgentACList> {
    return new Promise<TsXmlAgentACList>((resolve, reject) => {
      const httpHeaders = this.getHttpHeader(uid, 'text/xml; charset=utf-8');
      const httpParams = this.getHttpParams3({ agentId: id });
      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.get(
        u3RestPrefix + 'agents/autoComplete', {
        headers: httpHeaders,
        params: httpParams,
      }).subscribe({
        next: (resp) => {
          delete this.authServ.subs[key];
          resolve(resp as TsXmlAgentACList);
        },
        error: (error) => {
          delete this.authServ.subs[key];
          error.message = 'function: agentsAutoCompleteGet;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 404));
        }
      });
    });
  }

  public agentsObjIdSuperordinates(objId: any, uid: TsXmlUid): Promise<TsXmlAgentSuperordinateList> {
    return new Promise<TsXmlAgentSuperordinateList>((resolve, reject) => {
      const headers = this.getHttpHeader(uid, 'text/xml; charset=utf-8');
      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.get(
        `${u3RestPrefix}agents/${objId}/superordinates`,
        { headers }
      ).subscribe({
        next: (resp) => {
          delete this.authServ.subs[key];
          resolve(resp as TsXmlAgentSuperordinateList);
        },
        error: (error) => {
          delete this.authServ.subs[key];
          error.message = `function: agentsObjIdSuperordinates; objId: ${objId}; ${error.message}`;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 404));
        }
      });
    });
  }

  /*********************************************************************************************************/
  /* authorize *********************************************************************************************/

  /**
   * Authorize user and return rest uid
   * @param user login
   * @param password password
   * @return Promise<TsXmlUid>
   */
  public authorizePost(
    user: string,
    password: string,
    code?: string,
  ): Promise<TsXmlUid> {
    return new Promise<TsXmlUid>((resolve, reject) => {
      const param = this.getHttpParams3({ user, password });
      const obj = {
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
        'Accept-Language': this.appServ.locale.code,
      };
      if (code) obj['REST-MFA'] = `REST_AUTHORIZE sms=${code}`;
      const headers = new HttpHeaders(obj);

      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.post(u3RestPrefix + 'authorize', param, { headers }).subscribe({
        next: (resp) => {
          delete this.authServ.subs[key];
          resolve(resp as TsXmlUid);
        },
        error: (error) => {
          delete this.authServ.subs[key];
          error.message = 'function: authorizePost;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 400, 404));
        }
      });
    });
  }

  public webAuthnGetAuthChallenge(): Promise<{ optionsJSON: PublicKeyCredentialRequestOptionsJSON, useBrowserAutofill?: boolean }> {
    return new Promise((resolve, reject) => {
      const headers = this.getHttpHeader(null, 'application/json');
      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.post(`${u3RestPrefix}RestUid/schemes/X-AIS-WebAuthn/challenge`, null, { headers }).subscribe({
        next: (resp) => {
          delete this.authServ.subs[key];
          resolve({ optionsJSON: resp[`publicKey`] } as { optionsJSON: PublicKeyCredentialRequestOptionsJSON });
        },
        error: (error) => {
          delete this.authServ.subs[key];
          error.message = 'function: webAuthnGetAuthChallenge;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 400, 404));
        }
      });
    });
  }

  public webAuthnVerifyAuth(credential: AuthenticationResponseJSON): Promise<TsXmlUid> {
    return new Promise((resolve, reject) => {
      const headers = this.getHttpHeader(null, 'application/json');
      const body = {
        credentials: JSON.stringify({
          authenticatorAttachment: credential.authenticatorAttachment,
          clientExtensionResults: credential.clientExtensionResults,
          id: credential.id,
          response: {
            authenticatorData: credential.response.authenticatorData,
            clientDataJSON: credential.response.clientDataJSON,
            signature: credential.response.signature,
            userHandle: credential.response.userHandle,
          },
          type: credential.type,
        }),
        scheme: 'X-AIS-WebAuthn'
      };
      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.post(`${u3RestPrefix}RestUid`, body, { headers }).subscribe({
        next: (resp) => {
          delete this.authServ.subs[key];
          resolve(resp as TsXmlUid);
        },
        error: (error) => {
          delete this.authServ.subs[key];
          error.message = 'function: webAuthnVerifyAuth;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 400, 404));
        }
      });
    });
  }

  /*********************************************************************************************************/
  /* currentTimeJBoss ************************************************************************************************/

  /**
   * Get current Time from server
   * @param uid rest uid
   * @return Promise<TDate>
   */
  public currentTimeJBossGet(uid: TsXmlUid): Promise<TDate> {
    return new Promise<TDate>((resolve) => {
      const headers = this.getHttpHeader(uid, 'text/xml; charset=utf-8');

      if (this.now) {
        resolve(this.now);
        return;
      }

      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.get(
        `${u3RestPrefix}currentTimeJBoss`,
        { headers }
      ).subscribe({
        next: (resp: TsXmlResponse) => {
          delete this.authServ.subs[key];
          const now = new TDate(resp.code);
          this.setNow(now);
          resolve(now);
        },
        error: (error) => {
          delete this.authServ.subs[key];
          error.message = 'function: currentTimeJBossGet;' + error.message;
          this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error);

          const dtString = new Date().toISOString().replace(/\..*/, '');
          resolve(new TDate(dtString));
        }
      });
    });
  }

  private setNow(now: TDate): void {
    this.now = now;
    const tomorrow = this.otherServ.getDateChangedBy(now, 0, 0, 1);
    const ms = tomorrow.getTime() - now.getTime();

    setTimeout(() => {
      this.now = tomorrow;
      this.setNow(this.now);
    }, ms);
  }

  /*********************************************************************************************************/
  /* logout ************************************************************************************************/

  /**
   * Logout and return TsXmlResponse
   * @param uid rest uid
   * @return Promise<TsXmlResponse>
   */
  public logoutPost(uid: TsXmlUid): Promise<TsXmlResponse> {
    return new Promise<TsXmlResponse>((resolve, reject) => {
      const headers = this.getHttpHeader(uid);
      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.post(u3RestPrefix + 'logout', null, { headers }).subscribe({
        next: (resp) => {
          delete this.authServ.subs[key];
          resolve(resp as TsXmlResponse);
        },
        error: (error) => {
          delete this.authServ.subs[key];
          error.message = 'function: logoutPost;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 404));
        }
      });
    });
  }

  /*********************************************************************************************************/
  /* codeLists *********************************************************************************************/

  /**
   * Get codelist entry by id
   * @param codeListCode codelist code
   * @param entryId codelist entry id
   * @param uid rest uid
   * @return Promise<TsXmlCodelistEntry>
   */
  public codeListsEntriesEntryIdGet(codeListCode: string, entryId: string, uid: TsXmlUid): Promise<TsXmlCodelistEntry> {
    return new Promise<TsXmlCodelistEntry>((resolve, reject) => {
      const headers = this.getHttpHeader(uid);

      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.get(`${u3RestPrefix}codeLists/${codeListCode}/entries/${entryId}`, { headers }).subscribe({
        next: (resp) => {
          delete this.authServ.subs[key];
          resolve(resp as TsXmlCodelistEntry);
        },
        error: (error) => {
          delete this.authServ.subs[key];
          error.message = `function: codeListsEntriesEntryIdGet;
  code: ${codeListCode}
  entryId: ${entryId}
${error.message}`;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 404));
        }
      });
    });
  }

  public getRES(ico: string, uid: TsXmlUid): Promise<TsXmlRESEntry> {
    return new Promise<TsXmlRESEntry>((resolve, reject) => {
      const headers = this.getHttpHeader(uid);
      const key = this.getSubsKey(this.authServ.subs);

      this.authServ.subs[key] = this.http.get(`${u3RestPrefix}codeLists/RES/entries/${ico}`, { headers }).subscribe({

        next: async (resp) => {
          delete this.authServ.subs[key];
          const res = new TsXmlRESEntry(resp);
          // const cdAr = [
          //   { key: 'districtId', id: '976' }, { key: 'endTypeId', id: '977' }, { key: 'statisticLegalFormId', id: '978' },
          //   { key: 'personRegistryLegalFormId', id: '979' }, { key: 'employeeCountCategoryId', id: '980' }, { key: 'activityTypeId', id: '981' },
          //   { key: 'areaIdentifierId', id: '982' }, { key: 'esa2010CodeId', id: '983' }, { key: 'landRegistryNrTypeId', id: '984' },
          // ];

          // for (const item of cdAr) {
          //   res[item.key] = (await this.codeListsEntriesEntryIdGet(item.id, res[item.key], uid)).sclDescription;
          // }
          console.log(res)
          resolve(res);
        },
        error: (error) => {
          delete this.authServ.subs[key];
          error.message = `function: getRES; ico: ${ico}; ${error.message}`;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 404));
        }
      });
    });
  }

  public getPartyFromRES(res: TsXmlRESEntry): TsXmlParParty {
    const party = new TsXmlParParty();
    party.companyIdentification = res.ico;
    party.companyName = res.companyName;
    party.partyType = res.esa2010CodeId === '14200' ? 'OSVC' : 'PO';
    party.address = {
      city: res.town,
      country: 'CZE',
      houseNumber: res.houseNr,
      landRegistryNumber: res.landRegistryNr,
      street: res.street ?? res.townPart,
      zipCode: res.postCode
    };
    return party;
  }

  /*********************************************************************************************************/
  /* documentArchive ***************************************************************************************/

  /**
   * Get documents by document identification
   * @param docId document Identification
   * @param uid rest uid
   * @return Promise<TsXmlDocumentWithContentList>
   */
  public documentsArchiveGet(docId: string, uid: TsXmlUid): Promise<TsXmlDocumentWithContentList> {
    return new Promise<TsXmlDocumentWithContentList>((resolve, reject) => {
      const headers = this.getHttpHeader(uid, 'text/xml; charset=utf-8');
      const params = this.getHttpParams3({ documentId: docId });

      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.get(`${u3RestPrefix}documentsArchive`, { headers, params }).subscribe({
        next: (resp) => {
          delete this.authServ.subs[key];
          resolve(resp as TsXmlDocumentWithContentList);
        },
        error: (error) => {
          delete this.authServ.subs[key];
          error.message = 'function: documentsArchiveGet;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 404));
        }
      });
    });
  }

  /**
   * Get File as Blob from document archive by docObjId and docSerialNum
   * @param docObjId document objId
   * @param docSerialNum documetn file serial number
   * @param uid rest uid
   * @return Promise<Blob>;
   */
  public documentsArchiveDocumentFilesGet(docObjId: number, docSerialNum: number, uid: TsXmlUid): Promise<Blob> {
    return new Promise<Blob>((resolve, reject) => {
      const headers = this.getHttpHeader(uid);

      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.get(
        `${u3RestPrefix}documentsArchive/document/${docObjId}/files/${docSerialNum}`,
        { headers, responseType: 'blob' }
      ).subscribe({
        next: (resp) => {
          delete this.authServ.subs[key];
          resolve(resp);
        },
        error: (error) => {
          delete this.authServ.subs[key];
          error.message = 'function: documentsArchiveDocumentFilesGet;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 404));
        }
      });
    });
  }

  /*********************************************************************************************************/
  /* workflow **********************************************************************************************/

  /**
   * Get specific wof message with objId
   * @param uid rest uid
   * @param objId endpoint objId
   * @return Observable with GwtXmlWofMessage
   */
  public workflowMessagesGet(uid: TsXmlUid, objId: string): Promise<TsXmlWofMessage> {
    return new Promise<TsXmlWofMessage>((resolve, reject) => {
      const headers = this.getHttpHeader(uid);

      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.get(`${u3RestPrefix}workflow/messages/${objId}`, { headers }).subscribe({
        next: (resp) => {
          delete this.authServ.subs[key];
          resolve(UtilsService.convertAllJsonDateStrings(resp) as TsXmlWofMessage);
        },
        error: (error) => {
          delete this.authServ.subs[key];
          error.message = 'function: workflowMessagesGet;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 404));
        }
      });
    });
  }

  /**
   * Set state of wof message
   * @param uid rest uid
   * @param objId wof message objId
   * @param state state
   * @return Promise<void>
   */
  public workflowMessagesStatePut(uid: TsXmlUid, objId: string, state: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const headers = this.getHttpHeader(uid, 'text/xml; charset=utf-8');
      const params = this.getHttpParams3({ stav: state });

      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.put(`${u3RestPrefix}workflow/messages/${objId}/state`, null, { headers, params }).subscribe({
        next: () => {
          delete this.authServ.subs[key];
          resolve();
        },
        error: (error) => {
          delete this.authServ.subs[key];
          error.message = 'function: workflowMessagesStatePut;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 404));
        }
      });
    });
  }

  /**
   * Delete specific wof message with objId
   * @param objId wof message objId
   * @param uid rest uid
   */
  public workflowMessagesDelete(
    objId: string,
    uid: TsXmlUid
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const headers = this.getHttpHeader(uid);

      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.delete(`${u3RestPrefix}workflow/messages/${objId}`, { headers }).subscribe({
        next: () => {
          delete this.authServ.subs[key];
          resolve();
        },
        error: (error) => {
          delete this.authServ.subs[key];
          error.message = 'function: workflowMessagesDelete;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 404));
        }
      });
    });
  }

  /*********************************************************************************************************/
  /* businessTransactions ****************************************************************************************/

  /**
   * Set administrator to business transactions
   * @param uid rest uid
   * @param objId business transactions objId
   * @return Promise<void>
   */
  public administratorPut(uid: TsXmlUid, objId: number, agentObjId?: number): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const headers = this.getHttpHeader(uid, 'text/xml; charset=utf-8');
      const params = this.getHttpParams3({ agentObjId: agentObjId + '' });

      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.put(
        `${u3RestPrefix}businessTransactions/${objId}/administrator`,
        null,
        { headers, params: agentObjId && agentObjId !== 0 ? params : null }
      ).subscribe({
        next: () => {
          delete this.authServ.subs[key];
          resolve();
        },
        error: (error) => {
          delete this.authServ.subs[key];
          error.message = 'function: administratorPut;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 404));
        }
      });
    });
  }

  /**
   * Set responsible operator to business transactions
   * @param uid rest uid
   * @param objId business transactions objId
   * @return Promise<void>
   */
  public respOperatorPut(uid: TsXmlUid, objId: number): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const headers = this.getHttpHeader(uid, 'text/xml; charset=utf-8');

      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.put(`${u3RestPrefix}businessTransactions/${objId}/respOperator`, null, { headers }).subscribe({
        next: () => {
          delete this.authServ.subs[key];
          resolve();
        },
        error: (error) => {
          delete this.authServ.subs[key];
          error.message = 'function: respOperatorPut;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 404));
        }
      });
    });
  }

  /**
   * Vrací seznam Obchodních případů pro přihlášeného operátora a předané podmínky.
   * @param uid rest uid
   * @param params dateFrom, dateTo: časové omezení;
   * @return Promise<TsXmlBusinessTransactionList>
   */
  public businessTransactionsGet(
    params: {
      offset: number;
      limit: number;
      products?: string[];
      definitionTypeIds?: string[];
      createdFrom?: TDate;
      createdTo?: TDate;
      searchText?: string;
      objIdParty?: number;
    },
    uid: TsXmlUid,
    subs?: { [key: string]: Subscription }
  ): Promise<TsXmlBusinessTransactionList> {
    params.searchText = params.searchText?.toUpperCase() || null;
    return new Promise<TsXmlBusinessTransactionList>((resolve, reject) => {
      const headers = this.getHttpHeader(uid);

      let cretedTo: string = null;
      if (params.createdTo) {
        cretedTo = params.createdTo.toJSON();
      }

      let httpParams = this.makeHttpParamsWith(
        ['createdFrom', 'createdTo', 'offset', 'limit', 'definitionId', 'definitionTypeIds',
          'stateIds', 'orderBy', 'orderDir', 'objIdParty'],
        [params.createdFrom, cretedTo, params.offset, params.limit, params.products,
        params.definitionTypeIds, ['1'], 'created', 'DESC', params.objIdParty],
        true
      );

      if (params.searchText) {
        httpParams = httpParams.append('searchText', '*' + params.searchText + '*');
      }
      const key = this.getSubsKey(subs ? { ...this.authServ.subs, ...subs } : this.authServ.subs);
      this.authServ.subs[key] = this.http.get(`${u3RestPrefix}businessTransactions`, {
        headers,
        params: httpParams,
      }).subscribe({
        next: (resp) => {
          delete this.authServ.subs[key];
          if (subs) delete subs[key];
          resolve(UtilsService.convertAllJsonDateStrings(resp) as TsXmlBusinessTransactionList);
        },
        error: (error) => {
          delete this.authServ.subs[key];
          if (subs) delete subs[key];
          error.message = 'function: businessTransactionsGet;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 403, 404));
        }
      });
      if (subs) subs[key] = this.authServ.subs[key];
    });
  }

  /**
   * Change state of Business transaction
   * @param objId business transaction objId
   * @param state state id from ciselnik 466
   * @param uid rest uid
   * @return Promise<void>
   */
  public businessTransactionsStatePut(objId: number, state: string, uid: TsXmlUid): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const headers = this.getHttpHeader(uid, 'text/xml; charset=utf-8');
      const params = this.getHttpParams3({ stateId: state });
      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.put(`${u3RestPrefix}businessTransactions/${objId}/state`, null, { headers, params }).subscribe({
        next: () => {
          delete this.authServ.subs[key];
          resolve();
        },
        error: (error) => {
          delete this.authServ.subs[key];
          error.message = 'function: businessTransactionsStatePut;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 404));
        }
      });
    });
  }

  /**
   * Load business transaction by objId
   * @param objId  business transaction objId
   * @param withPolicy load with policy
   * @param uid rest uid
   * @return Promise<TsXmlBusinessTransaction>
   */
  public businessTransactionsObjIdGet(objId: number, withPolicy: boolean, uid: TsXmlUid): Promise<TsXmlBusinessTransaction> {
    return new Promise<TsXmlBusinessTransaction>((resolve, reject) => {
      const headers = this.getHttpHeader(uid, 'text/xml; charset=utf-8');
      const params = this.getHttpParams3({ withPolicy });

      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.get(`${u3RestPrefix}businessTransactions/${objId}`, { headers, params }).subscribe({
        next: (resp) => {
          delete this.authServ.subs[key];
          resolve(UtilsService.convertAllJsonDateStrings(resp) as TsXmlBusinessTransaction);
        },
        error: (error) => {
          delete this.authServ.subs[key];
          error.message = 'function: businessTransactionsObjIdGet;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 404));
        }
      });
    });
  }

  /**
   * @param btResourceId TObjId Obj-Id Obchodního případu
   * @param stateId TStr20 Nový stav Obchodního případu (záznam v číselníku 466)
   * @param uid TsXmlUid
   */
  public businessTransactionsBtResourceIdStatePut(btResourceId: number, stateId: string, uid: TsXmlUid): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const headers = this.getHttpHeader(uid, 'text/xml; charset=utf-8');
      const params = this.getHttpParams2([{ stateId }]);

      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.put(
        `${u3RestPrefix}businessTransactions/${btResourceId}/state`,
        null,
        { headers, params }
      ).subscribe({
        next: () => {
          delete this.authServ.subs[key];
          resolve();
        },
        error: (error) => {
          delete this.authServ.subs[key];
          error.message = 'function: businessTransactionsBtResourceIdStatePut;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 404));
        }
      });
    });
  }

  /**
   * Vrací seznam Obchodních případů pro přihlášeného operátora a předané podmínky.
   * UC GET Vrať obchodní případy (AIS.Core / C0A81465-0BB8-0010-00000159EE4F9690-707372616A6572)
   * @param withPolicy Zda se načítá i smlouva Kalkulace (default = false)
   * @param withParty Zda se načítají i údaje Strany pojistníka OP (default = false)
   * @param createFrom Datum vytvoření obchodního případu - Od
   * @param stateIds Stavy obchodního případu (záznamy číselníku 466)
   *
   * @param stateCategoryIds Kategorie stavu obchodního případu (záznamy číselníku 540)
   * @param definitionTypeIds Typy obchodního případu (záznamy číselníku 464) - Definice obchodního případu.Typ
   * @param transHolderId Id pojistníka obchodního případu (Strana.Verze.Identifikace)
   * @param transAdministrator ObjId správce obchodního případu(Obchodní případ.Verze.Správce OP)
   *
   * @param transResponsible ObjId odpovědného za obchodní případ (Obchodní případ.Verze.Odpovědný)
   * @param transactionDesc Popis obchodního případu (Obchodní případ.Verze.Popis)
   * @param transHolderKindId Druh strany pojistníka obchodního případu (záznam číselníku 241)
   * @param transactionId Obchodní případ.Identifikátor
   *
   * @param definitionId Definice obchodního případu.Identifikátor
   * @param createdTo Datum vytvoření obchodního případu - Do
   * @param transHolderAbbr Zkratka pojistníka obchodního případu (Strana.Zkratka)
   * @param transAgentId Id získatele obchodního případu (Obchodní případ.Verze.ZískatelOP)
   *
   * @param definitionKindIds Druhy obchodního případu (záznamy číselníku 465) - Definice obchodního případu.Druh
   * @param sourcePolicyId Id výchozí smlouvy obchodního případu (Obchodní případ.Výchozí smlouva)
   * @param forOperator ObjId operátora, jehož Obchodní případy se mají vyhledávat (zpravidla podřízený přihlášeného)
   * @param searchText Vyhledávací údaje kalkulace
   *                    - klíčem jsou identifikátory záznamů v číselníku 781 - Druh vyhledávacího údaje
   *                    - pro vyhledání nehledě na druh použijte lze použít '*'
   * @param offset Offset návratového seznamu Obchodních případů
   * @param limit Limit velikosti návratového seznamu Obchodních případů
   * @param orderBy Parametr řazení
   * @param orderDir Způsob řazení výsledků
   *
   * @returns TsXmlBusinessTransactionList
   */
  public businessTransactionsGetFull(
    data: {
      withPolicy?: boolean, withParty?: boolean, createFrom?: TDate,
      stateIds?: string[], stateCategoryIds?: string[], definitionTypeIds?: string[],
      transHolderId?: string, transAdministrator?: number, transResponsible?: number,
      transactionDesc?: string, transHolderKindId?: string, transactionId?: string,
      definitionId?: string, createdTo?: TDate, transHolderAbbr?: string,
      transAgentId?: string, definitionKindIds?: string[], sourcePolicyId?: string,
      forOperator?: number, searchText?: string, offset?: number,
      limit?: number, orderBy?: string, orderDir?: string
    },
    uid: TsXmlUid
  ): Promise<TsXmlBusinessTransactionList> {

    const headers = this.getHttpHeader(uid);
    const params = this.getHttpParams3(data);

    return new Promise((resolve, reject) => {
      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.get(`${u3RestPrefix}businessTransactions`, { headers, params }).subscribe({
        next: (resp: TsXmlBusinessTransactionList) => {
          delete this.authServ.subs[key];
          resolve(resp as TsXmlBusinessTransactionList);
        },
        error: error => {
          delete this.authServ.subs[key];
          reject(this.handleError(error, 'businessTransactionsGetFull', params));
        }
      });
    });
  }

  /**
   * @param objId TObjId identifikace OP.
   * @param content požadované změny. GwtXmlParPolicyChangeRequest
   */
  public parBusinessTransactionsBtObjIdParChangePost(objId: number, content: TsXmlParPolicyChangeRequest, uid: TsXmlUid) {
    const headers = this.getHttpHeader(uid);

    return new Promise<TsXmlParPolicyChangeRequest>((resolve, reject) => {
      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.post(
        `${u3RestPrefix}par/businessTransactions/${objId}/parChange`,
        content,
        { headers }).subscribe({
          next: (resp: TsXmlParPolicyChangeRequest) => {
            delete this.authServ.subs[key];
            resolve(resp);
          },
          error: (error: TsXmlHttpErrorResponse) => {
            delete this.authServ.subs[key];
            let ignore = null;
            if (
              error?.status === 400 &&
              error?.error?.runtimeMsgs?.length &&
              [
                '211032', '211184', '621098', '622127', '621815', '621928', '621929', '622912'
              ].includes(error.error.runtimeMsgs[0]?.msgCode)
            ) {
              ignore = 400;
            }
            error.message = 'function: parBusinessTransactionsBtObjIdParChange;' + error.message;
            reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, ignore));
          }
        });
    });
  }

  /**
   * @param objId TObjId identifikace obchodního případu.
   * @param uid TsXmlUid
   */
  public parBusinessTransactionsBtObjIdParChangeGet(
    objId: number,
    uid: TsXmlUid,
    subs?: { [key: string]: Subscription }
  ): Promise<TsXmlParPolicyChangeRequest> {
    const headers = this.getHttpHeader(uid);

    return new Promise<TsXmlParPolicyChangeRequest>((resolve, reject) => {
      const key = this.getSubsKey(subs ? { ...this.authServ.subs, ...subs } : this.authServ.subs);
      this.authServ.subs[key] = this.http.get(
        `${u3RestPrefix}par/businessTransactions/${objId}/parChange`,
        { headers }
      ).subscribe({
        next: (resp: TsXmlParPolicyChangeRequest) => {
          delete this.authServ.subs[key];
          if (subs) delete subs[key];
          resolve(resp);
        },
        error: error => {
          delete this.authServ.subs[key];
          if (subs) delete subs[key];
          error.message = 'function: parBusinessTransactionsBtObjIdParChange;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 403));
        }
      });
      if (subs) subs[key] = this.authServ.subs[key];
    });
  }

  public recommendedSumsInsuredPm1Get(
    objId: number,
    uid: TsXmlUid,
    subs?: { [key: string]: Subscription }
  ): Promise<TsXmlParPm1SumsInsured> {
    const headers = this.getHttpHeader(uid);

    return new Promise<TsXmlParPm1SumsInsured>((resolve, reject) => {
      const key = this.getSubsKey(subs ? { ...this.authServ.subs, ...subs } : this.authServ.subs);
      this.authServ.subs[key] = this.http.get(
        `${u3RestPrefix}par/businessTransactions/${objId}/parChange/policy/recommendedSumsInsured/PM1`,
        { headers }
      ).subscribe({
        next: (resp: TsXmlParPm1SumsInsured) => {
          delete this.authServ.subs[key];
          if (subs) delete subs[key];
          resolve(resp);
        },
        error: error => {
          delete this.authServ.subs[key];
          if (subs) delete subs[key];
          error.message = 'function: parBusinessTransactionsBtObjIdParChange;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 403));
        }
      });
      if (subs) subs[key] = this.authServ.subs[key];
    });
  }

  /**
   * Returns a list of changing business cases for the policy
   * @param params definitionId e.g. ZMENA_SML_PILLOW, transResponsible objId of logined user, stateIds e.g. NOVY
   * @param uid rest uid
   * @return Promise<TsXmlBusinessTransactionList>
   */
  public businessTransactionsChangingCasesGet(
    data: {
      sourcePolicyId: string,
      definitionId: string,
      transResponsible: number,
      stateIds: string
    },
    uid: TsXmlUid
  ): Promise<TsXmlBusinessTransactionList> {
    const headers = this.getHttpHeader(uid);
    const params = this.getHttpParams3(data);

    return new Promise((resolve, reject) => {
      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.get(`${u3RestPrefix}businessTransactions/`, { headers, params }).subscribe({
        next: resp => {
          delete this.authServ.subs[key];
          resolve(resp as TsXmlBusinessTransactionList);
        },
        error: error => {
          delete this.authServ.subs[key];
          error.message = `function: businessTransactionsObjIdGet; ${error.message} ;;; ${params}`;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 404));
        }
      });
    });
  }

  /**
   * Create business transaction
   * @param definitionId business transaction definition id
   * @param uid rest id
   * @return Promise<TsXmlBusinessTransaction>
   */
  public businessTransactionsPost(definitionId: string, uid: TsXmlUid): Promise<TsXmlBusinessTransaction> {
    return new Promise<TsXmlBusinessTransaction>((resolve, reject) => {
      const headers = this.getHttpHeader(uid, 'text/xml; charset=utf-8');
      const params = this.getHttpParams3({ definitionId });

      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.post(`${u3RestPrefix}businessTransactions`, null, { headers, params }).subscribe({
        next: (bt) => {
          delete this.authServ.subs[key];
          resolve(UtilsService.convertAllJsonDateStrings(bt) as TsXmlBusinessTransaction);
        },
        error: (error) => {
          delete this.authServ.subs[key];
          error.message = 'function: businessTransactionsPost;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error));
        }
      });
    });
  }

  /**
   * Create business proposal
   * @param btObjId business transaction obj id
   * @param productCode product code
   * @param uid rest id
   * @return Promise<TsXmlBusinessProposal>
   */
  public businessTransactionsProposalsPost(
    btObjId: number,
    productCode: string,
    uid: TsXmlUid
  ): Promise<TsXmlBusinessProposal> {
    return new Promise<TsXmlBusinessProposal>((resolve, reject) => {
      const headers = this.getHttpHeader(uid, 'text/xml; charset=utf-8');
      const params = this.getHttpParams3({ productCode });
      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.post(
        `${u3RestPrefix}businessTransactions/${btObjId}/proposals`,
        null,
        { headers, params }
      ).subscribe({
        next: (bp) => {
          delete this.authServ.subs[key];
          resolve(UtilsService.convertAllJsonDateStrings(bp) as TsXmlBusinessProposal);
        },
        error: (error) => {
          delete this.authServ.subs[key];
          error.message = 'function: businessTransactionsProposalsPost;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error));
        }
      });
    });
  }

  /**
   * Get calculation
   * @param btObjId business transaction objId
   * @param bpObjId business proposal objId
   * @param withPolicy load with policy
   * @param uid rest uid
   * @return Observable as TsXmlBPCalculation
  */
  public businessTransactionsProposalsCalculationGet(
    btObjId: number,
    bpObjId: number,
    withPolicy: boolean,
    uid: TsXmlUid
  ): Promise<TsXmlBPCalculation> {
    return new Promise<TsXmlBPCalculation>((resolve, reject) => {
      const headers = this.getHttpHeader(uid, 'text/xml; charset=utf-8');
      const params = this.getHttpParams3({ withPolicy });

      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.get(
        `${u3RestPrefix}businessTransactions/${btObjId}/proposals/${bpObjId}/calculation`,
        { headers, params }
      ).subscribe({
        next: (resp) => {
          delete this.authServ.subs[key];
          resolve(UtilsService.convertAllJsonDateStrings(resp) as TsXmlBPCalculation);
        },
        error: (error) => {
          delete this.authServ.subs[key];
          error.message = 'function: businessTransactionsProposalsCalculationGet;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error));
        }
      });
    });
  }

  /**
   * Set calculation on business proposal
   * @param btObjId business transaction objId
   * @param bpObjId business proposal objId
   * @param content TsXmlBPCalculation
   * @param uid rest uid
   * @return Promise<TsXmlBPCalculation>
  */
  public businessTransactionsProposalsCalculationPost(
    btObjId: number,
    bpObjId: number,
    content: TsXmlBPCalculation,
    uid: TsXmlUid
  ): Promise<TsXmlBPCalculation> {
    return new Promise<TsXmlBPCalculation>((resolve, reject) => {
      const headers = this.getHttpHeader(uid, 'text/xml; charset=utf-8');

      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.post(
        `${u3RestPrefix}businessTransactions/${btObjId}/proposals/${bpObjId}/calculation`,
        content,
        { headers }
      ).subscribe({
        next: (resp: TsXmlBPCalculation) => {
          delete this.authServ.subs[key];
          resolve(UtilsService.convertAllJsonDateStrings(resp) as TsXmlBPCalculation);
        },
        error: (error) => {
          delete this.authServ.subs[key];
          error.message = 'function: businessTransactionsProposalsCalculationPost;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error));
        }
      });
    });
  }

  /**
   * Adds documents to businessTransaction
   * @param btObjId business transaction objId
   * @param documents TsXmlDocumentWithContentList
   * @param uid rest uid
   * @return Promise<TsXmlDocumentWithContentList>
   */
  public businessTransactionsBtObjIdDocumentsPost(
    btObjId: number,
    documents: TsXmlDocumentWithContentList,
    uid: TsXmlUid
  ): Promise<TsXmlDocumentWithContentList> {
    const headers = this.getHttpHeader(uid);

    return new Promise((resolve) => {
      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.post(
        `${u3RestPrefix}businessTransactions/${btObjId}/documents`,
        documents,
        { headers }
      ).subscribe({
        next: (resp: TsXmlDocumentWithContentList) => {
          delete this.authServ.subs[key];
          resolve(resp);
        },
        error: (error) => {
          delete this.authServ.subs[key];
          this.handleError(error, 'businessTransactionsBtObjIdDocumentsPost');
        }
      });
    });
  }

  /** Don't delete me yet. */
  // copyOP(numberOfOP: string, uid: TsXmlUid) {
  //   const httpOptions = {
  //     headers: new HttpHeaders({
  //       RestUID: uid && uid.uid ? uid.uid : '',
  //     }),
  //   };
  //   const key = this.getSubsKey(this.authServ.subs);
  //   this.authServ.subs[key] = this.http.post(u3RestPrefix + 'businessTransactions/' + numberOfOP + '/clone', httpOptions);
  // return this.authServ.subs[key];
  // }

  /*********************************************************************************************************/
  /* kontext SYSTEM ****************************************************************************************/
  /*********************************************************************************************************/

  /*********************************************************************************************************/
  /* system/address ****************************************************************************************/

  // public _searchAddress(methodName: string, address: TsXmlAdresaU3, uid: TsXmlUid): Observable<object> {
  //   const headers = this.getHttpHeader(uid);

  //   const key = this.getSubsKey(this.authServ.subs);
  //   this.authServ.subs[key] = this.http.post(`${u3RestPrefix}system/address/${methodName}`, address, { headers });
  //   return this.authServ.subs[key];
  // }

  /*********************************************************************************************************/
  /* system/captcha ****************************************************************************************/

  public systemCaptchaCreateToken(): Promise<TsXmlResponse> {
    return new Promise((resolve, reject) => {
      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.post(`${u3RestPrefix}system/captcha/createToken`, null).subscribe({
        next: (resp) => {
          delete this.authServ.subs[key];
          resolve(resp as TsXmlResponse);
        },
        error: (e) => {
          delete this.authServ.subs[key];
          reject(e);
        }
      });
    });
  }

  public systemCaptchaVerifyToken(handle: string, userCaptcha: string): Promise<TsXmlResponse> {
    return new Promise((resolve, reject) => {
      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.post(`${u3RestPrefix}system/captcha/verifyToken/${handle}?token=${userCaptcha}`, null).subscribe({
        next: (resp: TsXmlResponse) => {
          delete this.authServ.subs[key];
          resolve(resp);
        },
        error: (e) => {
          delete this.authServ.subs[key];
          reject(e);
        }
      });
    });
  }

  public getSystemConfParams(confParam: string, uid: TsXmlUid): Promise<TsXmlResponse> {
    return new Promise((resolve, reject) => {
      const key = this.getSubsKey(this.authServ.subs);
      const headers = this.getHttpHeader(uid);
      this.authServ.subs[key] = this.http.get(`${u3RestPrefix}system/confParams/${confParam}`, { headers }).subscribe({
        next: (resp: TsXmlResponse) => {
          delete this.authServ.subs[key];
          resolve(resp);
        },
        error: (e) => {
          delete this.authServ.subs[key];
          reject(e);
        }
      });
    });
  }


  /*********************************************************************************************************/
  /* system/documents **************************************************************************************/

  /**
   * Send temp file to server
   * @param file file
   * @param contents contents
   * @return Promise<TsXmlDocumentWithContent>
   */
  public systemDocumentsPost(file: File, contents: string): Promise<TsXmlDocumentWithContent> {
    return new Promise<TsXmlDocumentWithContent>((resolve, reject) => {
      const headers = this.getHttpHeader(null, 'application/base64-data');
      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.post(`${u3RestPrefix}system/documents/${file.name}`, contents, { headers }).subscribe({
        next: (resp) => {
          delete this.authServ.subs[key];
          resolve(UtilsService.convertAllJsonDateStrings(resp) as TsXmlDocumentWithContent);
        },
        error: (error) => {
          delete this.authServ.subs[key];
          error.message = 'function: systemDocumentsPost;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error));
        }
      });
    });
  }

  /**
   * Get temp file from server (unathorized)
   * @param fileName file name
   * @return Observable as Blob
   */
  public systemDocumentsGet(fileName: string): Promise<Blob> {
    return new Promise((resolve, reject) => {
      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.get(`${u3RestPrefix}system/documents/${fileName}`, { responseType: 'blob' }).subscribe({
        next: (blob) => {
          delete this.authServ.subs[key];
          resolve(blob);
        },
        error: (e) => {
          delete this.authServ.subs[key];
          reject(e);
        }
      });
    });
  }

  public systemDocumentsUploadTrackerPost(file: File, trackerId: string, contents: string, uid: TsXmlUid): Promise<TsXmlDocumentWithContent> {
    return new Promise((resolve, reject) => {
      const headers = this.getHttpHeader(uid, 'application/base64-data');

      let fileName = file.name;
      const lastIndex = file.name.lastIndexOf('.');
      if (lastIndex > 0) {
        fileName = file.name.substring(0, lastIndex);
        fileName += file.name.substring(lastIndex, file.name.length).toLowerCase();
      }

      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.post(
        `${u3RestPrefix}/system/documents/${fileName}?uploadTracker=${trackerId}`,
        contents,
        { headers }
      ).subscribe({
        next: (resp: TsXmlDocumentWithContent) => {
          delete this.authServ.subs[key];
          resolve(resp);
        },
        error: (e) => {
          delete this.authServ.subs[key];
          reject(e);
        }
      });
    });
  }

  // doesn't work yet
  public systemDocumentsUploadTrackerGet(trackerId: string, uid: TsXmlUid) {
    return new Promise((resolve, reject) => {
      const headers = this.getHttpHeader(uid);
      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.get(`${u3RestPrefix}system/documents/uploadTracker/${trackerId}`, { headers }).subscribe({
        next: (resp) => {
          delete this.authServ.subs[key];
          resolve(resp);
        },
        error: (e) => {
          delete this.authServ.subs[key];
          reject(e);
        }
      });
    });
  }

  public systemDocumentsUploadTrackerDelete(trackerId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.delete(`${u3RestPrefix}system/documents/uploadTracker/${trackerId}`).subscribe({
        next: () => {
          delete this.authServ.subs[key];
          resolve();
        },
        error: (e) => {
          delete this.authServ.subs[key];
          reject(e);
        }
      });
    });
  }

  public systemDocumentsUploadTrackerGetUnauth(): Promise<{ code: string }> {
    return new Promise((resolve, reject) => {
      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.get(`${u3RestPrefix}system/documents/uploadTracker`).subscribe({
        next: (resp: { code: string }) => {
          delete this.authServ.subs[key];
          resolve(resp);
        },
        error: (e) => {
          delete this.authServ.subs[key];
          reject(e);
        }
      });
    });
  }

  /*********************************************************************************************************/
  /* system/operator ***************************************************************************************/

  /**
   * Get operator info
   * @param uid rest uid
   * @return Promise<TsXmlOperator>
   */
  public systemOperatorGet(uid: TsXmlUid): Promise<TsXmlOperator> {
    return new Promise<TsXmlOperator>((resolve, reject) => {
      const headers = this.getHttpHeader(uid);

      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.get(`${u3RestPrefix}system/operator`, { headers }).subscribe({
        next: (resp) => {
          delete this.authServ.subs[key];
          resolve(resp as TsXmlOperator);
        },
        error: (error) => {
          delete this.authServ.subs[key];
          error.message = 'function: systemOperatorGet;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 404));
        }
      });
    });
  }

  public hasPrivileges(code: number, uid: TsXmlUid): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      const headers = this.getHttpHeader(uid);

      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.get(`${u3RestPrefix}system/operator/privileges/${code}/assigned`, { headers }).subscribe({
        next: (resp: { value: boolean }) => {
          delete this.authServ.subs[key];
          resolve(resp?.value || false);
        },
        error: () => {
          delete this.authServ.subs[key];
          resolve(false);
        }
      });
    });
  }

  public hasPrivilegesParameters(code: number, uid: TsXmlUid): Promise<any> {
    return new Promise<boolean>((resolve) => {
      const headers = this.getHttpHeader(uid);

      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.get(`${u3RestPrefix}system/privileges/${code}/parameters`, { headers }).subscribe({
        next: (resp) => {
          delete this.authServ.subs[key];
          resolve(resp as any);
        },
        error: () => {
          delete this.authServ.subs[key];
          resolve(false);
        }
      });
    });
  }


  public webAuthnGetRegisterData(uid: TsXmlUid): Promise<{ optionsJSON: PublicKeyCredentialCreationOptionsJSON }> {
    return new Promise((resolve, reject) => {
      const headers = this.getHttpHeader(uid, 'application/json');
      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.post(`${u3RestPrefix}system/operator/authorizationInstruments/WebAuthn/challenge`, null, { headers }).subscribe({
        next: (resp) => {
          delete this.authServ.subs[key];
          resolve({ optionsJSON: resp[`publicKey`] } as { optionsJSON: PublicKeyCredentialCreationOptionsJSON });
        },
        error: (error) => {
          delete this.authServ.subs[key];
          error.message = 'function: webAuthnGetRegisterData;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 404));
        }
      });
    });
  }

  public webAuthnVerifyRegistration(credential: RegistrationResponseJSON, uid: TsXmlUid): Promise<TsXmlAuthorizationInstrument> {
    return new Promise((resolve, reject) => {
      const headers = this.getHttpHeader(uid, 'application/json');
      const body = {
        authenticatorAttachment: credential.authenticatorAttachment,
        clientExtensionResults: credential.clientExtensionResults,
        id: credential.id,
        response: {
          attestationObject: credential.response.attestationObject,
          clientDataJSON: credential.response.clientDataJSON,
          transports: credential.response.transports,
        },
        type: credential.type
      };
      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.post(
        `${u3RestPrefix}system/operator/authorizationInstruments/WebAuthn`,
        body,
        { headers }
      ).subscribe({
        next: (resp: TsXmlAuthorizationInstrument) => {
          delete this.authServ.subs[key];
          resolve(new TsXmlAuthorizationInstrument(resp));
        },
        error: (error) => {
          delete this.authServ.subs[key];
          error.message = 'function: webAuthnSendRegisterData;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 404));
        }
      });
    });
  }

  public authorizationInstrumentsGet(
    param: {
      state?: 'VALID' | 'INVALID' | 'REQUEST',
      type?: 'EMAIL' | 'MATRIX' | 'QUESTION' | 'PHONE' | 'EXTERNAL_AUTHORITY' | 'BIO_SIG',
    },
    uid: TsXmlUid
  ): Promise<TsXmlAuthorizationInstrumentList> {
    return new Promise((resolve, reject) => {

      const headers = this.getHttpHeader(uid, 'application/json');
      const params = param ? this.getHttpParams3(param) : null;

      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.get(
        `${u3RestPrefix}system/operator/authorizationInstruments`,
        { headers, params }
      ).subscribe({
        next: (resp: TsXmlAuthorizationInstrumentList) => {
          delete this.authServ.subs[key];
          resolve(new TsXmlAuthorizationInstrumentList(resp));
        },
        error: (error) => {
          delete this.authServ.subs[key];
          error.message = 'function: authorizationInstrumentsGet;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 404));
        }
      });
    });
  }

  public authorizationInstrumentsStateInvalidatePut(objId: number, uid: TsXmlUid): Promise<TsXmlAuthorizationInstrumentList> {
    return new Promise((resolve, reject) => {

      const headers = this.getHttpHeader(uid, 'application/json');
      const params = this.getHttpParams3({ state: 'INVALID' });

      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.put(
        `${u3RestPrefix}system/operator/authorizationInstruments/${objId}/state`,
        null,
        { headers, params }
      ).subscribe({
        next: (resp) => {
          delete this.authServ.subs[key];
          resolve(resp);
        },
        error: (error) => {
          delete this.authServ.subs[key];
          error.message = 'function: authorizationInstrumentsStatePut;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 404));
        }
      });
    });
  }

  /*********************************************************************************************************/
  /* system/privileges *************************************************************************************/

  /**
   * Check privileges for portal access
   * @param uid rest uid
   * @return Promise<void>
   */
  public checkPortalAccess(uid: TsXmlUid): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const headers = this.getHttpHeader(uid);
      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.get(`${u3RestPrefix}system/privileges/550008/parameters`, { headers }).subscribe({
        next: () => {
          delete this.authServ.subs[key];
          resolve();
        },
        error: (error) => {
          delete this.authServ.subs[key];
          error.message = 'function: checkPortalAccess;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 404));
        }
      });
    });
  }

  /*********************************************************************************************************/
  /* kontext PORTAL ****************************************************************************************/
  /*********************************************************************************************************/

  /*********************************************************************************************************/
  /* portal/myBanners **************************************************************************************/

  public myBannersBannerGet(bannerId: string, uid: TsXmlUid): Promise<Blob> {
    return new Promise((resolve, reject) => {
      const headers = this.getHttpHeader(uid);
      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.get(`${u3RestPrefix}/portal/myBanners/banner/${bannerId}`, { headers, responseType: 'blob' }).subscribe({
        next: (resp: Blob) => {
          delete this.authServ.subs[key];
          resolve(resp);
        },
        error: (e) => {
          delete this.authServ.subs[key];
          reject(e);
        }
      });
    });
  }

  /*********************************************************************************************************/
  /* portal/myTasks ****************************************************************************************/

  /**
   * Create new post on task
   * @param uid rest uid
   * @param objId task objId
   * @param text text
   * @param documents attachments
   * @return Promise<TsXmlTask>
   */
  public portalMyTasksObjIdPost(
    uid: TsXmlUid,
    objId: number,
    data: TsXmlTaskPostCreateData
  ): Promise<TsXmlTask> {
    return new Promise<TsXmlTask>((resolve, reject) => {
      const headers = this.getHttpHeader(uid, 'text/xml; charset=utf-8');
      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.post(
        `${u3RestPrefix}portal/myTasks/${objId}`,
        data,
        { headers }
      ).subscribe({
        next: (resp) => {
          delete this.authServ.subs[key];
          resolve(UtilsService.convertAllJsonDateStrings(resp) as TsXmlTask);
        },
        error: (error) => {
          delete this.authServ.subs[key];
          error.message = 'function: portalMyTasksObjIdPost;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 404));
        }
      });
    });
  }

  /**
   * Create insurance request on entity
   * @param uid rest uid
   * @param entityObjId entity objId
   * @param text text
   * @param documents attachments
   * @return Promise<TsXmlTask>
   */
  public portalMyTasksInsuranceRequestPost(
    uid: TsXmlUid,
    entityObjId: number,
    text: string,
    documents: TsXmlDocumentWithContent[]
  ): Promise<TsXmlTask> {
    return new Promise<TsXmlTask>((resolve, reject) => {
      const headers = this.getHttpHeader(uid, 'text/xml; charset=utf-8');
      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.post(
        `${u3RestPrefix}portal/myTasks/insuranceRequest`,
        {
          product: {
            code: 'AP1',
          },
          entity: {
            idTridy: 1033,
            objId: entityObjId,
          },
          codeDefTask: 'ZME_POJ',
          text,
          documents,
        },
        { headers }
      ).subscribe({
        next: (resp) => {
          delete this.authServ.subs[key];
          resolve(UtilsService.convertAllJsonDateStrings(resp) as TsXmlTask);
        },
        error: (error) => {
          delete this.authServ.subs[key];
          error.message = 'function: portalMyTasksInsuranceRequestPost;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 404));
        }
      });
    });
  }

  /**
   * Create new task with post
   * @param uid rest uid
   * @param name task name
   * @param text post text
   * @param documents attachments
   * @return Promise<TsXmlTask>
   */
  public portalMyTasksPost(
    uid: TsXmlUid,
    name: string,
    text: string,
    documents: TsXmlDocumentWithContent[]
  ): Promise<TsXmlTask> {
    return new Promise<TsXmlTask>((resolve, reject) => {
      const headers = this.getHttpHeader(uid, 'text/xml; charset=utf-8');

      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.post(
        `${u3RestPrefix}portal/myTasks/`,
        {
          name,
          text,
          defRequest: {
            id: '2',
          },
          documents,
        },
        { headers }
      ).subscribe({
        next: (resp) => {
          delete this.authServ.subs[key];
          resolve(UtilsService.convertAllJsonDateStrings(resp) as TsXmlTask);
        },
        error: (error) => {
          delete this.authServ.subs[key];
          error.message = 'function: portalMyTasksPost;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 404));
        }
      });
    });
  }

  public portalMyTasksDocumentsGet(cancelRequestObjId: number, uid: TsXmlUid): Promise<TsXmlDocumentWithContentList> {
    const headers = this.getHttpHeader(uid, 'text/xml; charset=utf-8');

    return new Promise((resolve, reject) => {
      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.get(
        `${u3RestPrefix}portal/myTasks/${cancelRequestObjId}/documents`,
        { headers }
      ).subscribe({
        next: doc => {
          delete this.authServ.subs[key];
          resolve(doc as TsXmlDocumentWithContentList);
        },
        error: error => {
          delete this.authServ.subs[key];
          error.message = 'function: portalMyTasksPost;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 404));
        }
      });
    });
  }

  public portalMyTasksGet(taskObjId: number, uid: TsXmlUid): Promise<TsXmlTask> {
    const headers = this.getHttpHeader(uid);
    return new Promise((resolve, reject) => {
      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.get(
        `${u3RestPrefix}portal/myTasks/${taskObjId}`,
        { headers }
      ).subscribe({
        next: task => {
          delete this.authServ.subs[key];
          resolve(task as TsXmlTask);
        },
        error: e => {
          delete this.authServ.subs[key];
          e.message = `function: portalMyTasksGet; ${e.message}`;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(e, 404));
        }
      });
    });
  }

  /*********************************************************************************************************/
  /* portal/passwordChange *********************************************************************************/

  /**
   * Change password
   * @param uid rest uid
   * @param newPassword new password
   * @param authToken authToken
   * @return Observable as void
   */
  public portalPasswordChangePost(
    uid: TsXmlUid,
    newPassword: string,
    authToken: TsXmlPortalAuthorizationToken
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      const headers = this.getHttpHeader(uid, 'text/xml; charset=utf-8');
      const data = { items: [newPassword], authToken };

      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.post(`${u3RestPrefix}portal/passwordChange`, data, { headers }).subscribe({
        next: () => {
          delete this.authServ.subs[key];
          resolve();
        },
        error: (e) => {
          delete this.authServ.subs[key];
          reject(e);
        }
      });
    });
  }

  /*********************************************************************************************************/
  /* portal/authorizedActions ******************************************************************************/

  /** Způsob autorizace pro akci
   * @param actionId Id autorizovane akce
   * @param uid rest uid
   * @return Promise<TsXmlPortalAuthorizationWay>
   */
  public portalAuthorizedActionsAuthWayGet(actionId: string, uid: TsXmlUid): Promise<TsXmlPortalAuthorizationWay> {
    return new Promise((resolve, reject) => {
      const headers = this.getHttpHeader(uid);

      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.get(`${u3RestPrefix}portal/authorizedActions/${actionId}/authWay`, { headers }).subscribe({
        next: (authWay) => {
          resolve(authWay as TsXmlPortalAuthorizationWay);
        },
        error: (error) => {
          error.message = 'function: portalAuthorizedActionsAuthWayGet;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 404));
        }
      });
    });
  }

  /** Overeni autorizacniho tokenu
   * @param actionId Id autorizovane akce
   * @param token podepisovane hodnoty
   * @param uid rest uid
   * @return Promise<void>
   */
  public portalAuthorizedActionsCheckAuthTokenPost(
    actionId: string,
    token: TsXmlPortalAuthorizationToken,
    uid: TsXmlUid
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      const headers = this.getHttpHeader(uid);
      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.post(
        `${u3RestPrefix}portal/authorizedActions/${actionId}/checkAuthToken`,
        token,
        { headers }
      ).subscribe({
        next: () => {
          delete this.authServ.subs[key];
          resolve();
        },
        error: (e) => {
          delete this.authServ.subs[key];
          reject(e);
        }
      });
    });
  }

  /** Vytvoreni a odeslani autorizacneho tokenu
   * @param actionId Id autorizovane akce
   * @param token podepisovane hodnoty
   * @param uid rest uid
   * @return Promise<void>
   */
  public portalAuthorizedActionsSendAuthToken(actionId: string, params: TsXmlParamStringList, uid: TsXmlUid): Promise<void> {
    return new Promise((resolve, reject) => {
      const headers = this.getHttpHeader(uid);

      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.post(
        `${u3RestPrefix}portal/authorizedActions/${actionId}/sendAuthToken`,
        params,
        { headers }
      ).subscribe({
        next: () => {
          delete this.authServ.subs[key];
          resolve();
        },
        error: (error) => {
          delete this.authServ.subs[key];
          error.message = 'function: portalAuthorizedActionsSendAuthToken;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 404));
        }
      });
    });
  }

  /** Aktualizace strany dle action id a predanych dat
   * @param actionId Id autorizovane akce (id zaznamu z cis. 562), je provedena kontrola vstupu
   * @param data TsXmlParPartyAuth
   * @param uid rest uid
   * @return Promise<TsXmlParParty>
   */
  public parPartiesSavePost(actionId: string, data: TsXmlParPartyAuth, uid: TsXmlUid): Promise<TsXmlParParty> {
    return new Promise<TsXmlParParty>((resolve, reject) => {
      const headers = this.getHttpHeader(uid);
      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.post(`${u3RestPrefix}par/parties/save/${actionId}`, data, { headers }).subscribe({
        next: (resp) => {
          delete this.authServ.subs[key];
          resolve(UtilsService.convertAllJsonDateStrings(resp) as TsXmlParParty);
        },
        error: (error) => {
          delete this.authServ.subs[key];
          error.message = 'function: parPartiesSavePost;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 404));
        }
      });
    });
  }

  /**
   * Get information about phone number
   * @param phone phone number in international format (+420123456789)
   * @param uid rest uid
   * @return Promise<TsXmlContactPointPhone>
   */
  public partiesPhoneGet(phone: string, uid: TsXmlUid): Promise<TsXmlContactPointPhone> {
    const headers = this.getHttpHeader(uid);

    return new Promise((resolve, reject) => {
      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.get(`${u3RestPrefix}parties/phone/${phone}`, { headers }).subscribe({
        next: (phoneInfo) => {
          delete this.authServ.subs[key];
          resolve(phoneInfo as TsXmlContactPointPhone);
        },
        error: (error) => {
          delete this.authServ.subs[key];
          error.message = 'function: partiesPhoneGet;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 400, 403, 404));
        }
      });
    });
  }

  /**
   * Service sends verification code to new email
   * @param email email
   * @param uid rest uid
   * @return Promise<void>
   */
  public parPartiesCheckNewEmailSendTokenPut(email: string, uid: TsXmlUid): Promise<void> {
    return new Promise((resolve, reject) => {
      const headers = this.getHttpHeader(uid, 'application/x-www-form-urlencoded; charset=utf-8');
      const params = this.getHttpParams3({ email });
      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.put(`${u3RestPrefix}par/parties/checkNewEmail/sendToken`, null, { headers, params }).subscribe({
        next: () => {
          delete this.authServ.subs[key];
          resolve();
        },
        error: (e) => {
          delete this.authServ.subs[key];
          reject(e);
        }
      });
    });
  }

  /**
   * Service sends verification code to new phone
   * @param phone phone number where to send code
   * @param uid rest uid
   * @return Promise<void>
   */
  public parPartiesCheckNewPhoneSendTokenPut(phone: string, uid: TsXmlUid): Promise<void> {
    return new Promise((resolve, reject) => {
      const headers = this.getHttpHeader(uid, 'text/xml; charset=utf-8');
      const params = this.getHttpParams3({ phone });

      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.put(`${u3RestPrefix}par/parties/checkNewPhone/sendToken`, null, { headers, params }).subscribe({
        next: () => {
          delete this.authServ.subs[key];
          resolve();
        },
        error: (e) => {
          delete this.authServ.subs[key];
          reject(e);
        }
      });
    });
  }

  /** checks if sent verification code is correct
   * @param email email
   * @param code verification code
   * @param uid rest uid
   */
  public parPartiesCheckNewEmailVerifyTokenPost(email: string, code: string, uid: TsXmlUid): Promise<void> {
    return new Promise((resolve, reject) => {
      const headers = this.getHttpHeader(uid, 'application/x-www-form-urlencoded; charset=utf-8');
      const params = this.getHttpParams3({ email, code });

      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.post(`${u3RestPrefix}par/parties/checkNewEmail/verifyToken`, null, { headers, params }).subscribe({
        next: () => {
          delete this.authServ.subs[key];
          resolve();
        },
        error: (e) => {
          delete this.authServ.subs[key];
          reject(e);
        }
      });
    });
  }

  /**
   * checks if sent verification code is correct
   * @param phone phone number in international format
   * @param code verification code
   * @param uid rest uid
   */
  public parPartiesCheckNewPhoneVerifyTokenPost(phone: string, code: string, uid: TsXmlUid): Promise<void> {
    return new Promise((resolve, reject) => {
      const headers = this.getHttpHeader(uid, 'application/x-www-form-urlencoded; charset=utf-8');
      const params = this.getHttpParams3({ phone, code });

      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.post(`${u3RestPrefix}par/parties/checkNewPhone/verifyToken`, null, { headers, params }).subscribe({
        next: () => {
          delete this.authServ.subs[key];
          resolve();
        },
        error: (e) => {
          delete this.authServ.subs[key];
          reject(e);
        }
      });
    });
  }

  /*********************************************************************************************************/
  /* kontext 'variable endpoint' ***************************************************************************/
  /*********************************************************************************************************/

  /**
   * Get documents from endpoint.
   * @param endpoint endpoint
   * @param uid rest uid
   * @return Observable with TsXmlDocumentWithContentList
   */
  public _endpointDocumentsGet(endpoint: string, uid: TsXmlUid): Observable<object> {
    const headers = this.getHttpHeader(uid);
    const key = this.getSubsKey(this.authServ.subs);
    this.authServ.subs[key] = this.http.get(`${u3RestPrefix}${endpoint}/documents`, { headers });
    return this.authServ.subs[key];
  }
  /**
   * Get documents from endpoint.
   * @param endpoint endpoint
   * @param uid rest uid
   * @return Promise<TsXmlDocumentWithContentList>
   */
  public endpointDocumentsGet(
    endpoint: string,
    uid: TsXmlUid,
    subs?: { [key: string]: Subscription }
  ): Promise<TsXmlDocumentWithContentList> {
    return new Promise((resolve, reject) => {
      const headers = this.getHttpHeader(uid);
      const key = this.getSubsKey(subs ? { ...this.authServ.subs, ...subs } : this.authServ.subs);
      this.authServ.subs[key] = this.http.get(`${u3RestPrefix}${endpoint}/documents`, { headers }).subscribe({
        next: (resp) => {
          delete this.authServ.subs[key];
          if (subs) delete subs[key];
          delete this.authServ.subs[key];
          resolve(UtilsService.convertAllJsonDateStrings(resp) as TsXmlDocumentWithContentList);
        },
        error: (error) => {
          delete this.authServ.subs[key];
          if (subs) delete subs[key];
          delete this.authServ.subs[key];
          error.message = 'function: endpointDocumentsGet;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 404));
        }
      });
      if (subs) subs[key] = this.authServ.subs[key];
    });
  }

  /**
   * Get documents from endpoint.
   * @param endpoint endpoint
   * @param uid rest uid
   * @return Promise<TsXmlDocumentWithContentList>
   */
  public endpointDocumentsPost(
    endpoint: string,
    data: TsXmlDocumentWithContentList,
    uid: TsXmlUid
  ): Promise<TsXmlDocumentWithContentList> {
    return new Promise((resolve, reject) => {
      const headers = this.getHttpHeader(uid);
      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.post(`${u3RestPrefix}${endpoint}/documents`, data, { headers }).subscribe({
        next: (resp) => {
          delete this.authServ.subs[key];
          resolve(UtilsService.convertAllJsonDateStrings(resp) as TsXmlDocumentWithContentList);
        },
        error: (error) => {
          delete this.authServ.subs[key];
          error.message = 'function: endpointDocumentsPost;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 404));
        }
      });
    });
  }

  /**
   * Get blob of document content from endpoint.
   * @param endpoint endpoint
   * @param objId objId of document
   * @param serialNumber poradi souboru
   * @param uid rest uid
   * @return Promise<Blob>
   */
  public endpointDocumentsFilesGet(
    data: {
      endpoint: string,
      objId: number,
      serialNumber: number,
      size?: 'SMALL' | 'MEDIUM'
    },
    uid: TsXmlUid,
    subs?: { [key: string]: Subscription }
  ): Promise<Blob> {
    return new Promise((resolve, reject) => {
      const headers = this.getHttpHeader(uid);
      const params = data.size ? this.getHttpParams3({ size: data.size }) : null;

      const key = this.getSubsKey(subs ? { ...this.authServ.subs, ...subs } : this.authServ.subs);
      this.authServ.subs[key] = this.http.get(
        `${u3RestPrefix}${data.endpoint}documents/${data.objId}/files/${data.serialNumber}`,
        {
          headers,
          params,
          responseType: 'blob',
        }
      ).subscribe({
        next: (resp) => {
          delete this.authServ.subs[key];
          if (subs) delete subs[key];
          resolve(resp);
        },
        error: (error) => {
          delete this.authServ.subs[key];
          if (subs) delete subs[key];
          error.message = 'function: endpointDocumentsFilesGet;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 404));
        }
      });
      if (subs) subs[key] = this.authServ.subs[key];
    });
  }

  /**
   * Get document types for endpoint
   * @param endpoint endpoint
   * @param uid rest uid
   * @return Promise<TsXmlDocumentTypeForEntityList>
   */
  public endpointDocumentTypesGet(endpoint: string, uid: TsXmlUid): Promise<TsXmlDocumentTypeForEntityList> {
    return new Promise((resolve, reject) => {
      const headers = this.getHttpHeader(uid);
      const url = u3RestPrefix + this._removeFirstSlash(endpoint) + 'documentTypesForEntity';
      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.get(url, { headers }).subscribe({
        next: (resp) => {
          delete this.authServ.subs[key];
          resolve(resp as TsXmlDocumentTypeForEntityList);
        },
        error: (error) => {
          delete this.authServ.subs[key];
          error.message = error.message ? `${error.message} ;;; function endpointDocumentTypesGet(endpoint: ${endpoint})` : `function endpointDocumentTypesGet(endpoint: ${endpoint})`;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 404));
        }
      });
    });
  }

  /*********************************************************************************************************/
  /* kontext KUK *******************************************************************************************/
  /*********************************************************************************************************/

  /*********************************************************************************************************/
  /* kuk/invitations ***************************************************************************************/

  /**
   * Check login is valid
   * @param login login
   * @return Promise<TsXmlResponse>
   */
  public kukInvitationsIsLoginValidGetUnauth(login: string): Promise<TsXmlResponse> {
    return new Promise((resolve, reject) => {
      const headers = this.getHttpHeader(null, 'text/xml; charset=utf-8');
      const params = this.getHttpParams3({ login });

      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.get(`${u3RestPrefix}kuk/invitations/isLoginValid`, { headers, params }).subscribe({
        next: (resp) => {
          delete this.authServ.subs[key];
          resolve(resp as TsXmlResponse);
        },
        error: (error) => {
          delete this.authServ.subs[key];
          error.message = 'function: kukInvitationsIsLoginValidGetUnauth;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 404));
        }
      });
    });
  }

  /*********************************************************************************************************/
  /* kuk/payments ***************************************************************************************/

  // Vrátí dostupné metody platby
  public kukPaymentsPaymentMethodsGet(uid: TsXmlUid): Promise<TsXmlPaymentMethodList> {
    const headers = this.getHttpHeader(uid, 'text/xml; charset=utf-8');
    return new Promise((resolve, reject) => {
      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.get(`${u3RestPrefix}kuk/payments/paymentMethods`, { headers }).subscribe({
        next: (methods: TsXmlPaymentMethodList) => {
          delete this.authServ.subs[key];
          resolve(methods);
        },
        error: (error) => {
          delete this.authServ.subs[key];
          error.message = 'function: kukPaymentsPaymentMethodsGet;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 404));
        }
      });
    });
  }

  public kukPaymentsPayPost(uid: TsXmlUid, data: TsXmlPaymentData): Promise<TsXmlResponse> {
    const headers = this.getHttpHeader(uid, 'text/xml; charset=utf-8');
    return new Promise((resolve, reject) => {
      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.post(`${u3RestPrefix}kuk/payments/pay`, data, { headers }).subscribe({
        next: (response: TsXmlResponse) => {
          delete this.authServ.subs[key];
          resolve(response);
        },
        error: (error) => {
          delete this.authServ.subs[key];
          error.message = 'function: kukPaymentsPayPost;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 404));
        }
      });
    });
  }

  /*********************************************************************************************************/
  /* kontext PAR *******************************************************************************************/
  /*********************************************************************************************************/

  /*********************************************************************************************************/
  /* par/accounts *******************************************************************************************/

  /** Vrátí informace o stavu konta a kam zaplatit peníze (na jaký bankovní účet) tak, aby se uložily na mé konto v pojišťovně. */
  public myInsuranceAccountGet(uid: TsXmlUid, partyObjId: number, subs?: { [key: string]: Subscription }): Promise<TsXmlParMyInsuranceAccount> {
    return new Promise((resolve, reject) => {
      const headers = this.getHttpHeader(uid, 'text/xml; charset=utf-8');

      const key = this.getSubsKey(subs ? { ...this.authServ.subs, ...subs } : this.authServ.subs);
      this.authServ.subs[key] = this.http.get(
        `${u3RestPrefix}par/parties/${partyObjId}/accounts/insuranceAccount`,
        { headers }
      ).subscribe({
        next: (insuranceAccount: TsXmlParMyInsuranceAccount) => {
          delete this.authServ.subs[key];
          if (subs) delete subs[key];
          resolve(insuranceAccount);
        },
        error: (error) => {
          delete this.authServ.subs[key];
          if (subs) delete subs[key];
          error.message = 'function: myInsuranceAccountGet;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 404));
        }
      });
      if (subs) subs[key] = this.authServ.subs[key];
    });
  }

  /** Vrátí provedené pohyby na kontě */
  public myInsuranceAccountChangesGet(
    uid: TsXmlUid,
    parameters: {
      incoming: boolean;
      outgoing: boolean;
      offset: number;
      limit: number;
      transactionType?: string[];
      createdFrom?: TDate;
      createdTo?: TDate;
      contextEntityObjId?: string;
      contextEntityIdTridy?: string;
      partyObjId?: number,
      version?: 1 | 2
    },
    subs?: { [key: string]: Subscription }
  ): Promise<TsXmlParAccountChangeList> {
    return new Promise((resolve, reject) => {
      const headers = this.getHttpHeader(uid, 'text/xml; charset=utf-8');

      let params = this.makeHttpParamsWith(
        [
          'incoming',
          'outgoing',
          'offset',
          'limit',
          'transactionType',
          //  'createdFrom', 'createdTo',
          'contextEntityObjId',
          'contextEntityIdTridy',
        ],
        [
          parameters.incoming,
          parameters.outgoing,
          parameters.offset,
          parameters.limit,
          parameters.transactionType,
          // parameters.createdFrom, parameters.createdTo,
          parameters.contextEntityObjId,
          parameters.contextEntityIdTridy,
        ]
      );
      if (parameters.createdFrom) {
        params = params.append(
          'createdFrom',
          parameters.createdFrom.getFullYear() +
          '-' +
          (parameters.createdFrom.getMonth() + 1) +
          '-' +
          parameters.createdFrom.getDate() +
          'T00:00:00'
        );
      }
      if (parameters.createdTo) {
        params = params.append(
          'createdTo',
          parameters.createdTo.getFullYear() +
          '-' +
          (parameters.createdTo.getMonth() + 1) +
          '-' +
          parameters.createdTo.getDate() +
          'T23:59:59'
        );
      }
      if (parameters.version) params = params.append('version', parameters.version);

      const key = this.getSubsKey(subs ? { ...this.authServ.subs, ...subs } : this.authServ.subs);
      this.authServ.subs[key] = this.http.get(
        `${u3RestPrefix}par/parties/${parameters.partyObjId}/accounts/insuranceAccount/changes`,
        { headers, params }
      ).subscribe({
        next: (cash: TsXmlParAccountChangeList) => {
          delete this.authServ.subs[key];
          if (subs) delete subs[key];
          resolve(cash);
        },
        error: (error) => {
          delete this.authServ.subs[key];
          if (subs) delete subs[key];
          error.message = 'function: myInsuranceAccountChangesGet;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 404));
        }
      });
      if (subs) subs[key] = this.authServ.subs[key];
    });
  }

  public myInsuranceAccountChangesPost(
    content: TsXmlParAccountPayment,
    partyObjId: number,
    uid: TsXmlUid,
    subs?: { [key: string]: Subscription }
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      const headers = this.getHttpHeader(uid, 'text/xml; charset=utf-8');

      const key = this.getSubsKey(subs ? { ...this.authServ.subs, ...subs } : this.authServ.subs);
      this.authServ.subs[key] = this.http.post(
        `${u3RestPrefix}par/parties/${partyObjId}/accounts/insuranceAccount/changes`, content,
        { headers }
      ).subscribe({
        next: () => {
          delete this.authServ.subs[key];
          if (subs) delete subs[key];
          resolve();
        },
        error: (error) => {
          delete this.authServ.subs[key];
          if (subs) delete subs[key];
          error.message = 'function: myInsuranceAccountChangesPost;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 404));
        }
      });
      if (subs) subs[key] = this.authServ.subs[key];
    });
  }

  public activatePendingPolicyPost(
    data: {
      partyObjId: number,
      policyId: string,
      amount: number
    },
    uid: TsXmlUid,
    subs?: { [key: string]: Subscription }
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      const headers = this.getHttpHeader(uid, 'text/xml; charset=utf-8');
      const params = this.getHttpParams3({ policyId: data.policyId, amount: data.amount });

      const key = this.getSubsKey(subs ? { ...this.authServ.subs, ...subs } : this.authServ.subs);
      this.authServ.subs[key] = this.http.post(
        `${u3RestPrefix}par/parties/${data.partyObjId}/accounts/insuranceAccount/changes/transfer/policy`,
        null,
        { headers, params }
      ).subscribe({
        next: () => {
          delete this.authServ.subs[key];
          if (subs) delete subs[key];
          resolve();
        },
        error: (error) => {
          delete this.authServ.subs[key];
          if (subs) delete subs[key];
          error.message = 'function: activatePendingPolicyPost;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 404));
        }
      });
      if (subs) subs[key] = this.authServ.subs[key];
    });
  }

  // Vrátí QRkód obsahující informace kam zaplatit peníze (na jaký bankovní účet a pod jakým VS), aby se uložily na mé konto v pojišťovně.
  // Pokud je zadán parametr "amount" , tak je v QR kódu obsažena i částka.
  public myInsuranceAccountPaymentQRGet(uid: TsXmlUid, amount?: string): Promise<SafeResourceUrl> {
    return new Promise((resolve, reject) => {
      const headers = this.getHttpHeader(uid, 'text/xml; charset=utf-8');
      const params = this.getHttpParams3({ amount });

      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.get(
        `${u3RestPrefix}par/accounts/myInsuranceAccount/paymentQR`,
        { headers, params, responseType: 'blob' }
      ).subscribe({
        next: (pic: Blob) => {
          delete this.authServ.subs[key];
          const output = this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(pic));
          resolve(output);
        },
        error: (error) => {
          delete this.authServ.subs[key];
          error.message = 'function: myInsuranceAccountPaymentQRGet;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 404));
        }
      });
    });
  }

  public proposalPaymentQRCode(calcId: string, uid: TsXmlUid): Promise<SafeResourceUrl> {
    return new Promise((resolve, reject) => {
      const headers = this.getHttpHeader(uid, 'text/xml; charset=utf-8');

      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.get(
        `${u3RestPrefix}par/calculations/${calcId}/policy/proposalPaymentQRCode`,
        { headers, responseType: 'blob' }
      ).subscribe({
        next: (pic: Blob) => {
          delete this.authServ.subs[key];
          const output = this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(pic));
          resolve(output);
        },
        error: (error) => {
          delete this.authServ.subs[key];
          error.message = 'function: proposalPaymentQRCode;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 404));
        }
      });
    });
  }

  public proposalPaymentQRData(calcId: string, uid: TsXmlUid): Promise<{
    variableSymbol: string,
    amount: string,
    account: {
      accountNumber: string,
      bank: string,
      iban: string,
      currency: string,
      bic: string
    }
  }> {
    return new Promise((resolve, reject) => {
      const headers = this.getHttpHeader(uid, 'text/xml; charset=utf-8');

      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.get(
        `${u3RestPrefix}par/calculations/${calcId}/policy/proposalPaymentInformation`,
        { headers }
      ).subscribe({
        next: (bank) => {
          delete this.authServ.subs[key];
          resolve(bank as any);
        },
        error: (error) => {
          delete this.authServ.subs[key];
          error.message = 'function: proposalPaymentQRData;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 404));
        }
      });
    });
  }

  public myInsuranceAccountchangesLastToAccountBankAccountGet(uid: TsXmlUid): Promise<TsXmlBankAccount> {
    return new Promise((resolve, reject) => {
      const headers = this.getHttpHeader(uid, 'text/xml; charset=utf-8');

      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.get(
        `${u3RestPrefix}par/accounts/myInsuranceAccount/changes/lastToAccount/bankAccount`,
        { headers }
      ).subscribe({
        next: (bankAccount: TsXmlBankAccount) => {
          delete this.authServ.subs[key];
          resolve(bankAccount);
        },
        error: (error) => {
          delete this.authServ.subs[key];
          error.message = 'function: myInsuranceAccountchangesLastToAccountBankAccountGet;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 404));
        }
      });
    });
  }

  public myInsuranceAccountchangesPost(
    body: TsXmlParAccountChangeCreateData,
    uid: TsXmlUid,
    subs?: { [key: string]: Subscription }
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      const headers = this.getHttpHeader(uid, 'text/xml; charset=utf-8');

      const key = this.getSubsKey(subs ? { ...this.authServ.subs, ...subs } : this.authServ.subs);
      this.authServ.subs[key] = this.http.post(`${u3RestPrefix}par/accounts/myInsuranceAccount/changes`, body, { headers }).subscribe({
        next: () => {
          delete this.authServ.subs[key];
          if (subs) delete subs[key];
          resolve();
        },
        error: (error: Error) => {
          delete this.authServ.subs[key];
          if (subs) delete subs[key];
          if (error.message) {
            error.message = error.message + ';;;' + JSON.stringify({
              accountNum: body?.bankAccount?.accountNumber?.replace(/\d/g, 'd'),
              bank: body?.bankAccount?.bank?.replace(/\d/g, 'd')
            });
          }
          error.message = 'function: myInsuranceAccountchangesPost;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 404));
        }
      });
      if (subs) subs[key] = this.authServ.subs[key];
    });
  }

  /** Služba vrací seznam závazků.
   * @param uid uid
   * @param limit Index prvního prvku. Číslováno od 0. Default 0.
   * @param offset Maximální počet vrácených prvků. Default 10. Povolený rozsah 1 až 100.
   * @returns TsXmlParPolicyPrescriptionList
   */
  public parAccountsMyPolicyPrescriptionsGet(
    uid: TsXmlUid,
    offset: number,
    limit: number,
    partyObjId: number,
    subs?: { [key: string]: Subscription },
  ): Promise<TsXmlParPolicyPrescriptionList> {
    return new Promise((resolve, reject) => {
      const headers = this.getHttpHeader(uid, 'text/xml; charset=utf-8');
      const params = this.getHttpParams3({ offset, limit, version: 2 });

      const key = this.getSubsKey(subs ? { ...this.authServ.subs, ...subs } : this.authServ.subs);
      this.authServ.subs[key] = this.http.get(
        `${u3RestPrefix}par/parties/${partyObjId}/accounts/policyPrescriptions`,
        { headers, params }
      ).subscribe({
        next: (resp) => {
          delete this.authServ.subs[key];
          if (subs) delete subs[key];
          const list = UtilsService.convertAllJsonDateStrings(resp) as TsXmlParPolicyPrescriptionList;
          resolve(list);
        },
        error: (error) => {
          delete this.authServ.subs[key];
          if (subs) delete subs[key];
          error.message = 'function: parAccountsMyPolicyPrescriptionsGet;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 404));
        }
      });
      if (subs) subs[key] = this.authServ.subs[key];
    });
  }

  /**
   * Bankovní účet, ze kterého byly naposled přeposlány peníze na klientské konto.
   * @param partyObjId number
   * @param uid TsXmlUid
   * @returns TsXmlBankAccount
   */
  public getBankAccoutForParty(partyObjId: number, uid: TsXmlUid): Promise<TsXmlBankAccount> {
    return new Promise((resolve, reject) => {
      const headers = this.getHttpHeader(uid);
      const key = this.getSubsKey(this.authServ.subs);

      this.authServ.subs[key] = this.http.get(
        `${u3RestPrefix}par/parties/${partyObjId}/accounts/insuranceAccount/lastToAccount/bankAccount`,
        { headers }
      ).subscribe({
        next: (resp) => {
          delete this.authServ.subs[key];
          resolve(resp as TsXmlBankAccount);
        },
        error: (error) => {
          delete this.authServ.subs[key];
          if (error?.message)
            error.message = 'function: parAccountsMyPolicyPrescriptionsGet;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 404));
        }
      });
    });
  }

  /*********************************************************************************************************/
  /* par/calculations **************************************************************************************/

  /**
 * Get calculations by search value
 * @param value search value
 * @param uid rest uid
 * @return Observable as TsXmlParCalculationIdList
 */
  public _parCalculationsGet(value: string, product: 'AP1' | 'RP1' | 'PM1' | 'AP2', uid: TsXmlUid): Observable<object> {
    const headers = this.getHttpHeader(uid, 'text/xml; charset=utf-8');
    const params = this.getHttpParams3({ value, offset: '0', limit: '50', product });

    const key = this.getSubsKey(this.authServ.subs);
    this.authServ.subs[key] = this.http.get(`${u3RestPrefix}par/calculations`, { headers, params });
    return this.authServ.subs[key];
  }

  /**
   * Get calculations by search value
   * @param value search value
   * @param uid rest uid
   * @return Promise<TsXmlParCalculationIdList>
   */
  public parCalculationsGet(value: string, product: 'AP1' | 'RP1' | 'PM1' | 'AP2', uid: TsXmlUid): Promise<TsXmlParCalculationIdList> {
    return new Promise((resolve, reject) => {
      const headers = this.getHttpHeader(uid, 'text/xml; charset=utf-8');
      const params = this.getHttpParams3({ value, offset: '0', limit: '50', product });

      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.get(`${u3RestPrefix}par/calculations`, { headers, params }).subscribe({
        next: (resp) => {
          delete this.authServ.subs[key];
          resolve(UtilsService.convertAllJsonDateStrings(resp) as TsXmlParCalculationIdList);
        },
        error: (error) => {
          delete this.authServ.subs[key];
          error.message = 'function: parCalculationsGet;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 404));
        }
      });
    });
  }

  /**
   * Get caluclation search values
   * @param objId calculation objId
   * @param uid rest uid
   * @return Promise<TsXmlParCalculationSearchEntryList>
   */
  public parCalculationsSearchValuesGet(
    objId: number,
    uid: TsXmlUid,
    subs?: { [key: string]: Subscription }
  ): Promise<TsXmlParCalculationSearchEntryList> {
    return new Promise((resolve, reject) => {
      const headers = this.getHttpHeader(uid, 'text/xml; charset=utf-8');
      const params = this.getHttpParams3({ offset: '0', limit: '100' });

      const key = this.getSubsKey(subs ? { ...this.authServ.subs, ...subs } : this.authServ.subs);
      this.authServ.subs[key] = this.http.get(`${u3RestPrefix}par/calculations/${objId}/searchValues`, { headers, params }).subscribe({
        next: (resp) => {
          delete this.authServ.subs[key];
          if (subs) delete subs[key];
          resolve(UtilsService.convertAllJsonDateStrings(resp) as TsXmlParCalculationSearchEntryList);
        },
        error: (error) => {
          delete this.authServ.subs[key];
          if (subs) delete subs[key];
          error.message = 'function: parCalculationsSearchValuesGet;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 404));
        }
      });
      if (subs) subs[key] = this.authServ.subs[key];
    });
  }

  /**
   * Get caluclation search values
   * @param objId calculation objId
   * @param uid rest uid
   * @return Promise<TsXmlParCalculationEntryList>
   */
  public parCalculationsValuesGet(objId: number, uid: TsXmlUid, subs?: { [key: string]: Subscription }): Promise<TsXmlParCalculationEntryList> {
    return new Promise((resolve, reject) => {
      const headers = this.getHttpHeader(uid, 'text/xml; charset=utf-8');
      const params = this.getHttpParams3({ offset: '0', limit: '100' });

      const key = this.getSubsKey(subs ? { ...this.authServ.subs, ...subs } : this.authServ.subs);
      this.authServ.subs[key] = this.http.get(`${u3RestPrefix}par/calculations/${objId}/values`, { headers, params }).subscribe({
        next: (resp) => {
          delete this.authServ.subs[key];
          if (subs) delete subs[key];
          resolve(UtilsService.convertAllJsonDateStrings(resp) as TsXmlParCalculationEntryList);
        },
        error: (error) => {
          delete this.authServ.subs[key];
          if (subs) delete subs[key];
          error.message = 'function: parCalculationsValuesGet;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 404));
        }
      });
      if (subs) subs[key] = this.authServ.subs[key];
    });
  }

  /**
   * Set calculation on business proposal
   * @param calculationId calculation id
   * @param businessProposalObjId business proposal objId
   * @param uid rest uid
   * @return Promise<void>
   */
  public parCalculationsBusinessProposalPut(
    calculationId: string,
    businessProposalObjId: number,
    uid: TsXmlUid
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      const headers = this.getHttpHeader(uid);
      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.put(
        `${u3RestPrefix}par/calculations/${calculationId}/businessProposal/${businessProposalObjId}`,
        null,
        { headers }
      ).subscribe({
        next: () => {
          delete this.authServ.subs[key];
          resolve();
        },
        error: (error) => {
          delete this.authServ.subs[key];
          error.message = 'function: parCalculationsBusinessProposalPut;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 404));
        }
      });
      return this.authServ.subs[key];
    });
  }

  /**
   * Select calculation/business proposal
   * @param calculationId calculation id
   * @param businessProposalObjId business proposal objId
   * @param uid rest uid
   * @return Promise<void>
   */
  public parCalculationsBusinessProposalSelectedPut(
    calculationObjId: string,
    businessProposalObjId: number,
    uid: TsXmlUid
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      const headers = this.getHttpHeader(uid);
      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.put(
        `${u3RestPrefix}par/calculations/${calculationObjId}/businessProposal/${businessProposalObjId}/selected`,
        null,
        { headers }
      ).subscribe({
        next: () => {
          delete this.authServ.subs[key];
          resolve();
        },
        error: (error) => {
          delete this.authServ.subs[key];
          error.message = 'function: parCalculationsBusinessProposalSelectedPut;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 404));
        }
      });
    });
  }

  /*********************************************************************************************************/
  /* par/ckp ***********************************************************************************************/

  /**
   * Search vehicle from CKP
   * @param licensePlate license plate
   * @param uid rest uid
   * @return Promise<TsXmlParAp1Vehicle>
   */
  public parCkpVehicleGet(licensePlate: string, uid: TsXmlUid): Promise<TsXmlParAp1Vehicle> {
    return new Promise((resolve, reject) => {
      const headers = this.getHttpHeader(uid, 'text/xml; charset=utf-8');
      const params = this.getHttpParams3({ licensePlate });

      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.get(`${u3RestV2Prefix}par/ckp/vehicle`, { headers, params }).subscribe({
        next: (resp) => {
          delete this.authServ.subs[key];
          resolve(UtilsService.convertAllJsonDateStrings(resp) as TsXmlParAp1Vehicle);
        },
        error: (error) => {
          delete this.authServ.subs[key];
          error.message = 'function: parCkpVehicleGet;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 404));
        }
      });
    });
  }

  /*********************************************************************************************************/
  /* par/codelists *****************************************************************************************/

  /**
   * Get codelist entries
   * @param codeListCode codelist code
   * @param uid rest uid
   * @return Promise<TsXmlCodelistEntries>
   */
  public parCodelistsEntriesGet(
    codeListCode: string,
    uid: TsXmlUid,
    subs?: { [key: string]: Subscription }
  ): Promise<TsXmlCodelistEntries> {
    return new Promise<TsXmlCodelistEntries>((resolve, reject) => {
      const headers = this.getHttpHeader(uid);

      const key = this.getSubsKey(subs ? { ...this.authServ.subs, ...subs } : this.authServ.subs);
      this.authServ.subs[key] = this.http.get(`${u3RestPrefix}par/codelists/${codeListCode}/entries`, { headers }).subscribe({
        next: (resp) => {
          delete this.authServ.subs[key];
          if (subs) delete subs[key];
          resolve(resp as TsXmlCodelistEntries);
        },
        error: (error) => {
          delete this.authServ.subs[key];
          if (subs) delete subs[key];
          error.message = 'function: parCodelistsEntriesGet;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 404, 429));
        }
      });
      if (subs) subs[key] = this.authServ.subs[key];
    });
  }

  /**
   * Get entries from codelist by columns in pattern
   * @param codeListCode codelist code
   * @param entry pattern
   * @param uid rest uid
   * @return Promise<TsXmlCodelistEntries>
   */
  public parCodelistsEntriesByColumnsPost(
    codeListCode: string,
    entry: TsXmlCodelistEntry,
    uid: TsXmlUid,
    subs?: { [key: string]: Subscription }
  ): Promise<TsXmlCodelistEntries> {
    return new Promise<TsXmlCodelistEntries>((resolve, reject) => {
      const headers = this.getHttpHeader(uid);

      const key = this.getSubsKey(subs ? { ...this.authServ.subs, ...subs } : this.authServ.subs);
      this.authServ.subs[key] = this.http.post(`${u3RestPrefix}par/codelists/${codeListCode}/entries/byColumns`, entry, { headers }).subscribe({
        next: (resp) => {
          delete this.authServ.subs[key];
          if (subs) delete subs[key];
          resolve(resp as TsXmlCodelistEntries);
        },
        error: (error: Error) => {
          delete this.authServ.subs[key];
          if (subs) delete subs[key];
          error.message = error.message ?
            `${error.message} ;;; function parCodelistsEntriesByColumnsPost ${JSON.stringify(entry)}` :
            `function parCodelistsEntriesByColumnsPost ${JSON.stringify(entry)}`;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 404));
        }
      });
      if (subs) subs[key] = this.authServ.subs[key];
    });
  }

  public myParCodelistsEntriesByColumnsPost(
    codeListCode: string,
    columnData: { code: string, value: string | number }[],
    uid: TsXmlUid
  ): Promise<TsXmlCodelistEntries> {
    return new Promise<TsXmlCodelistEntries>((resolve, reject) => {
      const entry = new TsXmlCodelistEntry();
      entry.gclColumn = [];
      columnData.forEach(data => {
        const column = new TsXmlCodelistColumn();
        column.columnCode = data.code;
        if (typeof data.value === 'string')
          column.stringValue = data.value;
        else
          column.intValue = data.value;
        entry.gclColumn.push(column);
      });
      const headers = this.getHttpHeader(uid);
      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.post(`${u3RestPrefix}par/codelists/${codeListCode}/entries/byColumns`, entry, { headers }).subscribe({
        next: (resp) => {
          delete this.authServ.subs[key];
          resolve(resp as TsXmlCodelistEntries);
        },
        error: (error: Error) => {
          delete this.authServ.subs[key];
          error.message = error.message ?
            `${error.message} ;;; function parCodelistsEntriesByColumnsPost ${JSON.stringify(entry)}` :
            `function parCodelistsEntriesByColumnsPost ${JSON.stringify(entry)}`;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 404));
        }
      });
    });
  }

  public cdByClmPost(
    codeListCode: string,
    columnData: { [code: string]: string | number }[],
    uid: TsXmlUid
  ): Promise<TsXmlCodelistEntries> {
    return new Promise<TsXmlCodelistEntries>((resolve, reject) => {
      const entry = new TsXmlCodelistEntry();
      entry.gclColumn = [];
      columnData.forEach(data => {
        const column = new TsXmlCodelistColumn();
        const key = Object.keys(data)[0];
        column.columnCode = key;
        if (typeof data[key] === 'string')
          column.stringValue = data[key] as string;
        else
          column.intValue = data[key] as number;
        entry.gclColumn.push(column);
      });
      const headers = this.getHttpHeader(uid);
      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.post(`${u3RestPrefix}par/codelists/${codeListCode}/entries/byColumns`, entry, { headers }).subscribe({
        next: (resp) => {
          delete this.authServ.subs[key];
          resolve(resp as TsXmlCodelistEntries);
        },
        error: (error: Error) => {
          delete this.authServ.subs[key];
          error.message = error.message ?
            `${error.message} ;;; function parCodelistsEntriesByColumnsPost ${JSON.stringify(entry)}` :
            `function parCodelistsEntriesByColumnsPost ${JSON.stringify(entry)}`;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 404));
        }

      });
    });
  }

  /**
   * Get bank account for Party from entry with identificator from codelist 697.
   * @param codeListCode codelist code
   * @param identificator entry id
   * @param uid rest uid
   * @return Promise<TsXmlBankAccount>
   */
  public parCodelistEntriesBankAccountGet(
    codeListCode: string,
    identificator: string,
    uid: TsXmlUid,
    subs?: { [key: string]: Subscription }
  ): Promise<TsXmlBankAccount> {
    return new Promise((resolve, reject) => {
      const headers = this.getHttpHeader(uid);

      const key = this.getSubsKey(subs ? { ...this.authServ.subs, ...subs } : this.authServ.subs);
      this.authServ.subs[key] = this.http.get(
        `${u3RestPrefix}par/codelists/${codeListCode}/entries/${identificator}/bankAccount`,
        { headers }
      ).subscribe({
        next: (bA: TsXmlBankAccount) => {
          delete this.authServ.subs[key];
          if (subs) delete subs[key];
          resolve(bA);
        },
        error: (error) => {
          delete this.authServ.subs[key];
          if (subs) delete subs[key];
          error.message = 'function: parCodelistEntriesBankAccountGet;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 404));
        }
      });
      if (subs) subs[key] = this.authServ.subs[key];
    });
  }

  public codelistIconGet(
    codeListCode: string,
    id: string,
    uid: TsXmlUid,
    subs?: { [key: string]: Subscription }
  ): Promise<Blob> {
    return new Promise((resolve, reject) => {
      const headers = this.getHttpHeader(uid);

      const key = this.getSubsKey(subs ? { ...this.authServ.subs, ...subs } : this.authServ.subs);
      this.authServ.subs[key] = this.http.get(
        `${u3RestPrefix}par/codelists/${codeListCode}/entries/${id}/icon`,
        { headers, responseType: 'blob' }
      ).subscribe({
        next: (resp) => {
          delete this.authServ.subs[key];
          if (subs) delete subs[key];
          resolve(resp);
        },
        error: (error) => {
          delete this.authServ.subs[key];
          if (subs) delete subs[key];
          error.message = 'function: codelistIconGet;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 404));
        }
      });
      if (subs) subs[key] = this.authServ.subs[key];
    });
  }

  /*********************************************************************************************************/
  /* par/messages ******************************************************************************************/

  /**
   * Get messages by params (parameters with null will be ignored)
   * @param uid rest uid
   * @param params params {
   *    definition: kód definice zprávy, povinný;
   *    incoming: určuje, zda se zobrazují doručené zprávy přihlášeného operátora;
   *    outgoing: určuje, zda se zobrazují odeslané zprávy přihlášeného operátora;
   *    limit?: maximální počet vrácených zpráv, pokud není předán, defaultní hodnota je 1000;
   *    state?: stav zprávy;
   *    offset?: index prvního prvku, který bude součastí výstupního seznamu, pokud není předán, defaultní hodnota je 0;
   *    createdFrom?: datum vytvoření od;
   *    createdTo?: datum vytvoření do;
   *    sortBy?: způsob řazení, oddělený čárkou přípustné hodnoty první části:
   *             created, state, přípustné hodnoty druhé části ASC, DESC, násobný
   *    policy?: boolean;
   *    policyId?: string;
   *    policyStatus?: string;
   * }
   * @return Promise with TsXmlMessageList
   */
  public parMessagesGet(
    data: {
      definition: string;
      incoming: boolean;
      outgoing: boolean;
      limit?: number;
      state?: string;
      offset?: number;
      createdFrom?: TDate;
      createdTo?: TDate;
      sortBy?: string[];
      policy?: boolean;
      policyId?: string;
      policyStatus?: string;
      partyObjId?: number;
    },
    uid: TsXmlUid,
    subs?: { [key: string]: Subscription }
  ): Promise<TsXmlWofMessageList> {
    const headers = this.getHttpHeader(uid, 'text/xml; charset=utf-8');
    if (data.policyId && data.partyObjId) data.partyObjId = null;
    const params = this.getHttpParams3(data);

    return new Promise((resolve, reject) => {
      const key = this.getSubsKey(subs ? { ...this.authServ.subs, ...subs } : this.authServ.subs);
      this.authServ.subs[key] = this.http.get(`${u3RestPrefix}par/messages`, { headers, params }).subscribe({
        next: resp => {
          delete this.authServ.subs[key];
          if (subs) delete subs[key];
          resolve(resp as TsXmlWofMessageList);
        },
        error: e => {
          delete this.authServ.subs[key];
          if (subs) delete subs[key];
          e.message = 'function: parMessagesGet;' + e.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(e, 403, 404));
        }
      });
      if (subs) subs[key] = this.authServ.subs[key];
    });
  }

  /**
   * Get specific wof message with objId from /par/messages
   * @param uid rest uid
   * @param objId wof message objId
   * @return Observable with TsXmlWofMessage
   */
  public _parMessagesObjIdGet(uid: TsXmlUid, objId: string): Observable<object> {
    const headers = this.getHttpHeader(uid);

    const key = this.getSubsKey(this.authServ.subs);
    this.authServ.subs[key] = this.http.get(`${u3RestPrefix}par/messages/${objId}`, { headers });
    return this.authServ.subs[key];
  }

  /*********************************************************************************************************/
  /* par/odometerState *************************************************************************************/

  /**
   * Send/check odometer state
   * @param checkOnly true=check, false=send
   * @param vehicleInspection vehicle inspection
   * @return Promise<void>
   */
  public parOdometerStatePost(checkOnly: boolean, vehicleInspection: TsXmlParVehicleInspection): Promise<TsXmlParOdometerStateResponse> {
    return new Promise((resolve, reject) => {
      if (checkOnly) {
        const vi: TsXmlParVehicleInspection = new TsXmlParVehicleInspection();
        vi.policyId = vehicleInspection.policyId;

        vi.partyId = vehicleInspection.partyId;
        vi.odometerReading = vehicleInspection.odometerReading ?? 10;
        vi.date = vehicleInspection.date ? new TDate(new Date(vehicleInspection.date)) : new Date();
        vehicleInspection = vi;
      }
      const headers = this.getHttpHeader(null);

      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.post(
        u3RestPrefix + (checkOnly ? 'par/odometerState?checkOnly=true' : 'par/odometerState'),
        vehicleInspection,
        { headers }
      ).subscribe({
        next: (resp) => {
          delete this.authServ.subs[key];
          resolve(resp as TsXmlParOdometerStateResponse);
        },
        error: (e) => {
          delete this.authServ.subs[key];
          reject(e);
        }
      });
    });
  }

  public getOdometerDocument(dt: { policyId: string, odometerObjId: number }, uid: TsXmlUid): Promise<TsXmlDocumentWithContentList> {
    const headers = this.getHttpHeader(uid);

    return new Promise((resolve, reject) => {
      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.get(
        `${u3RestPrefix}par/policies/${dt.policyId}/odometerReadings/${dt.odometerObjId}/documents`,
        { headers }
      ).subscribe({
        next: resp => {
          delete this.authServ.subs[key];
          resolve(resp as TsXmlDocumentWithContentList);
        },
        error: (error) => {
          delete this.authServ.subs[key];
          error.message = 'function: operatorsGet;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 404));
        }
      });
    });
  }

  public getOdometerDocumentPhoto(dt: { policyId: string, odometerObjId: number, documentObjId: number }, uid: TsXmlUid): Promise<Blob> {
    const headers = this.getHttpHeader(uid);

    return new Promise((resolve, reject) => {
      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.get(
        `${u3RestPrefix}par/policies/${dt.policyId}/odometerReadings/${dt.odometerObjId}/documents/${dt.documentObjId}/files/1`,
        { headers, responseType: 'blob' }
      ).subscribe({
        next: resp => {
          delete this.authServ.subs[key];
          resolve(resp);
        },
        error: (error) => {
          delete this.authServ.subs[key];
          error.message = 'function: operatorsGet;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 404));
        }
      });
    });
  }

  public getOdometerReadings(policyId: string, uid: TsXmlUid): Promise<TsXmlParPolicyOdometerReadingList> {
    const headers = this.getHttpHeader(uid);
    const params = this.getHttpParams3({ state: 'U' });

    return new Promise((resolve, reject) => {
      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.get(
        `${u3RestPrefix}par/policies/${policyId}/odometerReadings`,
        { headers, params }
      ).subscribe({
        next: resp => {
          delete this.authServ.subs[key];
          resolve(resp as TsXmlParPolicyOdometerReadingList);
        },
        error: (error) => {
          delete this.authServ.subs[key];
          error.message = 'function: operatorsGet;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 404));
        }
      });
    });
  }

  public getVehicleInspection(
    data: { policyId: string, state: string, limit?: number, offset?: number },
    uid: TsXmlUid
  ): Promise<TsXmlParPolicyVehicleInspectionList> {
    const headers = this.getHttpHeader(uid);
    const params = this.getHttpParams3({ state: data.state, limit: data.limit, offset: data.offset });

    return new Promise((resolve, reject) => {
      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.get(
        `${u3RestPrefix}par/policies/${data.policyId}/vehicleInspections`,
        { headers, params }
      ).subscribe({
        next: resp => {
          delete this.authServ.subs[key];
          resolve(resp as TsXmlParPolicyVehicleInspectionList);
        },
        error: (error) => {
          delete this.authServ.subs[key];
          error.message = 'function: getVehicleInspection;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 404));
        }
      });
    });
  }

  public getVehicleInspectionDocuments(
    data: { policyId: string, viObjId: number },
    uid: TsXmlUid
  ): Promise<TsXmlDocumentWithContentList> {
    const headers = this.getHttpHeader(uid);

    return new Promise((resolve, reject) => {
      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.get(
        `${u3RestPrefix}par/policies/${data.policyId}/vehicleInspections/${data.viObjId}/documents`,
        { headers }
      ).subscribe({
        next: resp => {
          delete this.authServ.subs[key];
          resolve(resp as TsXmlDocumentWithContentList);
        },
        error: (error) => {
          delete this.authServ.subs[key];
          error.message = 'function: getVehicleInspectionDocuments;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 404));
        }
      });
    });
  }

  public getVehicleInspectionDocumentsFile(
    data: { policyId: string, viObjId: number, docObjId: number, serialNumber: number, size?: 'SMALL' | 'MEDIUM' },
    uid: TsXmlUid
  ): Promise<Blob> {
    const headers = this.getHttpHeader(uid);
    const params = data.size ? this.getHttpParams3({ size: data.size }) : null;

    return new Promise((resolve, reject) => {
      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.get(
        `${u3RestPrefix}par/policies/${data.policyId}/vehicleInspections/${data.viObjId}/documents/${data.docObjId}/files/${data.serialNumber}`,
        { headers, params, responseType: 'blob' }
      ).subscribe({
        next: resp => {
          delete this.authServ.subs[key];
          resolve(resp);
        },
        error: (error) => {
          delete this.authServ.subs[key];
          error.message = 'function: getVehicleInspectionDocumentsFile;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 404));
        }
      });
    });
  }


  public operatorsGet(partyObjId: number, uid: TsXmlUid): Promise<TsXmlOperatorInfoList> {
    const headers = this.getHttpHeader(uid);
    const params = this.getHttpParams3({ partyObjId });

    return new Promise((resolve, reject) => {

      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.get(`${u3RestPrefix}operators`, { headers, params }).subscribe({
        next: resp => {
          delete this.authServ.subs[key];
          resolve(resp as TsXmlOperatorInfoList);
        },
        error: (error) => {
          delete this.authServ.subs[key];
          error.message = 'function: operatorsGet;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 400, 403, 404));
        }
      });
    });
  }

  /*********************************************************************************************************/
  /* par/operators *****************************************************************************************/

  /**
   * Check token
   * @param token token
   * @return Promise<void>
   */
  public parOperatorsPartyExtVerifyEmailPostUnauth(token: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const headers = this.getHttpHeader(null, 'application/x-www-form-urlencoded');
      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.post(`${u3RestPrefix}par/operators/party/extVerifyEmail`, `token=${token}`, { headers }).subscribe({
        next: () => {
          delete this.authServ.subs[key];
          resolve();
        },
        error: (e) => {
          delete this.authServ.subs[key];
          reject(e);
        }
      });
    });
  }

  /**
   * Check party id (unathorized)
   * @param data TsXmlParCreateOperator
   * @return Observable as TsXmlResponse
   */
  public parOperatorsPartyCheckIdPutUnauth(data: TsXmlParCreateOperator): Promise<TsXmlResponse> {
    return new Promise((resolve, reject) => {
      const headers = this.getHttpHeader(null);
      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.put(`${u3RestPrefix}par/operators/party/checkId`, data, { headers }).subscribe({
        next: (resp) => {
          delete this.authServ.subs[key];
          resolve(resp as TsXmlResponse);
        }, error: (e) => {
          delete this.authServ.subs[key];
          reject(e);
        }
      });
    });
  }

  /**
   * Check name/phone (unathorized)
   * @param data TsXmlParCreateOperator
   * @return Observable as TsXmlResponse
   */
  public parOperatorsPartyCheckNamePhonePutUnauth(data: TsXmlParCreateOperator): Promise<TsXmlResponse> {
    return new Promise((resolve, reject) => {
      const headers = this.getHttpHeader(null);
      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.put(`${u3RestPrefix}par/operators/party/checkNamePhone`, data, { headers }).subscribe({
        next: (resp) => {
          delete this.authServ.subs[key];
          resolve(resp as TsXmlResponse);
        },
        error: (e) => {
          delete this.authServ.subs[key];
          reject(e);
        }
      });
    });
  }

  /**
   * Check address (unathorized)
   * @param data TsXmlParCreateOperator
   * @return Observable as TsXmlResponse
   */
  public parOperatorsPartyCheckAddressPutUnauth(data: TsXmlParCreateOperator): Promise<TsXmlResponse> {
    return new Promise((resolve, reject) => {
      const headers = this.getHttpHeader(null);
      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.put(`${u3RestPrefix}par/operators/party/checkAddress`, data, { headers }).subscribe({
        next: (resp) => {
          delete this.authServ.subs[key];
          resolve(resp as TsXmlResponse);
        },
        error: (e) => {
          delete this.authServ.subs[key];
          reject(e);
        }
      });
    });
  }

  /**
   * Send email auth token (unathorized)
   * @param data TsXmlParCreateOperator
   * @return Promise<void>
   */
  public parOperatorsPartySendEmailAuthTokenPutUnauth(data: TsXmlParCreateOperator): Promise<void> {
    return new Promise((resolve, reject) => {
      const headers = this.getHttpHeader(null);
      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.put(`${u3RestPrefix}par/operators/party/sendEmailAuthToken`, data, { headers }).subscribe({
        next: () => {
          delete this.authServ.subs[key];
          resolve();
        },
        error: (e) => {
          delete this.authServ.subs[key];
          reject(e);
        }
      });
    });
  }

  /**
   * Check email auth token (unathorized)
   * @param data TsXmlParCreateOperator
   * @return Promise<TsXmlResponse>
   */
  public parOperatorsPartyCheckEmailAuthTokenPutUnath(data: TsXmlParCreateOperator): Promise<TsXmlResponse> {
    return new Promise((resolve, reject) => {
      const headers = this.getHttpHeader(null);
      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.put(`${u3RestPrefix}par/operators/party/checkEmailAuthToken`, data, { headers }).subscribe({
        next: (resp: TsXmlResponse) => {
          delete this.authServ.subs[key];
          resolve(resp);
        },
        error: (e) => {
          delete this.authServ.subs[key];
          reject(e);
        }
      });
    });
  }

  /**
   * Send sms auth token (unathorized)
   * @param data TsXmlParCreateOperator
   * @return Promise<void>
   */
  public parOperatorsPartySendSMSAuthTokenPutUnath(data: TsXmlParCreateOperator): Promise<void> {
    return new Promise((resolve, reject) => {
      const headers = this.getHttpHeader(null);
      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.put(`${u3RestPrefix}par/operators/party/sendSmsAuthToken`, data, { headers }).subscribe({
        next: () => {
          delete this.authServ.subs[key];
          resolve();
        },
        error: (e) => {
          delete this.authServ.subs[key];
          reject(e);
        }
      });
    });
  }

  /**
   * Check email auth token (unathorized)
   * @param data TsXmlParCreateOperator
   * @return Promise<TsXmlResponse>
   */
  public parOperatorsPartyCheckSMSAuthTokenPutUnath(data: TsXmlParCreateOperator): Promise<TsXmlResponse> {
    return new Promise((resolve, reject) => {
      const headers = this.getHttpHeader(null);
      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.put(`${u3RestPrefix}par/operators/party/checkSmsAuthToken`, data, { headers }).subscribe({
        next: (resp: TsXmlResponse) => {
          delete this.authServ.subs[key];
          resolve(resp);
        },
        error: (e) => {
          delete this.authServ.subs[key];
          reject(e);
        }
      });
    });
  }

  /**
   * Create operator (unathorized)
   * @param data TsXmlParCreateOperator
   * @return Promise<void>
   */
  public parOperatorsPartyCreatePutUnath(data: {
    id: string;
    email: string;
    partyType: string;
    authCodeEmail: string;
    authCodeSms: string;
    login: string;
    password: string;
  }): Promise<void> {
    return new Promise((resolve, reject) => {
      const headers = this.getHttpHeader(null);
      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.put(`${u3RestPrefix}par/operators/party/create`, data, { headers }).subscribe({
        next: () => {
          delete this.authServ.subs[key];
          resolve();
        },
        error: (e) => {
          delete this.authServ.subs[key];
          reject(e);
        }
      });
    });
  }

  /**
   * Send login to phone (unathorized)
   * @param email operator party email
   * @param id operator party id
   * @return Promise<TsXmlResponse>
   */
  public parOperatorsLostLoginPutUnath(email: string, id: string): Promise<TsXmlResponse> {
    return new Promise((resolve, reject) => {
      const headers = this.getHttpHeader(null, 'text/xml; charset=utf-8');
      const params = this.getHttpParams3({ email, id });

      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.put(`${u3RestPrefix}par/operators/lostLogin`, null, { headers, params }).subscribe({
        next: (resp: TsXmlResponse) => {
          delete this.authServ.subs[key];
          resolve(resp);
        },
        error: (e) => {
          delete this.authServ.subs[key];
          reject(e);
        }
      });
    });
  }

  /**
   * Send password recovery email (unathorized)
   * @param email operator party email
   * @return Promise<void>
   */
  public parOperatorsLostPasswordPostUnath(email: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const headers = this.getHttpHeader(null, 'application/x-www-form-urlencoded; charset=utf-8');
      const params = this.getHttpParams3({ email });

      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.post(`${u3RestPrefix}par/operators/lostPassword`, params, { headers }).subscribe({
        next: () => {
          delete this.authServ.subs[key];
          resolve();
        },
        error: (e) => {
          delete this.authServ.subs[key];
          reject(e);
        }
      });
    });
  }

  /**
   * On change password send Sms code (unathorized)
   * @param email operator party email
   * @param emailCode email code
   * @return Promise as TsXmlResponse
   */
  public parOperatorsChangePasswordSendSmsCodePostUnath(email: string, emailCode: string): Promise<TsXmlResponse> {
    return new Promise((resolve, reject) => {
      const headers = this.getHttpHeader(null, 'application/x-www-form-urlencoded; charset=utf-8');
      const params = this.getHttpParams3({ email, emailCode });

      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.post(`${u3RestPrefix}par/operators/changePasswordSendSmsCode`, params, { headers }).subscribe({
        next: (resp: TsXmlResponse) => {
          delete this.authServ.subs[key];
          resolve(resp);
        },
        error: (e) => {
          delete this.authServ.subs[key];
          reject(e);
        }
      });
    });
  }

  /**
   * Change password (unathorized)
   * @param email operator party email
   * @param emailCode email code
   * @param password password
   * @param smsCode sms code
   * @return Promise<void>
   */
  public parOperatorsChangePasswordPostUnath(
    email: string,
    emailCode: string,
    password: string,
    smsCode: string
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      const headers = this.getHttpHeader(null, 'application/x-www-form-urlencoded; charset=utf-8');
      const params = this.getHttpParams3({ email, emailCode, password, smsCode });

      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.post(
        `${u3RestPrefix}par/operators/changePassword`,
        params,
        { headers }
      ).subscribe({
        next: () => {
          delete this.authServ.subs[key];
          resolve();
        },
        error: (e) => {
          delete this.authServ.subs[key];
          reject(e);
        }
      });
    });
  }


  public parOperatorsDiDemandsGet(uid: TsXmlUid): Promise<TsXmlBoolResponse> {
    return new Promise((resolve, reject) => {
      const headers = this.getHttpHeader(uid);

      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.get(`${u3RestPrefix}par/operators/di/demands`, { headers }).subscribe({
        next: (resp: TsXmlBoolResponse) => {
          delete this.authServ.subs[key];
          resolve(resp);
        },
        error: error => {
          delete this.authServ.subs[key];
          error.message = 'function: parOperatorsDiDemandsGet;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 400, 403, 404));
        }
      });
    });
  }

  public parOperatorsDiDemandsPut(uid: TsXmlUid, content: TsXmlBoolResponse): Promise<void> {
    return new Promise((resolve, reject) => {
      const headers = this.getHttpHeader(uid);

      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.put(`${u3RestPrefix}par/operators/di/demands`, content, { headers }).subscribe({
        next: () => {
          delete this.authServ.subs[key];
          resolve();
        },
        error: (e) => {
          delete this.authServ.subs[key];
          e.message = 'function: parOperatorsDiDemandsPut;' + e.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(e, 400, 404));
        }
      });
    });
  }

  public changeLoginName(uid: TsXmlUid, content: TsXmlParOperatorLoginNameAuth): Promise<void> {
    return new Promise((resolve, reject) => {
      const headers = this.getHttpHeader(uid);

      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.post(`${u3RestPrefix}par/operators/operator/changeLoginName`, content, { headers }).subscribe({
        next: () => {
          delete this.authServ.subs[key];
          resolve();
        },
        error: (e) => {
          delete this.authServ.subs[key];
          e.message = 'function: changeLoginName;' + e.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(e, 400, 404));
        }
      });
    });
  }

  /*********************************************************************************************************/
  /* par/parties *******************************************************************************************/

  /**
   * Get party
   * @param partyType party type
   * @param partyId party id
   * @param uid rest uid
   * @return Promise<TsXmlParParty>
   */
  public parPartiesSuggestionGet(
    dt: { partyType: string, partyId: string, companyId: 'PILLOW_CZ' | 'PILLOW_SK' },
    uid: TsXmlUid
  ): Promise<TsXmlParParty> {
    return new Promise<TsXmlParParty>((resolve, reject) => {
      const headers = this.getHttpHeader(uid, 'text/xml; charset=utf-8');
      const params = this.getHttpParams3(dt);

      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.get(`${u3RestPrefix}par/parties/suggestion`, { headers, params }).subscribe({
        next: (resp) => {
          delete this.authServ.subs[key];
          resolve(UtilsService.convertAllJsonDateStrings(resp) as TsXmlParParty);
        },
        error: (error) => {
          delete this.authServ.subs[key];
          error.message = 'function: parPartiesSuggestionGet;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 400, 404));
        }
      });
    });
  }

  public parPartiesRuianGet(
    parameters: {
      zip: string, // TStr5
      city: string, // TStr50
      street?: string, // TStr50
      landRegistryNumber?: string, // TStr5Null cislo popisne
      houseNumber?: string, // TStr5Null cislo orientacni
      houseNumberChar?: string, // TStr1Null pismeno orientacni
    },
    uid: TsXmlUid
  ): Promise<TsXmlParRuianAddressPointList> {
    return new Promise((resolve, reject) => {
      const headers = this.getHttpHeader(uid);
      const params = this.getHttpParams2(parameters);

      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.get(u3RestPrefix + 'par/parties/ruian', { headers, params }).subscribe({
        next: resp => {
          delete this.authServ.subs[key];
          resolve(UtilsService.convertAllJsonDateStrings(resp) as TsXmlParRuianAddressPointList);
        },
        error: error => {
          delete this.authServ.subs[key];
          error.message = 'function: parPartiesRuianGet;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 404));
        }
      });
    });
  }

  public parPartiesGet(
    parameters: {
      limit: number,
      offset?: number,
      products?: string[],
      partyType?: string[],
      otherId?: string,
      context?: string,
      abbr?: string,
      partyCode?: string,
    },
    uid: TsXmlUid,
    subs?: { [key: string]: Subscription }
  ): Promise<TsXmlParPartyList> {
    return new Promise((resolve, reject) => {
      ['limit', 'offset'].forEach(nm => {
        if (parameters[nm] || parameters[nm] === 0)
          parameters[nm] = String(parameters[nm]);
      });
      const headers = this.getHttpHeader(uid);
      const params = this.getHttpParams3(parameters);

      const key = this.getSubsKey(subs ? { ...this.authServ.subs, ...subs } : this.authServ.subs);
      this.authServ.subs[key] = this.http.get(`${u3RestPrefix}par/parties`, { headers, params }).subscribe({
        next: resp => {
          delete this.authServ.subs[key];
          if (subs) delete subs[key];
          resolve(UtilsService.convertAllJsonDateStrings(resp) as TsXmlParPartyList);
        },
        error: error => {
          delete this.authServ.subs[key];
          if (subs) delete subs[key];
          error.message = 'function: parPartiesGet;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 404));
        }
      });
      if (subs) subs[key] = this.authServ.subs[key];
    });
  }

  public parPartiesObjIdGet(objId: number, uid: TsXmlUid): Promise<TsXmlParParty> {
    return new Promise((resolve, reject) => {
      const headers = this.getHttpHeader(uid);
      const params = this.getHttpParams3({ objId });

      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.get(`${u3RestPrefix}par/parties/objId`, { headers, params }).subscribe({
        next: resp => {
          delete this.authServ.subs[key];
          resolve(UtilsService.convertAllJsonDateStrings(resp) as TsXmlParParty);
        },
        error: error => {
          delete this.authServ.subs[key];
          error.message = 'function: parPartiesObjIdGet;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 404));
        }
      });
    });
  }

  public transferMoneyBetweenClientsPost(
    data: {
      objId: number,
      codeRecipientParty: string,
      amount: number,
      date: TDateD,
      type?: 1 | 2,
      note?: string,
      noteRecipientParty?: string,
    },
    uid: TsXmlUid,
  ): Promise<void> {
    const headers = this.getHttpHeader(uid);
    const params = this.getHttpParams3(data);
    return new Promise((resolve, reject) => {
      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.post(
        `${u3RestPrefix}par/parties/${data.objId}/accounts/insuranceAccount/changes/transfer/otherClient`,
        data,
        { headers, params }
      ).subscribe({
        next: () => {
          delete this.authServ.subs[key];
          resolve();
        },
        error: error => {
          delete this.authServ.subs[key];
          error.message = 'function: transferMoneyBetweenClientsPost;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 404));
        }
      });
    });
  }

  /**
   * Připíše bonus na konto klienta.
   * @param objId objId strany
   * @param amount částka připsaná na konto klienta (může být < 0)
   * @param date datum účinnosti záznamu rezervy
   * @param type typ (1 nebo 2 - typ záznamu rezervy KOKL_IMPORT
   * @param note1 poznámka 1 (OBE poznámka záznamu rezervy)
   * @param note2 poznámka 2 (OBE poznámka záznamu rezervy)
   * @param policyId číslo smlouvy (OBE poznámka záznamu rezervy)
   * @return Promise<TsXmlResponse>
   */
  public sendBonusPost(
    data: {
      objId: number,
      amount: number,
      date: TDateD,
      type?: 1 | 2,
      note1?: string,
      note2?: string,
      policyId?: string,
    },
    uid: TsXmlUid,
  ): Promise<void> {
    const headers = this.getHttpHeader(uid);
    const params = this.getHttpParams3(data);
    return new Promise((resolve, reject) => {
      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.post(
        `${u3RestPrefix}par/parties/${data.objId}/accounts/insuranceAccount/changes/bonus`,
        data,
        { headers, params }
      ).subscribe({
        next: () => {
          delete this.authServ.subs[key];
          resolve();
        },
        error: error => {
          delete this.authServ.subs[key];
          error.message = 'function: sendBonusPost;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 404));
        }
      });
    });
  }


  public prescriptionsProcessingPost(
    objId: number,
    uid: TsXmlUid,
  ): Promise<TsXmlParPolicyPrescriptionList> {
    const headers = this.getHttpHeader(uid);

    return new Promise((resolve, reject) => {
      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.post(
        `${u3RestPrefix}par/parties/${objId}/accounts/insuranceAccount/prescriptionsProcessing`,
        null,
        { headers }
      ).subscribe({
        next: (resp) => {
          delete this.authServ.subs[key];
          resolve(resp as TsXmlParPolicyPrescriptionList);
        },
        error: error => {
          delete this.authServ.subs[key];
          error.message = 'function: prescriptionsProcessingGet;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 404));
        }
      });
    });
  }

  public policyPrescriptionsGet(
    data: {
      partyObjId: number,
      policyId: string,
      limit: number,
      offset: number,
    },
    uid: TsXmlUid,
  ): Promise<void> {
    const headers = this.getHttpHeader(uid);

    return new Promise((resolve, reject) => {
      const key = this.getSubsKey(this.authServ.subs);
      const params = this.getHttpParams3({ policyId: data.policyId, limit: data.limit, offset: data.offset });
      this.authServ.subs[key] = this.http.get(
        `${u3RestPrefix}par/parties/${data.partyObjId}/accounts/policyPrescriptions`,
        { headers, params }
      ).subscribe({
        next: () => {
          delete this.authServ.subs[key];
          resolve();
        },
        error: error => {
          delete this.authServ.subs[key];
          error.message = 'function: policyPrescriptionsGet;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 404));
        }
      });
    });
  }

  /*********************************************************************************************************/
  /* par/policies ******************************************************************************************/

  public parPoliciesChangesAccessible(policyId: string, uid: TsXmlUid) {
    const headers = this.getHttpHeader(uid);
    return new Promise((resolve, reject) => {
      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.get(`${u3RestPrefix}par/policies/${policyId}/changes`, {
        headers
      }).subscribe({
        next: val => {
          delete this.authServ.subs[key];
          resolve(val);
        },
        error: error => {
          delete this.authServ.subs[key];
          error.message = 'function: parPoliciesChangesAccessible; ' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 403));
        }
      });
    });
  }

  public parPoliciesGet(
    data: {
      offset?: number;
      limit?: number;
      states?: string[];
      products?: string[];
      beginsFrom?: Date;
      beginsTo?: Date;
      sortBy?: string[];
      objId?: number;
      id?: string;
      context?: string;
      objIdParty?: number;
    },
    uid: TsXmlUid,
    subs?: { [key: string]: Subscription }
  ): Promise<TsXmlParReducedPolicyList> {
    return new Promise((resolve, reject) => {
      const headers = this.getHttpHeader(uid, 'text/xml; charset=utf-8');
      const params = this.getHttpParams3(data);

      const key = this.getSubsKey(subs ? { ...this.authServ.subs, ...subs } : this.authServ.subs);
      this.authServ.subs[key] = this.http.get(`${u3RestPrefix}par/policies`, { headers, params }).subscribe({
        next: (policies: TsXmlParReducedPolicyList) => {
          delete this.authServ.subs[key];
          delete this.authServ.subs[key];
          if (subs) delete subs[key];
          resolve(policies);
        },
        error: (error: TsXmlHttpErrorResponse) => {
          delete this.authServ.subs[key];
          delete this.authServ.subs[key];
          if (subs) delete subs[key];
          error.message = 'function: parPoliciesGet;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error));
        }
      });
      if (subs) subs[key] = this.authServ.subs[key];
    });
  }

  /**
   * Get policy detail
   * @param id policy id
   * @param uid rest uid
   * @return Observable as TsXmlParPolicy
   */
  public _parPolicyIdGet(id: string, uid: TsXmlUid): Observable<object> {
    const headers = this.getHttpHeader(uid);

    const key = this.getSubsKey(this.authServ.subs);
    this.authServ.subs[key] = this.http.get(`${u3RestPrefix}par/policies/${id}`, { headers });
    return this.authServ.subs[key];
  }

  public parPoliciesCancelTypes(
    data: {
      policyId: string,
      offset: number,
      limit: number
    },
    uid: TsXmlUid,
    subs?: { [key: string]: Subscription }
  ): Promise<TsXmlParCancelTypeList> {
    const headers = this.getHttpHeader(uid);
    const params = this.getHttpParams3(data);

    return new Promise((resolve, reject) => {
      const key = this.getSubsKey(subs ? { ...this.authServ.subs, ...subs } : this.authServ.subs);
      this.authServ.subs[key] = this.http.get(
        `${u3RestPrefix}par/policies/${data.policyId}/cancelTypes`,
        { headers, params }
      ).subscribe({
        next: (types: TsXmlParCancelTypeList) => {
          delete this.authServ.subs[key];
          if (subs) delete subs[key];
          resolve(new TsXmlParCancelTypeList(types));
        },
        error: (error: TsXmlHttpErrorResponse) => {
          delete this.authServ.subs[key];
          if (subs) delete subs[key];
          error.message = 'function: parPoliciesCancelTypes;' + error.message;
          const ignores = [403, 404];
          if (error.error.runtimeMsgs?.length && ['621842', '621006'].includes(error.error.runtimeMsgs[0].msgCode))
            ignores.push(400);
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, ...ignores));
        }
      });
      if (subs) subs[key] = this.authServ.subs[key];
    });
  }

  public parPoliciesCancelTypesPost(
    policyId: string,
    reasonId: string,
    data: TsXmlParCancelRequestSTR_11 |
      TsXmlParCancelRequestSTR_24 |
      TsXmlParCancelRequestSTR_30_31 |
      TsXmlParCancelRequestSTR_22_23_25 |
      TsXmlParCancelRequestSTR_43,
    uid: TsXmlUid,
    type?: string
  ): Promise<TsXmlParCancelRequest> {
    const headers = this.getHttpHeader(uid);

    let params = new CustomHttpParams();
    if (type) {
      params = params.append('type', type);
    }

    // params = params.append('policyId', policyId);
    // params = params.append('content', JSON.stringify(data));

    return new Promise((resolve, reject) => {
      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.post(
        `${u3RestPrefix}par/policies/${policyId}/cancelTypes/${reasonId}/request`,
        data,
        { headers, params }
      ).subscribe({
        next: (out: TsXmlParCancelRequest) => {
          delete this.authServ.subs[key];
          resolve(out);
        },
        error: error => {
          delete this.authServ.subs[key];
          error.message = 'function: parPoliciesCancelTypesPost;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 404));
        }
      });
    });
  }

  /**
   * Get agent for policy
   * @param policyId policy id
   * @param uid rest uid
   * @return Promise<TsXmlParPolicyAgents>
   */
  public parPoliciesAgentsGet(policyId: string, uid: TsXmlUid, subs?: { [key: string]: Subscription }): Promise<TsXmlParPolicyAgents> {
    return new Promise((resolve, reject) => {
      const headers = this.getHttpHeader(uid, 'text/xml; charset=utf-8');
      const key = this.getSubsKey(subs ? { ...this.authServ.subs, ...subs } : this.authServ.subs);
      this.authServ.subs[key] = this.http.get(`${u3RestPrefix}par/policies/${policyId}/agents`, { headers }).subscribe({
        next: (agent: TsXmlParPolicyAgents) => {
          delete this.authServ.subs[key];
          if (subs) delete subs[key];
          resolve(agent);
        },
        error: (error) => {
          delete this.authServ.subs[key];
          if (subs) delete subs[key];
          error.message = 'function: parPoliciesAgentsGet;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 404));
        }
      });
      if (subs) subs[key] = this.authServ.subs[key];
    });
  }

  /**
   * Get agent icon for policy
   * @param policyId policy id
   * @param uid rest uid
   * @return Promise<SafeResourceUrl>
   */
  public parPoliciesAgentsGetIcon(policyId: string, uid: TsXmlUid): Promise<SafeResourceUrl> {
    return new Promise((resolve, reject) => {
      const headers = this.getHttpHeader(uid);

      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.get(
        `${u3RestPrefix}par/policies/${policyId}/agents/icon`,
        { headers, responseType: 'blob' }
      ).subscribe({
        next: (pic: Blob) => {
          delete this.authServ.subs[key];
          const output = this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(pic));
          resolve(output);
        },
        error: (error) => {
          delete this.authServ.subs[key];
          error.message = 'function: parPoliciesAgentsGetIcon;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 404));
        }
      });
    });
  }

  /**
   * Load calculation
   * @param calculationId calculation id
   * @param uid rest uid
   * @return Promise<TsXmlParAp1SavePolicyReq>
   */
  public parPoliciesLoadAP1Get(calculationId: string, uid: TsXmlUid): Promise<TsXmlParAp1SavePolicyReq> {
    return new Promise<TsXmlParAp1SavePolicyReq>((resolve, reject) => {
      const headers = this.getHttpHeader(uid);

      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.get(
        `${u3RestV2Prefix}par/policies/load/AP1?calculationId=${calculationId}`,
        { headers }
      ).subscribe({
        next: (resp) => {
          delete this.authServ.subs[key];
          resolve(UtilsService.convertAllJsonDateStrings(resp) as TsXmlParAp1SavePolicyReq);
        },
        error: (error) => {
          delete this.authServ.subs[key];
          error.message = 'function: parPoliciesLoadAP1Get;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 404));
        }
      });
    });
  }

  /**
 * Load calculation
 * @param calculationId calculation id
 * @param uid rest uid
 * @return Promise<TsXmlParAp1SavePolicyReq>
 */
  public parPoliciesLoadAP2Get(calculationId: string, uid: TsXmlUid): Promise<TsXmlParAp2SavePolicyReq> {
    return new Promise<TsXmlParAp2SavePolicyReq>((resolve, reject) => {
      const headers = this.getHttpHeader(uid);

      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.get(
        `${u3RestV2Prefix}par/policies/load/AP2?calculationId=${calculationId}`,
        { headers }
      ).subscribe({
        next: (resp) => {
          resolve(UtilsService.convertAllJsonDateStrings(resp) as TsXmlParAp2SavePolicyReq);
        },
        error: (error) => {
          error.message = 'function: parPoliciesLoadAP2Get;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 404));
        }
      });
    });
  }

  public parPoliciesLoadRp1Get(calculationId: string, uid: TsXmlUid): Promise<TsXmlParRp1SavePolicyReq> {
    return new Promise((resolve, reject) => {
      this._parPoliciesLoadGet('RP1', calculationId, uid).then(resp => {
        resolve(resp as TsXmlParRp1SavePolicyReq);
      }).catch(e => {
        reject(e);
      });
    });
  }

  /** rest service not finished yet */
  public parPoliciesLoadPm1Get(calculationId: string, uid: TsXmlUid): Promise<TsXmlParPm1SavePolicyReq> {
    return new Promise((resolve, reject) => {
      this._parPoliciesLoadGet('PM1', calculationId, uid).then(resp => {
        resolve(resp as TsXmlParPm1SavePolicyReq);
      }).catch(e => {
        reject(e);
      });
    });
  }

  /**
   * Load calculation
   * @param productCode AP1||RP1||...
   * @param calculationId calculation id
   * @param uid rest uid
   * @return Promise<TsXmlParAp1SavePolicyReq|TsXmlParRp1SavePolicyReq|...>
   */
  public _parPoliciesLoadGet(
    productCode: 'RP1' | 'PM1',
    calculationId: string,
    uid: TsXmlUid
  ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      const headers = this.getHttpHeader(uid);
      const params = this.getHttpParams3({ calculationId });

      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.get(`${u3RestV2Prefix}par/policies/load/${productCode}`, { headers, params }).subscribe({
        next: (resp) => {
          delete this.authServ.subs[key];
          resp[`demands`] = this.fixDemands(resp[`demands`]);
          resolve(UtilsService.convertAllJsonDateStrings(resp));
        },
        error: (error) => {
          delete this.authServ.subs[key];
          error.message = 'function: _parPoliciesLoadGet;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 404));
        }
      });
    });
  }

  private fixDemands(demands: TsXmlParDemands): TsXmlParDemands {
    if (!demands || !Object.keys(demands).length) return null;
    demands.response.forEach(rsp => {
      if (rsp.value && !Object.keys(rsp.value).length)
        rsp.value = null;
      else if (typeof rsp.value?.bigDecimalValue === 'number')
        rsp.value.bigDecimalValue = new TBigDecimal(rsp.value.bigDecimalValue);
    });
    return demands;
  }

  /**
   * Print calculation
   * @param product 'AP2' | 'RP1' | 'PM1'
   * @param req data
   * @param calculationId calculation id
   * @param uid rest uid
   * @return Promise<Blob>
   */
  public parPoliciesPrintCalculationPrintPost(
    product: 'RP1' | 'PM1' | 'AP2',
    req: TsXmlParRp1PrintCalculationReq | TsXmlParPm1PrintCalculationReq | TsXmlParAp2PrintCalculationReq,
    calculationId: string,
    uid: TsXmlUid
  ): Promise<Blob> {
    const headers = this.getHttpHeader(uid, 'application/pdf');

    return new Promise((resolve, reject) => {
      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.post(
        `${u3RestPrefix}par/policies/print/${product}/calculation/${calculationId}/print`,
        req,
        { headers, responseType: 'blob' }
      ).subscribe({
        next: blob => {
          delete this.authServ.subs[key];
          resolve(blob);
        },
        error: error => {
          delete this.authServ.subs[key];
          error.message = 'function: parPoliciesPrintCalculationPrintPost;' + calculationId + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 404));
        }
      });
    });
  }

  /**
   * Print AP1 calculation
   * @param req data
   * @param calculationId calculation id
   * @param uid rest uid
   * @return Promise<Blob>
   */
  public parPoliciesPrintAP1CalculationPrintPost(
    req: TsXmlParAp1PrintCalculationReq,
    calculationId: string,
    uid: TsXmlUid
  ): Promise<Blob> {
    return new Promise((resolve, reject) => {
      const reqV2 = req.toXmlV2(attributesXmlParAp1RatePolicyReq);
      const headers = this.getHttpHeader(uid, 'application/pdf');

      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.post(
        `${u3RestV2Prefix}par/policies/print/AP1/calculation/${calculationId}/print`,
        reqV2,
        { headers, responseType: 'blob' }
      ).subscribe({
        next: (resp) => {
          delete this.authServ.subs[key];
          resolve(resp);
        },
        error: (e) => {
          delete this.authServ.subs[key];
          reject(e);
        }
      });
    });
  }

  /**
 * Print AP2 calculation
 * @param req data
 * @param calculationId calculation id
 * @param uid rest uid
 * @return Promise<Blob>
 */
  public parPoliciesPrintAP2CalculationPrintPost(
    req: TsXmlParAp2PrintCalculationReq,
    calculationId: string,
    uid: TsXmlUid
  ): Promise<Blob> {
    return new Promise((resolve, reject) => {
      const clReq = new TsXmlParAp2PrintCalculationReq();
      [1, 2, 3].forEach(i => {
        clReq[`printedVariant${i}`] = [];

        req[`printedVariant${i}`].forEach(it => {
          const vr = new TsXmlParAp2PrintVariantOfSubjectReq();
          vr.risk = it.risk;
          vr.variant = it.variant;
          clReq[`printedVariant${i}`].push(vr);
        });
      });

      const reqV2 = clReq.toXmlV2('xmlns="www.ais.cz" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="www.ais.cz XmlParAp2RatePolicyReq.xsd"');

      const headers = this.getHttpHeader(uid, 'application/pdf');

      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.post(
        `${u3RestV2Prefix}par/policies/print/AP2/calculation/${calculationId}/print`,
        reqV2,
        { headers, responseType: 'blob' }
      ).subscribe({
        next: (resp) => {
          delete this.authServ.subs[key];
          resolve(resp);
        },
        error: (e) => {
          delete this.authServ.subs[key];
          reject(e);
        }
      });
    });
  }

  /**
   * Send calculation to email
   * @param product AP1 | RP1 | PM1
   * @param req data
   * @param calculationId calculation id
   * @param email email
   * @param uid rest uid
   * @return Promise as void
   */
  public parPoliciesPrintCalculationEmailPost(
    product: 'AP1' | 'RP1' | 'PM1' | 'AP2',
    req: TsXmlParAp1PrintCalculationReq | TsXmlParRp1PrintCalculationReq | TsXmlParPm1PrintCalculationReq | TsXmlParAp1PrintCalculationReq,
    calculationId: string,
    email: string,
    uid: TsXmlUid
  ): Promise<void> {
    const headers = this.getHttpHeader(uid, 'text/xml; charset=utf-8');
    const params = this.getHttpParams3({ email });

    return new Promise((resolve, reject) => {
      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.post(
        `${u3RestPrefix}par/policies/print/${product}/calculation/${calculationId}/email`,
        req,
        { headers, params }
      ).subscribe({
        next: () => {
          delete this.authServ.subs[key];
          resolve();
        },
        error: error => {
          delete this.authServ.subs[key];
          error.message = 'function: parPoliciesPrintCalculationEmailPost;' + calculationId + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 404));
        }
      });
    });
  }


  /**
   * Send calculation to email
   * @param req data
   * @param calculationId calculation id
   * @param email email
   * @param uid rest uid
   * @return Promise<void>
   */
  public parPoliciesPrintAP1CalculationEmailPost(
    req: TsXmlParAp1PrintCalculationReq,
    calculationId: string,
    email: string,
    uid: TsXmlUid
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      const reqV2 = req.toXmlV2(attributesXmlParAp1RatePolicyReq);
      const headers = this.getHttpHeader(uid, 'text/xml; charset=utf-8');
      const params = this.getHttpParams3({ email });

      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.post(
        `${u3RestV2Prefix}par/policies/print/AP1/calculation/${calculationId}/email`,
        reqV2,
        { headers, params }
      ).subscribe({
        next: () => {
          delete this.authServ.subs[key];
          resolve();
        },
        error: (e) => {
          delete this.authServ.subs[key];
          reject(e);
        }
      });
    });
  }

  /**
   * Print product policy preview
   * @param calculationId calculation id
   * @param uid rest uid
   * @return Observable as Blob
   */
  public parPoliciesPrintPolicyPreviewPrintPost(
    product: 'AP1' | 'AP2' | 'RP1' | 'PM1',
    calculationId: string,
    uid: TsXmlUid
  ): Promise<Blob> {
    const headers = this.getHttpHeader(uid, 'application/pdf');

    return new Promise((resolve, reject) => {
      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.post(
        `${u3RestV2Prefix}par/policies/print/${product}/policyPreview/${calculationId}/print`,
        null,
        { headers, responseType: 'blob' }
      ).subscribe({
        next: resp => {
          delete this.authServ.subs[key];
          resolve(resp);
        },
        error: error => {
          delete this.authServ.subs[key];
          error.message = `function: parPoliciesPrintPolicyPreviewPrintPost; ${product}, ${calculationId} error.message`;
          this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 400, 404);
          reject(error);
        }
      });
    });
  }

  /**
   * Print AP1 policy preview
   * @param calculationId calculation id
   * @param uid rest uid
   * @return Promise<Blob>
   */
  public parPoliciesPrintAP1PolicyPreviewPrintPost(calculationId: string, uid: TsXmlUid): Promise<Blob> {
    return new Promise((resolve, reject) => {
      const headers = this.getHttpHeader(uid, 'application/pdf');

      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.post(
        `${u3RestV2Prefix}par/policies/print/AP1/policyPreview/${calculationId}/print`,
        null,
        { headers, responseType: 'blob' }
      ).subscribe({
        next: (resp) => {
          delete this.authServ.subs[key];
          resolve(resp);
        },
        error: (e) => {
          delete this.authServ.subs[key];
          reject(e);
        }
      });
    });
  }

  /**
   * Print AP1 policy proposal
   * @param calculationId calculation id
   * @param documentType document type
   * @param uid rest uid
   * @return Promise<Blob>
   */
  public parPoliciesPrintAP1PolicyProposalPrintDocumentTypePost(
    calculationId: string,
    documentType: string,
    uid: TsXmlUid
  ): Promise<Blob> {
    return new Promise((resolve, reject) => {
      const headers = this.getHttpHeader(uid, 'application/pdf');

      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.post(
        `${u3RestV2Prefix}par/policies/print/AP1/policyProposal/${calculationId}/print/${documentType}`,
        null,
        { headers, responseType: 'blob' }
      ).subscribe({
        next: (resp) => {
          delete this.authServ.subs[key];
          resolve(resp);
        },
        error: (e) => {
          delete this.authServ.subs[key];
          reject(e);
        }
      });
    });
  }

  public _parPoliciesPrintPolicyProposalPrintDocumentTypePost(
    data: {
      product: 'PM1' | 'AP1' | 'AP2',
      calculationId: string,
      documentType: string,
    },
    uid: TsXmlUid
  ): Promise<any> {
    const headers = this.getHttpHeader(uid, 'application/pdf');

    return new Promise((resolve, reject) => {
      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.post(
        `${u3RestV2Prefix}par/policies/print/${data.product}/policyProposal/${data.calculationId}/print/${data.documentType}`,
        null,
        { headers, responseType: 'blob' }
      ).subscribe({
        next: (blob) => {
          delete this.authServ.subs[key];
          resolve(blob);
        },
        error: (e) => {
          delete this.authServ.subs[key];
          e.message = 'function: _parPoliciesPrintPolicyProposalPrintDocumentTypePost;' + e.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(e));
        }
      });
    });
  }

  /**
   * Rate/Save AP1 base calculation
   * @param req data
   * @param uid rest uid
   * @return Promise<TsXmlParAp1RatePolicyReq>
   */
  public parPoliciesRateAP1Post(req: TsXmlParAp1RatePolicyReq, uid: TsXmlUid): Promise<TsXmlParAp1RatePolicyResp> {
    return new Promise<TsXmlParAp1RatePolicyResp>((resolve, reject) => {
      const headers = this.getHttpHeader(uid, 'text/xml');
      const numbersVars = [
        'numberOfInsuredMonths', 'numberOfDamages', 'engineVolume',
        'enginePower', 'weight', 'expectedKm', 'numberOfSeats'
      ];
      req.vehicle.x4x4 = !!req?.vehicle?.engine?.includes('4x4');
      const _req = deepCopy(req);
      this.znumberuj(_req, numbersVars);
      if (['EV', 'EK'].includes(_req.vehicle.vehicleType)) {
        _req.vehicle.enginePower = Math.ceil(_req.vehicle.enginePower);
        if (_req.vehicle?.vIN?.length < 17)
          _req.vehicle.vIN = '0'.repeat(17 - _req.vehicle.vIN.length) + _req.vehicle.vIN;
        else if (_req.vehicle?.vIN?.length > 17)
          _req.vehicle.vIN = _req.vehicle.vIN.slice(-17);
      }

      if (_req.vehicle.firstRegistrationDate) _req.vehicle.firstRegistrationDate = new TDate(_req.vehicle.firstRegistrationDate);
      if (_req.vehicle.productionYear) _req.vehicle.productionYear = new TDate(_req.vehicle.productionYear);
      if (_req.birthdate) _req.birthdate = new TDate(_req.birthdate);
      if (_req.policyBeginning) _req.policyBeginning = new TDate(_req.policyBeginning);
      this.ap1VehicleContrFix(_req.vehicle, _req.demands);

      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.post(`${u3RestPrefix}par/policies/rate/AP1`, _req, { headers }).subscribe({
        next: (resp) => {
          delete this.authServ.subs[key];
          resolve(UtilsService.convertAllJsonDateStrings(resp) as TsXmlParAp1RatePolicyResp);
        },
        error: (error) => {
          delete this.authServ.subs[key];
          error.message = 'function: parPoliciesRateAP1Post;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 400, 404));
        }
      });
    });
  }

  private ap1VehicleContrFix(vehicle: TsXmlParAp1Vehicle, demands: TsXmlParAp1Demands) {
    if (
      !demands ||
      typeof vehicle.contractualAuthorizedService === 'boolean' ||
      typeof vehicle.contractualService === 'boolean'
    )
      return;
    const dtMinus7Years = this.otherServ.getDateChangedBy(this.now, -7);
    const vehicleDt = vehicle.firstRegistrationDate ||
      vehicle.productionYear ||
      this.now;

    let type = 1;

    if (dtMinus7Years.getTime() < vehicleDt.getTime()) {
      type = 2;
    }
    vehicle.contractualService = type === 1;
    vehicle.contractualAuthorizedService = type === 2;
  }


  public parPoliciesRateAP2Post(req: TsXmlParAp2RatePolicyReq, uid: TsXmlUid): Promise<TsXmlParAp1RatePolicyResp> {
    return new Promise<TsXmlParAp1RatePolicyResp>((resolve, reject) => {
      const numberVars = [
        'agentsDiscount', 'numberOfDamages', 'numberOfInsuredMonths'
      ];
      req.vehicle.x4x4 = !!req?.vehicle?.engine?.includes('4x4');
      const _req = deepCopy(req);
      this.znumberuj(req, numberVars);
      if (['EV', 'EK', 'EB'].includes(_req.vehicle.vehicleType)) {
        _req.vehicle.enginePower = Math.ceil(_req.vehicle.enginePower);
        if (_req.vehicle?.vIN?.length < 17)
          _req.vehicle.vIN = '0'.repeat(17 - _req.vehicle.vIN.length) + _req.vehicle.vIN;
        else if (_req.vehicle?.vIN?.length > 17)
          _req.vehicle.vIN = _req.vehicle.vIN.slice(-17);
      }
      if (_req.birthdate) _req.birthdate = new TDate(_req.birthdate);
      if (_req.policyBeginning) _req.policyBeginning = new TDate(_req.policyBeginning);
      if (_req.vehicle && typeof _req.vehicle.contractualService !== 'boolean') _req.vehicle.contractualService = false;
      if (_req.vehicle.productionYear) _req.vehicle.productionYear = new TDate(new TDateD(new Date(_req.vehicle.productionYear)));
      this._parPoliciesRatePost({ product: 'AP2', req: _req }, uid).then(resp => {
        resolve(resp as TsXmlParAp1RatePolicyResp);
      }).catch(e => {
        reject(e);
      });
    });
  }

  /**
   * Rate/Save RP1 base calculation
   * @param req data
   * @param uid rest uid
   * @return Promise<TsXmlParRp1RatePolicyResp>
   */
  public parPoliciesRateRP1Post(req: TsXmlParRp1RatePolicyReq, uid: TsXmlUid): Promise<TsXmlParRp1RatePolicyResp> {
    return new Promise<TsXmlParRp1RatePolicyResp>((resolve, reject) => {
      const numberVars = [
        'agentsDiscount', 'height', 'weight', 'mode',
        'intValue', 'insurancePeriod', 'termPremium'
      ];
      this.znumberuj(req, numberVars);
      this.fixRp1(req, uid).then(req2 => {
        this._parPoliciesRatePost({ product: 'RP1', req: req2 }, uid).then(resp => {
          resolve(resp as TsXmlParRp1RatePolicyResp);
        }).catch(e => {
          reject(e);
        });
      });
    });
  }

  private async fixRp1(req: TsXmlParRp1RatePolicyReq, uid: TsXmlUid): Promise<TsXmlParRp1RatePolicyReq> {
    if (!['FODN', 'FORC'].includes(req.partyType)) return req;
    const date = UtilsService.getBirthDateFromParty({
      birthdate: req.birthdate,
      personalIdentification: req.personalIdentification
    } as any);
    const now = await this.currentTimeJBossGet(uid);
    if (date.getTime() <= this.otherServ.getDateChangedBy(now, -18).getTime()) return req;
    req.subject = req?.subject?.map(it => {
      if (it.risk === 'IK2') it.risk = 'I2';
      if (it.risk === 'IUK2') it.risk = 'IU2';
      return it;
    }) || null;
    return req;
  }

  /**
   * Rate/Save PM1 base calculation
   * @param req data
   * @param uid rest uid
   * @return Promise<TsXmlParPm1RatePolicyResp>
   */
  public parPoliciesRatePM1Post(req: TsXmlParPm1RatePolicyReq, uid: TsXmlUid): Promise<TsXmlParPm1RatePolicyResp> {
    return new Promise<TsXmlParPm1RatePolicyResp>((resolve, reject) => {
      const numberVars = [
        'agentsDiscount', 'intValue', 'objId',
        'idTridy', 'floor', 'area', 'ageOfBuilding'
      ];
      this.znumberuj(req, numberVars);
      this._parPoliciesRatePost({ product: 'PM1', req }, uid).then(resp => {
        resolve(resp as TsXmlParPm1RatePolicyResp);
      }).catch(e => {
        reject(e);
      });
    });
  }

  /**
   * Rate/Save PM1 base calculation
   * @param req data
   * @param uid rest uid
   * @return Promise<TsXmlParAp1RatePolicyResp>
   */
  public parPoliciesRateAp1Post(req: TsXmlParAp1RatePolicyReq, uid: TsXmlUid): Promise<TsXmlParAp1RatePolicyResp> {
    return new Promise<TsXmlParAp1RatePolicyResp>((resolve, reject) => {
      const numberVars = [
        'agentsDiscount', 'intValue', 'objId',
        'idTridy', 'floor', 'area', 'ageOfBuilding'
      ];
      this.znumberuj(req, numberVars);
      this._parPoliciesRatePost({ product: 'AP1', req }, uid).then(resp => {
        resolve(resp as TsXmlParAp1RatePolicyResp);
      }).catch(e => {
        reject(e);
      });
    });
  }

  public _parPoliciesRatePost(
    data: {
      product: 'AP1' | 'RP1' | 'PM1' | 'AP2',
      req: TsXmlParAp1RatePolicyReq | TsXmlParRp1RatePolicyReq | TsXmlParPm1RatePolicyReq | TsXmlParAp2RatePolicyReq,
    },
    uid: TsXmlUid
  ) {
    return new Promise((resolve, reject) => {
      const headers = this.getHttpHeader(uid, 'text/xml');
      if (data.req.calculationId)
        this.authServ.subs[data.req.calculationId]?.unsubscribe();
      data.req = this.allDates2TDates(data.req);
      this.authServ.subs[data.req.calculationId] = this.http.post(
        `${u3RestPrefix}par/policies/rate/${data.product}`,
        data.req,
        { headers }
      ).subscribe({
        next: (resp) => {
          delete this.authServ.subs[data.req.calculationId];
          resolve(UtilsService.convertAllJsonDateStrings(resp));
        },
        error: (error: TsXmlHttpErrorResponse) => {
          delete this.authServ.subs[data.req.calculationId];
          error.message = `function: parPoliciesRate${data.product}Post; + ${error.message}`;
          if (error?.error?.runtimeMsgs && error?.error?.runtimeMsgs[0]?.msgCode === '621422') {
            reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 400, 404));
          } else {
            reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 404));
          }
        }
      });
    });
  }

  /**
   * Save RP1 base calculation
   * @param req data (Telem)
   * @param uid rest uid
   * @param saveByBatch (Parametrem:) Priznak, zda se ma smlouva ulozit nasledne davkou nebo okamzite.
   * @return Promise<TsXmlParRp1SavePolicyResp>
   */
  public parPoliciesSaveRP1Post(
    req: TsXmlParRp1SavePolicyReq,
    saveByBatch: boolean,
    uid: TsXmlUid
  ): Promise<TsXmlParRp1SavePolicyResp> {
    return new Promise<TsXmlParRp1SavePolicyResp>((resolve, reject) => {
      const numberVars = [
        'agentsDiscount', 'height', 'weight', 'objId',
        'insurancePeriod', 'termPremium', 'intValue'
      ];
      this.znumberuj(req, numberVars);
      this._parPoliciesSavePost('RP1', req, saveByBatch, uid).then(resp => {
        resolve(resp as TsXmlParRp1SavePolicyResp);
      }).catch(e => {
        reject(e);
      });
    });
  }

  /**
   * Save PM1 base calculation
   * @param req data (Telem)
   * @param uid rest uid
   * @param saveByBatch (Parametrem:) Priznak, zda se ma smlouva ulozit nasledne davkou nebo okamzite.
   * @return Promise<TsXmlParPm1SavePolicyResp>
   */
  public parPoliciesSavePM1Post(
    req: TsXmlParPm1SavePolicyReq,
    saveByBatch: boolean,
    uid: TsXmlUid
  ): Promise<TsXmlParPm1SavePolicyResp> {
    return new Promise<TsXmlParPm1SavePolicyResp>((resolve, reject) => {
      const numberVars = [
        'agentsDiscount', 'intValue', 'objId',
        'idTridy', 'objId', 'floor', 'area', 'ageOfBuilding'
      ];
      this.znumberuj(req, numberVars);
      this._parPoliciesSavePost('PM1', req, saveByBatch, uid).then(resp => {
        resolve(resp as TsXmlParPm1SavePolicyResp);
      }).catch(e => {
        reject(e);
      });
    });
  }

  public _parPoliciesSavePost(
    product: 'RP1' | 'PM1',
    req: TsXmlParRp1SavePolicyReq | TsXmlParPm1SavePolicyReq,
    saveByBatch: boolean,
    uid: TsXmlUid
  ) {
    return new Promise((resolve, reject) => {
      const httpHeader = this.getHttpHeader(uid, 'text/xml; charset=utf-8');
      const httpParams = this.getHttpParams3({ saveByBatch });

      if (product === 'RP1') {
        req = req as TsXmlParRp1SavePolicyReq;
        if (req?.insured?.birthdate) {
          req.insured.birthdate = new TDate(req.insured.birthdate);
        }
      }
      if (req?.policyHolder?.birthdate) {
        req.policyHolder.birthdate = new TDate(req.policyHolder.birthdate);
      }
      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.post(
        `${u3RestPrefix}par/policies/save/${product}`,
        req,
        {
          headers: httpHeader,
          params: httpParams
        }).subscribe({
          next: (resp) => {
            delete this.authServ.subs[key];
            resolve(UtilsService.convertAllJsonDateStrings(resp));
          },
          error: (error) => {
            delete this.authServ.subs[key];
            error.message = `function: parPoliciesSave${product}Post; + ${error.message}`;
            reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 404));
          }
        });
    });
  }

  /**
   * Save AP1 calculation
   * @param req data
   * @param saveByBatch save by batch
   * @param uid rest uid
   * @return Promise<TsXmlParAp1SavePolicyResp>
   */
  public parPoliciesSaveAP1Post(
    req: TsXmlParAp1SavePolicyReq,
    saveByBatch: boolean,
    uid: TsXmlUid
  ): Promise<TsXmlParAp1SavePolicyResp> {
    return new Promise<TsXmlParAp1SavePolicyResp>((resolve, reject) => {
      const numberVars = [
        'objId', 'engineVolume', 'enginePower',
        'weight', 'expectedKm', 'numberOfSeats',
      ];
      req.vehicle.x4x4 = !!req?.vehicle?.engine?.includes('4x4');
      const _req = deepCopy(req);
      this.znumberuj(_req, numberVars);
      if (_req.policyHolder.mailingAddress !== null && UtilsService.isNull(_req.policyHolder.mailingAddress.street)) {
        _req.policyHolder.mailingAddress = null;
      }

      if (
        _req.vehicleHolder !== null &&
        _req.vehicleHolder.mailingAddress !== null &&
        UtilsService.isNull(_req.vehicleHolder.mailingAddress.street)
      ) {
        _req.vehicleHolder.mailingAddress = null;
      }

      if (
        _req.vehicleOwner !== null &&
        _req.vehicleOwner.mailingAddress !== null &&
        UtilsService.isNull(_req.vehicleOwner.mailingAddress.street)
      ) {
        _req.vehicleOwner.mailingAddress = null;
      }

      if (['EV', 'EK'].includes(_req.vehicle.vehicleType)) {
        _req.vehicle.enginePower = Math.ceil(_req.vehicle.enginePower);
        if (_req.vehicle?.vIN?.length < 17)
          _req.vehicle.vIN = '0'.repeat(17 - _req.vehicle.vIN.length) + _req.vehicle.vIN;
        else if (_req.vehicle?.vIN?.length > 17)
          _req.vehicle.vIN = _req.vehicle.vIN.slice(-17);
      }

      if (_req.vehicle.firstRegistrationDate) _req.vehicle.firstRegistrationDate = new TDate(_req.vehicle.firstRegistrationDate);
      if (_req.vehicle.productionYear) _req.vehicle.productionYear = new TDate(_req.vehicle.productionYear);
      if (_req.policyBeginning) _req.policyBeginning = new TDate(_req.policyBeginning);
      ['policyHolder', 'vehicleHolder', 'vehicleOwner'].forEach(key => {
        if (_req?.[key]?.birthdate) _req[key].birthdate = new TDate(_req[key].birthdate);
      });

      const headers = new HttpHeaders({
        'Content-Type': 'text/xml; charset=utf-8',
        RestUID: uid && uid.uid ? uid.uid : '',
      });

      let params = new CustomHttpParams();
      if (!UtilsService.isNull(saveByBatch)) {
        params = params.append('saveByBatch', '' + saveByBatch);
      }

      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.post(`${u3RestPrefix}par/policies/save/AP1`, _req, { headers, params }).subscribe({
        next: (resp) => {
          delete this.authServ.subs[key];
          resolve(UtilsService.convertAllJsonDateStrings(resp) as TsXmlParAp1SavePolicyResp);
        },
        error: (error) => {
          delete this.authServ.subs[key];
          error.message = 'function: parPoliciesSaveAP1Post;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 400, 404));
        }
      });
    });
  }

  /**
 * Save AP1 calculation
 * @param req data
 * @param saveByBatch save by batch
 * @param uid rest uid
 * @return Promise<TsXmlParAp1SavePolicyResp>
 */
  public parPoliciesSaveAP2Post(
    req: TsXmlParAp2SavePolicyReq,
    saveByBatch: boolean,
    uid: TsXmlUid
  ): Promise<TsXmlParAp1SavePolicyResp> {
    return new Promise<TsXmlParAp1SavePolicyResp>((resolve, reject) => {
      const numberVars = [
        'objId', 'engineVolume', 'enginePower',
        'weight', 'expectedKm', 'numberOfSeats',
      ];
      req.vehicle.x4x4 = !!req?.vehicle?.engine?.includes('4x4');
      this.znumberuj(req, numberVars);
      if (req.policyHolder.mailingAddress !== null && UtilsService.isNull(req.policyHolder.mailingAddress.street)) {
        req.policyHolder.mailingAddress = null;
      }

      if (
        req.vehicleHolder !== null &&
        req.vehicleHolder.mailingAddress !== null &&
        UtilsService.isNull(req.vehicleHolder.mailingAddress.street)
      ) {
        req.vehicleHolder.mailingAddress = null;
      }

      if (
        req.vehicleOwner !== null &&
        req.vehicleOwner.mailingAddress !== null &&
        UtilsService.isNull(req.vehicleOwner.mailingAddress.street)
      ) {
        req.vehicleOwner.mailingAddress = null;
      }

      const headers = this.getHttpHeader(uid, 'text/xml; charset=utf-8');

      let params = new CustomHttpParams();
      if (!UtilsService.isNull(saveByBatch)) {
        params = params.append('saveByBatch', '' + saveByBatch);
      }
      if (req.policyBeginning) req.policyBeginning = new TDate(req.policyBeginning);

      req = this.allDates2TDates(req);
      if (req.vehicle.productionYear) req.vehicle.productionYear = new TDate(new TDateD(new Date(req.vehicle.productionYear)));
      if (['EV', 'EK', 'EB'].includes(req.vehicle.vehicleType)) {
        req.vehicle.enginePower = Math.ceil(req.vehicle.enginePower);
        if (req.vehicle?.vIN?.length < 17)
          req.vehicle.vIN = '0'.repeat(17 - req.vehicle.vIN.length) + req.vehicle.vIN;
        else if (req.vehicle?.vIN?.length > 17)
          req.vehicle.vIN = req.vehicle.vIN.slice(-17);
      }
      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.post(`${u3RestPrefix}par/policies/save/AP2`, req, { headers, params, }).subscribe({
        next: (resp) => {
          delete this.authServ.subs[key];
          resolve(UtilsService.convertAllJsonDateStrings(resp) as TsXmlParAp1SavePolicyResp);
        },
        error: (error) => {
          delete this.authServ.subs[key];
          error.message = 'function: parPoliciesSaveAP2Post;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 400, 404));
        }
      });
    });
  }

  /**
   * Get insurance terms from policy.
   * @param policyId id of policy
   * @param uid rest uid
   * @return Promise<TsXmlParInsuranceTermList>
   */
  public parPoliciesInsuranceTermsGet(policyId: string, uid: TsXmlUid): Promise<TsXmlParInsuranceTermList> {
    const headers = this.getHttpHeader(uid);
    return new Promise((resolve, reject) => {
      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.get(`${u3RestPrefix}par/policies/${policyId}/insuranceTerms`, { headers }).subscribe({
        next: resp => {
          delete this.authServ.subs[key];
          resolve(resp as TsXmlParInsuranceTermList);
        },
        error: error => {
          delete this.authServ.subs[key];
          error.message = 'function: parPoliciesInsuranceTermsGet;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 404));
        }
      });
    });
  }

  /**
   * Rejects cancel request
   * @param policyId id of policy
   * @param cancelRequestObjId: objId of TsXmlParCancelRequest
   * @param uid rest uid
   */
  public parPoliciesCancelRequestsActionsReject(policyId: string, cancelRequestObjId: number, uid: TsXmlUid) {
    const headers = this.getHttpHeader(uid);

    return new Promise((resolve, reject) => {
      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.post(
        `${u3RestPrefix}par/policies/${policyId}/cancelRequests/${cancelRequestObjId}/actions/reject`,
        null,
        { headers }
      ).subscribe({
        next: a => {
          delete this.authServ.subs[key];
          resolve(a);
        },
        error: error => {
          delete this.authServ.subs[key];
          error.message = 'function: parPoliciesCancelRequestsActionsReject;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 400, 404));
        }
      });
    });
  }

  /**
   * Rejects cancel request
   * @param policyId id of policy
   * @param cancelRequestObjId: objId of TsXmlParCancelRequest
   * @param content: struktura s podpisovnými kódy (optional)
   * @param uid rest uid
   */
  public parPoliciesCancelRequestsActionsAccept(
    policyId: string,
    cancelRequestObjId: number,
    uid: TsXmlUid,
    content?: TsXmlPortalAuthorizationToken
  ) {
    const headers = this.getHttpHeader(uid);

    // let params = null;
    // if (content) {
    //   params = new CustomHttpParams();
    //   params = params.append('agentId', JSON.stringify(content));
    // }

    return new Promise((resolve, reject) => {
      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.post(
        `${u3RestPrefix}par/policies/${policyId}/cancelRequests/${cancelRequestObjId}/actions/accept`,
        content ? content : null,
        { headers }
      ).subscribe({
        next: a => {
          delete this.authServ.subs[key];
          resolve(a);
        },
        error: error => {
          delete this.authServ.subs[key];
          error.message = 'function: parPoliciesCancelRequestsActionsAccept;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 400, 404));
        }
      });
    });
  }

  /**
   * @param policyId TStr20 identifikátor smlouvy
   * @param type TStr20 výčet POLICY_CHANGE_REQ, POLICY_ROLE_CH_REQ, POLICY_LIC_PL_CH_REQ
   * @param uid TsXmlUid
   */
  public parPoliciesPolicyIdChangesPost(policyId: string, type: string, uid: TsXmlUid): Promise<TsXmlParPolicyChange> {
    const headers = this.getHttpHeader(uid);
    const params = this.getHttpParams3({ type });

    const promise = new Promise<TsXmlParPolicyChange>((resolve, reject) => {
      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.post(
        `${u3RestPrefix}par/policies/${policyId}/changes`,
        null,
        { headers, params }
      ).subscribe({
        next: (resp: TsXmlParPolicyChange) => {
          delete this.authServ.subs[key];
          resolve(resp);
        },
        error: error => {
          delete this.authServ.subs[key];
          const er = this.handleError(error, 'parPoliciesPolicyIdChanges', null, [400]);
          reject(er);
        }
      });
    });
    return promise;
  }

  /**
   * @param policyId TStr20 identifikátor smlouvy
   * @param shouldBeAcceptedByClient TBooleanNull true=změnu má schválit pojistník smlouvy, false=změna je hned provedena, defaultne je true
   * @param licensePlate TStr20 nová SPZ
   * @param uid TsXmlUid
   */
  public parPoliciesChangesLicensePlatePost(
    data: {
      policyId: string,
      shouldBeAcceptedByClient: boolean
      licensePlate?: string,
      technicalCertificateNumber?: string,
    },
    uid: TsXmlUid
  ): Promise<TsXmlParPolicyChange> {
    const headers = this.getHttpHeader(uid);
    const params = this.getHttpParams3({
      shouldBeAcceptedByClient: data.shouldBeAcceptedByClient,
      licensePlate: data.licensePlate,
      technicalCertificateNumber: data.technicalCertificateNumber
    });

    const promise = new Promise<TsXmlParPolicyChange>((resolve, reject) => {
      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.post(
        `${u3RestPrefix}par/policies/${data.policyId}/changes/licensePlateTechnicalCertificateNumber`,
        null,
        { headers, params }
      ).subscribe({
        next: (resp: TsXmlParPolicyChange) => {
          delete this.authServ.subs[key];
          resolve(resp);
        },
        error: error => {
          delete this.authServ.subs[key];
          const er = this.handleError(error, 'parPoliciesChangesLicensePlatePost', null, [400]);
          reject(er);
        }
      });
    });
    return promise;
  }

  /**
   * @param policyId TStr20 identifikator smlouvy
   * @param changeObjId TObjId ObjId změny - úkolu definice POZ_NA_ZMENU
   * @param content TsXmlPortalAuthorizationToken token obsahující pro operátora typu Osoba autorizační kódy pro jednu z akcí (čís. 562):
   *  POLICY_CHANGE_REQ, POLICY_ROLE_CH_REQ a POLICY_LIC_PL_CH_REQ
   */
  public parPoliciesPolicyIdChangesChangeObjIdActionPost(
    data: {
      policyId: string,
      changeObjId: number,
      action: 'reject' | 'accept',
      content?: TsXmlPortalAuthorizationToken
    },
    uid: TsXmlUid,
    subs?: { [key: string]: Subscription }
  ): Promise<void> {
    const headers = this.getHttpHeader(uid);

    const promise = new Promise<void>((resolve, reject) => {
      const key = this.getSubsKey(subs ? { ...this.authServ.subs, ...subs } : this.authServ.subs);
      this.authServ.subs[key] = this.http.post(
        `${u3RestPrefix}par/policies/${data.policyId}/changes/${data.changeObjId}/actions/${data.action}`,
        data.content ? data.content : null,
        { headers }
      ).subscribe({
        next: () => {
          delete this.authServ.subs[key];
          if (subs) delete subs[key];
          resolve();
        },
        error: error => {
          delete this.authServ.subs[key];
          if (subs) delete subs[key];
          const er = this.handleError(error, 'parPoliciesPolicyIdChangesChangeObjIdActionPost', null, [400]);
          reject(er);
        }
      });
      if (subs) subs[key] = this.authServ.subs[key];
    });
    return promise;
  }

  /**
   * @param policyId TStr20 identifikator smlouvy
   * @param changeObjId TObjId ObjId změny - úkolu definice POZ_NA_ZMENU
   * @param uid TsXmlUid
   */
  public parPoliciesPolicyIdChangesChangeObjIdGet(
    data: {
      policyId: string,
      changeObjId: number
    },
    uid: TsXmlUid,
    subs?: { [key: string]: Subscription }
  ): Promise<TsXmlParPolicyChange> {
    const headers = this.getHttpHeader(uid);

    const promise = new Promise<TsXmlParPolicyChange>((resolve, reject) => {
      const key = this.getSubsKey(subs ? { ...this.authServ.subs, ...subs } : this.authServ.subs);
      this.authServ.subs[key] = this.http.get(
        `${u3RestPrefix}par/policies/${data.policyId}/changes/${data.changeObjId}`,
        { headers }
      ).subscribe({
        next: (data: TsXmlParPolicyChange) => {
          delete this.authServ.subs[key];
          if (subs) delete subs[key];
          resolve(data);
        },
        error: error => {
          delete this.authServ.subs[key];
          if (subs) delete subs[key];
          const er = this.handleError(error, 'parPoliciesPolicyIdChangesChangeObjIdGet', null, [400]);
          reject(er);
        }
      });
      if (subs) subs[key] = this.authServ.subs[key];
    });
    return promise;
  }

  /**
   * @param idPolicy?: string, TStr20 (cestou) identifikátor smlouvy
   * @param types?: string[], List<TStr20> typy změn, ktoré se budou vyhledávat
   * @param states?: string[], List<TStr20> stavy změn, ktoré se budou vyhledávat
   * @param offset?: number, TIntegerNull první index vrácené entity od 0, default 0
   * @param limit?: number, TIntegerNull maximální počet vrácených entit, default 10, max 100
   * @param uid: TsXmlUid
   */
  public parPoliciesPolicyIdChangesGet(
    data: {
      policyId: string,
      types?: string[],
      states?: string[],
      offset?: number,
      limit?: number
    },
    uid: TsXmlUid
  ): Promise<TsXmlParPolicyChangeList> {
    const headers = this.getHttpHeader(uid);
    const _paramsArray = [];
    Object.entries(data).forEach(dt => {
      if (dt[1] || dt[1] === 0) {
        const key = dt[0];
        const param: { [key: string]: string | string[] } = {};
        param[key] = typeof dt[1] === 'number' ? String(dt[1]) : dt[1];
        _paramsArray.push(param);
      }
    });
    const params = this.getHttpParams2(_paramsArray);

    const promise = new Promise<TsXmlParPolicyChangeList>((resolve, reject) => {
      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.get(
        `${u3RestPrefix}par/policies/${data.policyId}/changes`,
        { headers, params }
      ).subscribe({
        next: (resp: TsXmlParPolicyChangeList) => {
          delete this.authServ.subs[key];
          resolve(resp);
        },
        error: error => {
          delete this.authServ.subs[key];
          const er = this.handleError(error, 'parPoliciesPolicyIdChanges', null, [400]);
          reject(er);
        }
      });
    });
    return promise;
  }

  public parPoliciesCancelRequestsGet(
    data: {
      policyId: string,
      limit: number,
      offset: number
    },
    uid: TsXmlUid): Promise<TsXmlParCancelRequest> {
    const headers = this.getHttpHeader(uid);
    const params = this.getHttpParams3(data);

    return new Promise((resolve, reject) => {
      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.get(
        `${u3RestPrefix}par/policies/${data.policyId}/cancelRequests`, { headers, params }).subscribe({
          next: resp => {
            delete this.authServ.subs[key];
            resolve(resp as TsXmlParCancelRequest);
          },
          error: error => {
            delete this.authServ.subs[key];
            error.message = 'function: parPoliciesCancelRequestsGet;' + error.message;
            reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error));
          }
        });
    });
  }

  public paymentsReportGet(
    policyId: string,
    uid: TsXmlUid
  ): Promise<TsXmlParPolicyPaymentsReportList> {
    const headers = this.getHttpHeader(uid);

    return new Promise((resolve, reject) => {
      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.get(
        `${u3RestPrefix}par/policies/${policyId}/payments/report`, { headers }).subscribe({
          next: resp => {
            delete this.authServ.subs[key];
            resolve(resp as TsXmlParPolicyPaymentsReportList);
          },
          error: error => {
            delete this.authServ.subs[key];
            error.message = 'function: paymentsReportGet;' + error.message;
            reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error));
          }
        });
    });
  }

  public prescriptionsGet(
    data: {
      policyId: string,
      offset: number,
      limit: number,
    },
    uid: TsXmlUid
  ): Promise<TsXmlParPolicyPrescriptionList> {
    const headers = this.getHttpHeader(uid);
    const params = this.getHttpParams3({ offset: data.offset, limit: data.limit });
    // const output = {
    //   totalEntries: 20,
    //   returnedEntries: 3,
    //   policyPrescriptions: [
    //     {
    //       objId: 123,
    //       type: 'DUE',
    //       due: new TBigDecimal(1234),
    //       amount: new TBigDecimal(2000),
    //       prescriptionFrom: new Date('2022'),
    //       prescriptionTo: new Date('2025'),
    //       policyObjId: 592793,
    //       policyId: '22294910',
    //       policyState: 'P',
    //     },
    //     {
    //       objId: 125,
    //       type: 'DUE',
    //       due: new TBigDecimal(2234),
    //       amount: new TBigDecimal(3000),
    //       prescriptionFrom: new Date('2022'),
    //       prescriptionTo: new Date('2025'),
    //       policyObjId: 592796,
    //       policyId: '22294943',
    //       policyState: 'P',
    //     },
    //     {
    //       objId: 127,
    //       type: 'DUE',
    //       due: new TBigDecimal(2234),
    //       amount: new TBigDecimal(3000),
    //       prescriptionFrom: new Date('2022'),
    //       prescriptionTo: new Date('2025'),
    //       policyObjId: 592797,
    //       policyId: '22294954',
    //       policyState: 'P'
    //     }
    //   ]
    // };

    // return new Promise((resolve) => {
    //   setTimeout(() => {
    //     resolve(output);
    //   }, 500);
    // });

    return new Promise((resolve, reject) => {
      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.get(
        `${u3RestPrefix}par/policies/${data.policyId}/prescriptions`,
        { headers, params }
      ).subscribe({
        next: (resp: TsXmlParPolicyPrescriptionList) => {
          delete this.authServ.subs[key];
          if (resp?.policyPrescriptions?.length) {
            resp.policyPrescriptions = resp.policyPrescriptions.map(it => {
              return {
                ...it,
                prescriptionFrom: it.prescriptionFrom ? new TDateD(new Date(it.prescriptionFrom)) : null,
                prescriptionTo: it.prescriptionTo ? new TDateD(new Date(it.prescriptionTo)) : null,
                dueDate: it.dueDate ? new TDateD(new Date(it.dueDate)) : null,
              };
            });
          }
          resolve(resp);
        },
        error: error => {
          delete this.authServ.subs[key];
          error.message = 'function: prescriptionsGet;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error));
        }
      });
    });
  }

  public paymentsBonusesGet(
    data: {
      policyId: string,
      offset: number,
      limit: number,
    },
    uid: TsXmlUid
  ): Promise<TsXmlParAccountChangeList> {
    const headers = this.getHttpHeader(uid);
    const params = this.getHttpParams3({ offset: data.offset, limit: data.limit });

    return new Promise((resolve, reject) => {
      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.get(
        `${u3RestPrefix}par/policies/${data.policyId}/payments/bonuses`,
        { headers, params }
      ).subscribe({
        next: resp => {
          delete this.authServ.subs[key];
          resolve(resp as TsXmlParAccountChangeList);
        },
        error: error => {
          delete this.authServ.subs[key];
          error.message = 'function: paymentsBonusesGet;' + error.message;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error));
        }
      });
    });
  }

  public policiesPolicyIdProcessesVns_0631(policyId: string, uid: TsXmlUid) {
    const headers = this.getHttpHeader(uid);

    return new Promise((resolve, reject) => {
      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.post(`${u3RestPrefix}policies/${policyId}/processes/VNS_0631`, null, { headers }).subscribe({
        next: resp => {
          delete this.authServ.subs[key];
          resolve(resp as TsXmlParCancelRequest);
        },
        error: error => {
          delete this.authServ.subs[key];
          error.message = `function: policiesPolicyIdProcessesVns_0631; policyId:${policyId}; ${error.message}`;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error));
        }
      });
    });
  }

  /**
   * @param policyId id of policy
   * @param flagId e.g. CEK_KM_STORNO
   * @param uid TsXmlUid
   * @returns TsXmlGeneralValue
   */
  public policiesFlagsFlagGet(policyId: string, flagId: string, uid: TsXmlUid) {
    const headers = this.getHttpHeader(uid);

    return new Promise((resolve, reject) => {
      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.get(`${u3RestPrefix}policies/${policyId}/flags/${flagId}`, { headers }).subscribe({
        next: resp => {
          delete this.authServ.subs[key];
          resolve(resp as TsXmlGeneralValue);
        },
        error: error => {
          delete this.authServ.subs[key];
          error.message = `function: policiesFlagsFlagGet; policyId:${policyId}; flagId:${flagId}; ${error.message}`;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 403, 404));
        }
      });
    });
  }

  public restPoliciesPolicyPolicyAgentRolesGet(policyId: string, uid: TsXmlUid): Promise<TsXmlPolicyAgentRoleList> {
    const headers = this.getHttpHeader(uid);
    return new Promise((resolve, reject) => {
      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.get(u3RestPrefix + `policies/${policyId}/policyAgentRoles`, { headers }).subscribe({
        next: resp => {
          delete this.authServ.subs[key];
          resolve(resp as TsXmlPolicyAgentRoleList);
        },
        error: error => {
          delete this.authServ.subs[key];
          error.message = `function: restPoliciesPolicyPolicyAgentRolesGet; policyId:${policyId}; ${error.message}`;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 403, 404));
        }
      });
    });
  }

  /*********************************************************************************************************/
  /* kontext par/onlinePayments *********************************************************************************/

  /**
   * Stav platby a navratove URL, kam sa ma prehliadac presmerovat.
   * HTTP 200 a GwtXmlThePay20Response - pokud je dle parametrů nalezena platba
   * HTTP 404 - pokud platba nalezena není
   * HTTP 429 - pokud je více dotazů  během minuty než je povoleno
   * @param parameters Object{ paymentUid: id platby, projectId: id projektu }
   * @returns Promise of TsXmlParThePay20Response
   */
  public parOnlinePaymentsChannelsTHEPAY20PaymentsStateGet(
    parameters: {
      paymentUid: string,
      projectId: string
    }
  ): Promise<TsXmlParThePay20Response> {
    const params = this.getHttpParams2(parameters);
    return new Promise((resolve, reject) => {
      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.get(`${u3RestPrefix}/par/onlinePayments/channels/THEPAY20/payments/state`, { params }).subscribe({
        next: resp => {
          delete this.authServ.subs[key];
          resolve(resp as TsXmlParThePay20Response);
        },
        error: error => {
          delete this.authServ.subs[key];
          error.message = `function: parOnlinePaymentsChannelsTHEPAY20PaymentsStateGet; paymentUid:${parameters.paymentUid}; projectId:${parameters.projectId}; ${error.message}`;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 404));
        }
      });
    });
  }

  public getPaymentMethods(uid: TsXmlUid): Promise<TsXmlParOnlinePaymentMethodList> {
    return new Promise((resolve, reject) => {
      const key = this.getSubsKey(this.authServ.subs);
      const headers = this.getHttpHeader(uid);
      this.authServ.subs[key] = this.http.get(`${u3RestV2Prefix}par/onlinePayments/channels/THEPAY20/methods`, { headers }).subscribe({
        next: resp => {
          delete this.authServ.subs[key];
          resolve(resp as TsXmlParOnlinePaymentMethodList);
        },
        error: error => {
          delete this.authServ.subs[key];
          error.message = `function: getPaymentMethods; ${error.message}`;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, 404));
        }
      });
    });
  }

  public setOnlinePayments(
    data: { clientAccountNr: string, amount: string, returnUrl: string, method: string },
    uid: TsXmlUid
  ): Promise<TsXmlOnlinePayment> {
    return new Promise((resolve, reject) => {
      const headers = this.getHttpHeader(uid);
      const params = this.getHttpParams3(data);
      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.post(`${u3RestPrefix}par/onlinePayments`, null, { headers, params }).subscribe({
        next: (resp) => {
          delete this.authServ.subs[key];
          resolve(resp as TsXmlOnlinePayment);
        },
        error: (e) => {
          delete this.authServ.subs[key];
          reject(e);
        }
      });
    });
  }


  /*********************************************************************************************************/
  /* kontext par/questions *********************************************************************************/

  /**
   * Get questions
   * @param uid restu uid
   * @param data search params
   * @return Observable as TsXmlTaskList
   */
  public _parQuestionsGet(
    uid: TsXmlUid,
    data: {
      limit?: number;
      offset?: number;
      unread?: boolean;
      definition?: string;
      currentState?: string;
      sortBy?: string[],
    }
  ): Observable<object> {
    const headers = this.getHttpHeader(uid);
    const params = this.getHttpParams3(data);

    const key = this.getSubsKey(this.authServ.subs);
    this.authServ.subs[key] = this.http.get(`${u3RestPrefix}par/questions`, { headers, params });
    return this.authServ.subs[key];
  }

  public parQuestionsGet(
    data: {
      limit?: number;
      offset?: number;
      lastPostFrom?: TDate,
      lastPostTo?: TDate,
      initiatorId?: string,
      initiatorAbbr?: string,
      initiatorInsuranceCompany?: string,
      unread?: boolean;
      definition?: string;
      currentState?: string[];
      sortBy?: string;
    },
    uid: TsXmlUid,
    subs?: { [key: string]: Subscription }
  ): Promise<TsXmlTaskList> {
    if (data.initiatorInsuranceCompany?.includes(','))
      data.initiatorInsuranceCompany = null;
    if (data.initiatorInsuranceCompany) data.initiatorInsuranceCompany = 'PILLOW_' + data.initiatorInsuranceCompany;
    const headers = this.getHttpHeader(uid);
    const params = this.getHttpParams3(data);

    return new Promise((resolve, reject) => {
      const key = this.getSubsKey(subs ? { ...this.authServ.subs, ...subs } : this.authServ.subs);
      this.authServ.subs[key] = this.http.get(`${u3RestPrefix}par/questions`, { headers, params }).subscribe({
        next: resp => {
          delete this.authServ.subs[key];
          if (subs) delete subs[key];
          resolve(resp as TsXmlTaskList);
        },
        error: error => {
          delete this.authServ.subs[key];
          if (subs) delete subs[key];
          reject(error);
        }
      });
      if (subs) subs[key] = this.authServ.subs[key];
    });
  }

  /**
   * Mark question as read
   * @param uid restu uid
   * @param objId task/question objId
   * @return Promise<void>
   */
  public parQuestionsReadPost(uid: TsXmlUid, objId: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const headers = this.getHttpHeader(uid);

      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.post(`${u3RestPrefix}par/questions/${objId}/read`, null, { headers }).subscribe({
        next: () => {
          delete this.authServ.subs[key];
          resolve();
        },
        error: (e) => {
          delete this.authServ.subs[key];
          reject(e);
        }
      });
    });
  }

  public voucherVoucherVoucher(voucherId: string, decisiveDate: TDate, uid: TsXmlUid): Promise<TsXmlParVoucherInfoForApp> {
    return new Promise((resolve, reject) => {
      const headers = this.getHttpHeader(uid);
      const params = this.getHttpParams3({ decisiveDate: decisiveDate.toJSON() });

      const key = this.getSubsKey(this.authServ.subs);
      this.authServ.subs[key] = this.http.get(
        `${u3RestPrefix}par/products/vouchers/${encodeURIComponent(voucherId)}/infoForApp`,
        { headers, params }
      ).subscribe({
        next: voucher => {
          delete this.authServ.subs[key];
          resolve(voucher as TsXmlParVoucherInfoForApp);
        },
        error: e => {
          delete this.authServ.subs[key];
          e.message = `function: voucherVoucherVoucher; voucherId: ${voucherId}; ${e.message}`;
          reject(this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(e, 404));
        }
      });
    });
  }

  /**
   * Remove first slash from string
   * @param str string
   * @return string without first slash
   */
  private _removeFirstSlash(str: string): string {
    if (str[0] === '/') {
      return str.replace('/', '');
    } else {
      return str;
    }
  }

  /**
   * Make HTTP parameters from params and values
   * This method ignores uid, endpoint, and arguments with null
   * @param params parameter names (dont use param names from func due to code obfuscation)
   * @param values parameter values
   * @return CustomHttpParams
   */

  private makeHttpParamsWith(params: string[], values: IArguments | any[], dateWithTime?: boolean): CustomHttpParams {
    let httpParams = new CustomHttpParams();
    for (const index in params) {
      if (params[index] !== 'uid' && params[index] !== 'endpoint' && !UtilsService.isNull(values[index])) {
        if (Array.isArray(values[index])) {
          for (const arg in values[index]) {
            if (values[index][arg] instanceof Date) {
              httpParams = httpParams.append(
                params[index],
                formatDate(values[index][arg], dateWithTime ? `yyyy-MM-dd'T'HH:mm:ss` : 'yyyy-MM-dd', 'en')
              );
            } else {
              httpParams = httpParams.append(params[index], values[index][arg].toString());
            }
          }
        } else {
          if (values[index] instanceof Date) {
            httpParams = httpParams.append(
              params[index],
              formatDate(values[index], dateWithTime ? `yyyy-MM-dd'T'HH:mm:ss` : 'yyyy-MM-dd', 'en')
            );
          } else {
            httpParams = httpParams.append(params[index], values[index].toString());
          }
        }
      }
    }

    return httpParams;
  }

  private getHttpHeader(uid: TsXmlUid, contentType?: string): HttpHeaders {
    const obj = {
      'Content-Type': contentType,
      RestUID: uid?.uid,
      'Accept-Language': this.appServ.locale.code
    };
    Object.keys(obj).forEach(key => {
      if (!obj[key]) delete obj[key];
    });
    return new HttpHeaders(obj);
  }

  private getHttpParams(params: { key: string, value: string | string[] }[]): CustomHttpParams {
    let httpParams = new CustomHttpParams();
    params.forEach(param => {
      if (typeof param.value === 'string') {
        httpParams = httpParams.append(param.key, param.value);
      } else if (param.value instanceof Array) {
        param.value.forEach(val => {
          httpParams = httpParams.append(param.key, val);
        });
      }
    });
    return httpParams;
  }

  private getHttpParams2(params: { [key: string]: string | string[] }[] | { [key: string]: string | string[] }): CustomHttpParams {
    if (!params) return;
    let httpParams = new CustomHttpParams();
    if (params instanceof Array) {
      params.forEach(param => {
        const key = Object.keys(param)[0];
        const value = Object.values(param)[0];
        if (typeof value === 'string') {
          httpParams = httpParams.append(key, value);
        } else if (value instanceof Date) {
          httpParams = httpParams.append(key, this.date2String(value));
        } else if (value instanceof Array) {
          value.forEach(val => {
            httpParams = httpParams.append(key, val);
          });
        }
      });
    } else {
      Object.entries(params).forEach(dt => {
        const key = dt[0];
        const value = dt[1];
        if (typeof value === 'string') {
          httpParams = httpParams.append(key, value);
        } else if (value instanceof Date) {
          httpParams = httpParams.append(key, this.date2String(value));
        } else if (value instanceof Array) {
          value.forEach(val => {
            httpParams = httpParams.append(key, val);
          });
        }
      });
    }
    return httpParams;
  }

  private getHttpParams3(params: { [key: string]: any }): CustomHttpParams {
    if (!params) return;
    let httpParams = new CustomHttpParams();
    Object.keys(params).forEach(key => {
      const value = params[key];
      if (value === null || value === undefined) return;
      if (value instanceof Array) {
        value.forEach(val => {
          httpParams = httpParams.append(key, this._getString(val));
        });
      } else {
        httpParams = httpParams.append(key, this._getString(value));
      }
    });
    return httpParams;
  }

  private _getString(value: any | unknown): string {
    if (typeof value === 'string') {
      return value;
    } else if (value instanceof Date) {
      return this.date2String(value, true);
    } else if (typeof value === 'number' || typeof value === 'boolean') {
      return String(value);
    }
  }

  private date2String(date: Date, dateWithTime?: boolean): string {
    return formatDate(date, dateWithTime ?
      `yyyy-MM-dd'T'HH:mm:ss` :
      'yyyy-MM-dd', 'en');
  }

  private getString(object: any) {
    switch (typeof object) {
      case 'string':
        return object;
      case 'number':
      case 'boolean':
        return object.toString();
      case 'object':
        if (object instanceof Date) {
          return this.date2String(object, true);
        } else if (object instanceof Array) {
          const output = [];
          for (const obj of object) {
            output.push(this.getString(obj));
          }
          return output;
        }
    }
  }

  private handleError(error: any, functionName: string, params?: any, ignores?: number[]) {
    error.message = `function: ${functionName}; ${JSON.stringify(params)};;; ${error.message}`;
    const ign = ignores ? ignores : [];
    return this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, ...ign);
  }

  private znumberuj(obj: object, keys: string[]) {
    Object.keys(obj).forEach(key => {
      if (obj[key]) {
        if (typeof obj[key] === 'string' && keys.includes(key))
          obj[key] = +obj[key];
        else if (typeof obj[key] === 'object')
          this.znumberuj(obj[key], keys);
      }
    });
  }

  private allDates2TDates(obj: object): any {
    Object.keys(obj).forEach(key => {
      if (!obj[key]) return;
      if (obj[key] instanceof Date) obj[key] = new TDate(obj[key]);
      else if (typeof obj[key] === 'object') this.allDates2TDates(obj[key]);
    });
    return obj;
  }

  public getSubsKey(subs: { [key: string]: Subscription }): string {
    if (!subs) return;
    let key = (Math.random() + 1).toString(36).replace('.', '');
    while (subs[key]) {
      key = (Math.random() + 1).toString(36).replace('.', '');
    }
    return key;
  }
}
