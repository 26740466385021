export class TsXmlParAddress {
  /** Stat. */
  country: string;
  /** Psc */
  zipCode: string;
  /** Obec. */
  city: string;
  /** Ulice. */
  street: string;
  /** Cislo popisne. */
  landRegistryNumber: string;
  /** Cislo orientacni. */
  houseNumber: string;

  constructor(address?: TsXmlParAddress) {
    this.country = address?.country;
    this.zipCode = address?.zipCode;
    this.city = address?.city;
    this.street = address?.street;
    this.landRegistryNumber = address?.landRegistryNumber;
    this.houseNumber = address?.houseNumber;
  }
}
