import { formatDate } from '@angular/common';

import { UtilsService } from '../services/utils.service';

import { TDate } from '../models/date.model';
import { TBigDecimal } from '../models/number.model';

export class TsXmlParAp1Vehicle {
  bodywork: string;
  brand: string;
  brandModelCode: string;
  clientsVehiclePrice: TBigDecimal;
  contractualService: boolean;
  contractualAuthorizedService: boolean;
  engine: string;
  enginePower: number;
  engineVolume: number;
  equipment: string;
  expectedKm: number;
  firstRegistrationDate: TDate;
  fuel: string;
  garaged: boolean;
  licensePlate: string;
  model: string;
  modelYear: string;
  noLicensePlate: boolean;
  noTechnicalCertificate: boolean;
  notRegistered: boolean;
  numberOfSeats: number;
  productionYear: TDate;
  technicalCertificateNumber: string;
  vehicleName: string;
  vehiclePrice: boolean;
  vehicleType: string;
  vehicleUse: string;
  vIN: string;
  weight: number;
  withoutVAT: boolean;
  x4x4: boolean;

  constructor(vehicle?: TsXmlParAp1Vehicle) {
    this.bodywork = vehicle?.bodywork;
    this.brand = vehicle?.brand;
    this.brandModelCode = vehicle?.brandModelCode;
    this.clientsVehiclePrice = vehicle?.clientsVehiclePrice;
    this.contractualService = vehicle?.contractualService;
    this.contractualAuthorizedService = vehicle?.contractualAuthorizedService;
    this.engine = vehicle?.engine;
    this.enginePower = vehicle?.enginePower;
    this.engineVolume = vehicle?.engineVolume;
    this.equipment = vehicle?.equipment;
    this.expectedKm = vehicle?.expectedKm;
    this.firstRegistrationDate = vehicle?.firstRegistrationDate ? new TDate(new Date(vehicle.firstRegistrationDate)) : vehicle?.firstRegistrationDate;
    this.fuel = vehicle?.fuel;
    this.garaged = vehicle?.garaged;
    this.licensePlate = vehicle?.licensePlate;
    this.model = vehicle?.model;
    this.modelYear = vehicle?.modelYear;
    this.noLicensePlate = vehicle?.noLicensePlate;
    this.noTechnicalCertificate = vehicle?.noTechnicalCertificate;
    this.notRegistered = vehicle?.notRegistered;
    this.numberOfSeats = vehicle?.numberOfSeats;
    this.productionYear = vehicle?.productionYear ? new TDate(new Date(vehicle.productionYear)) : vehicle?.productionYear;
    this.technicalCertificateNumber = vehicle?.technicalCertificateNumber;
    this.vehicleName = vehicle?.vehicleName;
    this.vehiclePrice = vehicle?.vehiclePrice;
    this.vehicleType = vehicle?.vehicleType;
    this.vehicleUse = vehicle?.vehicleUse;
    this.vIN = vehicle?.vIN;
    this.weight = vehicle?.weight;
    this.withoutVAT = vehicle?.withoutVAT;
    this.x4x4 = vehicle?.x4x4;
  }

  /**
   * Metoda prevede instanci do XML V2.
   *
   * @param attributes xml attributes.
   * @return String xml.
   */
  public toXmlV2(attributes: string): string {
    let result = '<xml-par-ap1-vehicle';
    if (attributes) {
      result += ' ' + attributes;
    }
    result += '>';

    if (this.vehicleType != null) {
      result += '<vehicleType>';
      result += UtilsService.xmlEscape(this.vehicleType);
      result += '</vehicleType>';
    }
    if (this.vehicleUse != null) {
      result += '<vehicleUse>';
      result += UtilsService.xmlEscape(this.vehicleUse);
      result += '</vehicleUse>';
    }
    if (this.brand != null) {
      result += '<brand>';
      result += UtilsService.xmlEscape(this.brand);
      result += '</brand>';
    }
    if (this.model != null) {
      result += '<model>';
      result += UtilsService.xmlEscape(this.model);
      result += '</model>';
    }
    if (this.modelYear != null) {
      result += '<modelYear>';
      result += UtilsService.xmlEscape(this.modelYear);
      result += '</modelYear>';
    }
    if (this.engine != null) {
      result += '<engine>';
      result += UtilsService.xmlEscape(this.engine);
      result += '</engine>';
    }
    if (this.bodywork != null) {
      result += '<bodywork>';
      result += UtilsService.xmlEscape(this.bodywork);
      result += '</bodywork>';
    }
    if (this.engineVolume != null) {
      result += '<engineVolume>';
      result += this.engineVolume;
      result += '</engineVolume>';
    }
    if (this.enginePower != null) {
      result += '<enginePower>';
      result += this.enginePower;
      result += '</enginePower>';
    }
    if (this.weight != null) {
      result += '<weight>';
      result += this.weight;
      result += '</weight>';
    }
    if (this.brandModelCode != null) {
      result += '<brandModelCode>';
      result += UtilsService.xmlEscape(this.brandModelCode);
      result += '</brandModelCode>';
    }
    if (this.fuel != null) {
      result += '<fuel>';
      result += UtilsService.xmlEscape(this.fuel);
      result += '</fuel>';
    }
    if (this.clientsVehiclePrice != null) {
      result += '<clientsVehiclePrice>';
      result += this.clientsVehiclePrice;
      result += '</clientsVehiclePrice>';
    }
    if (this.vehiclePrice != null) {
      result += '<vehiclePrice>';
      result += this.vehiclePrice;
      result += '</vehiclePrice>';
    }
    if (this.withoutVAT != null) {
      result += '<withoutVAT>';
      result += this.withoutVAT;
      result += '</withoutVAT>';
    }
    if (this.noLicensePlate != null) {
      result += '<noLicensePlate>';
      result += this.noLicensePlate;
      result += '</noLicensePlate>';
    }
    if (this.licensePlate != null) {
      result += '<licensePlate>';
      result += UtilsService.xmlEscape(this.licensePlate);
      result += '</licensePlate>';
    }
    if (this.vIN != null) {
      result += '<VIN>';
      result += UtilsService.xmlEscape(this.vIN);
      result += '</VIN>';
    }
    if (this.firstRegistrationDate != null) {
      result += '<firstRegistrationDate>';
      result += formatDate(this.firstRegistrationDate, 'yyyy-MM-dd', 'en');
      result += '</firstRegistrationDate>';
    }
    if (this.productionYear != null) {
      result += '<productionYear>';
      result += formatDate(this.productionYear, 'yyyy-MM-dd', 'en');
      result += '</productionYear>';
    }
    if (this.notRegistered != null) {
      result += '<notRegistered>';
      result += this.notRegistered;
      result += '</notRegistered>';
    }
    if (this.garaged != null) {
      result += '<garaged>';
      result += this.garaged;
      result += '</garaged>';
    }
    if (this.contractualService != null) {
      result += '<contractualService>';
      result += this.contractualService;
      result += '</contractualService>';
    }
    if (this.contractualAuthorizedService != null) {
      result += '<contractualAuthorizedService>';
      result += this.contractualAuthorizedService;
      result += '</contractualAuthorizedService>';
    }
    if (this.equipment != null) {
      result += '<equipment>';
      result += UtilsService.xmlEscape(this.equipment);
      result += '</equipment>';
    }
    if (this.x4x4 != null) {
      result += '<x4x4>';
      result += this.x4x4;
      result += '</x4x4>';
    }
    if (this.expectedKm != null) {
      result += '<expectedKm>';
      result += this.expectedKm;
      result += '</expectedKm>';
    }
    if (this.vehicleName != null) {
      result += '<vehicleName>';
      result += UtilsService.xmlEscape(this.vehicleName);
      result += '</vehicleName>';
    }
    if (this.numberOfSeats != null) {
      result += '<numberOfSeats>';
      result += this.numberOfSeats;
      result += '</numberOfSeats>';
    }
    if (this.noTechnicalCertificate != null) {
      result += '<noTechnicalCertificate>';
      result += this.noTechnicalCertificate;
      result += '</noTechnicalCertificate>';
    }
    if (this.technicalCertificateNumber != null) {
      result += '<technicalCertificateNumber>';
      result += UtilsService.xmlEscape(this.technicalCertificateNumber);
      result += '</technicalCertificateNumber>';
    }
    result += '</xml-par-ap1-vehicle>';

    return result;
  }
}
