import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogClose, MatDialogTitle, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { NgIf } from '@angular/common';
import { CdkScrollable } from '@angular/cdk/scrolling';

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss'],
    imports: [MatDialogClose, NgIf, MatDialogTitle, CdkScrollable, MatDialogContent, MatDialogActions]
})
export class ConfirmDialogComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {
      message: string,
      okBuText: string,
      cancelBuText: string,
      icon: string
      header?: string,
    }
  ) { }

  protected onGetText(str: string, ind: 0 | 1) : string{
    if (str) return str;
    if (ind) return $localize`Ponechat`;
    if (ind === 0) return $localize`Odstranit`;
    throw new Error(`onGetText wrong input: str=${str}, ind=${ind}`);
  }

}
