<header [ngClass]="{ mini: mini }">
    <div class="banner" [ngClass]="{ hidden: bannerHidden }">
        <div></div>
        <div class="phone-col">
            <span i18n>Sleva 20 % na celé pojištění právě teď. </span>
            <span
                class="banner__link"
                i18n
                (click)="onGo2SpecialOfferLink()"
                tabindex="0"
                (keydown.enter)="onGo2SpecialOfferLink()">
                Nezávazná kalkulace zde
            </span>
        </div>
        <i (click)="onHideBanner()" tabindex="0" (keydown.enter)="onHideBanner()">
            <span class="icon-krizek pilic-20 pilic"> </span>
        </i>
    </div>
    <div class="line2">
        <i class="pilic icon-logo-full pilic-56" (click)="onGoHome()" tabindex="0" (keydown.enter)="onGoHome()"></i>
        <i class="pilic icon-logo-icon pilic-56" (click)="onGoHome()" tabindex="0" (keydown.enter)="onGoHome()"></i>
        <div>
            <i
                *ngIf="cartVisible"
                class="pilic icon-kosik pilic-24"
                (click)="onGoCalcs()"
                tabindex="0"
                (keydown.enter)="onGoCalcs()"></i>
            <div>
                <div *ngIf="userType > 0">
                    <i class="no-phone pilic pilic-24 {{ userType > 1 ? 'icon-poradce' : 'icon-login' }}"></i>
                    <span
                        class="no-phone"
                        mat-button
                        #menuTrigger="matMenuTrigger"
                        [matMenuTriggerFor]="menu"
                        tabindex="0"
                        (keydown.enter)="toggleMenuDelayed(menuTrigger)">
                        {{ onGetUserName() }}
                    </span>
                </div>
                <div *ngIf="userType === 0" class="u-underline-anim">
                    <i class="no-phone pilic pilic-24 {{ userType > 1 ? 'icon-poradce' : 'icon-login' }}"></i>
                    <i
                        class="on-phone pilic pilic-24 icon-login focusable"
                        (click)="onGo2('/login')"
                        tabindex="0"
                        (keydown.enter)="onGo2('/login')"></i>
                    <span
                        class="no-phone"
                        (click)="onGo2('/login')"
                        i18n
                        tabindex="0"
                        (keydown.enter)="onGo2('/login')">
                        Přihlásit se
                    </span>
                </div>

                <span
                    class="icon-language icon-language-{{ lang }}-bold pilic pilic-24"
                    mat-button
                    #menuTrigger="matMenuTrigger"
                    [matMenuTriggerFor]="menuLang"
                    tabindex="0"
                    (keydown.enter)="toggleMenuDelayed(menuTrigger)"
                    (menuOpened)="menuOpened(menuTrigger)"></span>
                <mat-icon
                    *ngIf="userType > 0"
                    #menuTrigger="matMenuTrigger"
                    [matMenuTriggerFor]="menuTheme"
                    tabindex="0"
                    (keydown.enter)="menuTrigger.toggleMenu()"
                    (menuOpened)="menuOpened(menuTrigger)"
                    >format_color_fill</mat-icon
                >
                <mat-icon
                    *ngIf="userType === 0"
                    (click)="onToggleTheme()"
                    tabindex="0"
                    (keydown.enter)="onToggleTheme()"
                    >{{ themeIcon }}</mat-icon
                >

                <span
                    *ngIf="userType > 0"
                    class="pilic pilic-11 icon-angle-down on-phone"
                    mat-button
                    #menuTrigger="matMenuTrigger"
                    [matMenuTriggerFor]="menu"></span>

                <div
                    class="on-phone menu-container"
                    #mn
                    (menuOpened)="onMenuClick()"
                    (menuClosed)="onMenuClick()"
                    mat-button
                    #menuTrigger="matMenuTrigger"
                    [matMenuTriggerFor]="menuLink">
                    <div class="bar1"></div>
                    <div class="bar2"></div>
                    <div class="bar3"></div>
                    <div class="bar4"></div>
                </div>
                <span class="menu-trigger" mat-button #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu"> </span>
            </div>
        </div>
    </div>
    <div class="links no-phone">
        @if (simplified) {
            <div class="simpl">
                <div class="tel">
                    <span i18n>Pojištění po telefonu</span>
                    @if (envLang === "cs") {
                        <a href="tel:+420777657827">+420 777 657 827</a>
                    } @else {
                        <a href="tel:+421522902902">+421 522 902 902</a>
                    }
                </div>
                <span i18n>(pracovní dny od 8:00 do 17:30)</span>
            </div>
        } @else {
            <a
                *ngFor="let link of onGetLinks()"
                (click)="onSetSelectedPage(link.link)"
                class="u-underline-anim"
                [ngClass]="{ active: link.link === selectedPage }"
                [routerLink]="link.link">
                <span *ngIf="link.icon" class="{{ link.icon }} pilic pilic-25 down"></span>
                <span class="font-roboto">{{ link.text }}</span>
            </a>
        }
    </div>
</header>

<mat-menu #menu="matMenu" xPosition="before" class="menu">
    <div
        (keydown.tab)="$event.stopPropagation()"
        (keydown.shift.tab)="$event.stopPropagation()"
        (keydown.shift)="$event.stopPropagation()"
        tabindex="-1">
        <button mat-menu-item routerLink="/user-info" i18n>Osobní údaje</button>
        <button mat-menu-item (click)="logout()">
            <span i18n>Odhlásit</span>
        </button>
    </div>
</mat-menu>

<mat-menu #menuLang="matMenu" xPosition="before" class="menu">
    <div
        *ngFor="let locale of languageList"
        mat-menu-item
        class="lang-bu-cont"
        tabindex="-1"
        (click)="changeLocale(locale.code)"
        (keydown.enter)="changeLocale(locale.code)"
        (keydown.tab)="$event.stopPropagation()"
        (keydown.shift.tab)="$event.stopPropagation()"
        (keydown.shift)="$event.stopPropagation()">
        <div class="lang-bu">
            <i [class]="locale.iconClass"></i>
            <span>{{ locale.label }}</span>
        </div>
    </div>
</mat-menu>

<mat-menu #menuLink="matMenu" xPosition="before" class="menu">
    <div
        (keydown.tab)="$event.stopPropagation()"
        (keydown.shift.tab)="$event.stopPropagation()"
        (keydown.shift)="$event.stopPropagation()"
        tabindex="-1"
        class="lang-bu-cont">
        <div
            *ngFor="let link of onGetLinks()"
            class="lang-bu"
            (click)="onGo2(link.link)"
            tabindex="0"
            (keydown.enter)="onGo2(link.link)">
            <i *ngIf="link.icon" class="{{ link.icon }} pilic pilic-25 down"></i>
            <span>{{ link.text }}</span>
        </div>
    </div>
</mat-menu>

<mat-menu #menuTheme="matMenu" xPosition="before" class="menu">
    <div
        class="theme-bb"
        (keydown.tab)="$event.stopPropagation()"
        (keydown.shift.tab)="$event.stopPropagation()"
        (keydown.shift)="$event.stopPropagation()"
        tabindex="-1">
        <div class="boxes">
            <div class="u-button">
                <div
                    class="box_1"
                    [ngClass]="{ selected: bg === 'light' }"
                    (click)="onSetBg($event, 'light')"
                    tabindex="0"
                    (keydown.enter)="onSetBg($event, 'light')"></div>
            </div>
            <div class="u-button">
                <div
                    class="box_2"
                    [ngClass]="{ selected: bg === 'dark' }"
                    (click)="onSetBg($event, 'dark')"
                    tabindex="0"
                    (keydown.enter)="onSetBg($event, 'dark')"></div>
            </div>
            <!-- <div class="u-button">
                <div class="box_3" [ngClass]="{'selected': bg === 'darker'}" (click)="onSetBg($event, 'darker')"></div>
            </div> -->
        </div>
        <div class="theme-bu-cont">
            <div class="u-button" *ngFor="let theme of themes">
                <div
                    class="theme-bu"
                    [ngClass]="{ selected: cl === theme.class }"
                    tabindex="0"
                    (keydown.enter)="onSetTheme($event, theme.class)"
                    (click)="onSetTheme($event, theme.class)"
                    [style]="onGetThemeSwitcherStyle(theme)"></div>
            </div>
        </div>
    </div>
</mat-menu>
