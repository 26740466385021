import { TDate } from '../models/date.model';
import { TsXmlParVoucher } from './tsXmlParVoucher.model';
import { TsXmlParPartyRole } from './tsXmlParPartyRole.model';
import { TsXmlParRp1Person } from './tsXmlParRp1Person.model';
import { TsXmlParAp1Vehicle } from './tsXmlParAp1Vehicle.model';
import { TsXmlParAp2Vehicle } from './tsXmlParAp2Vehicle.model';
import { TsXmlParPolicyIssue } from './tsXmlParPolicyIssue.model';
import { TsXmlParPm1Property } from './tsXmlParPm1Property.model';
import { TsXmlParPolicyPillow } from './tsXmlParPolicyPillow.model';
import { TsXmlParInsuranceRisk } from './tsXmlParInsuranceRisk.model';
import { TsXmlParAuthorizedPerson } from './tsXmlParAuthorizedPerson.model';

/**
 * DTO represent policy
 */
export class TsXmlParPolicy {
  /** PojistnaSmlouva.objId */
  objId: number;
  /** identifikace (číslo) pojistné smlouvy */
  id: string;
  /** Stav smlouvy - PojistnaSmlouva.stav (písmena z domatypu TStavSmlouvy) */
  state: string;
  /** uživatelský název smlouvy resp. objektu pro zobrazení eq. "Žihadlo" v zadání. */
  userDescription: string;
  /** identifikace objektu; Značka + SPZ nebo jen značka u auta, adresa u nemovitosti, název pojištěného u života etc.; pro zobrazení */
  objectId: string;
  /** měsíční pojistné */
  premium: number;
  /** lhůtní pojistné */
  termPremium: number;
  /** Výčet BANK, SINK; TStr20Null */
  paymentMethod: 'BANK' | 'SINK';
  /** Frekvence placení (JCBH 33 - identifikatorZaznamu) */
  frequency: string;
  /** datum počátku smlouvy */
  begin: TDate;
  /** datum konce smlouvy */
  end: TDate;
  /** datum účinnosti storna */
  cancelEffectDate: TDate;
  /** do kdy je smlouva zaplacena */
  paidUntil: TDate;
  /** seznam rolí na smlouvě */
  parties: TsXmlParPartyRole[];
  /** seznam informací o rizicích smlouvy resp. všech přípustných rizicích. Tj. obsahuje jak sjednaná tak možná rizika */
  risks: TsXmlParInsuranceRisk[];
  /** Seznam oprávněných / obmyšlených osob; GwtXmlParAuthorizedPerson[] */
  authorizedPerson: TsXmlParAuthorizedPerson[];
  /** informace o vozidle smlouvy */
  vehicle: TsXmlParAp1Vehicle | TsXmlParAp2Vehicle;
  /** informace o pojistene osobe (plni sa v pripade produktu RP1, jinak null); GwtXmlParRp1Person */
  person: TsXmlParRp1Person;
  /** seznam chyb na smlouvě např. chybí SPZ, fotky, nezaplaceno v odpovídajících úrovních dle zadání */
  issues: TsXmlParPolicyIssue[];
  /** poznámka; TStr3600Null */
  note: string;
  /** seznam informací o polštářích smlouvy resp. všech přípustných polštářích. Tj. obsahuje jak sjednaná tak možná rizika/polštáře */
  pillows: TsXmlParPolicyPillow[];
  /** údaje pojištěného místa (plní se v případě PM1 jinak null) */
  property: TsXmlParPm1Property;
  voucher?: TsXmlParVoucher;

  constructor(policy?: TsXmlParPolicy) {
    this.objId = policy?.objId;
    this.id = policy?.id;
    this.state = policy?.state;
    this.userDescription = policy?.userDescription;
    this.objectId = policy?.objectId;
    this.premium = policy?.premium;
    this.termPremium = policy?.termPremium;
    this.paymentMethod = policy?.paymentMethod;
    this.frequency = policy?.frequency;
    this.begin = policy?.begin ? new TDate(new Date(policy?.begin)) : policy?.begin;
    this.end = policy?.end ? new TDate(new Date(policy?.end)) : policy?.begin;
    this.cancelEffectDate = policy?.cancelEffectDate ? new TDate(new Date(policy?.cancelEffectDate)) : policy?.begin;
    this.paidUntil = policy?.paidUntil ? new TDate(new Date(policy?.paidUntil)) : policy?.begin;
    this.parties = policy?.parties?.length ? policy.parties.map(it => new TsXmlParPartyRole(it)) : policy?.parties;
    this.risks = policy?.risks?.length ? policy.risks.map(it => new TsXmlParInsuranceRisk(it)) : policy?.risks;
    this.authorizedPerson = policy?.authorizedPerson?.length ? policy.authorizedPerson.map(it => new TsXmlParAuthorizedPerson(it)) : policy?.authorizedPerson;
    this.vehicle = policy?.vehicle ? (policy.risks[0].id.endsWith('1') ? new TsXmlParAp1Vehicle(policy.vehicle as TsXmlParAp1Vehicle) : new TsXmlParAp2Vehicle(policy.vehicle as TsXmlParAp2Vehicle)) : policy?.vehicle;
    this.person = policy?.person;
    this.issues = policy?.issues;
    this.note = policy?.note;
    this.pillows = policy?.pillows;
    this.property = policy?.property;
    this.voucher = policy?.voucher;
  }
}
