import { UtilsService } from '../services/utils.service';

export class TsXmlParAp2PrintVariantOfSubjectReq {
  /** Atribut Risk. */
  risk: string;
  /** Atribut Variant. */
  variant: string;

  public fromXmlV2?(model: TsXmlParAp2PrintVariantOfSubjectReq) {
    this.risk = unescape(model.risk);
    this.variant = unescape(model.variant);
  }

  /**
   * Metoda prevede instanci do XML V2.
   *
   * @param attributes xml attributes.
   * @return String xml.
   */
  public toXmlV2?(attributes: string): string {
    let result = '<xml-par-ap2-print-variant-of-subject-req';
    if (attributes) {
      result += ' ' + attributes;
    }
    result += '>';

    if (this.risk != null) {
      result += '<risk>';
      result += UtilsService.xmlEscape(this.risk);
      result += '</risk>';
    }
    if (this.variant != null) {
      result += '<variant>';
      result += UtilsService.xmlEscape(this.variant);
      result += '</variant>';
    }

    result += '</xml-par-ap2-print-variant-of-subject-req>';

    return result;
  }
}