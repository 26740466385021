<div class="header">
    <i class="icon-krizek pilic dialog-close" (click)="_htmlFunCloseDialog()" tabindex="0"
        (keydown.enter)="_htmlFunCloseDialog()"></i>
    <h1 *ngIf="data.header" mat-dialog-title>{{ data.header }}</h1>
</div>
<div class="cont">
    <p *ngIf="data.message" class="message">{{ data.message }}</p>

    <div class="selects-container">
        <div class="selects-sub-container">
            <div class="u-btn" *ngFor="let item of data.selects; let i = index">
                <button [mat-dialog-close]="i">
                    {{ item }}
                </button>
            </div>
        </div>
    </div>
</div>