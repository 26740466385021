import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { first } from 'rxjs/operators';

import { AppService } from './app.service';

import { TooltipDialogComponent } from '../modules/dialog/tooltip/tooltip.component';
import { DialogSelectComponent } from '../modules/dialog/dialog-select/dialog-select.component';
import { ConfirmDialogComponent } from '../modules/dialog/confirm-dialog/confirm-dialog.component';
import { WarningDialogComponent } from '../modules/dialog/warning-dialog/warning-dialog.component';
import { BehaviorSubject } from 'rxjs';
import { DialogDetailComponent } from '../modules/dialog/dialog-detail/dialog-detail.component';


@Injectable({
  providedIn: 'root'
})
/** you have to have OuhaService in providers */
export class OuhaService {

  constructor(
    private appServ: AppService,
    private matDialog: MatDialog,
  ) { }

  public ouha(data?: { header?: string, message?: string, button?: string }, _width?: string): Promise<void> {
    this.appServ.stopLoading();
    return new Promise<void>((resolve) => {
      if (this.matDialog.openDialogs?.length) this.matDialog.closeAll();
      const _ref = this.matDialog.open(WarningDialogComponent, {
        maxWidth: _width || '450px',
        data: {
          header: typeof data?.header === 'string' ? data.header : $localize`Ouha`,
          message: typeof data?.message === 'string' ? data.message : $localize`Něco se pokazilo.`,
          button: typeof data?.button === 'string' ? data.button : 'OK',
        }
      });
      _ref.afterClosed().pipe(first()).subscribe(() => {
        resolve();
      });
    });
  }

  public getSelectDialogResponse(header: string, message: string, selects: string[], width?: number): Promise<number> {
    this.appServ.stopLoading();
    if (selects.length === 2) {
      const dialog = this.matDialog.open(ConfirmDialogComponent, {
        data: {
          okBuText: selects[1],
          cancelBuText: selects[0],
          header,
          message,
          selects,
        },
        maxWidth: width || 490,
      });
      return new Promise(resolve => {
        dialog.afterClosed().pipe(first()).subscribe((condition) => {
          if (condition === 'true') {
            resolve(1);
          } else if (condition === 'false') {
            resolve(0);
          }
        });
      });
    } else {
      const dialog = this.matDialog.open(DialogSelectComponent, {
        data: {
          header,
          message,
          selects,
        },
        maxWidth: width || 450,
      });
      return new Promise(resolve => {
        dialog.afterClosed().pipe(first()).subscribe((condition) => {
          resolve(condition);
        });
      });
    }
  }

  public tooltip(data: {
    icon?: string,
    headline?: string,
    text?: string | (string | { text: string, link: string })[],
    img?: string,
    imgBottom?: string,
    hued?: boolean,
    lines?: (string | (string | { text: string, link: string })[])[],
  }) {
    this.appServ.stopLoading();
    this.matDialog.open(TooltipDialogComponent, {
      data
    });
  }

  public showSendAgentYouKnowWhereDialog() {
    this.ouha({
      header: $localize`Informace`,
      message: $localize`Smlouvu nelze změnit. Je nám líto, ale klient se ještě neregistroval do klientského portálu a Vámi navrženou změnu by tak nemohl schválit.`,
      button: $localize`Rozumím`
    });
  }

  public multimediaDialog(
    data: {
      blob: BehaviorSubject<Blob> | Blob,
      fileName?: string,
      video?: {
        videoId: string,
        width: number,
        height: number
      }
    },
    width?: string,
    maxWidth?: string
  ) {
    return new Promise(resolve => {
      const dialog = this.matDialog.open(DialogDetailComponent, {
        data,
        width,
        maxWidth,
      });
      dialog.afterClosed().pipe(first()).subscribe((condition) => {
        resolve(condition);
      });
    });
  }

}
