import { TsXmlParParty } from './tsXmlParParty.model';

/**
 * DTO represent party kind
 */
export class TsXmlParPartyRole {
  /** Druh role */
  kind: string;
  /** Strana */
  party: TsXmlParParty;
  /** Pořadí role v rámci smlouvy. Nabývá hodnot 0..N a je v rámci rolí na smlouvě unikátní; int */
  partyRoleOrder: number;

  constructor(role?: TsXmlParPartyRole) {
    this.kind = role?.kind;
    this.party = new TsXmlParParty(role?.party);
    this.partyRoleOrder = role?.partyRoleOrder;
  }
}
