<h2 i18n>Vozidlo</h2>
<ul *ngIf="vehicleForm && options" class="u-input-menu">
    <ng-container *ngFor="let dt of _htmlData">

        <li *ngIf="visibilities[dt.name]" class="menu-item">

            <app-tooltip [tooltip]="dt.name && dt.name !== 'clientsVehiclePrice' ? tooltips[dt.name] : null">
                <label>{{dt.title}}</label>
            </app-tooltip>

            <div class="cont">
                <mat-form-field *ngIf="dt.type === 'select'" class="full_width" appearance="fill"
                    subscriptSizing="dynamic" [ngClass]="{'form-group--error': _htmlFunIsFCInvalid(dt.name)}">
                    <mat-select disableOptionCentering [formControl]="dt.name === 'spz' ? spz : fgModel[dt.name]"
                        (blur)="onBlur(dt.name)" #item required>
                        <mat-option *ngFor="let val of options[dt.name]" [value]="val.value">
                            {{val.text}}
                        </mat-option>
                    </mat-select>
                    <mat-error *ngIf="_htmlFunIsFCInvalid(dt.name)">
                        <ng-container *ngTemplateOutlet="errors; context: { aa: dt }"></ng-container>
                    </mat-error>
                </mat-form-field>

                <mat-form-field *ngIf="dt.type === 'input'" subscriptSizing="dynamic"
                    [ngClass]="{'form-group--error': _htmlFunIsFCInvalid(dt.name)}">
                    <input matInput #item required [formControl]="fgModel[dt.name]" [name]="dt.name"
                        (blur)="onBlur(dt.name)">
                    <mat-error *ngIf="_htmlFunIsFCInvalid(dt.name)">
                        <ng-container *ngTemplateOutlet="errors; context: { aa: dt }"></ng-container>
                    </mat-error>
                </mat-form-field>

                <mat-form-field *ngIf="dt.type === 'number' && isElectro !== undefined" subscriptSizing="dynamic"
                    [ngClass]="{'form-group--error': _htmlFunIsFCInvalid(dt.name), 'reduced': dt.name === 'clientsVehiclePrice'}">
                    <app-num-input #item required [unit]="dt.extras.unit" [unitSub]="dt.extras.unitSub"
                        [formControl]="fgModel[dt.name]" (blurEvent)="onBlur(dt.name)"
                        [numOfDecimals]="dt.name === 'enginePower' ? (isElectro ? 2 : 0) : 0">
                    </app-num-input>
                    <mat-error *ngIf="_htmlFunIsFCInvalid(dt.name)">
                        <ng-container *ngTemplateOutlet="errors; context: { aa: dt }"></ng-container>
                    </mat-error>
                </mat-form-field>

                <mat-form-field *ngIf="dt.type === 'date'"
                    [ngClass]="{'form-group--error': _htmlFunIsFCInvalid(dt.name)}" subscriptSizing="dynamic">
                    <input matInput [matDatepicker]="item" autocomplete="off" [min]="minDate" [max]="maxDate"
                        [formControl]="fgModel[dt.name]" [name]="dt.name" required
                        (dateChange)="onGetPriceOrSmth($event.value)" (click)="item.open()">
                    <mat-datepicker #item></mat-datepicker>
                    <mat-datepicker-toggle matSuffix [for]="item">
                        <img matDatepickerToggleIcon class="u-dt-icon" src="assets/img/input-list.png" alt="">
                    </mat-datepicker-toggle>
                    <mat-error *ngIf="_htmlFunIsFCInvalid(dt.name)">
                        <ng-container *ngTemplateOutlet="errors; context: { aa: dt }"></ng-container>
                    </mat-error>
                </mat-form-field>

                <div *ngIf="dt.type === 'radio'" class="form-group"
                    [ngClass]="{'form-group--error': _htmlFunIsFCInvalid(dt.name)}">
                    <mat-radio-group class="check form-control-nc" #item [formControl]="fgModel[dt.name]"
                        (blur)="onBlur(dt.name)" required>
                        <mat-radio-button *ngFor="let radio of dt.extras" [value]="radio.value">
                            {{radio.title}}
                        </mat-radio-button>
                    </mat-radio-group>
                    <div class="warning" *ngIf="_htmlFunIsFCInvalid(dt.name)">
                        <ng-container *ngTemplateOutlet="errors; context: { aa: dt }"></ng-container>
                    </div>
                </div>

                <div *ngIf="dt.name === 'clientsVehiclePrice'" class="u-button-shadowless suffix">
                    <u i18n (click)="onShowTooltip(dt.name)" tabindex="0" (keydown.enter)="onShowTooltip(dt.name)">
                        Jak určit?
                    </u>
                </div>
            </div>
        </li>
    </ng-container>
    <li *ngIf="spz.value || !hideAllAfterSPZ" class="menu-item">
        <app-tooltip>
            <label (click)="radio.focus()" tabindex="-1" (keydown.enter)="radio.focus()">
                {{sameAsUserText}}
            </label>
        </app-tooltip>
        <div class="form-group">
            <mat-radio-group class="check form-control-nc" [formControl]="sameAsUser" required #radio>
                <mat-radio-button [value]="true" i18n>shodný s klientem</mat-radio-button>
                <mat-radio-button [value]="false" i18n>jiný</mat-radio-button>
            </mat-radio-group>
        </div>
    </li>
</ul>

<div class="u-btn u-bu-bigger" *ngIf="buVisible">
    <button (click)="onFinishHim()" i18n>
        Spočítat
    </button>
</div>

<ng-template #errors let-person="aa">
    <ng-container *ngIf="!vehicleForm.get(person.name).errors?.message &&
                !vehicleForm.get(person.name).errors?.matDatepickerParse &&
                person.name !== 'phone' &&
                vehicleForm.get(person.name).errors?.required" i18n>
        Údaj {{person.title}} musí být vyplněn.
    </ng-container>
    <ng-container *ngIf="vehicleForm.get(person.name).errors?.message">
        {{vehicleForm.get(person.name).errors.message}}
    </ng-container>
    <ng-container *ngIf="vehicleForm.get(person.name).errors?.matDatepickerParse" i18n>
        Chybně vyplněné datum. Prosíme o opravu.
    </ng-container>
</ng-template>