import { Injectable } from '@angular/core';

import { Ap1Service } from '../../ap1.service';
import { RestService } from 'src/app/services/rest.service';
import { AuthenticationService } from 'src/app/services/authentication.service';

import { TsXmlCodelistEntry } from 'src/app/tsXml/tsXmlCodelistEntry.model';


@Injectable()
export class OtherService {

  constructor(
    private restServ: RestService,
    private authServ: AuthenticationService,
    private ap1Service: Ap1Service,
  ) { }

  private _frequencies: { text: string; id: '1' | '2' | '4' | '12'; }[];
  private _marketingActions: { text: string; id: string; }[];
  private _contractualService: boolean;
  private _paymentFrequency: '1' | '2' | '4' | '12';
  private _discountId: string;
  private _marketingActionsEntries: TsXmlCodelistEntry[];
  private _agentId: string;

  public get contractualService(): boolean {
    return this._contractualService;
  }
  public set contractualService(value: boolean) {
    this._contractualService = value;
  }

  public get paymentFrequency(): '1' | '2' | '4' | '12' {
    return this._paymentFrequency;
  }
  public set paymentFrequency(value: '1' | '2' | '4' | '12') {
    this._paymentFrequency = value;
  }

  public get discountId(): string {
    return this._discountId;
  }
  public set discountId(value: string) {
    this._discountId = value;
  }

  public get frequencies(): { text: string; id: '1' | '2' | '4' | '12'; }[] {
    return this._frequencies;
  }

  public get marketingActions(): { text: string; id: string; }[] {
    return this._marketingActions;
  }

  public get marketingActionsEntries(): TsXmlCodelistEntry[] {
    return this._marketingActionsEntries;
  }

  public get agentId(): string {
    return this._agentId;
  }
  public set agentId(value: string) {
    this._agentId = value;
  }

  public async prepareOtherData(agentId1: string): Promise<void> {
    const uid = this.authServ.currentUserUidValue;
    const columnCode = [
      { code: 'AGENT', value: agentId1 }
    ];
    this._frequencies = [];
    if (!this._marketingActions) this._marketingActions = [];
    await Promise.all([
      this.restServ.parCodelistsEntriesGet('5071', uid).then((entries) => {
        const output = [];
        if (entries?.entry?.length) {
          ['12', '4', '2', '1'].forEach((id: '1' | '2' | '4' | '12') => {
            const entry = entries.entry.find(ent => ent.id === id);
            const text = entry.gclColumn.find(clm => clm.columnCode === 'TISK_LOK').stringValue;
            this._frequencies.push({ text, id });
            output.push(entry);
          });
        }
        this.ap1Service.paymentFrequencies = output;
      }),
      this.restServ.myParCodelistsEntriesByColumnsPost('5119', columnCode, uid).then(entries => {
        this._marketingActionsEntries = entries?.entry;
        this._marketingActions = entries?.entry?.map(ent => {
          return {
            text: ent.gclColumn.find(clm => clm.columnCode === 'KOD_SLEVY').stringValue,
            id: ent.id
          };
        });
      }),
    ]);
  }

  public emptyMe() {
    this._frequencies = null;
    this._marketingActions = null;
    this._contractualService = null;
    this._paymentFrequency = null;
    this._discountId = null;
    this._agentId = null;
  }

}
