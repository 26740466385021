import { TsXmlAuthorizationInstrument } from "./tsXmlAuthorizationInstrument.model";

/**
 *
 * GwtXmlAuthorizationInstrumentList (AIS.Core / C0A81465-0BB8-0007-00000173944B1436-7A736B72646C6F76) 28.7.2020
 *
*/
export class TsXmlAuthorizationInstrumentList {
  authorizationInstruments?: TsXmlAuthorizationInstrument[];

  constructor(it?: TsXmlAuthorizationInstrumentList) {
    this.authorizationInstruments = it?.authorizationInstruments?.map(item => {
      return new TsXmlAuthorizationInstrument(item);
    });
  }
}
