import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class Step3Service {
  private _hideGreenCardPrint: boolean;
  private _vehicleUse: string;

  public get vehicleUse(): string {
    return this._vehicleUse;
  }
  public set vehicleUse(value: string) {
    this._vehicleUse = value;
  }

  public get hideGreenCardPrint(): boolean {
    return this._hideGreenCardPrint;
  }

  public set hideGreenCardPrint(value: boolean) {
    this._hideGreenCardPrint = value;
  }

  public emptyMe(): void {
    this._hideGreenCardPrint = null;
    this._vehicleUse = null;
  }
}
