<ng-container *ngIf="initialized">
  <div class="gradient-top">
    <img src="../../../assets/img/content-404.png" alt="Pillow" />
  </div>

  <div class="box">
    <h2 i18n>Stránka nenalezena</h2>
    <p i18n>
      Ať hledáme, jak hledáme, stránka tu prostě není. Zkuste se vrátit na&nbsp;úvod a&nbsp;ještě jednou najít, co jste
      potřebovali.
    </p>
    <div class="u-btn u-bu-bigger">
      <a routerLink="" i18n>Úvodní stránka</a>
    </div>
  </div>
</ng-container>