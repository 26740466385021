import { TDate } from '../models/date.model';
import { TsXmlDocumentWithContentList } from './tsXmlDocumentWithContentList.model';

export class TsXmlParVehicleInspection {
  partyId: string;
  policyId: string;
  licensePlate: string;
  odometerReading: number;
  location: string;
  vehicleType: string;
  date: TDate;
  documents: TsXmlDocumentWithContentList;
}
