import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { NgClass, NgIf, NgFor } from '@angular/common';

import { MatIcon } from '@angular/material/icon';
import { MatMenuTrigger, MatMenu, MatMenuItem } from '@angular/material/menu';

import { BehaviorSubject, Subscription } from 'rxjs';

import { UtilsService } from 'src/app/services/utils.service';
import { StorageService } from 'src/app/services/storage.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { AppService, LOCALE_CS, LOCALE_EN, LOCALE_SK } from 'src/app/services/app.service';

import { Locale } from 'src/app/models/locale.model';

import { _themes } from './themes';
import { environment } from 'src/environments/environment';


interface LINKS {
  unauth: { link: string, icon: string, text: string }[];
  auth: { link: string, icon: string, text: string }[];
  agent: { link: string, icon: string, text: string }[];
  kliserv: { link: string, icon: string, text: string }[];
}

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    imports: [NgClass, NgIf, MatMenuTrigger, MatIcon, NgFor, RouterLink, MatMenu, MatMenuItem]
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() amISmall: BehaviorSubject<boolean>;
  @ViewChild('mn', { static: false }) mn: ElementRef;


  private links: LINKS;
  private subs: Subscription[];
  private clObj: { [key: string]: string };

  protected languageList: Locale[] = [];
  protected cartVisible: boolean;
  protected lang: string;
  protected bannerHidden: boolean;
  protected selectedPage: string;
  protected mini: boolean;
  protected themeIcon: string;
  protected themes: { color: string, background: string, class: string }[];
  protected userType: number;
  protected bg: string;
  protected cl: string;
  protected simplified: boolean;
  protected envLang: string;


  constructor(
    private appServ: AppService,
    private authServ: AuthenticationService,
    private router: Router,
    private storageServ: StorageService,
  ) { }

  ngOnInit(): void {
    this.initialize();
    this.setSubs();
  }

  private initialize() {
    this.envLang = environment.language;
    this.clObj = {
      light: 'white',
      dark: '#303030',
      darker: 'black'
    };
    this.loadTheme();
    this.themes = _themes as any;
    this.subs = [];
    this.userType = this.authServ.currentUserValue?.userType;
    this.languageList = [LOCALE_CS, LOCALE_SK, LOCALE_EN].sort((a, b) => {
      if (a.code === environment.language && b.code) return -1;
      return 1;
    });
    this.lang = this.appServ.locale.code;
    this.links = this.getLinks();
    this.cartVisible = this.userType === 0 && !['/ap1', '/rp1', '/pm1'].some(str => window.location.pathname.includes(str));
    this.selectedPage = this.getSelectedPage();
  }

  private loadTheme() {
    this.takeCareOfOldThemeStorages();
    const bg = this.storageServ.getItem('pilThmBg');
    if (bg) {
      this.bg = bg;
      this.themeIcon = bg === 'light' ? 'nightlight_round' : 'wb_sunny';
      window.document.body.classList.add(bg);
    } else {
      const dark = window.matchMedia('(prefers-color-scheme: dark)').matches;
      if (dark) {
        window.document.body.classList.add('dark');
        this.bg = 'dark';
        this.themeIcon = 'wb_sunny';
      } else {
        this.bg = 'light';
        this.themeIcon = 'nightlight_round';
      }
    }

    const cl = this.storageServ.getItem('pilThmCl');
    if (cl) {
      this.cl = cl;
      window.document.body.classList.add(cl);
    } else {
      this.cl = 'orange';
      window.document.body.classList.add('orange');
    }

  }

  private takeCareOfOldThemeStorages() {
    const strg = this.storageServ.getItem('pilThm');
    if (['d', 'dark-theme'].includes(strg)) {
      this.storageServ.setItem('pilThmBg', 'dark');
      this.storageServ.setItem('pilThmCl', 'orange');
    } else if (strg === 'l') {
      this.storageServ.setItem('pilThmBg', 'light');
      this.storageServ.setItem('pilThmCl', 'orange');
    }
    this.storageServ.removeItem('pilThm');
  }

  private getLinks(): LINKS {
    return {
      unauth: [
        { icon: null, link: '/auto', text: $localize`Auto` },
        { icon: null, link: '/majetek', text: $localize`Majetek` },
        { icon: null, link: '/uraz_a_nemoc', text: $localize`Úraz a nemoc` },
        { icon: null, link: '/kontakty', text: $localize`Škody a kontakty` }
      ],
      auth: [
        { icon: 'icon-zpravy', link: '/workflow', text: $localize`Zprávy` },
        { icon: 'icon-smlouva', link: '/smlouvy', text: $localize`Smlouvy` },
        { icon: 'icon-penezenka', link: '/konto', text: $localize`Peněženka` },
      ],
      agent: [
        { icon: 'icon-domu', link: '/portal-home', text: $localize`Domů` },
        { icon: 'icon-kalkulace', link: '/portal-calculation', text: $localize`Kalkulace` },
        { icon: 'icon-smlouva', link: '/portal-policies', text: $localize`Smlouvy` },
        { icon: 'icon-fyzicka_osoba', link: '/portal-clients', text: $localize`Klienti` },
      ],
      kliserv: [
        { icon: 'icon-domu', link: '/portal-home', text: $localize`Domů` },
        { icon: 'icon-kalkulace', link: '/portal-calculation', text: $localize`Kalkulace` },
        { icon: 'icon-smlouva', link: '/portal-policies', text: $localize`Smlouvy` },
        { icon: 'icon-fyzicka_osoba', link: '/portal-clients', text: $localize`Klienti` },
        { icon: 'icon-zpravy', link: '/portal-chat', text: 'Chat' },
      ]
    };
  }

  private getSelectedPage(): string {
    const nm = { 0: 'unauth', 1: 'auth', 2: 'agent', 3: 'kliserv' }[this.userType];
    const pathName = window.location.pathname;
    for (const obj of this.links[nm]) {
      if (pathName.includes(obj.link)) return obj.link;
    }
    return '';
  }

  private setSubs(): void {
    this.subs.push(this.router.events.subscribe(ev => {
      if (ev instanceof NavigationEnd) {
        this.mini = false;
        this.selectedPage = ev.urlAfterRedirects;
        const userType = this.authServ.currentUserValue.userType;
        this.cartVisible = userType === 0 &&
          !['/ap1', '/rp1', '/pm1'].some(str => window.location.pathname.includes(str));
        this.simplified = this.userType === 0;
      }
    }));
    this.subs.push(this.amISmall.subscribe(mini => {
      this.mini = mini;
    }));
    this.subs.push(this.authServ.currentUserSubject.subscribe(val => {
      this.userType = val.userType;
      if (this.simplified && this.userType !== 0) this.simplified = false;
    }));
  }

  protected onGetLinks() {
    if (this.userType === 0) return this.links.unauth;
    if (this.userType === 1) return this.links.auth;
    if (this.userType === 2) return this.links.agent;
    if (this.userType === 3) return this.links.kliserv;
  }

  protected onGoHome(): void {
    if (this.userType === 1) {
      this.router.navigate(['/smlouvy']);
    } else if (this.userType > 1) {
      this.router.navigate(['/portal-home']);
    } else {
      const domain = window.location.hostname.replace(/portal[-.]+/, '');
      window.location.href = `https://${domain}`;
    }
  }

  protected onGo2(link: string): void {
    this.router.navigate([link]);
  }

  protected onGoCalcs() {
    const domain = window.location.hostname.replace(/portal[-.]+/, '');
    const party = this.authServ.currentUserValue?.party;
    const isCs = party?.companyIdentification === 'PILLOW_CZ' || environment.language === 'cs';
    window.location.href = `https://${domain}/` + (isCs ? 'kalkulace/' : 'kalkulacka/');
  }

  protected onGo2SpecialOfferLink(): void {
    const _link = this.userType > 1 ? '/portal-home' : '/kalkulace';
    if (_link === '/kalkulace') {
      const domain = window.location.hostname.replace(/portal[-.]+/, '');
      window.location.href = `https://${domain}/` + (environment.language === 'cs' ? 'kalkulace/' : 'kalkulacka/');
    } else {
      this.router.navigate([_link]);
    }
  }

  protected onGo2Calamity(): void {
    window.location.href = `https://blog.pillow.cz/jak-postupovat-pri-povodni-vichrici-krupobiti-a-dalsich-kalamitach/`;
  }

  protected onGetUserName(): string {
    const user = this.authServ.currentUserValue.party;
    return UtilsService.getPartyNameAsString(user);
  }

  protected changeLocale(lang: string): void {
    if (this.lang === lang || ['4400', '4600'].includes(window.location.port)) return;
    let path = window.location.pathname;
    if (this.lang !== environment.language) path = path.substring(this.lang.length + 1);

    if (lang !== environment.language) {
      path = '/' + lang + path;
    }

    window.location.pathname = path;
    this.lang = lang;
    const locales = {
      cs: LOCALE_CS,
      sk: LOCALE_SK,
      en: LOCALE_EN
    };
    this.appServ.locale = locales[lang];
  }

  protected logout() {
    this.authServ.logout();
  }

  protected onMenuClick() {
    this.mn?.nativeElement?.classList?.toggle('change');
  }

  protected onSetTheme(event: Event, cls: string): void {
    event.stopPropagation();
    const classes = Array.from(window.document.body.classList);
    const pCl = classes.find(cl => ['teal', 'green', 'blue', 'purple', 'lime', 'pink'].includes(cl)) || 'orange';

    if (cls !== pCl) window.document.body.classList.remove(pCl);

    // if (currentClass) window.document.body.classList.remove(currentClass);
    if (cls) window.document.body.classList.add(cls);
    this.cl = cls;
    this.storageServ.setItem('pilThmCl', cls);
  }

  protected onGetThemeSwitcherStyle(theme: { background: string, color: string, class: string }): string {
    return `background: linear-gradient(130deg, ${this.clObj[this.bg]} 50%, ${theme.color} 55%)`;
  }

  protected onSetSelectedPage(link: string) {
    this.selectedPage = link;
  }

  protected onSetBg(event: Event, bg: string) {
    event.stopPropagation();
    this.bg = bg;
    const classes = Array.from(window.document.body.classList);
    const bgCl = classes.find(cl => ['light', 'dark', 'darker'].includes(cl));
    if (bg !== bgCl) window.document.body.classList.remove(bgCl);
    if (bg) window.document.body.classList.add(bg);

    this.storageServ.setItem('pilThmBg', bg);
  }

  protected onToggleTheme() {
    if (this.themeIcon === 'wb_sunny') {
      window.document.body.classList.remove('darker');
      window.document.body.classList.remove('dark');
      window.document.body.classList.add('light');
      this.storageServ.setItem('pilThmBg', 'light');
      this.bg = 'light';
      this.themeIcon = 'nightlight_round';
    } else {
      window.document.body.classList.remove('light');
      window.document.body.classList.add('dark');
      this.storageServ.setItem('pilThmBg', 'dark');
      this.bg = 'dark';
      this.themeIcon = 'wb_sunny';
    }
  }

  protected onHideBanner() {
    this.bannerHidden = true;
  }

  protected toggleMenuDelayed(trigger: MatMenuTrigger): void {
    setTimeout(() => {
      trigger.toggleMenu();
    }, 200);
  }

  protected menuOpened(trigger: MatMenuTrigger): void {
    trigger[`_overlayRef`]._pane.querySelector('[tabindex="0"]').focus();
  }

  ngOnDestroy(): void {
    this.subs?.forEach(sub => sub?.unsubscribe());
  }

}
