import { Component } from '@angular/core';

import { AppService } from 'src/app/services/app.service';
import { NgIf } from '@angular/common';
import { LoadingScreenDefaultComponent } from './loading-screen-default/loading-screen-default.component';


@Component({
    selector: 'app-loading-screen',
    templateUrl: './loading-screen.component.html',
    styleUrls: ['./loading-screen.component.scss'],
    imports: [NgIf, LoadingScreenDefaultComponent]
})
export class LoadingScreenComponent {

  constructor(
    public appService: AppService
  ) {}
}
