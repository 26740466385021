import { AbstractControl, ValidatorFn } from '@angular/forms';

export const disallowedCharactersValidators = {
    disallowedCharacters(reg: RegExp): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (control?.value && reg.test(control.value)) {
                const output = String(control.value).replace(reg, '');
                control.setValue(output);
            }
            return null;
        };
    }
};
