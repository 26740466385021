import { TDate } from '../models/date.model';
import { TBigDecimal } from '../models/number.model';
import { TsXmlParParty } from './tsXmlParParty.model';
import { TsXmlParVoucher } from './tsXmlParVoucher.model';
import { TsXmlParAp1Vehicle } from './tsXmlParAp1Vehicle.model';
import { TsXmlParAp1Demands } from './tsXmlParAp1Demands.model';
import { TsXmlParAp1DiscountReq } from './tsXmlParAp1DiscountReq.model';
import { TsXmlParAp1VariantOfSubjectReq } from './tsXmlParAp1VariantOfSubjectReq.model';


export class TsXmlParAp1SavePolicyReq {
  accountNumber: string;
  agentId1: string;
  agentId2: string;
  agentsDiscount: TBigDecimal;
  bankCode: string;
  calculationId: string;
  createPolicy: boolean;
  demands: TsXmlParAp1Demands;
  discount: TsXmlParAp1DiscountReq[];
  emailForSendingOffer: string;
  externalId: string;
  firstPaymentMethod: string;
  greenCard: boolean;
  municipalityCode: string;
  paymentFrequency: '1' | '2' | '4' | '12';
  paymentMethod: string;
  policyBeginning: TDate;
  policyHolder: TsXmlParParty;
  policyHolderSameAsVehicleHolder: boolean;
  policyHolderSameAsVehicleOwner: boolean;
  variantOfSubject: TsXmlParAp1VariantOfSubjectReq[];
  vehicle: TsXmlParAp1Vehicle;
  vehicleHolder: TsXmlParParty;
  vehicleOwner: TsXmlParParty;
  voucher: TsXmlParVoucher;

  constructor() {
    this.policyHolder = new TsXmlParParty();
    this.vehicleHolder = new TsXmlParParty();
    this.vehicleOwner = new TsXmlParParty();
    this.vehicle = new TsXmlParAp1Vehicle();
    this.demands = new TsXmlParAp1Demands();
  }
}
