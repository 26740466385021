import { Injectable } from '@angular/core';

import { Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';

import { RestService } from './rest.service';
import { UtilsService } from './utils.service';
import { MyErrorHandler } from './error.service';
import { AuthenticationService } from './authentication.service';

import { TsXmlUid } from '../tsXml/tsXmlUid.model';
import { TsXmlParPolicy } from '../tsXml/tsXmlParPolicy.model';
import { TsXmlRestError } from '../tsXml/tsXmlRestError.model';
import { TsXmlParReducedPolicyList } from '../tsXml/tsXmlParReducedPolicyList.model';


@Injectable({
    providedIn: 'root'
})

export class PolicyService2 {
    private uidStr: string;
    private details: {
        [id: string]: TsXmlParPolicy | Promise<TsXmlParPolicy>
    } = {};

    constructor(
        private authServ: AuthenticationService,
        private errorHandler: MyErrorHandler,
        private restServ: RestService,
    ) {
        this.uidStr = this.authServ.currentUserUidValue?.uid;
    }

    private deleteDetailsForNewUser(uid: TsXmlUid): void {
        if (this.uidStr !== uid?.uid) {
            Object.keys(this.details).forEach(key => delete this.details[key]);
            this.uidStr = uid?.uid;
        }
    }

    public requestPolicies(
        params: {
            offset?: number;
            limit?: number;
            states?: string[];
            products?: string[];
            beginsFrom?: Date;
            beginsTo?: Date;
            sortBy?: string[];
            objId?: number;
            id?: string;
            context?: string;
            objIdParty?: number;
        },
        subs?: { [key: string]: Subscription }
    ): Promise<TsXmlParReducedPolicyList> {
        return new Promise((resolve, reject) => {
            const uid = this.authServ.currentUserUidValue;
            this.deleteDetailsForNewUser(uid);
            this.restServ.parPoliciesGet(params, uid, subs).then((data: TsXmlParReducedPolicyList) => {
                const policies: TsXmlParReducedPolicyList = UtilsService.convertAllJsonDateStrings(data);
                resolve(policies);
            }).catch(error => {
                reject(this.errorHandler.handleError(error));
            });
        });
    }

    public getPolicyDetail(id: string, subs?: { [key: string]: Subscription }): Promise<TsXmlParPolicy> {
        const uid = this.authServ.currentUserUidValue;
        this.deleteDetailsForNewUser(uid);

        if (this.details[id]) {
            return this._getExistingDetail(id);
        }

        const key = this.restServ.getSubsKey(subs);
        this.details[id] = new Promise((resolve, reject) => {
            const sub = this.restServ._parPolicyIdGet(id, uid).subscribe({
                next: (data: TsXmlParPolicy) => {
                    if (subs) delete subs[key];
                    const detail = new TsXmlParPolicy(data);
                    resolve(detail);
                },
                error: error => {
                    if (subs) delete subs[key];
                    const output = this._getError(error, 'getPolicyDetail');
                    reject(output);
                }
            });
            if (subs) subs[key] = sub;
        });
        return this._getExistingDetail(id);
    }

    private _getError(error: HttpErrorResponse, funName: string): TsXmlRestError {
        error[<any>'message'] = `function: ${funName};` + error.message;
        const err = error.error.runtimeMsgs;
        const ignores = err?.length && err[0]?.msgCode === '621577' ? 400 : null;
        return this.errorHandler.handleErrorIgnoreBugReportForHttpErrorStatus(error, ignores);
    }

    private async _getExistingDetail(id: string): Promise<TsXmlParPolicy> {
        if (this.details[id] instanceof Promise) {
            try {
                const detail = await (this.details[id] as Promise<TsXmlParPolicy>);
                this.details[id] = detail;
                return detail;
            } catch (e) {
                delete this.details[id];
                throw new Error(e);
            }
        } else {
            return this.details[id];
        }
    }

    public forgetPolicy(id: string) {
        if (this.details[id]) delete this.details[id];
    }

    public getCorrectMoney(premium: string, frequency: string): number {
        let output = +premium;
        switch (frequency) {
            case '0':
                output *= 1;
                break;
            case '1':
                output *= 12;
                break;
            case '2':
                output *= 6;
                break;
            case '4':
                output *= 3;
                break;
            case '12':
                output *= 1;
                break;
            default:
                return;
        }
        return output;
    }

    public frequency(n: string): string {
        if (!n) {
            return n;
        }
        switch (n) {
            case '0':
                return $localize`jednorázově`;
            case '1':
                return $localize`ročně`;
            case '2':
                return $localize`pololetně`;
            case '4':
                return $localize`čtvrtletně`;
            case '12':
                return $localize`měsíčně`;
        }
    }

    public getProduct(policy: TsXmlParPolicy): string {
        if (policy.vehicle) {
            if (policy.risks[0].id.endsWith('1')) return 'AP1';
            if (policy.risks[0].id.endsWith('2')) return 'AP2';
        }
        if (policy.property) return 'PM1';
        if (policy.person) return 'RP1';
    }
}
