import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private _timeout: boolean;
  public get timeout(): boolean {
    return this._timeout;
  }
  public set timeout(value: boolean) {
    this._timeout = value;
  }
}
