import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  Component, ElementRef, EventEmitter, HostBinding, Inject, Input,
  OnDestroy, OnInit, Optional, Output, Renderer2, Self, ViewChild
} from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormField, MatFormFieldControl, MAT_FORM_FIELD } from '@angular/material/form-field';

import { Subject, Subscription } from 'rxjs';
import { NgClass, NgIf } from '@angular/common';
import { InputDirective } from '../../directives/input.directive';


@Component({
    selector: 'app-num-input',
    templateUrl: './num-input.component.html',
    styleUrls: ['./num-input.component.scss'],
    providers: [{ provide: MatFormFieldControl, useExisting: NumInputComponent }],
    imports: [
        NgClass,
        FormsModule,
        InputDirective,
        ReactiveFormsModule,
        NgIf,
    ]
})
export class NumInputComponent implements ControlValueAccessor, OnInit, OnDestroy, MatFormFieldControl<number> {
  static nextId = 0;
  @Input() numOfDecimals: number = 0;
  @Input() unit: string;
  @Input() unitSub: string;
  @Input() maxLength: number;
  @Input() nullAsA: string;
  @Input() zeroIsNullToo: boolean;
  @Input() iWantIt2BRight: boolean;
  @Input() unitAlwaysVisible: boolean;
  @Output() blurEvent: EventEmitter<number> = new EventEmitter();

  @ViewChild('inputRef', { static: true }) inputRef: ElementRef<HTMLInputElement>;


  numInputCtrl: FormControl<number>;

  private onChange: (num: number) => void;
  private onTouched: () => void;
  focused = false;
  unitText: string;
  _amIDisabled: boolean;
  private subs: Subscription[] = [];
  private touched: boolean;

  @Input()
  get value(): number {
    return this.numInputCtrl.value;
  }

  set value(val: number) {
    this.numInputCtrl.setValue(val);
    this.stateChanges.next();
  }

  constructor(
    private renderer: Renderer2,
    private _elementRef: ElementRef<HTMLElement>,
    private _focusMonitor: FocusMonitor,
    @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
    @Optional() @Self() public ngControl: NgControl
  ) {
    this.numInputCtrl = new FormControl();
    ngControl.valueAccessor = this;
    this.stateChanges = new Subject<void>();
  }
  id = `num-input-${NumInputComponent.nextId++}`;
  stateChanges: Subject<void>;
  @Input()
  get placeholder() {
    return this._placeholder;
  }
  set placeholder(plh) {
    this._placeholder = plh;
    this.stateChanges.next();
  }
  private _placeholder: string;
  get empty() {
    return !this.numInputCtrl.value;
  }
  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }
  @Input()
  get required() {
    return this._required as any;
  }
  set required(req) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }
  private _required: string | boolean = false;
  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    if (this._disabled) {
      this.ngControl.control.disable();
      this.numInputCtrl.disable();
    } else {
      this.numInputCtrl.enable();
      this.ngControl.control.enable();
    }
    this.stateChanges.next();
  }
  private _disabled = false;
  get errorState(): boolean {
    return !this.ngControl.control.valid && this.ngControl.control.touched;
  }

  controlType = 'num-input';
  autofilled?: boolean;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('aria-describedby') userAriaDescribedBy: string;
  setDescribedByIds(ids: string[]): void {
    const controlElement = this._elementRef?.nativeElement?.querySelector('.numeric');
    controlElement.setAttribute('aria-describedby', ids.join(' '));
  }
  onContainerClick(event: MouseEvent) {
    if ((event.target as Element).tagName.toLowerCase() != 'input') {
      this._elementRef?.nativeElement?.querySelector('input').focus();
    }
  }

  ngOnInit() {
    if (this.iWantIt2BRight) {
      this.renderer.setStyle(this.inputRef?.nativeElement, 'textAlign', 'right');
    }

    if (this.unit === $localize`let`) {
      this.subs.push(this.numInputCtrl.valueChanges.subscribe(val => {
        this.stateChanges.next();
        if (+val === 1) {
          this.unit = $localize`rok`;
          this.unitText = this.unit;
        } else if (+val < 5 && +val > 1) {
          this.unit = $localize`roky`;
          this.unitText = this.unit;
        } else {
          this.unit = $localize`let`;
          this.unitText = this.unit;
        }
      }));
    } else {
      this.unitText = this.unit;
    }
    setTimeout(() => {
      this.subs.push(this.numInputCtrl.valueChanges.subscribe(val => {
        if (!val) val = val === 0 ? 0 : null;
        else val = +val;
        this.onChange(val);
      }));
      this._disabled = this.ngControl.control.disabled;
    });
  }

  writeValue(obj: any): void {
    if (!obj && (obj !== 0 || this.zeroIsNullToo) && !this.focused) {
      if (this.numInputCtrl !== null) this.numInputCtrl.setValue(null);
      this.unitText = this.nullAsA;
    } else {
      if (this.numInputCtrl !== obj) this.numInputCtrl.setValue(obj);
      this.unitText = this.unit;
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this._disabled = isDisabled;
    this._amIDisabled = isDisabled;
    if (isDisabled) this.numInputCtrl.disable();
    else this.numInputCtrl.enable();
    this.numInputCtrl.updateValueAndValidity();
    this.stateChanges.next();
  }

  doInput() {
    this.onChange(this.numInputCtrl.value);
  }

  doBlur() {
    this.focused = false;
    if (!this.numInputCtrl.value) {
      this.unitText = this.nullAsA || null;
    } else {
      this.unitText = this.unit || null;
    }
    this.onTouched();
    this.blurEvent.emit(this.numInputCtrl.value);
  }

  onFocusIn() {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
    this.unitText = this.unit;
  }

  onFocusOut(event: FocusEvent) {
    if (!this._elementRef?.nativeElement?.contains(event.relatedTarget as Element)) {
      this.touched = true;
      this.focused = false;
      this.onTouched();
      this.stateChanges.next();
    }
  }

  public focus(): void {
    this.inputRef.nativeElement.focus();
  }

  ngOnDestroy() {
    this.subs?.forEach(sub => sub?.unsubscribe());
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

}
