import { TDateD } from "../models/date.model";

export class TsXmlRESEntry {
  /** ičo */
  ico?: string;
  /** položka okreslau - identifikátor záznamu číselníku 976 */
  districtId?: string;
  /** datum vzniku */
  established?: TDateD;
  /** datum ukončení */
  ended?: TDateD;
  /** položka zpzan - identifikátor záznamu číselníku 977 */
  endTypeId?: string;
  /** datum aktualizace */
  updated?: TDateD;
  /** položka forma - identifikátor záznamu 978 */
  statisticLegalFormId?: string;
  /** identifikátor záznamu číselníku 979 */
  personRegistryLegalFormId?: string;
  /** položka katpo - identifikátor záznamu číselníku 980 */
  employeeCountCategoryId?: string;
  /** položka nace - identifikátor záznamu číselníku 981 */
  activityTypeId?: string;
  /** položka iczuj - identifikátor záznamu číselníku 982 */
  areaIdentifierId?: string;
  /** položka firma - název firmy */
  companyName?: string;
  /** položka ciss2010 - kód instituálního sektoru identifikátor záznamu číselníku 983 */
  esa2010CodeId?: string;
  /** oložka kodadm */
  isuiAddressCode?: string;
  /** položka textadr - adresa textově pokud není adresní bod */
  addressText?: string;
  /** položka psc - psč */
  postCode?: string;
  /** položka obec_text - obec */
  town?: string;
  /** položka cobce_text - část obce */
  townPart?: string;
  /** položka ulice_text - název ulice */
  street?: string;
  /** položka typcdom - typ čísla domu identifikátor záznamu číselníku 984 */
  landRegistryNrTypeId?: string;
  /** položka cdom - číslo popisné */
  landRegistryNr?: string;
  /** položka cor - číslo orientační */
  houseNr?: string;
  /** položka obdobi - datum platnosti */
  period?: TDateD;
  /** položka priznak - příznak z číselníku RES */
  flag?: string;

  constructor (res: TsXmlRESEntry) {
    this.ico = res.ico;
    this.districtId = res.districtId;
    if (typeof res.established === 'string') this.established = new TDateD(new Date(res.established));
    if (typeof res.ended === 'string') this.ended = new TDateD(new Date(res.ended));
    this.endTypeId = res.endTypeId;
    if (typeof res.updated === 'string') this.updated = new TDateD(new Date(res.updated));
    this.statisticLegalFormId = res.statisticLegalFormId;
    this.personRegistryLegalFormId = res.personRegistryLegalFormId;
    this.employeeCountCategoryId = res.employeeCountCategoryId;
    this.activityTypeId = res.activityTypeId;
    this.areaIdentifierId = res.areaIdentifierId;
    this.companyName = res.companyName;
    this.esa2010CodeId = res.esa2010CodeId;
    this.isuiAddressCode = res.isuiAddressCode;
    this.addressText = res.addressText;
    this.postCode = res.postCode;
    this.town = res.town;
    this.townPart = res.townPart;
    this.street = res.street;
    this.landRegistryNrTypeId = res.landRegistryNrTypeId;
    this.landRegistryNr = res.landRegistryNr;
    this.houseNr = res.houseNr;
    if (typeof res.period === 'string') this.period = new TDateD(new Date(res.period));
    this.flag = res.flag;
  }
}
