import { Injectable } from '@angular/core';

@Injectable()
export class LostPasswordService {
  public STEPS: { DONE: boolean } = {
    DONE: false,
  };

  email: string;
  emailCode: string;
  anonymizedPhone: string;
  smsCode: string;

  resetSteps() {
    this.STEPS.DONE = false;
    this.emailCode = null;
    this.anonymizedPhone = null;
    this.smsCode = null;
  }
}
