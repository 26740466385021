import { TsXmlBt } from './tsXmlBt.model';
import { TDate } from '../models/date.model';
import { TsXmlFile } from './tsXmlFile.model';
import { TsXmlDocumentKey } from './tsXmlDocumentKey.model';

export class TsXmlDocumentWithContent {
  contentLength: number;
  correspRequestObjId: number;
  created: TDate;
  defId: string;
  defName: string;
  defObjId: number;
  entities: TsXmlBt[];
  fileName: string;
  files: TsXmlFile[];
  fileType: string;
  identification: string;
  keys: TsXmlDocumentKey[];
  name: string;
  note: string;
  objId: number;
  objVerze: number;

  constructor(dc?: TsXmlDocumentWithContent) {
    this.contentLength = dc?.contentLength;
    this.correspRequestObjId = dc?.correspRequestObjId;
    this.created = dc?.created ? new TDate(new Date(dc.created)) : dc?.created;
    this.defId = dc?.defId;
    this.defName = dc?.defName;
    this.defObjId = dc?.defObjId;
    this.entities = dc?.entities;
    this.fileName = dc?.fileName;
    this.files = dc?.files;
    this.fileType = dc?.fileType;
    this.identification = dc?.identification;
    this.keys = dc?.keys;
    this.name = dc?.name;
    this.note = dc?.note;
    this.objId = dc?.objId;
    this.objVerze = dc?.objVerze;
  }
}
