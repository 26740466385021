import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root',
})
export class StorageService {
    constructor(
    ) { }

    public setItem(item: 'newsHidden' | 'pilThmBg' | 'pilThmCl', value: string) {
        if (!item || !value || typeof value !== 'string') throw new Error('Wrong input for setStorage');
        localStorage.setItem(item, value);
    }

    public removeItem(item: string) {
        localStorage.removeItem(item);
    }

    public getItem(item: string) {
        return localStorage.getItem(item);
    }

    public clear() {
        ['newsHidden', 'pilThmBg', 'pilThmCl'].forEach(key => {
            localStorage.removeItem(key);
        });
    }

}
