
export class TsXmlBankAccount {
  /** VerzeFinUctuBanka.ucet */
  accountNumber: string;
  /** VerzeFinUctuBanka.banka.identifikatorZaznamu */
  bank: string;
  /** VerzeFinUctuBanka.iban */
  iban: string;
  /** měna (mena.identifikatorZaznamu (JCBH 42)) */
  currency: string;
  /** VerzeFinUctuBanka.bicSwift. */
  bic: string;

  constructor(bank?: TsXmlBankAccount) {
    this.accountNumber = bank?.accountNumber ?? null;
    this.bank = bank?.bank ?? null;
    this.iban = bank?.iban ?? null;
    this.currency = bank?.currency ?? null;
    this.bic = bank?.bic ?? null;
  }
}
