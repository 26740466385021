import { TDate } from '../models/date.model';
import { TBigDecimal } from '../models/number.model';

/**
 * DTO represent policy risk/subject
 */
export class TsXmlParInsuranceRisk {
  /** Identifikator rizika/predmetu */
  readonly id: string;
  /** Priznak, zdali je sjednano */
  active: boolean;
  /** Varianta eq. A,B,C, pokud je evidovana, pro nesjednane nastavena na defaultni */
  variant: string;
  /** Pripustne varianty */
  possibleVariants: string[];
  /** Limit (pojistna castka) z pripustnych limitu pokud je sjednano nebo defaultne prednastaveno na default pro nesjednane */
  limit: string;
  /** Pripustne limity / pojistne castky */
  possibleLimits: string[];
  /** Spoluucast z pripustnych */
  deductible: string;
  /** Pripustne spoluucasti */
  readonly possibleDeductibles: string[];
  /** Identifikator dokumentu s pojistnymi podminkami */
  readonly insuranceConditions: string;
  /** Pojistne */
  readonly premium: number;
  /** Platnost rizika do (jenom u produktu RP1) */
  end: TDate;

  sumInsured: string;

  riskAllowed: boolean;

  /** lhutni pojistne na polštář */
  termPremium: TBigDecimal; // TFinSet
  /** Hodnota slevy (koeficient 1.0 = 100%). */
  retentionDiscount: number; // TDec8x6Null
  /** Pole id polštářů/rizik, které musí být sjednány s tímto rizikem. */
  readonly requiredRisks: string[]; // TStr20[]

  constructor(risk?: TsXmlParInsuranceRisk) {
    this.id = risk?.id;
    this.active = risk?.active;
    this.variant = risk?.variant;
    this.possibleVariants = risk?.possibleVariants;
    this.limit = risk?.limit;
    this.possibleLimits = risk?.possibleLimits;
    this.deductible = risk?.deductible;
    this.possibleDeductibles = risk?.possibleDeductibles;
    this.insuranceConditions = risk?.insuranceConditions;
    this.premium = risk?.premium;
    this.end = risk?.end ? new TDate(new Date(risk.end)) : risk?.end;
    this.sumInsured = risk?.sumInsured;
    this.riskAllowed = risk?.riskAllowed;
    this.termPremium = risk?.termPremium;
    this.retentionDiscount = risk?.retentionDiscount;
    this.requiredRisks = risk?.requiredRisks;
  }
}
