import { TDate } from '../models/date.model';
import { TsXmlParAddress } from './tsXmlParAddress.model';
import { TsXmlBankAccount } from './tsXmlBankAccount.model';
import { TsXmlDocumentWithContent } from './tsXmlDocumentWithContent.model';

export class TsXmlParParty {
  /** ObjId */
  objId: number;
  /** Typ strany.
   * "FORC" Fyzická osoba s rodným číslem,
   * "FODN" Fyzická osoba bez rodného čísla, cudzinec s datumom narodenia,
   * "PO" Právnická osoba,
   * "OSVC" OSVČ,
   * "AGENT" poradce ziskatel
   */
  partyType: 'FORC' | 'FODN' | 'PO' | 'OSVC' | 'AGENT' | 'ZIS';
  /** IČO. */
  companyIdentification: string;
  /** Název společnosti. */
  companyName: string;
  /** Rodné číslo. */
  personalIdentification: string;
  /** Kód. */
  code: string;
  /** Jméno. */
  forename: string;
  /** Příjmení. */
  surname: string;
  /** Titul před jménem. */
  titleBefore: string;
  /** Titul za jménem. */
  titleAfter: string;
  /** Datum narození. */
  birthdate: TDate;
  /** Email. */
  email: string;
  /** Telefon. */
  phone: string;
  /** Příznak: marketingové souhlasy. */
  marketingApproval: boolean;
  /** Příznak: marketingové souhlasy (PONUKA). */
  marketingApprovalOffer: boolean;
  /** Trvalá adresa/sídlo. */
  address: TsXmlParAddress;
  /** Korespondenční adresa. */
  mailingAddress: TsXmlParAddress;
  /** Číslo účtu Inkaso. */
  bankAccount: TsXmlBankAccount;
  /** Kam chodí info (DI na strane s id "KAM_INFO" - Kam chodí informace ("M" - mail, "P"  - portál). */
  infoWay: 'P' | 'M';
  /** Profilová fotografie klienta. */
  profilePicture: TsXmlDocumentWithContent;
  trusteePersonForename: string;
  trusteePersonSurname: string;
  salutation: string;
  insuranceCompany?: 'PILLOW_SK' | 'PILLOW_CZ'; // TStr20Null

  constructor(party?: TsXmlParParty) {
    this.address = new TsXmlParAddress();
    this.mailingAddress = new TsXmlParAddress();

    this.objId = party?.objId;
    this.partyType = party?.partyType;
    this.companyIdentification = party?.companyIdentification;
    this.companyName = party?.companyName;
    this.personalIdentification = party?.personalIdentification;
    this.code = party?.code;
    this.forename = party?.forename;
    this.surname = party?.surname;
    this.titleBefore = party?.titleBefore;
    this.titleAfter = party?.titleAfter;
    this.birthdate = party?.birthdate ? new TDate(new Date(party.birthdate)) : party?.birthdate;
    this.email = party?.email;
    this.phone = party?.phone;
    this.marketingApproval = party?.marketingApproval;
    this.marketingApprovalOffer = party?.marketingApprovalOffer;
    this.address = new TsXmlParAddress(party?.address);
    this.mailingAddress = new TsXmlParAddress(party?.mailingAddress);
    this.bankAccount = party?.bankAccount ? new TsXmlBankAccount(party.bankAccount) : null;
    this.infoWay = party?.infoWay;
    this.profilePicture = party?.profilePicture ? new TsXmlDocumentWithContent(party.profilePicture) : party?.profilePicture;
    this.trusteePersonForename = party?.trusteePersonForename;
    this.trusteePersonSurname = party?.trusteePersonSurname;
    this.salutation = party?.salutation;
    this.insuranceCompany = party?.insuranceCompany;
  }
}
