import { Component, Inject, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogActions } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { BehaviorSubject, Subscription } from 'rxjs';
import { NgIf } from '@angular/common';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { YouTubePlayer } from '@angular/youtube-player';
import { LoadingScreenDefaultComponent } from '../../loading-screen/loading-screen-default/loading-screen-default.component';

@Component({
    templateUrl: 'dialog-detail.component.html',
    styleUrls: ['dialog-detail.component.scss'],
    imports: [
        NgIf,
        MatDialogActions,
        NgxExtendedPdfViewerModule,
        YouTubePlayer,
        LoadingScreenDefaultComponent,
    ]
})
export class DialogDetailComponent implements OnDestroy {
  currentFile: SafeResourceUrl;
  openableBlobTypeBoolean = false;
  currentFileType: string;
  isLoaded = false;
  blob: Blob | string;
  fileName: string;
  iOS: boolean;
  iOSChrome: boolean;

  subs1: Subscription;


  constructor(
    private sanitizer: DomSanitizer,
    public dialogRef: MatDialogRef<DialogDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      blob: BehaviorSubject<Blob> | Blob,
      fileName?: string,
      video?: {
        videoId: string,
        width: number,
        height: number
      }
    }
  ) {
    this.iOS = !!navigator.userAgent.match('FxiOS') ||
      !!navigator.userAgent.match(/iPad/i) ||
      !!navigator.userAgent.match(/iPhone/i) ||
      !!navigator.userAgent.match('CriOS');
    this.iOSChrome = !!navigator.userAgent.match('CriOS');
    if (this.data.fileName) {
      this.fileName = this.data.fileName;
    }
    if (this.data && this.data.blob && this.data.blob instanceof Blob) {
      this.blob = this.data.blob;
      this.refresh();
    } else if (this.data && this.data.blob) {
      this.subs1 = (this.data.blob as BehaviorSubject<Blob>).subscribe(blob => {
        if (blob) {
          this.blob = blob;
          this.refresh();
        }
      });
    } else if (this.data && this.data instanceof Blob) {
      this.blob = this.data;
      this.refresh();
    }
  }

  private refresh() {
    const blobType = (this.blob as Blob).type;

    this.currentFile = this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(this.blob as any));
    this.openableBlobTypeBoolean = true;
    if (blobType === 'application/pdf') {
      this.currentFileType = 'pdf';
    } else if (blobType.includes('image/')) {
      this.currentFileType = 'image';
    } else if (blobType.includes('video/')) {
      this.currentFileType = 'video';
    } else {
      this.openableBlobTypeBoolean = false;
    }
  }

  open() {
    if (navigator.userAgent.match(/iPad/i) || navigator.userAgent.match(/iPhone/i)) { //Safari & Opera iOS
      const url = window.URL.createObjectURL(this.blob as Blob);
      window.open(url, '_blank');
    } else {
      const fileURL = URL.createObjectURL(this.blob as Blob);
      window.open(fileURL, '_blank');
    }
  }

  download() {
    (document.querySelector('#download') as any).click();
  }

  iOSChromeDownloadPdf() {
    const reader = new FileReader();
    reader.onloadend = function () {
      window.open(reader.result as any, '_blank');
    };
    reader.readAsDataURL(this.blob as Blob);
  }

  close() {
    this.dialogRef.close();
  }

  ngOnDestroy() {
    if (this.subs1) {
      this.subs1.unsubscribe();
    }
    this.blob = null;
  }
}
