import { TsXmlContactPointPhone } from './tsXmlContactPointPhone.model';
import { TsXmlContactPointAddress } from './tsXmlContactPointAddress.model';

/** Struktura reprezentující Stranu. */
export class TsXmlParCreateOperator {
    /** Typ strany:
     * „FORC“ Fyzická osoba s rodným číslem
     * „FODN“ Fyzická osoba bez rodného čísla, cudzinec s datumom narodenia,
     * „PO“ Právnická osoba,
     * „OSVC“ OSVČ
     * „AGENT“ Poradce - uložen s druhen identifikace strany = ZIS
     */
    partyType: string;
    /** Strana."aktualnePlatnaVerze".identifikace
     * (v pripade partyType "FODN" bude do tejto polozky ulozeny datum narodenia vo formate yyyyMMdd)
     */
    id: string;
    nextId: string;
    forename: string;
    /** Strana."aktualnePlatnaVerze".prijmeni */
    surname: string;
    /** Datum narození. */
    birthdate: Date;
    email: string;
    phone: TsXmlContactPointPhone;
    address: TsXmlContactPointAddress;
    /** Autorizacny kod pre email. */
    authCodeEmail: string;
    /** Autorizacny kod pre sms. */
    authCodeSms: string;
    login: string;
    password: string;
    companyName: string;
    insuranceCompany: 'PILLOW_CZ' | 'PILLOW_SK';
}
