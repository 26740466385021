import { UtilsService } from '../services/utils.service';
import { TBigDecimal } from '../models/number.model';

export class TsXmlParAp1VariantOfSubjectReq {
  /** Atribut Risk. */
  risk: string;
  /** Atribut Variant. */
  variant: string;
  /** Atribut TermPremium. */
  termPremium?: TBigDecimal;
  /** Atribut KmPremium. */
  kmPremium?: TBigDecimal;

  /*
  public fromXmlV2(model: TsXmlParAp1VariantOfSubjectReq) {
    this.risk = unescape(model.risk);
    this.variant = unescape(model.variant);
    this.termPremium = UtilsService.getNumber(model.termPremium);
    this.kmPremium = UtilsService.getNumber(model.kmPremium);
  }*/

  /**
   * Metoda prevede instanci do XML V2.
   *
   * @param attributes xml attributes.
   * @return String xml.
   */
  public toXmlV2?(attributes: string): string {
    let result = '<xml-par-ap1-variant-of-subject-req';
    if (attributes) {
      result += ' ' + attributes;
    }
    result += '>';

    if (this.risk != null) {
      result += '<risk>';
      result += UtilsService.xmlEscape(this.risk);
      result += '</risk>';
    }
    if (this.variant != null) {
      result += '<variant>';
      result += UtilsService.xmlEscape(this.variant);
      result += '</variant>';
    }
    if (this.termPremium != null) {
      result += '<termPremium>';
      result += this.termPremium;
      result += '</termPremium>';
    }
    if (this.kmPremium != null) {
      result += '<kmPremium>';
      result += this.kmPremium;
      result += '</kmPremium>';
    }

    result += '</xml-par-ap1-variant-of-subject-req>';

    return result;
  }
}
